import { SignatureCompleteEvent, DocuSignEvent } from "#/lib/carrot-api/signatureClient"
import { DocumentAction } from "./DocumentStatusApi"

export const determineDocumentAction = (event: SignatureCompleteEvent): DocumentAction => {
  switch (event.data.event) {
    case DocuSignEvent.SigningComplete:
      return "SIGNED"
    case DocuSignEvent.Decline:
      return "DECLINED"
    default:
      return "SKIPPED"
  }
}
