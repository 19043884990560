import React, { useState, useEffect } from "react"
import { useStripe } from "@stripe/react-stripe-js"
import PaymentStatus from "./PaymentStatus"
import { Box, Progress } from "@carrotfertility/carotene-core"

type InboundMemberPaymentProps = {
  paymentIntentClientSecret: any
}

const InboundMemberPaymentStatusMessage = ({ paymentIntentClientSecret }: InboundMemberPaymentProps) => {
  const stripe = useStripe()
  const [paymentConfirmationInfo, setPaymentConfirmationInfo] = useState(null)

  useEffect(() => {
    if (!stripe) {
      return
    }

    if (!paymentIntentClientSecret) {
      return
    }

    stripe.retrievePaymentIntent(paymentIntentClientSecret).then(({ paymentIntent }) => {
      setPaymentConfirmationInfo(
        <PaymentStatus
          amount={paymentIntent.amount}
          currency={paymentIntent.currency}
          status={paymentIntent.status}
          description={paymentIntent.description}
          email={paymentIntent.receipt_email}
        />
      )
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps -- See https://carrotfertility.atlassian.net/wiki/spaces/PE/pages/2050295461/Remove+Build+Warnings#react-hooks%2Fexhaustive-deps
  }, [stripe])

  return paymentConfirmationInfo ? (
    <>{paymentConfirmationInfo}</>
  ) : (
    <Box display="flex" justifyContent="center" alignItems="center">
      <Progress />
    </Box>
  )
}

export default InboundMemberPaymentStatusMessage
