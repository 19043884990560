import React from "react"
import useUpdateBankDetails from "./hooks/useUpdateBankDetails"
import { useCurrentUser } from "#/components/context/user/UserContext"
import { FormattedMessage, useIntl } from "react-intl"
import { bankDetailTitleMessages } from "./BankDetailTitleMessages"
import { bankDetailValidationMessages } from "./BankDetailValidationMessages"
import { bankDetailDropdownMessages } from "./BankDetailDropdownMessages"
import { BankDetailsData } from "#/lib/carrot-api/types/Bank"
import { JsonSchemaForm } from "./json-schema-form/form"
import { Box, Button, Link, Progress, Typography } from "@carrotfertility/carotene-core"

interface CountrySpecificBankDetailsProps {
  schema: any
  data: BankDetailsData
  isLoading: boolean
}

const CountrySpecificBankDetails = (props: CountrySpecificBankDetailsProps) => {
  const { schema, data } = props
  const { mutate } = useUpdateBankDetails()
  const { isUsa } = useCurrentUser()
  const [formData, setFormData] = React.useState({})
  const [showForm, setShowForm] = React.useState(false)
  const [selectedBankCountry, setSelectedBankCountry] = React.useState()
  const intl = useIntl()

  const uiSchema = {
    bankCountry: { props: { flex: "1 / 2" } },
    bankName: { props: { flex: "1 / 2" } }
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'values' implicitly has an 'any' type.
  function handleOnSubmit(values): void {
    const dataToUpdate = {}

    for (const key in values.formData) {
      if (!values.formData[key]?.includes("*")) {
        // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        dataToUpdate[key] = values.formData[key]
      }
    }

    setFormData(values.formData)
    mutate(dataToUpdate)
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'formData' implicitly has an 'any' type.
  function clearBankCountryData(formData): void {
    const { bankCountry, nameOnAccount } = formData

    const clearedFormData = {
      bankCountry,
      nameOnAccount
    }
    setFormData(clearedFormData)
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'values' implicitly has an 'any' type.
  function handleOnChange(values): void {
    const { formData } = values
    const isNewCountrySelected =
      formData?.bankCountry !== data.bankCountry && selectedBankCountry !== formData?.bankCountry

    if (isNewCountrySelected) {
      setSelectedBankCountry(formData?.bankCountry)
      clearBankCountryData(formData)
    }
  }

  React.useEffect(() => {
    if (data) {
      setFormData(data)
      setShowForm(true)
    }
  }, [data])

  return (
    <Box
      sx={{ display: "flex", flexDirection: "column", blockSize: "100%", maxInlineSize: { xs: "100%", lg: "650px" } }}
    >
      <Typography>
        {isUsa ? (
          <FormattedMessage
            defaultMessage="To help us reimburse you as quickly as possible, please check your banking details 
                        each time you submit a request."
          />
        ) : (
          <FormattedMessage
            defaultMessage="To help us reimburse you as quickly as possible, please check your banking details 
                        each time you submit a request. <link>Learn which fields are required.</link>"
            values={{
              link: (linkContent) => (
                <Link color="inherit" target="_blank" href="https://get-carrot.zendesk.com/hc/articles/9760524807963">
                  {linkContent}
                </Link>
              )
            }}
          />
        )}
      </Typography>

      {schema && (
        <Box sx={{ paddingBlock: (theme) => theme.tokens.spacing.xxl }}>
          {showForm ? (
            <JsonSchemaForm
              schema={schema}
              uiSchema={uiSchema}
              formData={formData}
              // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
              overrideTitle={(messageKey) => intl.formatMessage(bankDetailTitleMessages[messageKey])}
              overrideSelectLabel={(fieldName, labelValue) => {
                if (fieldName === "bankCountry") {
                  return intl.formatDisplayName(labelValue.toString(), { type: "region" })
                }
                // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
                return intl.formatMessage(bankDetailDropdownMessages[`${fieldName}_${labelValue}`])
              }}
              overrideErrorMessage={(messageKey, vars) =>
                // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
                intl.formatMessage(bankDetailValidationMessages[messageKey], vars)
              }
              onSubmit={handleOnSubmit}
              onChange={handleOnChange}
              usePlaceholders={true}
            >
              <Box
                sx={{ paddingBlock: (theme) => theme.tokens.spacing.md, display: "flex", justifyContent: "flex-end" }}
              >
                <Button type="submit">
                  <FormattedMessage defaultMessage="Submit" />
                </Button>
              </Box>
            </JsonSchemaForm>
          ) : (
            <Progress />
          )}
        </Box>
      )}
    </Box>
  )
}

export default CountrySpecificBankDetails
