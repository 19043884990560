import { Checkbox, FormControl, FormControlLabel, Stack, useTheme } from "@carrotfertility/carotene-core"
import React from "react"
import { FormCheckbox } from "@carrotfertility/carotene-core-x"
import {
  useGetPartnerEmailOptIn,
  useUpdatePartnerEmailOptIn,
  useUserRole
} from "#/components/partner-access/hooks/usePartnerAccess"
import { useIntl } from "react-intl"

export function EmailCommunicationSettings({ isForPartner }: { isForPartner?: boolean }): JSX.Element {
  const theme = useTheme()
  const { isPartnerAccessingAccount } = useUserRole()
  const intl = useIntl()

  return (
    <Stack spacing={theme.spacing(theme.tokens.spacing.lg)}>
      {isForPartner ? (
        <PartnerEmailOptInSettings />
      ) : (
        <FormCheckbox
          disabled={isPartnerAccessingAccount}
          name="emailOptIn"
          label={intl.formatMessage({
            defaultMessage: "Email"
          })}
        />
      )}
    </Stack>
  )
}

function PartnerEmailOptInSettings(): JSX.Element {
  const { partnerEmailOptIn } = useGetPartnerEmailOptIn()
  const { mutate: updatePartnerEmailOptIn } = useUpdatePartnerEmailOptIn()
  const intl = useIntl()

  if (partnerEmailOptIn == null) {
    return null
  }

  return (
    <FormControl>
      <FormControlLabel
        label={intl.formatMessage({
          defaultMessage:
            "Receive updates about your benefit, such as new features and partnerships, as well as member-only expert resources."
        })}
        control={
          <Checkbox
            aria-labelledby={"partnerEmailOptIn-label"}
            id={"PartnerEmailOptIn"}
            onChange={(e) => updatePartnerEmailOptIn(e.target.checked)}
            checked={partnerEmailOptIn}
          />
        }
      />
    </FormControl>
  )
}
