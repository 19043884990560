import React, { ReactNode } from "react"
import { RawContentfulConditionSkeleton, toCondition } from "../utils/conditionUtils"
import { useConditionGate } from "#/services/contentful/conditional-gate"
import { EntryWithoutUnresolvableLinks } from "#/utils/ContentTypes"

type ContentfulConditionalGateProps = {
  children: ReactNode
  conditions: EntryWithoutUnresolvableLinks<RawContentfulConditionSkeleton>[]
  allowConditionsOverride?: boolean
}

type UseContentfulConditionalGateArgs = Array<{
  element: React.ReactNode
  conditions?: EntryWithoutUnresolvableLinks<RawContentfulConditionSkeleton>[]
  allowConditionsOverride?: boolean
}>

export function useContentfulConditionalGate(
  componentsAndConditions: UseContentfulConditionalGateArgs,
  allowConditionsOverride = false
): Array<React.ReactNode> {
  const filterNoConditions = componentsAndConditions.map((componentAndCondition) => ({
    ...componentAndCondition,
    conditions: componentAndCondition?.conditions
      ? componentAndCondition?.conditions.map(toCondition).filter(Boolean)
      : []
  }))
  return useConditionGate(filterNoConditions, allowConditionsOverride)
}

function ContentfulConditionalGate({
  children,
  conditions,
  allowConditionsOverride = false
}: ContentfulConditionalGateProps): JSX.Element {
  const [child] = useContentfulConditionalGate([{ element: children, conditions }], allowConditionsOverride)
  return child ? <>{child}</> : null
}

export { ContentfulConditionalGate }
