import React, { useContext } from "react"
import { FormattedMessage } from "react-intl"
import { Alert, DialogContentText, DialogTitle, Link } from "@carrotfertility/carotene-core"
import { Form, FormOnSubmitHandler } from "@carrotfertility/carotene-core-x"
import { useStateMachine } from "#/components/context/stateMachine/StateMachineV2"
import RequestCardWrapper from "#/pages/request-carrot-card-flow/requestCardWrapper"
import { InternationalAddress, InternationalAddressFields } from "#/services/common-forms"
import { reportError } from "#/utils/ErrorReporting"
import { RequestCardContext } from "#/pages/request-carrot-card-flow"
import { TtcPaths } from "../../../utils/Paths"
import { useCurrentUser } from "#/components/context/user/UserContext"
import { useMutation } from "@tanstack/react-query"
import { carrotClient } from "#/utils/CarrotClient"
import { MemberInternationalAddress } from "#/lib/carrot-api/types/Address"

export function RequestCardInternationalAddress() {
  const { canGoBack, send } = useStateMachine(null)
  const showInformationScreen = useContext(RequestCardContext).showInformationScreen
  const { employeeId, internationalCountry, internationalAddressLine, city, state, zip, refreshUserInfo } =
    useCurrentUser()

  const updateInternationalAddress = useMutation({
    mutationFn: (addressFields: MemberInternationalAddress) => carrotClient.updateMemberCardAddress(addressFields),
    onSuccess: () => {
      refreshUserInfo()
      send("")
    }
  })

  const onSubmit: FormOnSubmitHandler<InternationalAddressFields> = async (fieldValues) => {
    try {
      await updateInternationalAddress.mutateAsync({
        ...fieldValues,
        internationalCountry: fieldValues.internationalCountry.value
      })
    } catch (error) {
      error.message =
        `Error for employeeId: "${employeeId}" while confirming Carrot Card® international address: RAW:` +
        error.message
      reportError(error)
    }
  }
  return (
    <Form onSubmit={onSubmit}>
      <RequestCardWrapper showBackButton={canGoBack || showInformationScreen} showContinueButton>
        <DialogTitle>
          <FormattedMessage defaultMessage="Confirm your address" />
        </DialogTitle>
        <DialogContentText marginBottom={(theme) => theme.tokens.spacing.xl}>
          <FormattedMessage defaultMessage="Please confirm or update your address to receive a Carrot Card." />
        </DialogContentText>
        <InternationalAddress defaultValues={{ internationalAddressLine, internationalCountry, city, state, zip }} />
        {updateInternationalAddress.isError && (
          <Alert severity="error">
            <FormattedMessage
              defaultMessage="An unexpected error occurred. Please <link>contact us</link> for assistance."
              values={{
                link: (linkContent) => (
                  <Link color="inherit" href={TtcPaths.TTC_CARE_NAV} target={"_blank"}>
                    {linkContent}
                  </Link>
                )
              }}
            />
          </Alert>
        )}
      </RequestCardWrapper>
    </Form>
  )
}
