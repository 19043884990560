import React from "react"
import { Button, DialogContentText } from "@carrotfertility/carotene-core"
import { useHistory } from "react-router-dom"
import { CarrotRoute } from "#/utils/CarrotUrls"
import { FormattedMessage } from "react-intl"
import { DialogActionsBar, DialogTitleImage, DialogTitleWithImage } from "@carrotfertility/carotene-core-x"

export function GetReimbursedDoIvfRequirement(): JSX.Element {
  const history = useHistory()
  const onExit = () => {
    history.push(CarrotRoute.IvfRequirements)
  }

  return (
    <>
      <DialogTitleWithImage
        image={
          <DialogTitleImage
            width="100%"
            maxWidth="228px"
            src={`/images/illo-claim.png`}
            srcSet={`/images/illo-claim-2x.png`}
          />
        }
      >
        <FormattedMessage defaultMessage="Complete your IVF requirements" />
      </DialogTitleWithImage>
      <DialogContentText>
        <FormattedMessage defaultMessage="Fulfill your requirements now so that we can complete your reimbursement as quickly as possible." />
      </DialogContentText>
      <DialogActionsBar
        primaryAction={
          <Button variant="contained" color="primary" size="medium" onClick={onExit}>
            <FormattedMessage defaultMessage="Complete requirements" />
          </Button>
        }
      />
    </>
  )
}
