import * as Sentry from "@sentry/browser"
import { ScopeContext } from "@sentry/types"
import Settings from "./CarrotConfig"
import { regexes } from "./Regexes"
import { determineDevicePlatform } from "#/services/tracking"

const URL_REGEX_LOOKUP = {
  ONE_TRUST: /^https:\/\/cdn\.cookielaw\.org/,
  LOCAL_FILE: /^file:\/\/\//
}

const initializeErrorReporting = (): void => {
  Sentry.init({
    dsn: Settings.SENTRY_URL,
    maxValueLength: 500,
    initialScope: {
      tags: {
        "Build-Number": process.env.REACT_APP_VERSION || "local",
        "device.platform": determineDevicePlatform()
      }
    },
    denyUrls: [URL_REGEX_LOOKUP.ONE_TRUST, URL_REGEX_LOOKUP.LOCAL_FILE],
    ignoreErrors: ["Object Not Found Matching Id"],
    beforeSend(event) {
      if (event.request?.url) {
        event.request.url = cleanQueryString(event.request.url)
      }

      // Group together certain errors by adding a fingerprint to the Sentry data.
      // https://blog.sentry.io/2018/01/18/setting-up-custom-fingerprints
      //
      // Unfortunately this callback only has access to the error message and stack trace, not extra fields.
      // So we have to regex match either the message or stack trace to determine the error type.
      const exception = event.exception
      // if the error has a `cause`, there will be more than 1 value. We don't want to group in that case.
      if (exception?.values?.length === 1) {
        const errorMessage = exception.values[0].value
        const sentryGroupMessages = [
          // Session expired errors
          "Provided credentials not valid or not sufficient to fulfill this request",
          // Fetch / network errors
          "Failed to fetch",
          "Network request failed",
          "NetworkError when attempting to fetch resource",
          // No origin (happens on IE11)
          "No origin header was found in the request",
          // Reimbursement upload failure
          "Unexpected end of JSON input"
        ]
        sentryGroupMessages.forEach((sentryGroupMessage) => {
          if (errorMessage.match(sentryGroupMessage)) {
            event.fingerprint = [`Type: ${sentryGroupMessage}`]
          }
        })
      }
      return event
    }
  })
}

function cleanErrorMessage(message: string): string {
  return message.replace(regexes.email, "EMAIL_REMOVED")
}

function cleanQueryString(url: string): string {
  const replacedUrl = url.replace(/\+/g, "%2B") // this will keep the + in the query string after decoding.
  let scrubbedUrl = new URL(replacedUrl).href?.split("?")[0] + "?"

  new URL(replacedUrl).searchParams.forEach(function (val, key) {
    const scrubbedVal = cleanErrorMessage(val)
    scrubbedUrl = `${scrubbedUrl}${key}=${scrubbedVal}&`
  })

  return scrubbedUrl.slice(0, -1)
}

const reportErrorMessage = (errorMessage: string, sentryContext?: Partial<ScopeContext>): string =>
  Sentry.captureException(new Error(cleanErrorMessage(errorMessage)), sentryContext)

const reportError = (error: any, extra?: Record<string, string>): void => {
  const extraData: { extra?: Record<string, string> } = {}
  if (extra) {
    extraData.extra = extra
  }
  if (error.correlationId !== undefined) {
    Sentry.captureException(error, {
      ...extraData,
      tags: { correlationId: error.correlationId, "Correlation-Id": error.correlationId }
    })
  } else {
    Sentry.captureException(error, extraData)
  }
}

export { initializeErrorReporting, reportErrorMessage, reportError, cleanQueryString }
