import { useQuery, UseQueryResult, useQueryClient } from "@tanstack/react-query"
import ResponseError from "../../../types/responseError"
import { HttpErrors } from "#/utils/HttpErrors"
import { useAuthentication } from "#/components/context/authentication/AuthenticationProvider"
import { UserConditionSubset } from "#/lib/carrot-api/types/UserInfo"
import { carrotClient } from "#/utils/CarrotClient"

const ENDPOINT = "GetUserInfoConditionsSubset"

const loadUserInfo = async (handleSessionExpiration: () => void) => {
  try {
    return carrotClient.getCurrentUserConditionsSubset()
  } catch (error) {
    if (error.name === HttpErrors.UNAUTHORIZED || error.name === HttpErrors.FORBIDDEN) {
      handleSessionExpiration()
    }
    return null
  }
}

export const getUserInfoConditionSubsetCacheKey = (): (string | number)[] => [ENDPOINT]

export default function useGetUserInfoConditionsSubset(): UseQueryResult<UserConditionSubset, ResponseError> {
  const { handleSessionExpiration } = useAuthentication()
  return useQuery<UserConditionSubset, ResponseError>(getUserInfoConditionSubsetCacheKey(), () =>
    loadUserInfo(handleSessionExpiration)
  )
}

export function useCreateOverride(): (attributes: UserConditionSubset) => void {
  const queryClient = useQueryClient()
  return (attributes: UserConditionSubset) => {
    queryClient.setQueryDefaults(getUserInfoConditionSubsetCacheKey(), { staleTime: Infinity, cacheTime: Infinity })
    queryClient.setQueryData(getUserInfoConditionSubsetCacheKey(), attributes)
  }
}
