import { useCurrentUser } from "#/components/context/user/UserContext"
import { useFlags } from "launchdarkly-react-client-sdk"
import React from "react"
import { useJourney } from "./useJourney"
import useCarrotPlanEmployeeSettings from "./useCarrotPlanEmployeeSettings"

type UseJourneyChange = {
  shouldShowEngagedJourneyDisplay: boolean
  shouldShowEnrolledJourneyDisplay: boolean
  journeyChangeDataIsLoaded: boolean
  isJourneyComplete: boolean
  resolvedJourney: string | null
  carrotPlanIsOnTheWay: boolean
  appIsUnlockedAndPendingCarrotPlan: boolean
}

export function useJourneyChange(): UseJourneyChange {
  const { isInAppLockdown } = useCurrentUser()
  const { journeyDataIsLoaded, isJourneyComplete, memberHasJourneyType, resolvedJourney } = useJourney()
  const { enrolledJourneySelectionHomePageDisplay, engagedJourneySelectionHomePageDisplay } = useFlags()
  const {
    data: carrotPlanEmployeeSettings,
    isLoading: isLoadingEmployeeSettings,
    isError: isErrorEmployeeSettings,
    appIsUnlockedAndPendingCarrotPlan
  } = useCarrotPlanEmployeeSettings()
  const [shouldShowEngagedJourneyDisplay, setShouldShowEngagedJourneyDisplay] = React.useState(false)
  const [shouldShowEnrolledJourneyDisplay, setShouldShowEnrolledJourneyDisplay] = React.useState(false)
  const [carrotPlanIsOnTheWay, setCarrotPlanIsOnTheWay] = React.useState(false)

  React.useEffect(() => {
    if (!journeyDataIsLoaded) return

    const isCarrotPlanOnTheWay =
      !isLoadingEmployeeSettings &&
      !isErrorEmployeeSettings &&
      !isJourneyComplete &&
      (carrotPlanEmployeeSettings?.initialCarrotPlanChatRequested || appIsUnlockedAndPendingCarrotPlan)

    const userIsEngaged =
      engagedJourneySelectionHomePageDisplay && !isInAppLockdown && !isJourneyComplete && memberHasJourneyType
    const userIsEnrolled = enrolledJourneySelectionHomePageDisplay && (isInAppLockdown || isJourneyComplete)

    setCarrotPlanIsOnTheWay(isCarrotPlanOnTheWay)
    setShouldShowEngagedJourneyDisplay(userIsEngaged)
    setShouldShowEnrolledJourneyDisplay(userIsEnrolled && !carrotPlanIsOnTheWay)
  }, [
    journeyDataIsLoaded,
    isJourneyComplete,
    memberHasJourneyType,
    engagedJourneySelectionHomePageDisplay,
    isInAppLockdown,
    enrolledJourneySelectionHomePageDisplay,
    isLoadingEmployeeSettings,
    isErrorEmployeeSettings,
    carrotPlanEmployeeSettings?.initialCarrotPlanChatRequested,
    appIsUnlockedAndPendingCarrotPlan,
    carrotPlanIsOnTheWay
  ])

  return {
    shouldShowEnrolledJourneyDisplay,
    shouldShowEngagedJourneyDisplay,
    journeyChangeDataIsLoaded: journeyDataIsLoaded,
    isJourneyComplete,
    resolvedJourney,
    carrotPlanIsOnTheWay,
    appIsUnlockedAndPendingCarrotPlan
  }
}
