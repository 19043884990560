import React from "react"
import { useStateMachine } from "#/components/context/stateMachine/StateMachineV2"
import useAboutYou from "#/components/carrot-plans/hooks/useAboutYou"
import { AboutYou } from "#/types/carrotPlanTypes"
import { FormattedMessage } from "react-intl"
import { Box, DialogContentText, DialogTitle, Progress } from "@carrotfertility/carotene-core"
import { Form, DialogActionsBar, FormOnSubmitHandler } from "@carrotfertility/carotene-core-x"
import { ArrowSubmitButton } from "../shared/layouts"
import { InternationalAddress, InternationalAddressFields } from "#/services/common-forms"

function canSkip(data: AboutYou): boolean {
  return (
    Boolean(data?.address?.internationalAddressLine) &&
    Boolean(data?.address?.internationalCountry) &&
    Boolean(data?.address?.city) &&
    Boolean(data?.address?.state)
  )
}

export const InternationalAddressUpdate = (): JSX.Element => {
  const { data, isLoading, updateAboutYou } = useAboutYou()
  const { send } = useStateMachine(canSkip(data) ? "" : null)

  const onSubmit: FormOnSubmitHandler<InternationalAddressFields> = async (data): Promise<void> => {
    await updateAboutYou({
      address: {
        address1: null,
        address2: null,
        city: data?.city,
        state: data?.state,
        zip: data?.zip,
        internationalCountry: data?.internationalCountry?.value,
        internationalAddressLine: data?.internationalAddressLine
      }
    })
    send("")
  }
  if (isLoading) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center" }}>
        <Progress />
      </Box>
    )
  }
  return (
    <>
      <DialogTitle>
        <FormattedMessage defaultMessage="Confirm your address" />
      </DialogTitle>
      <DialogContentText paddingBottom={(theme) => theme.spacing(theme.tokens.spacing.lg)}>
        <FormattedMessage defaultMessage="Please confirm or update your address so that we can reimburse you." />
      </DialogContentText>
      <Form onSubmit={onSubmit}>
        <InternationalAddress
          defaultValues={{
            city: data?.address?.city,
            state: data?.address?.state,
            zip: data?.address?.zip,
            internationalCountry: data?.address?.internationalCountry,
            internationalAddressLine: data?.address?.internationalAddressLine
          }}
        />
        <DialogActionsBar
          primaryAction={
            <ArrowSubmitButton>
              <FormattedMessage defaultMessage="Continue" />
            </ArrowSubmitButton>
          }
        />
      </Form>
    </>
  )
}
