import { useMutation, UseMutationResult } from "@tanstack/react-query"
import ResponseError from "#/types/responseError"
import buildCarrotApiRetryPolicy from "#/utils/CarrotApiRetryPolicy"
import { carrotClient } from "#/utils/CarrotClient"

const queryKey = "getPantoken"

export interface AirwallexPantokenResponse {
  expires_at: string
  token: string
  card_id: string
  status_code: string
  sca_session_code: string
}

const request = async (scaToken: string): Promise<AirwallexPantokenResponse> => {
  const token = await carrotClient.createAirwallexPanToken(scaToken)
  return token.json()
}

export default function useCreateAirwallexPanToken(): UseMutationResult<AirwallexPantokenResponse, ResponseError> {
  return useMutation([queryKey], request, {
    retry: buildCarrotApiRetryPolicy(3),
    cacheTime: 0
  })
}
