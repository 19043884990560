import EmployeeOnlyModalContainer from "../../employee-only-containers/EmployeeOnlyModalContainer"
import ActionModal from "../../views/modal/ActionModal"
import { Spacer } from "../../views/atoms/Atoms"
import React from "react"
import { Body } from "../../views/atoms/Bodies"
import { Button } from "../../views/atoms/buttons/Button"
import { ButtonPrimary } from "../../views/atoms/buttons/ButtonPrimary"
import { CircleImage } from "../../views/atoms/images/CircleImage"
import { useHistory } from "react-router-dom"
import { H2 } from "@carrotfertility/carotene"
import { FormattedMessage, useIntl } from "react-intl"

// @ts-expect-error TS(7031) FIXME: Binding element 'onClose' implicitly has an 'any' ... Remove this comment to see the full error message
export const UpdateBankInfoModal = ({ onClose, onNavigateToAccountPage }) => {
  const history = useHistory()

  const handleGoToBankDetailsClick = () => {
    onNavigateToAccountPage && onNavigateToAccountPage()
    history.push("/bank-details")
  }
  const intl = useIntl()

  return (
    <EmployeeOnlyModalContainer onClose={onClose}>
      {/* @ts-expect-error TS(2322) FIXME: Type '{ children: Element; onExit: any; titleText:... Remove this comment to see the full error message */}
      <ActionModal onExit={onClose} titleText={intl.formatMessage({ defaultMessage: "Please update your bank info" })}>
        <div className="w-100 flex flex-column items-center ph4-l ph3-m ph35-s">
          <div className="ph2-ns w-100 flex flex-column items-center">
            <div className="mw-968-l mw-600-m w-100">
              <div className="mw6">
                <CircleImage
                  className="mt25-l mt35"
                  size={88}
                  padding={14}
                  backgroundColor="porcelain"
                  iconName="icn-dollar-sign-large"
                />
                <Spacer height={1.5} />
                <H2 variant="primary">
                  <FormattedMessage defaultMessage="Please update your bank info" />
                </H2>
                <Spacer height={0.375} />
                <Body>
                  <FormattedMessage defaultMessage="Before you can submit a reimbursement request, please update your bank info so we can direct the funds to the right account." />
                </Body>
                <hr className="w-100 mv35 center b--black-05" />
                <div className="flex flex-row-ns flex-column">
                  <ButtonPrimary fullWidth onClick={handleGoToBankDetailsClick}>
                    <FormattedMessage defaultMessage="Update now" />
                  </ButtonPrimary>
                  <Spacer width={1} height={1} />
                  <Button outline fullWidth onClick={onClose}>
                    <FormattedMessage defaultMessage="I'll do this later" />
                  </Button>
                </div>
                <Spacer height={1.5} />
              </div>
            </div>
          </div>
        </div>
      </ActionModal>
    </EmployeeOnlyModalContainer>
  )
}
