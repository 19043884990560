import { useMutation, UseMutationResult } from "@tanstack/react-query"
import { InAppMessagingThread, NewThread } from "../inAppMessagingTypes"
import ResponseError from "../../../types/responseError"
import { carrotClient } from "#/utils/CarrotClient"
import { HttpStatusCodes } from "../../../utils/HttpStatusCodes"

export default function useCreateThread(): UseMutationResult<InAppMessagingThread, ResponseError, NewThread> {
  return useMutation<InAppMessagingThread, ResponseError, NewThread>(
    ({ subject, message, viaFollowUpSourceId }: NewThread) =>
      carrotClient.createThread(subject, message, viaFollowUpSourceId),
    {
      retry: (failureCount, error) => {
        switch (error.statusCode) {
          case HttpStatusCodes.UNAUTHORIZED:
          case HttpStatusCodes.BAD_REQUEST:
          case HttpStatusCodes.NOT_FOUND:
            return false
          default:
            return failureCount <= 3
        }
      }
    }
  )
}
