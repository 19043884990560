import React, { useEffect } from "react"
import { useStateMachine } from "../../../context/stateMachine/StateMachineV2"
import { useSelector } from "react-redux"
import { getBenefitConfiguration } from "#/redux/reducers/benefitConfiguration"

function GenderIdentityDecisionStep(): JSX.Element {
  const benefitConfiguration = useSelector(getBenefitConfiguration)
  const lgbtqEnabled = benefitConfiguration.featureConfig.isLgtbqEnabled
  const { send } = useStateMachine(null)
  useEffect(() => {
    send(lgbtqEnabled ? "YES" : "NO")
  })
  return <></>
}

export const AboutYouGenderIdentityDecisionStep = GenderIdentityDecisionStep
export const PartnerGenderIdentityDecisionStep = GenderIdentityDecisionStep
