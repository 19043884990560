import {
  getIssuingRegion,
  IssuingRegion
} from "#/pages/request-carrot-card-flow/steps/AirwallexUserAgreement/agreement-helpers"
import { Steps } from "../steps"
import { Workflows } from "../workflows"
import {
  ActiveStepFunction,
  GetWorkflowFunction,
  InitialStepFunction,
  ShouldSeeUserAgreementFunction,
  TotalStepsFunction
} from "./routing-helper-types"

export const getInitialAirwallexInternationalRequestCardStep: InitialStepFunction = ({ showInformationScreen }) => {
  if (showInformationScreen) return Steps.REQUEST_CARD_INFORMATION
  return Steps.INTERNATIONAL_ADDRESS
}

export const getAirwallexShouldSeeUserAgreement: ShouldSeeUserAgreementFunction = ({ companyCountryCode }) => {
  const issuingRegion = getIssuingRegion(companyCountryCode)
  return issuingRegion !== IssuingRegion.Unknown
}

export const getAirwallexWorkflow: GetWorkflowFunction = () => Workflows.REQUEST_INTERNATIONAL_AIRWALLEX_CARD

export const getTotalStepsAirwallexInternationalRequestCard: TotalStepsFunction = () => {
  return 4
}

export const getActiveStepAirwallexInternationalRequestCard: ActiveStepFunction = (step) => {
  let activeSection = -1
  switch (step) {
    case Steps.INTERNATIONAL_ADDRESS:
      activeSection = 1
      break
    case Steps.CONFIRM_PHONE_NUMBER:
    case Steps.VERIFY_PHONE_NUMBER:
    case Steps.NO_USER_AGREEMENT_REQUEST_CARD:
      activeSection = 2
      break
    case Steps.AIRWALLEX_USER_AGREEMENT:
      activeSection = 3
      break
  }

  return activeSection
}
