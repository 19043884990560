import { useMutation, UseMutationResult, useQuery, useQueryClient, UseQueryResult } from "@tanstack/react-query"
import ResponseError from "#/types/responseError"
import buildCarrotApiRetryPolicy from "#/utils/CarrotApiRetryPolicy"
import { CarrotDocumentType, getAllDocumentStatuses, updateDocumentStatus } from "#/lib/carrot-api/signatureClient"
import { reportErrorMessage } from "#/utils/ErrorReporting"
import { getUserInfoConditionSubsetCacheKey } from "../../../services/user/hooks/useGetCurrentUserConditionsSubset"

export type DocumentAction = "SIGNED" | "SKIPPED" | "DECLINED"

export type DocumentStatusResponse = {
  documentType: CarrotDocumentType
  documentTypeDescription: string
  documentAction: DocumentAction
  timestamp: Date
  isSignatureExpired: boolean
}

export type DocumentStatusRequest = {
  signatureId?: number | null
  documentAction: DocumentAction
}

export type DocumentStatusUpdateRequest = {
  documentType: CarrotDocumentType
  documentStatusRequest: DocumentStatusRequest
}

export function useGetAllDocumentStatuses(): UseQueryResult<DocumentStatusResponse[], ResponseError> {
  return useQuery<DocumentStatusResponse[], ResponseError>(["getAllDocumentStatuses"], () => getAllDocumentStatuses(), {
    refetchOnWindowFocus: false,
    retry: buildCarrotApiRetryPolicy(3),
    onError: (error) => reportErrorMessage(`Failed to fetch document statuses, error: ${error}`)
  })
}

export function useCreateGetAllDocumentStatusesOverride(): (responseOverride: DocumentStatusResponse[]) => void {
  const queryClient = useQueryClient()
  return (responseOverride: DocumentStatusResponse[]) => {
    queryClient.setQueryDefaults(getUserInfoConditionSubsetCacheKey(), { staleTime: Infinity, cacheTime: Infinity })
    queryClient.setQueryData(getUserInfoConditionSubsetCacheKey(), responseOverride)
  }
}

export function useUpdateDocumentStatus(): UseMutationResult<unknown, Error, DocumentStatusUpdateRequest> {
  return useMutation<unknown, Error, DocumentStatusUpdateRequest>(
    ({
      documentType,
      documentStatusRequest
    }: {
      documentType: CarrotDocumentType
      documentStatusRequest: DocumentStatusRequest
    }) => updateDocumentStatus(documentType, documentStatusRequest),
    {
      retry: false
    }
  )
}
