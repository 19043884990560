/* eslint formatjs/no-literal-string-in-jsx: off -- translation planned for Q1 2024. See https://carrotfertility.atlassian.net/wiki/spaces/PROD/pages/2190215916/WS+Support+E2E+Translation */

import React, { useState } from "react"
import { useStripe, useElements, PaymentElement } from "@stripe/react-stripe-js"
import { FlexContainer, Text, Stack } from "@carrotfertility/carotene"
import { useHistory } from "react-router"
import { useStripeContext } from "#/components/context/stripePayments/StripePaymentsContext"
import { getFormattedAmount } from "../utils/currency-helper"
import { Box, Progress, Typography } from "@carrotfertility/carotene-core"

type CheckoutFormProps = {
  paymentIntentAmount: string
  paymentIntentCurrencyCode: string
}

function CheckoutForm({ paymentIntentCurrencyCode, paymentIntentAmount }: CheckoutFormProps): JSX.Element {
  const stripe = useStripe()
  const elements = useElements()
  const history = useHistory()
  const { setSelectedCurrencyCode, setAmount, companyCurrencyCode, companyCountryCode } = useStripeContext()
  const formattedAmount = getFormattedAmount(paymentIntentAmount, paymentIntentCurrencyCode)

  const [errorMessage, setErrorMessage] = useState(null)
  const [hover, setHover] = useState(false)
  const [loading, setLoading] = useState(false)

  const hoverStyle = (): object => {
    if (hover) {
      return { background: "#C97500", cursor: "pointer" }
    } else {
      return { background: "#E48400", cursor: "" }
    }
  }

  const loader = loading ? (
    <Box display="flex" justifyContent="center" alignItems="center">
      <Progress />
    </Box>
  ) : null

  const handleSubmit = async (event: any) => {
    event.preventDefault()
    setLoading(true)

    if (!stripe || !elements) {
      return
    }

    const { error, paymentIntent } = await stripe.confirmPayment({
      elements,
      confirmParams: {},
      redirect: "if_required"
    })
    if (error) {
      setErrorMessage(error.message)
      setLoading(false)
    } else {
      setSelectedCurrencyCode(companyCurrencyCode)
      setAmount(undefined)
      setLoading(false)
      history.push({
        pathname: `/make-a-payment/status`,
        state: { paymentIntentClientSecret: paymentIntent?.client_secret }
      })
    }
  }

  return (
    <>
      {loader}
      <form hidden={loading ? true : false} onSubmit={handleSubmit}>
        <Stack spacing="medium">
          {companyCountryCode === "US" && (
            <Typography>
              Please note that health savings account (HSA) and flexible spending account (FSA) funds can only be used
              for qualified medical expenses. Before using these funds, check your HSA or FSA portal for information on
              eligible expenses and tax implications.
            </Typography>
          )}
          <Typography variant="h6">Your credit card will be charged {formattedAmount}.</Typography>
          <PaymentElement
            id="payment-element"
            options={{
              wallets: { googlePay: "never", applePay: "never" }
            }}
          />
        </Stack>
        {errorMessage && (
          <FlexContainer sx={{ paddingBlockStart: "10px" }}>
            <Text variant="error">x {errorMessage}</Text>
          </FlexContainer>
        )}
        <button
          style={{
            width: 115,
            height: 40,
            marginTop: 25,
            font: "Gotham",
            color: "white",
            // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
            backgroundColor: hoverStyle()["background"],
            borderRadius: 20,
            border: "none",
            fontSize: "13px",
            fontWeight: "bold",
            // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
            cursor: hoverStyle()["cursor"]
          }}
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
          disabled={!stripe}
        >
          Submit
        </button>
      </form>
    </>
  )
}

export default CheckoutForm
