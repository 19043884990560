import React from "react"
import { Spacer } from "./Atoms"

// @ts-expect-error TS(7006) FIXME: Parameter 'element' implicitly has an 'any' type.
const render = (element) => element && (React.isValidElement(element) ? element : React.createElement(element))

// @ts-expect-error TS(7031) FIXME: Binding element 'sideBarElements' implicitly has a... Remove this comment to see the full error message
export const BodyWithLeftSidebarLayout = ({ contentId = "", sideBarElements, children }) => (
  <div id={contentId} className="flex flex-row-l flex-column flex-auto">
    <div className="w-220px-l">
      {/* @ts-expect-error TS(7006) FIXME: Parameter 'sideBar' implicitly has an 'any' type. */}
      {sideBarElements.map((sideBar) => (
        <div key={sideBar.key || ""}>{render(sideBar)}</div>
      ))}
    </div>
    <div className="db-l dn">
      <Spacer width={3} />
    </div>
    <div className="flex flex-auto">{children}</div>
  </div>
)
