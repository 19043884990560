import React from "react"
import useInitialSetup from "#/components/cmd-enrollment/hooks/useInitialSetup"
import { useStateMachine } from "#/components/context/stateMachine/StateMachineContext"
import { Steps } from "#/components/cmd-enrollment/workflow/steps"
import { Container } from "@carrotfertility/carotene"
import { useBenefitEnrollment } from "#/components/context/enrollment/BenefitEnrollmentContext"

function EmptySpace(): JSX.Element {
  // This is to prevent extra flashing of the modal.
  // We might want to look to make a more elegant solution for this in the future.
  return (
    <Container paddingTopBottom="giant">
      <Container paddingTopBottom="giant">
        <Container paddingTopBottom="giant">
          <Container paddingTopBottom="giant">
            <Container paddingTopBottom="medium">{}</Container>
          </Container>
        </Container>
      </Container>
    </Container>
  )
}

export function PartnerShouldShowPlanCoverageDateStep(): JSX.Element {
  const { shouldCollectPartnersCoverageDates } = useBenefitEnrollment()
  const viewName = Steps.PARTNER_SHOULD_SHOW_COLLECT_PLAN_COVERAGE
  const { setNextStep } = useStateMachine(viewName, null)
  useInitialSetup(() => {
    setNextStep(viewName, shouldCollectPartnersCoverageDates)
  })
  return <EmptySpace />
}
