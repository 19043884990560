import React from "react"
import { ArrowSubmitButton, ReimbursementDialogFraming, BackArrow } from "../shared/layouts"
import { Progress } from "#/components/views/modal/Progress"
import { useStateMachine } from "#/components/context/stateMachine/StateMachineV2"
import { useReimbursementContext } from "#/components/context/reimbursement/GetReimbursedContext"
import { FormattedMessage, useIntl } from "react-intl"
import { FormControlLabel, Checkbox, DialogContentText, DialogTitle, Box } from "@carrotfertility/carotene-core"
import { DialogActionsBar } from "@carrotfertility/carotene-core-x"

export const AttestationMedicallyRelated = (): JSX.Element => {
  const { send, back } = useStateMachine()
  const intl = useIntl()

  const { state, updateAttestationIsMedicallyRelated, expenseId } = useReimbursementContext()

  const canSubmit = state.attestationIsMedicallyRelated

  // expenseRelatedTo has been translated before setting state
  const journeyType = state.expenseRelatedTo?.label?.toString().toLocaleLowerCase(intl.locale)

  const handleContinueClick = (): void => {
    send("")
  }

  const goBack = (): void => {
    updateAttestationIsMedicallyRelated(false)
    back()
  }

  const confirmationMessage = expenseId
    ? intl.formatMessage({
        defaultMessage: "We need your confirmation to process your expense in a legally compliant way."
      })
    : intl.formatMessage({
        defaultMessage: "We need your confirmation to process your reimbursement in a legally compliant way."
      })

  return (
    <>
      <Progress
        numSteps={3}
        step={2}
        label={intl.formatMessage({ defaultMessage: "About This Expense" })}
        inactiveStepBackgroundColor="#DBE6EE"
      />
      <BackArrow onClick={goBack} />
      <ReimbursementDialogFraming>
        <DialogTitle>
          <FormattedMessage defaultMessage="Please confirm one thing." />
        </DialogTitle>
        <Box sx={{ display: "flex", flexDirection: "column", gap: (theme) => theme.tokens.spacing.lg }}>
          <DialogContentText>{confirmationMessage}</DialogContentText>
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              alignItems: { xs: "flex-start", md: "center" }
            }}
          >
            <FormControlLabel
              label={
                <FormattedMessage
                  defaultMessage="This is a medical expense related to {journeyType}"
                  values={{
                    journeyType
                  }}
                />
              }
              control={
                <Checkbox
                  value="acknowledged"
                  required={true}
                  checked={state.attestationIsMedicallyRelated}
                  onChange={(e) => {
                    updateAttestationIsMedicallyRelated(e.target.checked)
                  }}
                  data-testid="checkbox-medically-related"
                />
              }
            />
          </Box>
        </Box>
      </ReimbursementDialogFraming>
      <DialogActionsBar
        primaryAction={
          <ArrowSubmitButton enabled={canSubmit} onClick={handleContinueClick}>
            <FormattedMessage defaultMessage="Continue" />
          </ArrowSubmitButton>
        }
      />
    </>
  )
}
