import React, { useEffect } from "react"
import { FormProvider, useForm } from "react-hook-form"
import { Steps } from "../workflow/steps"
import {
  BenefitEnrollmentFlowModalBackButton,
  BenefitEnrollmentModalContinueButton,
  BenefitEnrollmentModalFormRadio
} from "../shared"
import { FormattedMessage, useIntl } from "react-intl"
import { Box, Typography } from "@carrotfertility/carotene-core"
import { useStateMachine } from "../../context/stateMachine/StateMachineContext"
import useSubmitWithErrorCatch from "../hooks/useSubmitWithErrorCatch"
import { carrotClient } from "#/utils/CarrotClient"
import { useProgressTracker } from "../../views/modal/ProgressTracker"

const CurrentFieldName = "areYouEnrolledInMedicare"
const CurrentStepName = Steps.ARE_YOU_ENROLLED_IN_MEDICARE

function AreYouEnrolledInMedicareStep(): JSX.Element {
  const intl = useIntl()
  const formMethods = useForm()
  const { handleSubmit } = formMethods

  const { showProgress } = useProgressTracker()
  useEffect(showProgress, [showProgress])

  const { onSubmit } = useSubmitWithErrorCatch(async (input: string) => {
    if (input === "NO") {
      await carrotClient.deactivateMedicareEnrollment()
    }
    setNextStep(CurrentStepName, input)
  })

  const { setNextStep } = useStateMachine(CurrentStepName)

  return (
    <FormProvider {...formMethods}>
      <Box paddingBottom={(theme) => theme.spacing(theme.tokens.spacing.xl)}>
        <BenefitEnrollmentFlowModalBackButton />
      </Box>
      <Typography
        id="step-heading"
        variant="h2"
        color={(theme) => theme.palette.text.primary}
        paddingBottom={(theme) => theme.tokens.spacing.xs}
      >
        <FormattedMessage defaultMessage="Are you enrolled in Medicare?" />
      </Typography>
      <Box paddingBottom={(theme) => theme.spacing(theme.tokens.spacing.xl)}>
        <Typography color={(theme) => theme.palette.text.secondary}>
          <FormattedMessage
            defaultMessage={
              "We are required to collect and share this information with the Centers for Medicare & Medicaid " +
              "Services. It enables Medicare to correctly pay for health insurance benefits by determining " +
              "primary versus secondary payer responsibility."
            }
          />
        </Typography>
      </Box>
      <Box paddingBottom="8rem">
        <BenefitEnrollmentModalFormRadio
          name={CurrentFieldName}
          aria-labelledby="step-heading"
          options={[
            { label: intl.formatMessage({ defaultMessage: "Yes" }), value: "YES" },
            { label: intl.formatMessage({ defaultMessage: "No" }), value: "NO" }
          ]}
        />
      </Box>
      <BenefitEnrollmentModalContinueButton onClick={handleSubmit((args: any) => onSubmit(args[CurrentFieldName]))} />
    </FormProvider>
  )
}

export { AreYouEnrolledInMedicareStep }
