import React from "react"
import { FormattedMessage } from "react-intl"
import { DbgPaths } from "#/utils/Paths"
import { Box, InternalIcon, Link } from "@carrotfertility/carotene-core"
import CardStates from "#/utils/CardStates"
import { CarrotCardModuleTemplate } from "./carrot-card-module-template"

export function CarrotCardRequestedModule() {
  return (
    <CarrotCardModuleTemplate
      cardState={CardStates.PENDING}
      body={
        <>
          <Box paddingBottom={(theme) => theme.spacing(theme.tokens.spacing.sm)}>
            <FormattedMessage defaultMessage="You'll receive an email when your virtual card is ready." />
          </Box>
          <Box>
            <FormattedMessage defaultMessage="We may contact you if the card issuer needs any additional information." />
          </Box>
        </>
      }
      links={[
        <Link
          key="carrot-card-how-to"
          endIcon={<InternalIcon fontSize="small" color="primary" />}
          href={DbgPaths.CARROT_CARD}
        >
          <FormattedMessage defaultMessage="How to use your Carrot Card" />
        </Link>
      ]}
    />
  )
}
