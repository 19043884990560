import { Box, Button, Dialog, Link, Stack, Typography, useTheme } from "@carrotfertility/carotene-core"
import React from "react"
import { DigitalFrontDoorSectionLayout } from "../../components/DigitalFrontDoorSectionLayout"
import { FormattedMessage, useIntl } from "react-intl"
import { useDigitalFrontDoorBreakpointViews } from "../../hooks/useDigitalFrontDoorBreakpointViews"
import { getEntryById } from "#/lib/contentful/contentfulClient"
import { ContentfulRawMarkdownRenderer } from "../../../../services/contentful/content-renderer"
import { CarrotMemberImageGroup } from "../../components/CarrotMemberImageGroup"
import { SaveOnFertilityMedicationsList } from "../../components/SaveOnFertilityMedicationsList"

function PriceQuotes(): JSX.Element {
  return (
    <Box
      marginTop={(theme) => theme.spacing(theme.tokens.spacing.lg)}
      marginBottom={(theme) => theme.spacing(theme.tokens.spacing.xxxl)}
      bgcolor={(theme) => theme.palette.background.default}
      gap={(theme) => theme.spacing(theme.tokens.spacing.xs)}
      display="flex"
      flexDirection="column"
      borderRadius={(theme) => theme.tokens.borderRadius.md}
      padding={(theme) => theme.spacing(theme.tokens.spacing.md, theme.tokens.spacing.lg)}
    >
      <Typography fontWeight={(theme) => theme.typography.fontWeightMedium}>
        <FormattedMessage defaultMessage="Price quotes" />
      </Typography>
      <Typography>
        <FormattedMessage
          defaultMessage="To check Carrot Rx prices on fertility medications, please complete <link>Alto's price quote request form</link>.{br}{br}You can also call Alto at 844-725-0001 if you have any questions."
          values={{
            br: <br />,
            link: (content) => (
              <Link color="inherit" href="https://www.surveymonkey.com/r/3VHLDLH">
                {content}
              </Link>
            )
          }}
        />
      </Typography>
    </Box>
  )
}

function PriceMatchingButton(): JSX.Element {
  const { isMobileView } = useDigitalFrontDoorBreakpointViews()
  const [show, setShow] = React.useState(false)
  const [dialogContent, setDialogContent] = React.useState("")
  const [dialogTitle, setDialogTitle] = React.useState("")
  const intl = useIntl()
  const { locale } = intl

  getEntryById("7KP0Q0E8IwBfoRFJKtBDSV", locale).then((entry: any) => {
    setDialogContent(entry.fields.content[0].fields.body)
    setDialogTitle(entry.fields.title)
  })

  return (
    <>
      <Dialog open={show} onClose={() => setShow(false)}>
        <Stack gap={(theme) => theme.tokens.spacing.sm}>
          <Typography variant="h2">{dialogTitle}</Typography>
          <ContentfulRawMarkdownRenderer>{dialogContent}</ContentfulRawMarkdownRenderer>
        </Stack>
      </Dialog>

      <Button
        id="extend-price-matching-button"
        variant="outlined"
        fullWidth={false}
        onClick={() => setShow(true)}
        sx={{
          inlineSize: "fit-content",
          background: (theme) => theme.palette.background.paper,
          color: (theme) => theme.palette.text.primary,
          fontSize: isMobileView ? "0.813rem" : "1rem",
          fontWeight: 325
        }}
      >
        <FormattedMessage defaultMessage="Price matching details" />
      </Button>
    </>
  )
}

export function FertilityMedicationSavingSection(): JSX.Element {
  const theme = useTheme()

  const { isUnderXLScreenView } = useDigitalFrontDoorBreakpointViews()

  return (
    <Box id="extend-save-on-fertility-meds">
      <DigitalFrontDoorSectionLayout backgroundColor={theme.palette.background.paper}>
        <Box
          flexDirection={isUnderXLScreenView ? "column-reverse" : "row"}
          display="flex"
          gap={(theme) => theme.spacing(theme.tokens.spacing.xxxl)}
          paddingTop={(theme) => theme.spacing(theme.tokens.spacing.xxxl)}
        >
          <CarrotMemberImageGroup />
          <Box flex={1} flexDirection="column">
            <Typography marginBottom={(theme) => theme.spacing(theme.tokens.spacing.xxl)} variant="h2">
              <FormattedMessage defaultMessage="Save up to 40% on fertility medications" />
            </Typography>
            <SaveOnFertilityMedicationsList />
            <Box
              marginTop={(theme) => theme.spacing(theme.tokens.spacing.lg)}
              marginBottom={(theme) => theme.spacing(theme.tokens.spacing.xxl)}
            >
              <PriceMatchingButton />
            </Box>
            <PriceQuotes />
          </Box>
        </Box>
      </DigitalFrontDoorSectionLayout>
    </Box>
  )
}
