import React, { useEffect } from "react"
import { useStateMachine } from "../../../context/stateMachine/StateMachineV2"
import { useCheckRoutingInfoQueryCache } from "#/components/carrot-plans/shared/useQueryRoutingInfo"

export default function WhatKindOfJourneyFinalDecisionStep(): JSX.Element {
  const routingData = useCheckRoutingInfoQueryCache()
  const journeyValue = routingData?.journey

  const { send } = useStateMachine()
  useEffect(() => {
    send(journeyValue)
  })
  return <></>
}
