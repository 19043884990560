import { defineMessage, MessageDescriptor } from "react-intl"
import { useMemo } from "react"
import { reportErrorMessage } from "../../../utils/ErrorReporting"
import { Benefit } from "#/lib/carrot-api/types/Benefit"

const OPTIONS = [
  {
    label: defineMessage({ defaultMessage: "Adoption" }),
    journeyMessage: defineMessage({ defaultMessage: "You selected adoption as your journey." }),
    value: "ADOPTION"
  },
  {
    label: defineMessage({ defaultMessage: "Gestational surrogacy" }),
    journeyMessage: defineMessage({
      defaultMessage: "You selected gestational surrogacy as your journey."
    }),
    value: "GESTATIONAL"
  },
  {
    label: defineMessage({ defaultMessage: "Fertility preservation (e.g., freezing eggs, sperm, or embryos)" }),
    journeyMessage: defineMessage({
      defaultMessage: "You selected fertility preservation (e.g., freezing eggs, sperm, or embryos) as your journey."
    }),
    shortLabel: defineMessage({ defaultMessage: "Fertility preservation" }),
    shortJourneyMessage: defineMessage({
      defaultMessage: "You selected fertility preservation as your journey."
    }),
    value: "PRESERVATION"
  },
  {
    label: defineMessage({ defaultMessage: "Assisted reproduction (e.g., IUI or IVF)" }),
    journeyMessage: defineMessage({
      defaultMessage: "You selected assisted reproduction (e.g., IUI or IVF) as your journey."
    }),
    shortLabel: defineMessage({ defaultMessage: "Assisted reproduction" }),
    shortJourneyMessage: defineMessage({
      defaultMessage: "You selected assisted reproduction as your journey."
    }),
    value: "ASSISTED_REPRODUCTION"
  },
  {
    label: defineMessage({ defaultMessage: "Trying to get pregnant (e.g., timed intercourse)" }),
    journeyMessage: defineMessage({
      defaultMessage: "You selected trying to get pregnant (e.g., timed intercourse) as your journey."
    }),
    shortLabel: defineMessage({ defaultMessage: "Trying to get pregnant" }),
    shortJourneyMessage: defineMessage({
      defaultMessage: "You selected trying to get pregnant as your journey."
    }),
    value: "TRY_PREGNANT"
  },
  {
    label: defineMessage({ defaultMessage: "Exploring family-forming options" }),
    journeyMessage: defineMessage({
      defaultMessage: "You selected exploring family-forming options as your journey."
    }),
    shortLabel: defineMessage({ defaultMessage: "Exploring options" }),
    shortJourneyMessage: defineMessage({
      defaultMessage: "You selected exploring options as your journey."
    }),
    value: "EXPLORING"
  },
  {
    label: defineMessage({ defaultMessage: "Pregnant or postpartum" }),
    journeyMessage: defineMessage({ defaultMessage: "You selected pregnant or postpartum as your journey." }),
    value: "PREGNANT"
  },
  {
    label: defineMessage({ defaultMessage: "Parenting (0-12 years old)" }),
    journeyMessage: defineMessage({ defaultMessage: "You selected parenting as your journey." }),
    shortLabel: defineMessage({ defaultMessage: "Parenting" }),
    value: "PARENTING"
  },
  {
    label: defineMessage({ defaultMessage: "Menopause" }),
    journeyMessage: defineMessage({ defaultMessage: "You selected menopause as your journey." }),
    value: "MENOPAUSE"
  },
  {
    label: defineMessage({ defaultMessage: "Low testosterone" }),
    journeyMessage: defineMessage({ defaultMessage: "You selected low testosterone as your journey." }),
    value: "LOW_TESTOSTERONE"
  },
  {
    label: defineMessage({ defaultMessage: "Gender-affirming care" }),
    journeyMessage: defineMessage({ defaultMessage: "You selected gender-affirming care as your journey." }),
    value: "GENDER_AFFIRMING_CARE"
  },
  {
    label: defineMessage({ defaultMessage: "Something else" }),
    journeyMessage: defineMessage({ defaultMessage: "You selected something else as your journey." }),
    value: "SOMETHING_ELSE"
  }
]

export function getShortJourneyMessage(memberJourney: string): MessageDescriptor {
  const journeyOption = OPTIONS.find((option) => option.value === memberJourney)
  const { shortJourneyMessage, journeyMessage } = journeyOption || {}

  return shortJourneyMessage ?? journeyMessage
}

export function getShortJourneyLabel(memberJourney: string): MessageDescriptor {
  const journeyOption = OPTIONS.find((option) => option.value === memberJourney)
  const { shortLabel, label } = journeyOption || {}

  return shortLabel ?? label
}

export function isJourneySupported({ journey, options }: { journey: string; options: JourneyOption[] }): boolean {
  return options.some((option) => option.value === journey)
}

export interface JourneyOption {
  label: MessageDescriptor
  journeyMessage: MessageDescriptor
  shortLabel?: MessageDescriptor
  shortJourneyMessage?: MessageDescriptor
  value: string
}

export function useEnabledJourneyOptions(memberBenefit: Benefit): {
  journeyOptions: JourneyOption[]
} {
  const hasMemberBenefitAndJourneyData = !!memberBenefit?.journeys && Object.keys(memberBenefit.journeys).length > 0
  const options = useMemo(
    () =>
      OPTIONS.filter(({ value }) => {
        if (hasMemberBenefitAndJourneyData) {
          const journeys = memberBenefit.journeys

          switch (value) {
            case "ADOPTION": {
              return journeys.adoption.isAppSupportEnabled
            }
            case "GESTATIONAL": {
              return journeys.gestationalCarrier.isAppSupportEnabled
            }
            case "PRESERVATION": {
              return journeys.fertilityPreservation.isAppSupportEnabled
            }
            case "ASSISTED_REPRODUCTION": {
              return journeys.fertilityCare.isAppSupportEnabled
            }
            case "TRY_PREGNANT": {
              return journeys.fertilityCare.isAppSupportEnabled || journeys.fertilityPreservation.isAppSupportEnabled
            }
            case "EXPLORING": {
              return journeys.fertilityCare.isAppSupportEnabled || journeys.fertilityPreservation.isAppSupportEnabled
            }
            case "PREGNANT": {
              return journeys.pregnancy.isAppSupportEnabled
            }
            case "MENOPAUSE": {
              return journeys.menopause.isAppSupportEnabled
            }
            case "LOW_TESTOSTERONE": {
              return journeys.lowTestosterone.isAppSupportEnabled
            }
            case "GENDER_AFFIRMING_CARE": {
              return journeys.genderAffirmingCare.isAppSupportEnabled
            }
            case "PARENTING": {
              return journeys.parenting.isAppSupportEnabled
            }
            case "SOMETHING_ELSE": {
              return true
            }
            default: {
              reportErrorMessage(`Journey ${value} is in options but not set up with the member benefit`)
              return false
            }
          }
        }
        return false
      }),
    [hasMemberBenefitAndJourneyData, memberBenefit?.journeys]
  )

  return {
    journeyOptions: options
  }
}
