import { Box } from "@carrotfertility/carotene-core"
import React from "react"
import { FertilityMedicationSavingSection } from "../../../features/carrot-rx/extend/info/FertilityMedicationSavingExtend"
import { DeliverySection } from "../../../features/carrot-rx/components/DeliverySection"
import { WhyMembersLoveCarrotRxSection } from "../../../features/carrot-rx/components/WhyMembersLoveCarrotRxSection"
import { ExceptionalCareSection } from "../../../features/carrot-rx/components/ExceptionalCareSection"
import { StartSavingWithCarrotRxSection } from "../../../features/carrot-rx/extend/info/StartSavingWithCarrotRxSectionExtend"
import { Footer } from "../../../features/carrot-rx/extend/info/FooterExtend"
import { Header } from "../../../features/carrot-rx/extend/info/HeaderExtend"
import { NavigationTab } from "../../../features/carrot-rx/extend/info/NavigationTabExtend"
import { Title } from "#/services/page-title/Title"
import { useIntl } from "react-intl"
import { ContactAlto } from "../../../features/carrot-rx/extend/info/ContactAltoExtend"
import { useCarrotRxExtend } from ".."
import { Redirect } from "react-router-dom"

export function CarrotRxExtendInfoPage(): JSX.Element {
  const intl = useIntl()

  const { isRegistered } = useCarrotRxExtend()

  if (!isRegistered) {
    return <Redirect to="/carrot-rx-extend" />
  }

  return (
    <Box display="flex" flexDirection="column" width="100%" minHeight="100vh">
      <Title title={intl.formatMessage({ defaultMessage: "Carrot Rx" })} />
      <Header isInAppLockdown={false} />
      <NavigationTab />
      <StartSavingWithCarrotRxSection />
      <ExceptionalCareSection backgroundColor={"#F1EFEB"} />
      <ContactAlto />
      <FertilityMedicationSavingSection />
      <DeliverySection isInAppLockdown={false} />
      <WhyMembersLoveCarrotRxSection />
      <Footer />
    </Box>
  )
}
