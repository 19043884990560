import React from "react"
import { reportErrorMessage } from "../../../utils/ErrorReporting"

import { defaultMarkdownElements } from "#/services/markdown"
import { ContentfulRawMarkdownRenderer } from "#/services/contentful"
import { EntryWithoutUnresolvableLinks } from "#/utils/ContentTypes"
import { TypePageGuidanceSkeleton } from "#/types/contentful"

type GuidanceElementProps = {
  guidance: EntryWithoutUnresolvableLinks<TypePageGuidanceSkeleton>
}

const GuidanceElement = ({ guidance }: GuidanceElementProps) => {
  if (!guidance || !guidance.fields) {
    if (guidance) {
      reportErrorMessage(`Missing fields data for Contentful page guidance with entityId: ${guidance?.sys?.id}`)
    }
    return null
  }

  const {
    fields: { title, description }
  } = guidance

  if (!title) {
    return null
  }

  return (
    <>
      <defaultMarkdownElements.h3.component {...defaultMarkdownElements.h3.props} component="h2">
        <ContentfulRawMarkdownRenderer options={{ forceInline: true }}>{title}</ContentfulRawMarkdownRenderer>
      </defaultMarkdownElements.h3.component>
      <ContentfulRawMarkdownRenderer>{description}</ContentfulRawMarkdownRenderer>
    </>
  )
}

export { GuidanceElement }
