import { useQuery, UseQueryResult } from "@tanstack/react-query"
import buildCarrotApiRetryPolicy from "#/utils/CarrotApiRetryPolicy"
import { getHeap } from "../utils/heap"
import { ProviderFinderParam } from "../utils/providerFinderSearchUrl"
import { Provider } from "../types/Providers"
import ResponseError from "../types/ResponseError"
import { useProviderFinderClient } from "./useProviderFinderClient"
import useGetBenefit from "./useGetBenefit"
import useGetCustomNetworks from "./useGetCustomNetworks"

export default function useGetProviders(params: URLSearchParams): UseQueryResult<Provider[], ResponseError> {
  const { data: benefit, isLoading, isError } = useGetBenefit()
  const {
    data: customNetworksData,
    isLoading: isCustomNetworksLoading,
    isError: isCustomNetworksError
  } = useGetCustomNetworks()
  const isCustomNetworks = !isCustomNetworksLoading && !isCustomNetworksError && customNetworksData?.hasCustomNetworks
  const isCarrotCore = !isLoading && !isError && benefit.program.isCarrotLite

  const latitude = params.get(ProviderFinderParam.Latitude)
  const longitude = params.get(ProviderFinderParam.Longitude)
  const locationCountryCode = params.get(ProviderFinderParam.Country)
  const radiusInKm = params.get(ProviderFinderParam.RadiusInKm)
  const companyCountryCode = params.get("companyCountryCode")
  const carrotPartner = params.get(ProviderFinderParam.CarrotPartner)
  const providerType = params.get(ProviderFinderParam.ProviderType)
  const keyword = params.get(ProviderFinderParam.Keyword)
  const isVirtual = params.get(ProviderFinderParam.IsVirtual)

  const client = useProviderFinderClient()

  if (isCarrotCore && !isCustomNetworks) {
    params.delete(ProviderFinderParam.Keyword)
  }

  return useQuery<Provider[], ResponseError>(
    [
      "getProviders",
      latitude,
      longitude,
      locationCountryCode,
      radiusInKm,
      carrotPartner,
      providerType,
      keyword,
      isVirtual
    ],
    async () => {
      if (providerType === "NONE" || locationCountryCode !== companyCountryCode) {
        return null
      }

      const providers = await client.getProviders(params)

      getHeap().track("Provider Search Results Loaded", {
        "Provider Count": providers.length
      })

      return providers
    },
    {
      refetchOnWindowFocus: false,
      retry: buildCarrotApiRetryPolicy(3)
    }
  )
}
