import React from "react"
import { useStateMachine } from "../../../context/stateMachine/StateMachineV2"
import { Form, FormTextField, useFormContext } from "@carrotfertility/carotene-core-x"
import useAboutYou from "#/components/carrot-plans/hooks/useAboutYou"
import { CarrotErrorCodes, getErrorMessageFromCode } from "../../../../utils/CarrotErrors"
import { getHeap } from "../../../../utils/heap"
import { useCurrentUser } from "../../../context/user/UserContext"
import { emailValidationCaroteneCore } from "../../../../utils/Regexes"
import { parseErrorBody } from "#/utils/HttpErrors"
import ResponseError from "#/types/responseError"
import { defineMessage, useIntl, FormattedMessage, MessageDescriptor } from "react-intl"
import { useIntlValidator } from "#/utils/hooks/useIntlValidator"
import { StepLayout } from "#/components/carrot-plans/shared/StepLayout"
import { ButtonFooterLayout, ContinueButton } from "#/components/carrot-plans/shared/ButtonFooter"
import { RoutingFlowTitle } from "#/components/carrot-plans/shared"

// @ts-expect-error TS(7010) FIXME: 'ServerErrorShim', which lacks return-type annotat... Remove this comment to see the full error message
function ServerErrorShim({ serverError }) {
  const { setError } = useFormContext()
  React.useEffect(() => {
    setError("partnerEmail", { type: "custom", message: serverError })
  }, [serverError, setError])
  return null
}

async function findErrorMessage(error: ResponseError): Promise<MessageDescriptor> {
  const body = await parseErrorBody(error)
  if (body) {
    const code = body?.code
    if (code === CarrotErrorCodes.PARTNER_EMAIL_ALREADY_EXISTS) {
      getHeap().track("DuplicateEmail", { EventName: "Attempted to use non-unique email" })
      return defineMessage({
        defaultMessage: "That email address is already in use"
      })
    }
    return getErrorMessageFromCode(code)
  }
  return defineMessage({
    defaultMessage: "There was an error saving your partner's email. Please try again."
  })
}

export default function AboutYourPartnerEmailStep(): JSX.Element {
  const intl = useIntl()
  const [serverError, setServerError] = React.useState<string>(null)
  const { data, updateAboutYou } = useAboutYou()
  const { email, personalEmail } = useCurrentUser()
  const { send } = useStateMachine(data?.partnerEmail ? "" : null)
  const intlEmailValidation = useIntlValidator(emailValidationCaroteneCore)

  function validateEmailIsAbleToBeUsed(partnerEmail: string): boolean | string {
    const sanitizedEmail = partnerEmail.toLowerCase()
    if (email === sanitizedEmail || personalEmail === sanitizedEmail) {
      return intl.formatMessage({
        defaultMessage: "This address is already associated with your account. Partner's address must be different."
      })
    }
    return intlEmailValidation(partnerEmail)
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'formValues' implicitly has an 'any' typ... Remove this comment to see the full error message
  async function onClickContinue(formValues): Promise<void> {
    setServerError(null)
    if (formValues?.partnerEmail) {
      try {
        await updateAboutYou(formValues)
      } catch (error) {
        const errorMessage = await findErrorMessage(error)
        setServerError(intl.formatMessage(errorMessage))
        return
      }
      send("")
    }
  }
  const header = intl.formatMessage({
    defaultMessage: "What's your partner's email address?"
  })
  return (
    <>
      <RoutingFlowTitle title={header} />
      <StepLayout
        header={header}
        formElements={
          <Form onSubmit={onClickContinue}>
            <ServerErrorShim serverError={serverError} />
            <FormTextField
              name="partnerEmail"
              label={intl.formatMessage({ defaultMessage: "Partner's email address" })}
              inputProps={{ "data-testid": "about-you-partnerEmail" }}
              defaultValue={data?.partnerEmail}
              registerOptions={{
                validate: validateEmailIsAbleToBeUsed,
                required: intl.formatMessage({ defaultMessage: "Required" })
              }}
            />
            <ButtonFooterLayout
              continueButton={
                <ContinueButton>
                  <FormattedMessage defaultMessage="Continue" />
                </ContinueButton>
              }
            />
          </Form>
        }
      />
    </>
  )
}
