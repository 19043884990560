import React from "react"
import { useStateMachine } from "#/components/context/stateMachine/StateMachineContext"
import { SendMessage } from "#/components/talk-to-carrot/send-message/SendMessage"
import { TalkToCarrotStep } from "./TalkToCarrotStep"

interface TalkToCarrotContainerProps {
  onExit: () => void
}

export function TalkToCarrotContainer({ onExit }: TalkToCarrotContainerProps): JSX.Element {
  const { previousStep } = useStateMachine()
  return <SendMessage modalTopic={previousStep.name} BenefitEnrollmentView={TalkToCarrotStep} onExit={onExit} />
}
