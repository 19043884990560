import { Link, Stack, Typography, useTheme } from "@carrotfertility/carotene-core"
import React from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { EmailCommunicationSettings } from "./EmailCommunicationSettings"
import { PushNotificationSettings } from "./PushNotificationSettings"
import { usePrivacyNoticeUrl, useTermsAndConditionsUrl } from "#/utils/hooks/legal-notice-urls"
import { useIsMobileAndSupportsPushNotifications } from "../modal/hooks/useMobileAppStatus"

export function CommunicationSettings({ isForPartner }: { isForPartner?: boolean }): JSX.Element {
  const theme = useTheme()
  const intl = useIntl()
  const privacyNoticeUrl = usePrivacyNoticeUrl()
  const termsUrl = useTermsAndConditionsUrl()

  const { data: isMobileAndSupportsPushNotifications, isLoading: isMobileAndSupportsPushNotificationsLoading } =
    useIsMobileAndSupportsPushNotifications()

  return (
    <Stack spacing={theme.spacing(theme.tokens.spacing.lg)}>
      <Typography variant="h2">
        {isForPartner
          ? intl.formatMessage({ defaultMessage: "Partner communication settings" })
          : intl.formatMessage({ defaultMessage: "Communication settings" })}
      </Typography>
      {!isForPartner && (
        <Typography>
          <FormattedMessage
            defaultMessage="            
              Receive occasional benefit updates such as new resources, tips, reminders, and more."
          />
        </Typography>
      )}
      <EmailCommunicationSettings isForPartner={isForPartner} />
      {!isForPartner && !isMobileAndSupportsPushNotificationsLoading && isMobileAndSupportsPushNotifications && (
        <PushNotificationSettings />
      )}
      <Stack direction="row" spacing={theme.spacing(theme.tokens.spacing.md)}>
        <Link target="_blank" href={termsUrl}>
          <FormattedMessage defaultMessage="Terms & Conditions" />
        </Link>
        <Link target="_blank" href={privacyNoticeUrl}>
          <FormattedMessage defaultMessage="Privacy Notice" />
        </Link>
      </Stack>
    </Stack>
  )
}
