import { useState } from "react"

// @ts-expect-error TS(7006) FIXME: Parameter 'submitFunc' implicitly has an 'any' typ... Remove this comment to see the full error message
export default function useSubmitWithErrorCatch(submitFunc) {
  const [error, setError] = useState(false)

  // @ts-expect-error TS(7019) FIXME: Rest parameter 'args' implicitly has an 'any[]' ty... Remove this comment to see the full error message
  async function onSubmit(...args): Promise<void> {
    try {
      await submitFunc(...args)
    } catch (e) {
      setError(e)
    }
  }
  if (error) {
    throw error
  }
  return {
    onSubmit
  }
}
