import * as cmdHDHPWorkflows from "./flows/cmd_hdhp"
import * as cmdNonHDHPWorkflows from "./flows/cmd_non_hdhp"
import * as acaWorkflows from "./flows/aca"
import * as emdWorkflows from "./flows/emd"
import { WorkflowDefinition } from "../../context/stateMachine/StateMachineContext"

export enum Workflows {
  CMD_HDHP = "CMD_HDHP",
  CMD_NON_HDHP = "CMD_NON_HDHP",
  ACA_ONLY = "ACAOnly",
  EMD = "EMD"
}

export const benefitEnrollmentWorkflows: Array<WorkflowDefinition> = [
  { name: Workflows.CMD_HDHP, workflow: cmdHDHPWorkflows.benefitEnrollmentFlow },
  { name: Workflows.CMD_NON_HDHP, workflow: cmdNonHDHPWorkflows.benefitEnrollmentFlow },
  { name: Workflows.ACA_ONLY, workflow: acaWorkflows.benefitEnrollmentFlow },
  { name: Workflows.EMD, workflow: emdWorkflows.benefitEnrollmentFlow }
]

export const connectInsuranceWorkflows = [
  { name: Workflows.CMD_HDHP, workflow: cmdHDHPWorkflows.connectInsuranceFlow },
  { name: Workflows.CMD_NON_HDHP, workflow: cmdNonHDHPWorkflows.connectInsuranceFlow },
  { name: Workflows.EMD, workflow: emdWorkflows.connectInsuranceFlow }
]
