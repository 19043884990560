import { InboundMemberPayments } from "."
import { InboundMemberPaymentsLayout } from "./components/InboundMemberPaymentsLayout"
import React from "react"
import { StripePaymentsContextProvider } from "#/components/context/stripePayments/StripePaymentsContext"
import { withRouter } from "react-router"
import { useCurrentUser } from "#/components/context/user/UserContext"
import currencyCodesToAllowedDecimalPlacesMap from "#/utils/CurrencyCode"

export const InboundMemberPaymentsAdapter = withRouter((): JSX.Element => {
  const { employeeId, company, email } = useCurrentUser()
  const { currencyCode: companyCurrencyCode, companyId, countryCode: companyCountryCode } = company

  return (
    <StripePaymentsContextProvider
      companyId={companyId}
      employeeId={employeeId.toString()}
      email={email}
      companyCurrencyCode={companyCurrencyCode}
      companyCountryCode={companyCountryCode}
      currencyCodesToAllowedDecimalPlacesMap={currencyCodesToAllowedDecimalPlacesMap}
    >
      <InboundMemberPaymentsLayout>
        <InboundMemberPayments />
      </InboundMemberPaymentsLayout>
    </StripePaymentsContextProvider>
  )
})
