import React from "react"
import { useToggle } from "#/utils/Hooks"
import { PartnerAccessInfoModal } from "./PartnerAccessInfoModal"
import { Container, Box, Typography, Stack, useTheme, IconButton, InfoIcon } from "@carrotfertility/carotene-core"
import { PiiText } from "#/services/tracking"
import { FormattedMessage, useIntl } from "react-intl"
import { useMemberName } from "#/services/user/derived-selectors/member-info"

export const PartnerAccessAccountBanner = ({ isRouting = false }) => {
  const intl = useIntl()
  const [showPartnerAccessModal, toggleShowPartnerAccessModal] = useToggle(false)
  const memberName = useMemberName()
  const theme = useTheme()

  if (memberName.isLoading) {
    return null
  }

  return (
    <Stack
      className="bg-pampas"
      borderRadius={(theme) => theme.tokens.borderRadius.md}
      paddingX={[theme.tokens.spacing.xxs, theme.tokens.spacing.xs]}
      marginTop={isRouting ? theme.tokens.spacing.none : theme.tokens.spacing.md}
      marginBottom={isRouting ? theme.tokens.spacing.lg : theme.tokens.spacing.none}
    >
      <Container>
        <Stack direction="row" justifyContent="center">
          <Box display="flex" alignItems="center">
            <Typography
              variant="body1"
              sx={isRouting ? { fontSize: ["14px", "16px"] } : null}
              color={theme.palette.text.secondary}
            >
              <FormattedMessage
                defaultMessage="You are viewing {memberFirstName}'s account"
                values={{
                  memberFirstName: (
                    <Typography component={PiiText} color={theme.palette.text.secondary}>
                      {memberName.data}
                    </Typography>
                  )
                }}
              />
            </Typography>
          </Box>
          <IconButton
            onClick={toggleShowPartnerAccessModal}
            aria-label={intl.formatMessage({ defaultMessage: "Partner access help modal" })}
            size="small"
          >
            <InfoIcon />
          </IconButton>
          {showPartnerAccessModal && <PartnerAccessInfoModal onExit={toggleShowPartnerAccessModal} />}
        </Stack>
      </Container>
    </Stack>
  )
}
