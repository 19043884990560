import { useLayoutEffect } from "react"
import { useStateMachine } from "#/components/context/stateMachine/StateMachineContext"
import { Steps } from "#/components/cmd-enrollment/workflow/steps"
import { useBenefitEnrollment } from "#/components/context/enrollment/BenefitEnrollmentContext"
import { BenefitAvailabilityStatus } from "#/types/benefitEnrollmentStatus"

export default function FirstTimeOrReEnroll(): JSX.Element {
  const viewName = Steps.FIRST_TIME_OR_RE_ENROLL

  const { availabilityStatus } = useBenefitEnrollment()

  const { setNextStep } = useStateMachine(viewName, null)

  useLayoutEffect(
    () =>
      setNextStep(
        viewName,
        availabilityStatus === BenefitAvailabilityStatus.ENROLLMENT_PLAN_EXPIRED
          ? Steps.RE_ENROLL_INTRO_INFORMATIONAL
          : Steps.INTRO_INFORMATIONAL
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps -- See https://carrotfertility.atlassian.net/wiki/spaces/PE/pages/2050295461/Remove+Build+Warnings#react-hooks%2Fexhaustive-deps
    []
  )

  return null
}
