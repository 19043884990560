import React from "react"
import { Provider } from "react-redux"
import { QueryCache, QueryClient, QueryClientProvider } from "@tanstack/react-query"
import { createRoot } from "react-dom/client"

// core-js is a polyfill for various es6 and above features in IE11
// see : http://kangax.github.io/compat-table/es6/
// and: https://github.com/zloirock/core-js
import "core-js/stable"
import "regenerator-runtime/runtime"

import "./polyfills/intl-polyfills"

import RootContainer from "#/components/containers/RootContainer"
import "#/css/main.scss"
import { initializeErrorReporting, reportError } from "#/utils/ErrorReporting"
import { lightFormTheme } from "#/utils/formThemes"

import configureStore from "#/redux/configureStore"
import { ThemeProvider as StyledComponentsThemeProvider } from "styled-components"
import { BrowserRouter } from "react-router-dom"
import { ExtendedStringifyOptions, QueryParamProvider, transformSearchStringJsonSafe } from "use-query-params"
import { Route } from "react-router"
import { ConnectInsuranceContextProvider } from "./components/context/enrollment/ConnectInsuranceContext"
import SignUpFlowProvider from "#/components/context/signup/SignUpFlowContext"
import { CarrotThemeProvider, GlobalStyles } from "@carrotfertility/carotene"
import { CssBaseline, ThemeProvider } from "@carrotfertility/carotene-core"
import { AuthenticationProvider } from "./components/context/authentication/AuthenticationProvider"
import ResponseError from "#/types/responseError"
import { HttpStatusCodes } from "#/utils/HttpStatusCodes"
import { getHeap } from "#/utils/heap"
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module '@kra... Remove this comment to see the full error message
import postRobot from "@krakenjs/post-robot"
import { isAndroidNativeApp, isIOSNativeApp } from "carrot-api/MobileNativeClient"
import {
  initSmartlingVisualContextCapture,
  shouldEnableSmartlingVisualContextCapture
} from "./utils/SmartlingVisualContextCapture"
import { IntlProviderWrapper } from "./components/context/user/LocaleContext"

const appBootDate = new Date()

initializeErrorReporting()

getHeap().addEventProperties({
  "React App Boot Timestamp": appBootDate.toISOString(),
  "React App Version": process.env.REACT_APP_VERSION || "local",
  "Is iOS Native Mobile App": isIOSNativeApp() ? "true" : "false",
  "Is Android Native Mobile App": isAndroidNativeApp() ? "true" : "false"
})

getHeap().track("React App Booted", {
  timestamp: appBootDate.toISOString(),
  protocol: window?.location?.protocol
})

if (shouldEnableSmartlingVisualContextCapture()) {
  initSmartlingVisualContextCapture()
}

// this is referenced in the modal object TODO: move this to a config file
const applicationNode = "body"

const store = configureStore()

const queryStringifyOptions: ExtendedStringifyOptions = {
  transformSearchString: transformSearchStringJsonSafe
}

function handleQueryError(error: ResponseError): void {
  const statusCode = error?.response?.status
  if (statusCode && statusCode === HttpStatusCodes.INTERNAL_SERVER_ERROR) {
    const {
      status: responseStatus,
      headers: responseHeaders,
      url: responseUrl,
      redirected: responseWasRedirected
    } = error?.response || {}
    reportError(error, {
      // @ts-expect-error TS(2322) FIXME: Type 'number' is not assignable to type 'string'.
      responseStatus,
      // @ts-expect-error TS(2322) FIXME: Type 'Headers' is not assignable to type 'string'.
      responseHeaders,
      responseUrl,
      // @ts-expect-error TS(2322) FIXME: Type 'boolean' is not assignable to type 'string'.
      responseWasRedirected
    })
  }
}

export const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError: handleQueryError
  })
})

if (window.self !== window.parent) {
  // @ts-expect-error TS(7006) FIXME: Parameter 'err' implicitly has an 'any' type.
  postRobot.send(window.parent, "memberAppLoaded").catch(function (err) {
    // eslint-disable-next-line no-console
    console.log(err)
  })
}

const root = createRoot(document.getElementById(applicationNode))

root.render(
  <CarrotThemeProvider>
    <ThemeProvider>
      <CssBaseline />
      <QueryClientProvider client={queryClient}>
        <Provider store={store}>
          <BrowserRouter>
            <StyledComponentsThemeProvider theme={lightFormTheme}>
              <QueryParamProvider ReactRouterRoute={Route} stringifyOptions={queryStringifyOptions}>
                <AuthenticationProvider>
                  <SignUpFlowProvider>
                    <ConnectInsuranceContextProvider>
                      <IntlProviderWrapper>
                        <GlobalStyles />
                        <RootContainer />
                      </IntlProviderWrapper>
                    </ConnectInsuranceContextProvider>
                  </SignUpFlowProvider>
                </AuthenticationProvider>
              </QueryParamProvider>
            </StyledComponentsThemeProvider>
          </BrowserRouter>
        </Provider>
      </QueryClientProvider>
    </ThemeProvider>
  </CarrotThemeProvider>
)
