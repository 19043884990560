import { Benefit } from "#/lib/carrot-api/types/Benefit"
import { useQuery, UseQueryResult } from "@tanstack/react-query"
import ResponseError from "../../../types/responseError"
import { useCarrotBenefitClient } from "./useCarrotBenefitClient"

const ENDPOINT = "GetBenefit"

const getBenefitCacheKey = (): (string | number)[] => [ENDPOINT]

export default function useBenefit(): UseQueryResult<Benefit, ResponseError> {
  const client = useCarrotBenefitClient()
  const benefitCall = async (): Promise<Benefit> => {
    const benefit = await client.getBenefit()
    return benefit
  }
  return useQuery<Benefit, ResponseError>(getBenefitCacheKey(), benefitCall)
}
