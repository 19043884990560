import React, { useContext, useReducer } from "react"
import { CarrotErrorCodes } from "#/utils/CarrotErrors"
import { reportError } from "#/utils/ErrorReporting"
import { isHttpError } from "#/utils/HttpErrors"
import { useSignUpFlow } from "../../context/signup/SignUpFlowContext"
import { getHeap } from "../../../utils/heap"
import { redirectToSamlExternalLogin } from "../../../utils/RedirectToSamlExternalLogin"
import { useIntl } from "react-intl"
import { carrotClient } from "#/utils/CarrotClient"

interface FindAccountByExternalEmployeeIdReducerState {
  errorMessage: string
  inputsEnabled: boolean
  shake: boolean
  showTryAgain: boolean
}

interface FindAccountByExternalEmployeeIdProviderProps extends FindAccountByExternalEmployeeIdReducerState {
  onSubmit: ({
    externalEmployeeId,
    parentCompanyId,
    dateOfBirth
  }: {
    externalEmployeeId: string
    parentCompanyId: number
    dateOfBirth: Date
  }) => Promise<void>
}

const FindAccountByExternalEmployeeIdContext = React.createContext<FindAccountByExternalEmployeeIdProviderProps>(null)

export const useFindAccountByExternalEmployeeId = () => useContext(FindAccountByExternalEmployeeIdContext)

const actionTypes = {
  error: "ERROR",
  tryAgainMessage: "TRY_AGAIN_MESSAGE",
  start: "START_REQUEST"
}

// @ts-expect-error TS(7006) FIXME: Parameter 'state' implicitly has an 'any' type.
function findAccountByExternalEmployeeIdReducer(state, action): FindAccountByExternalEmployeeIdReducerState {
  switch (action.type) {
    case actionTypes.error: {
      return {
        ...state,
        inputsEnabled: true,
        shake: true,
        errorMessage: action.payload.errorCode
      }
    }
    case actionTypes.tryAgainMessage: {
      return {
        ...state,
        showTryAgain: true,
        inputsEnabled: true,
        shake: true,
        errorMessage: null
      }
    }
    case actionTypes.start: {
      return {
        ...state,
        inputsEnabled: false,
        shake: false
      }
    }
    default: {
      throw new Error(`Unhandled type: ${action.type}`)
    }
  }
}

export function FindAccountByExternalEmployeeIdProvider({
  // @ts-expect-error TS(7031) FIXME: Binding element 'children' implicitly has an 'any'... Remove this comment to see the full error message
  children,
  // @ts-expect-error TS(7031) FIXME: Binding element 'onEmailFound' implicitly has an '... Remove this comment to see the full error message
  onEmailFound,
  // @ts-expect-error TS(7031) FIXME: Binding element 'onEmailNotFound' implicitly has a... Remove this comment to see the full error message
  onEmailNotFound,
  // @ts-expect-error TS(7031) FIXME: Binding element 'onMultipleEmployeesFound' implici... Remove this comment to see the full error message
  onMultipleEmployeesFound,
  // @ts-expect-error TS(7031) FIXME: Binding element 'onEmployeeAlreadyRegistered' impl... Remove this comment to see the full error message
  onEmployeeAlreadyRegistered,
  // @ts-expect-error TS(7031) FIXME: Binding element 'onEmployeeHasSamlAccount' implici... Remove this comment to see the full error message
  onEmployeeHasSamlAccount
}) {
  const [state, dispatch] = useReducer(findAccountByExternalEmployeeIdReducer, {
    errorMessage: null,
    inputsEnabled: true,
    shake: false,
    showTryAgain: false
  })
  const { handleSaveMemberInfoInMemory, saveMultipleEmployeesFound } = useSignUpFlow()

  // @ts-expect-error TS(7006) FIXME: Parameter 'errorCode' implicitly has an 'any' type... Remove this comment to see the full error message
  const setFormError = (errorCode) => dispatch({ type: actionTypes.error, payload: { errorCode } })
  const showTryAgain = () => dispatch({ type: actionTypes.tryAgainMessage })
  const startRequest = () => dispatch({ type: actionTypes.start })

  // @ts-expect-error TS(7006) FIXME: Parameter 'errorCode' implicitly has an 'any' type... Remove this comment to see the full error message
  const setErrorState = (errorCode) => {
    setFormError(errorCode)
  }

  const { locale } = useIntl()

  // @ts-expect-error TS(7031) FIXME: Binding element 'externalEmployeeId' implicitly ha... Remove this comment to see the full error message
  const onSubmit = async ({ externalEmployeeId, parentCompanyId, dateOfBirth }) => {
    getHeap().track("NoEmailOnboarding", { EventName: "submit external employee ID for signup" })
    startRequest()
    let memberInfo
    try {
      memberInfo = await carrotClient.signUpExternalEmployeeId(
        externalEmployeeId,
        parentCompanyId,
        dateOfBirth.format("YYYY-MM-DD"),
        locale
      )
    } catch (error) {
      if (isHttpError(error)) {
        const { code } = await error.response.json()
        if (code === CarrotErrorCodes.EMPLOYEE_NOT_FOUND) {
          //   handleSaveMemberInfoInMemory({ email, dateOfBirth, firstName, lastName })
          showTryAgain()
          return
        }
        if (code === CarrotErrorCodes.MULTIPLE_EMPLOYEES_FOUND) {
          //   handleSaveMemberInfoInMemory({ email, dateOfBirth, firstName, lastName })
          saveMultipleEmployeesFound()
          onMultipleEmployeesFound()
          return
        }
        setErrorState(code)
        return
      }

      reportError(error)
      setErrorState(CarrotErrorCodes.UNEXPECTED_ERROR)
      return
    }

    if (memberInfo.samlIdpEntityId && memberInfo.existingEmail) {
      onEmployeeHasSamlAccount(memberInfo.existingEmail)
      return
    } else if (
      memberInfo.samlIdpEntityId &&
      memberInfo.ssoOnly &&
      memberInfo.hasChannelMemberId &&
      !memberInfo.existingEmail
    ) {
      // SSO Enabled No Email Onboarding member must authenticate before providing their email.
      redirectToSamlExternalLogin(memberInfo.samlExternalLoginUrl, memberInfo.samlIdpEntityId, false)
      return
    }

    if (memberInfo.existingEmail) {
      if (!memberInfo.employeeAlreadyRegistered) {
        onEmailFound(memberInfo.existingEmail)
      } else {
        handleSaveMemberInfoInMemory({
          externalEmployeeId,
          email: memberInfo.existingEmail,
          parentCompanyId,
          dateOfBirth
        })
        onEmployeeAlreadyRegistered()
      }
    } else {
      handleSaveMemberInfoInMemory({
        externalEmployeeId,
        email: memberInfo.existingEmail,
        parentCompanyId,
        dateOfBirth
      })
      onEmailNotFound({ externalEmployeeId, parentCompanyId, dateOfBirth })
    }
  }

  return (
    <FindAccountByExternalEmployeeIdContext.Provider value={{ ...state, onSubmit }}>
      {children}
    </FindAccountByExternalEmployeeIdContext.Provider>
  )
}
