import React from "react"
import { Form } from "@carrotfertility/carotene-core-x"
import { useCurrentUser } from "#/components/context/user/UserContext"
import useAboutYou from "#/components/carrot-plans/hooks/useAboutYou"
import { AboutYou } from "#/types/carrotPlanTypes"
import { useStateMachine } from "../../../context/stateMachine/StateMachineV2"
import { StepLayout } from "#/components/carrot-plans/shared/StepLayout"
import { ButtonFooterLayout, ContinueButton } from "#/components/carrot-plans/shared/ButtonFooter"
import { FormattedMessage, useIntl } from "react-intl"
import {
  Address,
  AddressFields,
  InternationalAddress,
  InternationalAddressFields,
  formatAddressFieldsToPayload,
  formatInternationalAddressFieldsToPayload,
  isValidAddress,
  isValidInternationalAddress
} from "#/services/common-forms"
import { Stack, Typography, useTheme } from "@carrotfertility/carotene-core"
import { RoutingFlowTitle } from "#/components/carrot-plans/shared"

type FormValues = AddressFields | InternationalAddressFields

// @ts-expect-error TS(7006) FIXME: Parameter 'isUsa' implicitly has an 'any' type.
function shouldSkipStep(isUsa, data: AboutYou) {
  if (isUsa) {
    return isValidAddress(data?.address)
  }
  return isValidInternationalAddress(data?.address)
}

export default function AboutYouAddressStep(): JSX.Element {
  const theme = useTheme()
  const intl = useIntl()
  const { data, updateAboutYou } = useAboutYou()
  const { isUsa } = useCurrentUser()

  const { send } = useStateMachine(shouldSkipStep(isUsa, data) ? "" : null)

  async function onSubmit(data: FormValues): Promise<void> {
    const aboutYouUpdate = isUsa
      ? formatAddressFieldsToPayload(data as AddressFields)
      : formatInternationalAddressFieldsToPayload(data as InternationalAddressFields)
    await updateAboutYou(aboutYouUpdate as AboutYou)
    send("")
  }
  const header = intl.formatMessage({
    defaultMessage: "What's your home address?"
  })

  return (
    <>
      <RoutingFlowTitle title={header} />
      <StepLayout
        header={intl.formatMessage({ defaultMessage: "What's your home address?" })}
        description={
          <Stack spacing={theme.spacing(theme.tokens.spacing.lg)}>
            <Typography>
              <FormattedMessage defaultMessage="Your address helps us recommend the right care and providers near you." />
            </Typography>
            <Typography variant="body2">
              <FormattedMessage defaultMessage="Fields marked with an asterisk are required" />
            </Typography>
          </Stack>
        }
        formElements={
          <Form onSubmit={onSubmit}>
            {isUsa ? <Address defaultValues={data?.address} /> : <InternationalAddress defaultValues={data?.address} />}
            <ButtonFooterLayout
              continueButton={
                <ContinueButton>
                  <FormattedMessage defaultMessage="Continue" />
                </ContinueButton>
              }
            />
          </Form>
        }
      />
    </>
  )
}
