import React from "react"
import { Box, Progress } from "@carrotfertility/carotene-core"

export const Loading = (): JSX.Element => (
  <Box display="flex" justifyContent="center">
    <Box display="flex" sx={{ position: "fixed", insetBlockStart: "50vh" }}>
      <Box display="flex" justifyContent="center" alignItems="center">
        <Progress />
      </Box>
    </Box>
  </Box>
)
