import React, { useEffect, useContext, useState, ReactNode } from "react"
import { FormProvider, useForm } from "react-hook-form"
import { useSignUpFlow } from "#/components/context/signup/SignUpFlowContext"
import { useHistory } from "react-router"
import { isHttpError } from "#/utils/HttpErrors"
import { CarrotErrorCodes, getErrorMessageFromCode } from "#/utils/CarrotErrors"
import { Link } from "@carrotfertility/carotene-core"
import { useIntl } from "react-intl"
import { EmployeeSupportUrl } from "../../../utils/EmployeeSupportLink"
import { carrotClient } from "#/utils/CarrotClient"

interface FindAccountByExternalEmployeeIdSuccessProviderContextProps {
  onSubmit: ({ email }: { email: any }) => Promise<void>
  getTranslatedErrorMessage: (errorCode: string) => string | ReactNode | (string | ReactNode)[]
  error: string
  loading: boolean
}

const FindAccountByExternalEmployeeIdSuccessProviderContext =
  React.createContext<FindAccountByExternalEmployeeIdSuccessProviderContextProps>(null)

export const useFindAccountByExternalEmployeeIdSuccess = () =>
  useContext(FindAccountByExternalEmployeeIdSuccessProviderContext)

// @ts-expect-error TS(7031) FIXME: Binding element 'children' implicitly has an 'any'... Remove this comment to see the full error message
export function FindAccountByExternalEmployeeIdSuccessProvider({ children }) {
  const [error, setError] = useState("")
  const [loading, setLoading] = useState(false)
  const formMethods = useForm({ reValidateMode: "onSubmit" })
  const history = useHistory()
  const intl = useIntl()
  const { locale } = intl
  const { lookupAccountByEmail, getHasFoundAccount, externalEmployeeId, parentCompanyId, dateOfBirth } = useSignUpFlow()

  useEffect(() => {
    if (!getHasFoundAccount()) {
      history.push("/signup")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- See https://carrotfertility.atlassian.net/wiki/spaces/PE/pages/2050295461/Remove+Build+Warnings#react-hooks%2Fexhaustive-deps
  }, [])

  // @ts-expect-error TS(7031) FIXME: Binding element 'email' implicitly has an 'any' ty... Remove this comment to see the full error message
  const onSubmit = async ({ email }) => {
    setError("")
    setLoading(true)

    const lookupResponseStatus = await lookupAccountByEmail(email)

    if (lookupResponseStatus != null) {
      setError(lookupResponseStatus)
      return
    }

    try {
      await carrotClient.signUpAddEmailToAccountByExternalEmployeeId(
        email,
        externalEmployeeId,
        parentCompanyId,
        dateOfBirth.format("YYYY-MM-DD")
      )
    } catch (error) {
      if (isHttpError(error)) {
        const { code } = await error.response.json()
        if (code === CarrotErrorCodes.EMPLOYEE_ALREADY_HAS_EMAIL) {
          setError(code)
          setLoading(false)
          return
        }
      }
      setError(CarrotErrorCodes.TRY_AGAIN_LATER)
      setLoading(false)
      return
    }

    try {
      await carrotClient.signUp(email, locale)
      history.push(`/signup-success?email=${encodeURIComponent(email)}`)
    } catch (error) {
      setError(CarrotErrorCodes.TRY_AGAIN_LATER)
      setLoading(false)
      return
    }
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'errorCode' implicitly has an 'any' type... Remove this comment to see the full error message
  const getTranslatedErrorMessage = (errorCode) => {
    if (errorCode === "MEMBER_INACTIVE") {
      return intl.formatMessage(getErrorMessageFromCode(errorCode), {
        link: (string) => (
          <Link color="inherit" href={EmployeeSupportUrl} target={"_blank"}>
            {string}
          </Link>
        )
      })
    }
    if (errorCode === CarrotErrorCodes.EMPLOYEE_ALREADY_HAS_EMAIL) {
      return intl.formatMessage(getErrorMessageFromCode(errorCode), {
        link: (string) => (
          <Link color="inherit" href={EmployeeSupportUrl} target={"_blank"}>
            {string}
          </Link>
        )
      })
    }
    return intl.formatMessage(getErrorMessageFromCode(errorCode))
  }

  return (
    <FormProvider {...formMethods}>
      <FindAccountByExternalEmployeeIdSuccessProviderContext.Provider
        value={{ error, loading, onSubmit, getTranslatedErrorMessage }}
      >
        {children}
      </FindAccountByExternalEmployeeIdSuccessProviderContext.Provider>
    </FormProvider>
  )
}
