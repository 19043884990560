import React from "react"
import { Link, Stack, Typography, useTheme } from "@carrotfertility/carotene-core"
import { Link as RouterLink } from "react-router-dom"
import { useUserRole } from "../../partner-access/hooks/usePartnerAccess"
import { IfMobileNative } from "#/lib/carrot-api/MobileNativeClient"
import { SettingsPageDivider } from "#/pages/account/page"
import { FormattedMessage } from "react-intl"

export function AccountDeletion(): JSX.Element {
  const { isPartnerAccessingAccount } = useUserRole()
  const theme = useTheme()

  if (!isPartnerAccessingAccount) {
    return (
      <IfMobileNative>
        <Stack spacing={theme.spacing(theme.tokens.spacing.lg)}>
          <Typography variant="h2">
            <FormattedMessage defaultMessage="Account deletion" />
          </Typography>
          <Link to="/account/delete" component={RouterLink}>
            <FormattedMessage defaultMessage="Request account deletion" />
          </Link>
        </Stack>
        <SettingsPageDivider />
      </IfMobileNative>
    )
  }
  return null
}
