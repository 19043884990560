import React from "react"
import { CarrotCardModuleContainer } from "#/components/views/molecules/CarrotCardModuleContainer"
import { useSelector } from "react-redux"
import { getIsMemberOfCarrotCardCompany } from "#/redux/derivedSelectors"
import { SupportModule } from "./SupportModule"
import { ConnectInsuranceInfoModule } from "./ConnectInsuranceInfoModule"
import { useToggle } from "../../../utils/Hooks"
import { BenefitEnrollmentFlowContainer } from "#/components/cmd-enrollment"
import { useConnectInsurance } from "../../context/enrollment/ConnectInsuranceContext"
import { useBenefitEnrollment } from "../../context/enrollment/BenefitEnrollmentContext"
import BenefitsEnrollmentRequired from "../../home/benefits-modules/BenefitsEnrollmentRequired"
import { getCompanyHasCarrotManagedDeductibleEnabled } from "#/redux/derivedSelectors/deductibleSelectors"
import BenefitsEnrollmentExpired from "../../home/benefits-modules/BenefitsEnrollmetExpired"
import { Box } from "@carrotfertility/carotene-core"

const BenefitResourcesSideBar = () => {
  const { showOptionalInsuranceInfoPromo } = useConnectInsurance()
  const isMemberOfCarrotCardCompany = useSelector(getIsMemberOfCarrotCardCompany)
  const [showBenefitEnrollmentModal, toggleBenefitEnrollmentModal] = useToggle(false)
  const { status: benefitEnrollmentStatus } = useBenefitEnrollment()
  const companyHasCarrotManagedDeductibleEnabled = useSelector(getCompanyHasCarrotManagedDeductibleEnabled)

  const determineWhichNeedsBenefitEnrollmentCTAToDisplay = () => {
    if (companyHasCarrotManagedDeductibleEnabled) {
      if (benefitEnrollmentStatus.needsBenefitEnrollment) {
        return <BenefitsEnrollmentExpired onClickEnroll={toggleBenefitEnrollmentModal} />
      }
      if (showOptionalInsuranceInfoPromo) {
        return <ConnectInsuranceInfoModule onClickEnroll={toggleBenefitEnrollmentModal} />
      }
    } else if (benefitEnrollmentStatus.needsBenefitEnrollment) {
      return <BenefitsEnrollmentRequired onClickEnroll={toggleBenefitEnrollmentModal} />
    }
  }

  return (
    <>
      <Box display="flex" flexDirection={"column"} gap={(theme) => theme.tokens.spacing.md}>
        {isMemberOfCarrotCardCompany && <CarrotCardModuleContainer />}
        <SupportModule />
        {determineWhichNeedsBenefitEnrollmentCTAToDisplay()}
      </Box>

      {showBenefitEnrollmentModal && <BenefitEnrollmentFlowContainer onExit={toggleBenefitEnrollmentModal} />}
    </>
  )
}

export { BenefitResourcesSideBar }
