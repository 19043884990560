import { Box, Dayjs, InputAdornment, Link, Stack, Tile, Typography, useTheme } from "@carrotfertility/carotene-core"
import {
  Form,
  FormAlert,
  FormButton,
  FormCheckbox,
  FormDatePicker,
  FormOnSubmitHandler,
  FormTextField
} from "@carrotfertility/carotene-core-x"
import React from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { usePrivacyNoticeUrl, useTermsAndConditionsUrl } from "#/utils/hooks/legal-notice-urls"
import CarrotRx from "../../resources/images/carrot-rx.svg"
import { isValidPhoneNumber, parsePhoneNumber } from "libphonenumber-js"
import { useDigitalFrontDoorBreakpointViews } from "#/features/carrot-rx/hooks/useDigitalFrontDoorBreakpointViews"
import useRegisterCarrotRxExtend from "#/features/carrot-rx/hooks/useRegisterCarrotRxExtend"
import { useCarrotRxExtend } from "#/pages/carrot-rx-extend"
import { useHistory } from "react-router"
import { emailValidationCaroteneCore } from "#/utils/Regexes"
import { useIntlValidator } from "#/utils/hooks/useIntlValidator"
import { useFormState } from "react-hook-form"
import { CarrotRxExtendSupportEmailLink } from "../shared/CarrotRxExtendSupportEmailLink"
import { UnauthPreferredLocale } from "#/components/views/UnauthPreferredLocale"
import { getHeap } from "#/utils/heap"

function LandingPageInfoSection(): JSX.Element {
  const { smallerThanDesktop } = useDigitalFrontDoorBreakpointViews()
  const theme = useTheme()

  return (
    <Box display={"flex"} flex={2} flexDirection={"column"} height="100vh" sx={{ padding: "68px 76px" }}>
      <Stack flex={1} spacing={"53px"} justifyContent={"center"}>
        <Box component="img" width={183} src={CarrotRx} alt="Carrot Rx" />
        <Typography>
          <FormattedMessage defaultMessage="Order medications through Carrot Rx for competitive pricing and price matching, convenient delivery, and 24/7 access to clinicians who can answer your questions." />
        </Typography>
      </Stack>
      <Tile sx={{ backgroundColor: "#E1DED7", marginBlockStart: theme.tokens.spacing.lg }}>
        <Stack direction={"row"} spacing={theme.spacing(theme.tokens.spacing.md)}>
          {!smallerThanDesktop && (
            <Box component={"img"} src={"/images/icn-help.png"} alt="help" height="40px" width="40px" />
          )}
          <Typography>
            <FormattedMessage
              defaultMessage="If you have questions about your Carrot Rx benefit, email <supportEmailLink>ContactSupport@get-carrot.com</supportEmailLink>.{br}{br}Please include your name, date of birth, and ZIP code."
              values={{
                supportEmailLink: (linkText) => (
                  <CarrotRxExtendSupportEmailLink>{linkText}</CarrotRxExtendSupportEmailLink>
                ),
                br: <br />
              }}
            />
          </Typography>
        </Stack>
      </Tile>
    </Box>
  )
}

function Alert(): JSX.Element {
  const { errors } = useFormState({ name: "root" })
  return errors?.root && <FormAlert severity="error">{errors.root?.message.toString()}</FormAlert>
}

function LandingPageForm(): JSX.Element {
  const intl = useIntl()
  const theme = useTheme()
  const { smallerThanDesktop } = useDigitalFrontDoorBreakpointViews()
  const privacyPolicyUrl = usePrivacyNoticeUrl()
  const termsAndConditionsUrl = useTermsAndConditionsUrl()
  const { mutateAsync } = useRegisterCarrotRxExtend()
  const { setIsRegistered } = useCarrotRxExtend()
  const history = useHistory()

  type CarrotRxExtendFormValues = {
    firstName: string
    lastName: string
    dateOfBirth: Dayjs
    preferredEmail: string
    zipCode: string
    phoneNumber: string
    termsAndConditions: boolean
  }

  const handleSubmit: FormOnSubmitHandler<CarrotRxExtendFormValues> = async (values, formMethods) => {
    const phoneNumber = values.phoneNumber ? parsePhoneNumber(values.phoneNumber, "US") : null
    const utcDate = new Date(values.dateOfBirth.toDate())
    utcDate.setUTCHours(0, 0, 0, 0)

    try {
      await mutateAsync({
        firstName: values.firstName,
        lastName: values.lastName,
        dateOfBirth: utcDate.toISOString(),
        email: values.preferredEmail,
        zipCode: values.zipCode,
        phoneNumber: phoneNumber?.number
      })
      setIsRegistered(true)
      history.push("/carrot-rx-extend/access/info")
    } catch (error) {
      const isNotFound = error.name === "NOT_FOUND"

      if (isNotFound) {
        getHeap().track("CarrotRxExtend - Account Not Found")
      }

      const errorMessage = isNotFound
        ? intl.formatMessage({
            defaultMessage:
              "Unable to access your benefit. Make sure your name, date of birth, and ZIP code match your benefit provider’s records, then try again."
          })
        : intl.formatMessage({
            defaultMessage: "An error occurred. Please try again."
          })

      formMethods.setError("root", {
        type: "custom",
        message: errorMessage
      })

      if (!isNotFound) {
        reportError(error)
      }
    }
  }

  return (
    <Form onSubmit={handleSubmit}>
      <Stack spacing={theme.spacing(theme.tokens.spacing.xs)}>
        <Alert />
        <Stack
          direction={smallerThanDesktop ? "column" : "row"}
          spacing={smallerThanDesktop ? theme.spacing(theme.tokens.spacing.xs) : theme.spacing(theme.tokens.spacing.lg)}
        >
          <FormTextField
            name="firstName"
            registerOptions={{ required: "Required" }}
            label={intl.formatMessage({ defaultMessage: "First Name" })}
          />
          <FormTextField
            name="lastName"
            registerOptions={{ required: "Required" }}
            label={intl.formatMessage({ defaultMessage: "Last Name" })}
          />
        </Stack>
        <FormDatePicker
          name="dateOfBirth"
          controllerProps={{ rules: { required: "Required" } }}
          label={intl.formatMessage({ defaultMessage: "Date of Birth" })}
        />
        <FormTextField
          name="preferredEmail"
          registerOptions={{ required: "Required", validate: useIntlValidator(emailValidationCaroteneCore) }}
          label={intl.formatMessage({ defaultMessage: "Preferred Email" })}
        />
        <FormTextField
          name="zipCode"
          registerOptions={{
            required: "Required",
            validate: (value) => {
              if (!value.match(/^\d{5}$/)) {
                return intl.formatMessage({ defaultMessage: "Zip code must be 5 digits long" })
              }
              return true
            }
          }}
          label={intl.formatMessage({ defaultMessage: "Zip Code" })}
        />
        <FormTextField
          name="phoneNumber"
          label={intl.formatMessage({ defaultMessage: "Phone Number" })}
          registerOptions={{
            validate: (value: string) =>
              !value || isValidPhoneNumber(value, "US") ? undefined : "Invalid phone number"
            // required: "Required"
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">{intl.formatMessage({ defaultMessage: "+1" })}</InputAdornment>
            )
          }}
        />
      </Stack>
      <Box marginTop={"40px"}>
        <FormCheckbox
          name="termsAndConditions"
          controllerProps={{ rules: { required: "Required" } }}
          label={
            <FormattedMessage
              defaultMessage="I agree to the <tcLink>Terms and Conditions</tcLink> and <privacyLink>Privacy Policy</privacyLink>."
              values={{
                privacyLink: (linkText) => (
                  <Link color="inherit" target="_blank" href={privacyPolicyUrl}>
                    {linkText}
                  </Link>
                ),
                tcLink: (linkText) => (
                  <Link color="inherit" target="_blank" href={termsAndConditionsUrl}>
                    {linkText}
                  </Link>
                )
              }}
            />
          }
        />
      </Box>
      <Box marginTop={"51px"} display="flex" justifyContent="space-between">
        <UnauthPreferredLocale />
        <FormButton type="submit">
          <FormattedMessage defaultMessage="Access your benefit" />
        </FormButton>
      </Box>
    </Form>
  )
}

export function CarrotRxExtendLandingPage(): JSX.Element {
  const { smallerThanDesktop } = useDigitalFrontDoorBreakpointViews()
  const theme = useTheme()

  return (
    <Box
      display="flex"
      flexDirection={smallerThanDesktop ? "column" : "row"}
      width="100%"
      minHeight="100vh"
      bgcolor={(theme) => theme.palette.background.default}
    >
      <LandingPageInfoSection />
      <Stack
        flex={3}
        alignContent={"center"}
        borderRadius={smallerThanDesktop ? 10 : 20}
        bgcolor={(theme) => theme.palette.background.paper}
        padding={smallerThanDesktop ? "50px" : "115px 136px"}
        spacing={"32px"}
      >
        <Stack spacing={theme.spacing(theme.tokens.spacing.lg)}>
          <Typography variant="h1">
            <FormattedMessage defaultMessage="Welcome" />
          </Typography>
          <Typography>
            <FormattedMessage defaultMessage="Complete this form to access your Carrot Rx benefit. Your name, date of birth, and ZIP code must match what your benefit provider has on file." />
          </Typography>
        </Stack>
        <LandingPageForm />
      </Stack>
    </Box>
  )
}
