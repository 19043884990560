import React, { FC, useContext } from "react"
import { RequestCardContext } from "#/pages/request-carrot-card-flow"
import { Container, Stepper } from "@carrotfertility/carotene-core"
import {
  BackButton,
  CancelButton,
  ContinueButton,
  ContinueButtonProps
} from "#/pages/request-carrot-card-flow/requestCardWrapper/buttons"
import { DialogActionsBar } from "@carrotfertility/carotene-core-x"
import { useStateMachine } from "#/components/context/stateMachine/StateMachineV2"
import { getActiveStep, getTotalSteps } from "../workflows/routing-helpers/helpers"
import { Steps } from "../workflows/steps"
import { Workflows } from "../workflows/workflows"
import { useTextDirection } from "#/services/locale/hooks/useTextDirection"

export type RequestCardWrapperProps = ContinueButtonProps & {
  showBackButton?: boolean
  showCancelButton?: boolean
  goBackToStepName?: string
  showContinueButton?: boolean
  children: React.ReactNode
  hideProgressBar?: boolean
}

const RequestCardWrapper: FC<RequestCardWrapperProps> = ({
  children,
  disableContinue,
  goBackToStepName,
  showBackButton,
  showCancelButton,
  showContinueButton,
  hideProgressBar
}) => {
  const { isUsa, showInformationScreen, companyCountryCode } = useContext(RequestCardContext)
  const { workflowName, stepName } = useStateMachine()
  const textDirection = useTextDirection()

  const totalSteps = getTotalSteps(workflowName as Workflows, {
    isUsa,
    showInformationScreen,
    companyCountryCode
  })
  const activeStep = getActiveStep(workflowName as Workflows, stepName as Steps, {
    isUsa,
    showInformationScreen,
    companyCountryCode
  })
  const showStepper = !hideProgressBar && activeStep > 0 && activeStep < totalSteps
  const showDialogActionBar = showContinueButton || showCancelButton || showBackButton
  return (
    <Container>
      {showStepper && (
        <Stepper
          dir={textDirection}
          sx={{ paddingBlockEnd: (theme) => theme.tokens.spacing.xl }}
          variant="progress"
          steps={totalSteps}
          position="static"
          activeStep={activeStep}
        />
      )}
      {children}
      {showDialogActionBar && (
        <DialogActionsBar
          primaryAction={showContinueButton && <ContinueButton disableContinue={disableContinue} />}
          secondaryAction={showCancelButton && <CancelButton />}
          tertiaryAction={showBackButton && <BackButton goBackToStepName={goBackToStepName} />}
        />
      )}
    </Container>
  )
}

export default RequestCardWrapper
