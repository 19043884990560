import React from "react"
import { useHits } from "react-instantsearch"
import { MenuItem, Typography } from "@carrotfertility/carotene-core"
import { Link as RouterLink } from "react-router-dom"
import useGetCategories from "./shared/useGetCategories"
import { useContentfulConditionalGate } from "#/lib/contentful/components/ContentfulConditionGate"
import ContentTypes, { EntryWithoutUnresolvableLinks } from "#/utils/ContentTypes"
import { useReadLearnLinks } from "./shared/ReadLearnLinks"
import { RawContentfulConditionSkeleton } from "#/lib/contentful/utils/conditionUtils"
import { EntryWithCategory, getEntryWithCategory } from "./shared/getEntryWithCategory"
import { Search } from "#/services/Search/Search"
import { FormattedMessage, useIntl } from "react-intl"

export function CarrotAcademySearch({ size = "small" }: { size?: "small" | "medium" }): JSX.Element {
  const intl = useIntl()
  return (
    <Search
      size={size}
      facetFilters={[
        [
          "sys.contentType.sys.id:groupSession",
          "sys.contentType.sys.id:guide",
          "sys.contentType.sys.id:article",
          "sys.contentType.sys.id:video",
          "sys.contentType.sys.id:subCategory",
          "sys.contentType.sys.id:contentBlock"
        ]
      ]}
      indexName="Library"
      hits={<CarrotAcademySearchHits {...{ size }} />}
      placeholder={intl.formatMessage({ defaultMessage: "Search Carrot Academy" })}
      id="carrotAcademySearch"
      label={intl.formatMessage({ defaultMessage: "Search Carrot Academy" })}
    />
  )
}

function CarrotAcademySearchHits({ size }: { size?: "small" | "medium" }): JSX.Element {
  const { items } = useHits()
  const categories = useGetCategories()
  const hitsToShow: Array<{
    element: React.ReactNode
    conditions?: EntryWithoutUnresolvableLinks<RawContentfulConditionSkeleton>[]
    allowConditionsOverride?: boolean
  }> = []

  for (const hit of items) {
    const entriesWithCategory: EntryWithCategory[] = getEntryWithCategory(
      hit.objectID,
      categories.data,
      hit.sys.contentType.sys.id
    )

    for (const entry of entriesWithCategory) {
      const articleConditions = entry.article?.fields.conditions ?? []
      const subCategoryConditions = entry.subCategory.fields.conditions ?? []
      const categoryConditions = entry.category.fields.conditions ?? []
      hitsToShow.push({
        element: (
          <Hit
            key={`${hit.objectID}${entry.category.sys.id}`}
            data-id={entry.contentType === ContentTypes.CONTENT_BLOCK ? entry.article.sys.id : hit.objectID}
            contentType={entry.contentType}
            entryWithCategory={entry}
            {...{ size }}
          />
        ),
        conditions: [...articleConditions, ...subCategoryConditions, ...categoryConditions]
      })
    }
  }
  const filteredHitsByConditions = useContentfulConditionalGate(hitsToShow, true).filter(
    (hit: any, index, allHits) => index === allHits.findIndex((o: any) => o.props["data-id"] === hit.props["data-id"])
  )

  return (
    <>
      {filteredHitsByConditions.length ? (
        filteredHitsByConditions
      ) : (
        <MenuItem>
          <Typography fontStyle="italic">
            <FormattedMessage defaultMessage="No results found" />
          </Typography>
        </MenuItem>
      )}
    </>
  )
}

function Hit({
  entryWithCategory,
  contentType,
  size
}: {
  entryWithCategory: EntryWithCategory
  contentType: string
  size?: "small" | "medium"
}): JSX.Element {
  const { articleLink, subCategoryLink } = useReadLearnLinks({
    category: entryWithCategory?.category,
    subCategory: entryWithCategory?.subCategory,
    article: entryWithCategory?.article
  })
  const intl = useIntl()

  function getHitDetailsByContentType(): { title: string; contentType: string; link: string } {
    const details = { title: entryWithCategory?.article?.fields?.title, contentType: "", link: articleLink }
    switch (contentType) {
      case ContentTypes.ARTICLE:
      case ContentTypes.CONTENT_BLOCK:
        details.contentType = intl.formatMessage({ defaultMessage: "Article" })
        return details
      case ContentTypes.GUIDE:
        details.contentType = intl.formatMessage({ defaultMessage: "Guide" })
        return details
      case ContentTypes.VIDEO:
        details.contentType = intl.formatMessage({ defaultMessage: "Video" })
        return details
      case ContentTypes.GROUP_SESSION:
        details.contentType = intl.formatMessage({ defaultMessage: "Group Session" })
        return details
      case ContentTypes.SUB_CATEGORY:
        details.title = entryWithCategory.subCategory.fields.title
        details.contentType = intl.formatMessage({ defaultMessage: "Category" })
        details.link = subCategoryLink
        return details
      default:
        return null
    }
  }

  const { title, contentType: type, link } = getHitDetailsByContentType()
  return (
    <MenuItem
      tabIndex={0}
      sx={{
        display: size === "small" ? "block" : "flex",
        justifyContent: "space-between",
        textWrap: "balance"
      }}
      component={RouterLink}
      to={`${link}`}
      target="_blank"
      id="carrotAcademySearchTerm"
    >
      <Typography
        sx={(theme) => ({
          pointerEvents: "none",
          marginInlineEnd: theme.spacing(theme.tokens.spacing.md),
          marginBlockEnd:
            size === "small" ? theme.spacing(theme.tokens.spacing.xxs) : theme.spacing(theme.tokens.spacing.none)
        })}
      >
        {title}
      </Typography>
      <Typography sx={{ pointerEvents: "none" }} variant="body2">
        {type}
      </Typography>
    </MenuItem>
  )
}
