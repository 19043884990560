import { useQuery, UseQueryResult } from "@tanstack/react-query"
import { StatusCodes } from "http-status-codes"
import ResponseError from "#/types/responseError"
import buildCarrotApiRetryPolicy from "#/utils/CarrotApiRetryPolicy"
import { getCountries } from "#/lib/contentful/contentfulClient"

export type Country = {
  name: string
  code: string
}

type QueryResponse = Country[]

export default function useGetContentulCountries(): UseQueryResult<QueryResponse, ResponseError> {
  return useQuery<QueryResponse, ResponseError>(
    ["Contentful Countries"],
    async () => {
      try {
        const countries = await getCountries()
        return countries
          .filter(({ fields }) => fields)
          .map(({ fields: { name, alpha2CountryCode } }) => ({ name, code: alpha2CountryCode }))
      } catch (error) {
        if (error.statusCode === StatusCodes.NOT_FOUND) {
          return null
        }
        throw error
      }
    },
    {
      refetchOnWindowFocus: false,
      retry: buildCarrotApiRetryPolicy(3)
    }
  )
}
