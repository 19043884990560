import React from "react"
import { reportError, reportErrorMessage } from "../utils/ProviderFinderErrorReporting"

const ErrorReportingContext = React.createContext(null)

export function ProviderFinderErrorReportingProvider({ children }: any): JSX.Element {
  return (
    <ErrorReportingContext.Provider
      value={{
        reportErrorMessage,
        reportError
      }}
    >
      {children}
    </ErrorReportingContext.Provider>
  )
}
