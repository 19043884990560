import { useQuery, UseQueryResult } from "@tanstack/react-query"
import { StatusCodes } from "http-status-codes"
import ResponseError from "#/types/responseError"
import buildCarrotApiRetryPolicy from "#/utils/CarrotApiRetryPolicy"
import GuidesClient from "../lib/GuidesClient"
import Settings from "#/utils/CarrotConfig"

const client = new GuidesClient(Settings.CARROT_BACKEND_URL)

export function useGetCountByGuideEmployee(guideId: string): UseQueryResult<number, ResponseError> {
  return useQuery<number, ResponseError>(
    ["useGetCountByGuideEmployee", guideId],
    async () => {
      try {
        const response = await client.getCountByGuideEmployee(guideId)
        return response ? response : null
      } catch (error) {
        if (error.status === StatusCodes.INTERNAL_SERVER_ERROR) {
          return null
        }
        throw error
      }
    },
    {
      refetchOnWindowFocus: false,
      retry: buildCarrotApiRetryPolicy(3)
    }
  )
}
