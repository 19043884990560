export const enum TextDirection {
  RightToLeft = "rtl",
  LeftToRight = "ltr"
}

export class Locale {
  private static readonly _locales: Locale[] = []

  public readonly languageTag: string
  public readonly displayName: string
  public readonly textDirection: TextDirection
  public readonly vimeoLanguageTag: string
  public readonly youtubeLanguageTag: string
  public readonly oneTrustLanguageTag?: string
  public readonly qualtricsLanguageCode: string

  protected constructor({
    languageTag,
    displayName,
    textDirection,
    vimeoLanguageTag,
    youtubeLanguageTag,
    oneTrustLanguageTag = languageTag,
    qualtricsLanguageCode
  }: Locale) {
    this.languageTag = languageTag
    this.displayName = displayName
    this.textDirection = textDirection

    /*
     * Our locale codes do not always map to Vimeo's
     * See the following:
     * https://carrotfertility.atlassian.net/wiki/spaces/OE/pages/2344714333/Adding+a+New+Language
     * https://help.vimeo.com/hc/en-us/articles/12426172156817-What-languages-can-I-use-for-captions-and-subtitles
     */
    this.vimeoLanguageTag = vimeoLanguageTag

    /*
     * Youtube uses ISO 639-1 two-letter language codes
     * See the following:
     * https://developers.google.com/youtube/player_parameters
     * https://www.loc.gov/standards/iso639-2/php/code_list.php
     */
    this.youtubeLanguageTag = youtubeLanguageTag

    this.oneTrustLanguageTag = oneTrustLanguageTag

    /*
     * Qualtrics language codes must be in all-caps and often do not contain a region subtag where not needed to differentiate
     * https://www.qualtrics.com/support/survey-platform/survey-module/survey-tools/translate-survey/#AvailableLanguageCodes
     */
    this.qualtricsLanguageCode = qualtricsLanguageCode

    Locale._locales.push(this)
  }

  toString() {
    return this.languageTag
  }

  static values(): ReadonlyArray<Locale> {
    return Locale._locales
  }

  static getLocaleFromString(languageTag: string): Locale | undefined {
    return Locale.values().find((locale) => locale.toString() === languageTag)
  }

  static getDisplayNameFromString(languageTag: string): string | undefined {
    return Locale.getLocaleFromString(languageTag)?.displayName
  }
}

export class SupportedLocale extends Locale {
  private static readonly _supportedLocales: SupportedLocale[] = []

  protected constructor(supportedLocale: SupportedLocale) {
    super(supportedLocale)

    SupportedLocale._supportedLocales.push(this)
  }

  static readonly English_UnitedStates = new SupportedLocale({
    languageTag: "en-US",
    displayName: "English (U.S.)",
    textDirection: TextDirection.LeftToRight,
    vimeoLanguageTag: "", // intentionally blank because we don't want English subtitles to show by default
    youtubeLanguageTag: "", // intentionally blank because we don't want English subtitles to show by default
    qualtricsLanguageCode: "EN"
  })

  static readonly Spanish_UnitedStates = new SupportedLocale({
    languageTag: "es-US",
    displayName: "Español (EE. UU.)",
    textDirection: TextDirection.LeftToRight,
    vimeoLanguageTag: "es-419",
    youtubeLanguageTag: "es", // There is no es-US equivalant
    qualtricsLanguageCode: "ES"
  })

  static readonly French_Canada = new SupportedLocale({
    languageTag: "fr-CA",
    displayName: "Français (Canada)",
    textDirection: TextDirection.LeftToRight,
    vimeoLanguageTag: "fr-CA",
    youtubeLanguageTag: "fr",
    qualtricsLanguageCode: "FR-CA"
  })
  static readonly German_Germany = new SupportedLocale({
    languageTag: "de-DE",
    displayName: "Deutsch (Deutschland)",
    textDirection: TextDirection.LeftToRight,
    vimeoLanguageTag: "de",
    youtubeLanguageTag: "de",
    qualtricsLanguageCode: "DE"
  })
  static readonly Portuguese_Brazil = new SupportedLocale({
    languageTag: "pt-BR",
    displayName: "Português (Brasil)",
    textDirection: TextDirection.LeftToRight,
    vimeoLanguageTag: "pt-BR",
    youtubeLanguageTag: "pt",
    qualtricsLanguageCode: "PT-BR"
  })
  static readonly Hindi_India = new SupportedLocale({
    languageTag: "hi-IN",
    displayName: "हिन्दी (भारत)",
    textDirection: TextDirection.LeftToRight,
    vimeoLanguageTag: "hi",
    youtubeLanguageTag: "hi",
    qualtricsLanguageCode: "HI"
  })
  static readonly Thai_Thailand = new SupportedLocale({
    languageTag: "th-TH",
    displayName: "ไทย (ประเทศไทย)",
    textDirection: TextDirection.LeftToRight,
    vimeoLanguageTag: "th",
    youtubeLanguageTag: "th",
    qualtricsLanguageCode: "TH"
  })
  static readonly Turkish_Turkey = new SupportedLocale({
    languageTag: "tr-TR",
    displayName: "Türkçe (Türkiye)",
    textDirection: TextDirection.LeftToRight,
    vimeoLanguageTag: "tr",
    youtubeLanguageTag: "tr",
    qualtricsLanguageCode: "TR"
  })
  static readonly Italian_Italy = new SupportedLocale({
    languageTag: "it-IT",
    displayName: "Italiano (Italia)",
    textDirection: TextDirection.LeftToRight,
    vimeoLanguageTag: "it",
    youtubeLanguageTag: "it",
    qualtricsLanguageCode: "IT"
  })
  static readonly Spanish_Spain = new SupportedLocale({
    languageTag: "es-ES",
    displayName: "Español (España)",
    textDirection: TextDirection.LeftToRight,
    vimeoLanguageTag: "es-ES",
    youtubeLanguageTag: "es",
    qualtricsLanguageCode: "ES-ES"
  })
  static readonly Serbian_Serbia = new SupportedLocale({
    languageTag: "sr-Latn-RS",
    displayName: "srpski (Srbija)",
    textDirection: TextDirection.LeftToRight,
    vimeoLanguageTag: "sr",
    oneTrustLanguageTag: "sr",
    youtubeLanguageTag: "sr",
    qualtricsLanguageCode: "SR"
  })
  static readonly Dutch_Netherlands = new SupportedLocale({
    languageTag: "nl-NL",
    displayName: "Nederlands (Nederland)",
    textDirection: TextDirection.LeftToRight,
    vimeoLanguageTag: "nl",
    youtubeLanguageTag: "nl",
    qualtricsLanguageCode: "NL"
  })
  static readonly Romanian_Romania = new SupportedLocale({
    languageTag: "ro-RO",
    displayName: "română (România)",
    textDirection: TextDirection.LeftToRight,
    vimeoLanguageTag: "ro",
    youtubeLanguageTag: "ro",
    qualtricsLanguageCode: "RO"
  })
  static readonly Polish_Poland = new SupportedLocale({
    languageTag: "pl-PL",
    displayName: "polski (Polska)",
    textDirection: TextDirection.LeftToRight,
    vimeoLanguageTag: "pl",
    youtubeLanguageTag: "pl",
    qualtricsLanguageCode: "PL"
  })

  static readonly Spanish_Argentina = new SupportedLocale({
    languageTag: "es-AR",
    displayName: "español (Argentina)",
    textDirection: TextDirection.LeftToRight,
    vimeoLanguageTag: "es-419",
    youtubeLanguageTag: "es",
    qualtricsLanguageCode: "ES"
  })

  static readonly French_France = new SupportedLocale({
    languageTag: "fr-FR",
    displayName: "français (France)",
    textDirection: TextDirection.LeftToRight,
    vimeoLanguageTag: "fr",
    youtubeLanguageTag: "fr",
    qualtricsLanguageCode: "FR"
  })
  static readonly Greek_Greece = new SupportedLocale({
    languageTag: "el-GR",
    displayName: "Ελληνικά (Ελλάδα)",
    textDirection: TextDirection.LeftToRight,
    vimeoLanguageTag: "el",
    youtubeLanguageTag: "el",
    qualtricsLanguageCode: "EL"
  })
  static readonly Russian_Russia = new SupportedLocale({
    languageTag: "ru-RU",
    displayName: "Русский (Россия)",
    textDirection: TextDirection.LeftToRight,
    vimeoLanguageTag: "ru",
    youtubeLanguageTag: "ru",
    qualtricsLanguageCode: "RU"
  })
  static readonly SimplifiedChinese_China = new SupportedLocale({
    languageTag: "zh-CN",
    displayName: "简体中文",
    textDirection: TextDirection.LeftToRight,
    vimeoLanguageTag: "zh",
    youtubeLanguageTag: "zh-CN",
    qualtricsLanguageCode: "ZH-S"
  })
  static readonly TraditionalChinese_Taiwan = new SupportedLocale({
    languageTag: "zh-TW",
    displayName: "繁體中文 (臺灣)",
    textDirection: TextDirection.LeftToRight,
    vimeoLanguageTag: "zh-tw",
    youtubeLanguageTag: "zh-TW",
    qualtricsLanguageCode: "ZH-T"
  })
  static readonly BahasaIndonesia_Indonesia = new SupportedLocale({
    languageTag: "id-ID",
    displayName: "Bahasa Indonesia (Indonesia)",
    textDirection: TextDirection.LeftToRight,
    vimeoLanguageTag: "id",
    youtubeLanguageTag: "id",
    qualtricsLanguageCode: "ID"
  })
  static readonly Vietnamese_Vietnam = new SupportedLocale({
    languageTag: "vi-VN",
    displayName: "Tiếng Việt (Việt Nam)",
    textDirection: TextDirection.LeftToRight,
    vimeoLanguageTag: "vi",
    youtubeLanguageTag: "vi",
    qualtricsLanguageCode: "VI"
  })
  static readonly Arabic_MSA = new SupportedLocale({
    languageTag: "ar",
    displayName: "العربية",
    textDirection: TextDirection.RightToLeft,
    vimeoLanguageTag: "ar",
    youtubeLanguageTag: "ar",
    qualtricsLanguageCode: "AR"
  })
  static readonly Urdu_Pakistan = new SupportedLocale({
    languageTag: "ur-PK",
    displayName: "اردو (پاکستان)",
    textDirection: TextDirection.RightToLeft,
    oneTrustLanguageTag: "ur",
    vimeoLanguageTag: "ur",
    youtubeLanguageTag: "ur",
    qualtricsLanguageCode: "UR"
  })
  static readonly Japanese_Japan = new SupportedLocale({
    languageTag: "ja-JP",
    displayName: "日本語（日本）",
    textDirection: TextDirection.LeftToRight,
    vimeoLanguageTag: "ja",
    youtubeLanguageTag: "ja",
    qualtricsLanguageCode: "JA"
  })

  static values(): ReadonlyArray<Locale> {
    return SupportedLocale._supportedLocales
  }

  static isSupportedLocale(languageTag: string): boolean {
    return SupportedLocale.values().some((locale) => locale.languageTag === languageTag)
  }
}
export const DEFAULT_LOCALE = SupportedLocale.English_UnitedStates

export class PseudoLocale extends Locale {
  private static readonly _pseudoLocales: PseudoLocale[] = []

  protected constructor(locale: PseudoLocale) {
    super(locale)

    PseudoLocale._pseudoLocales.push(this)
  }

  static readonly PseudoElongated = new PseudoLocale({
    languageTag: "xx-LS",
    displayName: "Elongated",
    textDirection: TextDirection.LeftToRight,
    vimeoLanguageTag: "",
    youtubeLanguageTag: "",
    oneTrustLanguageTag: "en-US",
    qualtricsLanguageCode: "EN"
  })

  static readonly PseudoAccented = new PseudoLocale({
    languageTag: "en-XA",
    displayName: "Accented",
    textDirection: TextDirection.LeftToRight,
    vimeoLanguageTag: "",
    youtubeLanguageTag: "",
    oneTrustLanguageTag: "en-US",
    qualtricsLanguageCode: "EN"
  })

  static readonly PseudoBidrectional = new PseudoLocale({
    languageTag: "en-XB",
    displayName: "Bidirectional",
    textDirection: TextDirection.RightToLeft,
    vimeoLanguageTag: "",
    youtubeLanguageTag: "",
    oneTrustLanguageTag: "en-US",
    qualtricsLanguageCode: "EN"
  })

  static values(): ReadonlyArray<Locale> {
    return PseudoLocale._pseudoLocales
  }

  static isPseudoLocale(languageTag: string): boolean {
    return PseudoLocale._pseudoLocales.some((locale) => locale.languageTag === languageTag)
  }
}
