import React, { FC, useEffect } from "react"
import { Container, FlexContainer } from "@carrotfertility/carotene"
import { Typography } from "@carrotfertility/carotene-core"
import { Steps } from "../workflow/steps"
import { useStateMachine } from "../../context/stateMachine/StateMachineContext"
import { useProgressTracker } from "../../views/modal/ProgressTracker"
import { IlloPercentCircle } from "../../views/atoms/Illustrations"
import { BenefitEnrollmentModalContinueButton } from "../shared"
import { useBenefitEnrollment } from "../../context/enrollment/BenefitEnrollmentContext"
import { Workflows } from "../workflow/workflows"
import { GetWorkflow } from "./BenefitEnrollmentFlow"
import { FormattedMessage } from "react-intl"

const ViewName = Steps.INTRO_INFORMATIONAL

function getIntroText(workflowType: Workflows): JSX.Element {
  switch (workflowType) {
    case Workflows.ACA_ONLY:
      return (
        <>
          <Container padding="none" stack="medium">
            <Typography color={(theme) => theme.palette.text.secondary}>
              <FormattedMessage
                defaultMessage="Keeping this information up to date allows you to access your Carrot funds in a way that complies with
               tax law."
              />
            </Typography>
          </Container>
        </>
      )
    default:
      return (
        <>
          <Container padding="none" stack="medium">
            <Typography color={(theme) => theme.palette.text.secondary}>
              <FormattedMessage defaultMessage="You may want to have your insurance card nearby. If you have a partner, we'll need information on their insurance as well." />
            </Typography>
          </Container>
        </>
      )
  }
}

const IntroToInsuranceFlow: FC = () => {
  const { needsBenefitEnrollment } = useBenefitEnrollment()
  const { setNextStep } = useStateMachine(ViewName, null)
  const workflowType = GetWorkflow()

  const { hideProgress } = useProgressTracker()

  useEffect(() => {
    hideProgress()
  }, [hideProgress])

  return (
    <div>
      <FlexContainer justifyContent="center" padding="none" stack="medium">
        <IlloPercentCircle />
      </FlexContainer>
      <Typography
        id="step-heading"
        variant="h2"
        color={(theme) => theme.palette.text.primary}
        paddingBottom={(theme) => theme.tokens.spacing.md}
      >
        <FormattedMessage defaultMessage="To access your Carrot funds, tell us about your health insurance" />
      </Typography>
      {getIntroText(workflowType)}
      <BenefitEnrollmentModalContinueButton
        onClick={() => setNextStep(ViewName, needsBenefitEnrollment ? "" : "SKIP_THIS_FLOW")}
      />
    </div>
  )
}

export { IntroToInsuranceFlow }
