import React, { useMemo } from "react"
import { toggleDymamicContentTogglesTool } from "#/redux/actions/testUserActions"
import useShowBankDetails from "#/components/bank-details/hooks/useShowBankDetails"
import { useAuthentication } from "#/components/context/authentication/AuthenticationProvider"
import { useBenefitEnrollment } from "#/components/context/enrollment/BenefitEnrollmentContext"
import { useDispatch } from "react-redux"
import { UserDropdown as InternalUserDropdown } from "./user-dropdown"
import { useIntl } from "react-intl"
import { useCurrentUser } from "#/components/context/user/UserContext"

function useGetUserDropdownName() {
  const currentUser = useCurrentUser()
  const firstName = currentUser?.firstName
  const preferredName = currentUser?.preferredName
  const intl = useIntl()

  const accountName = useMemo(() => {
    const defaultAccountName = intl.formatMessage({ defaultMessage: "Account" })
    if (preferredName) {
      return preferredName.length < 12 ? preferredName : defaultAccountName
    }
    return firstName?.length < 12 ? firstName : defaultAccountName
  }, [firstName, preferredName, intl])

  return {
    name: accountName
  }
}

export function UserDropdown() {
  const intl = useIntl()
  const currentUser = useCurrentUser()
  const dispatch = useDispatch()
  const { name } = useGetUserDropdownName()
  const { shouldDisplayInsuranceDetails } = useBenefitEnrollment()
  const { showBankDetails } = useShowBankDetails()
  const { handleSignOut } = useAuthentication()

  const isTestUser = currentUser?.isTestUser

  return (
    <InternalUserDropdown
      name={name}
      navigationLinks={[
        {
          id: "nav-account-settings-link",
          label: intl.formatMessage({ defaultMessage: "Account settings" }),
          href: "/account"
        },
        {
          condition: showBankDetails,
          id: "nav-bank-details-link",
          label: intl.formatMessage({ defaultMessage: "Bank details" }),
          href: "/bank-details"
        },
        {
          condition: shouldDisplayInsuranceDetails,
          id: "nav-insurance-details-link",
          label: intl.formatMessage({ defaultMessage: "Insurance details" }),
          href: "/insurance-details"
        },
        {
          condition: isTestUser,
          id: "nav-dynamic-content-tool",
          label: "Dynamic Content Tool", // intentionally not translated so it can be easily found by internal users
          onClick: (handleClose) => {
            handleClose()
            dispatch(toggleDymamicContentTogglesTool())
          }
        },
        {
          id: "nav-sign-out-link",
          label: intl.formatMessage({ defaultMessage: "Sign out" }),
          onClick: (handleClose) => {
            handleClose()
            handleSignOut()
          }
        }
      ]}
    />
  )
}
