import React, { FC } from "react"
import { Redirect, Route } from "react-router-dom"
import ActivateContainer from "#/components/containers/activate/ActivateContainer"
import ActivateDone from "#/components/views/activate/ActivateDone"
import ActivateSuccess from "../activate/ActivateSuccess"
import { useSelector } from "react-redux"
import { getCarrotLite } from "#/redux/reducers/companyInfo"
import { CarrotRoute } from "#/utils/CarrotUrls"
import { PageNotFound } from "../error/PageNotFound"
import { Switch } from "#/services/routing/Switch"
import { IvfRequirements } from "./IvfRequirements"

const ReimbursementsRouter: FC = () => {
  const carrotLite = useSelector(getCarrotLite)

  return (
    <Switch>
      {carrotLite && <Redirect to="/" />}
      <Redirect path="/reimbursements/upload" to="/transactions" />
      <Redirect exact path="/reimbursements" to="/transactions" />
      <Route exact path="/reimbursements/activate" component={ActivateContainer} />
      <Route exact path="/reimbursements/activate/done" component={ActivateDone} />
      <Route exact path="/reimbursements/activate/success" component={ActivateSuccess} />
      <Route exact path="/reimbursements/ivf-requirements/:expenseId?" component={IvfRequirements} />
      <Route exact path={`${CarrotRoute.IvfRequirementsSigned}/:expenseId?`} component={IvfRequirements} />
      <Route component={PageNotFound} />
    </Switch>
  )
}

export { ReimbursementsRouter }
