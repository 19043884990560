import React from "react"
import { Link, Alert, Box, Stack } from "@carrotfertility/carotene-core"
import { FormattedMessage } from "react-intl"
import { DoulaConditions } from "./utils/doulaAttestationConditions"
import { useCurrentUser } from "#/components/context/user/UserContext"

function AlertGuidanceVariant({ doulaConditions }: { doulaConditions: DoulaConditions }) {
  const { noPartnersEligible, anyLegalPartnerEligible, onlyLegalPartnerOnMedicalPlanEligible } = doulaConditions
  const currentUser = useCurrentUser()

  if (noPartnersEligible) {
    return (
      <FormattedMessage
        defaultMessage="You can only use funds for eligible services you receive. Partners are not covered. <link>Visit your benefit guide</link> for more information."
        values={{
          link: (linkText) => (
            <Link color="inherit" target="_blank" href={"/your-benefit-guide/coverage/pregnancy-and-postpartum"}>
              {linkText}
            </Link>
          )
        }}
      />
    )
  }
  if (anyLegalPartnerEligible) {
    return (
      <FormattedMessage
        defaultMessage="You can only use funds for eligible services received by you, your spouse, or your legal partner. <link>Visit your benefit guide</link> for more information."
        values={{
          link: (linkText) => (
            <Link color="inherit" target="_blank" href={"/your-benefit-guide/coverage/pregnancy-and-postpartum"}>
              {linkText}
            </Link>
          )
        }}
      />
    )
  }
  if (onlyLegalPartnerOnMedicalPlanEligible) {
    return (
      <>
        <Stack gap={(theme) => theme.spacing(theme.tokens.spacing.md)}>
          <FormattedMessage
            defaultMessage="You can only use funds for eligible services received by you, your spouse, or your legal partner. To be covered by your benefit, your partner must be enrolled in a medical plan sponsored by {segmentName}."
            values={{ segmentName: currentUser?.company?.companyDisplayName }}
          />
          <Box display="flex" gap={(theme) => theme.spacing(theme.tokens.spacing.md)} />
        </Stack>
        <FormattedMessage
          defaultMessage="<link>Visit your benefit guide</link> for more information."
          values={{
            link: (linkText) => (
              <Link color="inherit" target="_blank" href={"/your-benefit-guide/coverage/pregnancy-and-postpartum"}>
                {linkText}
              </Link>
            )
          }}
        />
      </>
    )
  }
}

export function DoulaAttestationFormAlert({
  isError,
  doulaConditions
}: {
  isError: boolean
  doulaConditions: DoulaConditions
}) {
  const { allPartnersEligible, anyPartnerOnMedicalPlanEligible } = doulaConditions

  const noAlertConditions = allPartnersEligible || anyPartnerOnMedicalPlanEligible

  return isError ? (
    <Alert severity="error">
      <FormattedMessage
        defaultMessage="Something went wrong on our end. Please try again. If the problem persists, <link>contact us.</link>"
        values={{
          link: (linkText) => (
            <Link color="inherit" target="_blank" href={"/talk-to-carrot/care-navigation"}>
              {linkText}
            </Link>
          )
        }}
      />
    </Alert>
  ) : (
    <>
      {!noAlertConditions && (
        <Alert>
          <AlertGuidanceVariant doulaConditions={doulaConditions} />
        </Alert>
      )}
    </>
  )
}
