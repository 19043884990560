import { createSelector } from "reselect"
import { getDeductibleStatus } from "#/redux/reducers/userInfo"
import { DeductibleStatus } from "carrot-api/types/DeductibleStatus"
import { getDeductibleRemaining, getSourceName } from "#/redux/reducers/deductibleStatus"

const getHasDeductible = createSelector([getDeductibleStatus], (deductibleStatus: DeductibleStatus) => {
  return deductibleStatus ? deductibleStatus !== DeductibleStatus.NoDeductible : null
})

const getHasDeductibleRemaining = createSelector(
  [getHasDeductible, getDeductibleRemaining],
  (hasDeductible, deductibleRemaining) => {
    return hasDeductible && (deductibleRemaining === null || deductibleRemaining > 0)
  }
)

const getCompanyHasCarrotManagedDeductibleEnabled = createSelector([getSourceName], (deductibleSourceName) => {
  return deductibleSourceName === "CarrotSpecificDeductibleSource"
})

export { getHasDeductible, getHasDeductibleRemaining, getCompanyHasCarrotManagedDeductibleEnabled }
