import React from "react"
import { useToggle } from "../../../../utils/Hooks"
import { FormattedMessage, FormattedNumber, useIntl } from "react-intl"
import EmployeeOnlyModalContainer from "../../../employee-only-containers/EmployeeOnlyModalContainer"
import { Modal } from "../../modal/Modal"
import { Container, H2, H5, Icon } from "@carrotfertility/carotene"
import { Spacer } from "../../atoms/Atoms"
import { BodySmall } from "../../atoms/Bodies"
import { useSelector } from "react-redux"
import { getCompanyCurrencyCode } from "#/redux/reducers/companyInfo"
import { ExpenseState } from "../../../../utils/ExpenseState"

const TransactionAmounts = ({
  // @ts-expect-error TS(7031) FIXME: Binding element 'id' implicitly has an 'any' type.
  id,
  // @ts-expect-error TS(7031) FIXME: Binding element 'style' implicitly has an 'any' ty... Remove this comment to see the full error message
  style,
  // @ts-expect-error TS(7031) FIXME: Binding element 'primaryAmount' implicitly has an ... Remove this comment to see the full error message
  primaryAmount,
  // @ts-expect-error TS(7031) FIXME: Binding element 'primaryCurrencyCode' implicitly h... Remove this comment to see the full error message
  primaryCurrencyCode,
  // @ts-expect-error TS(7031) FIXME: Binding element 'secondaryAmount' implicitly has a... Remove this comment to see the full error message
  secondaryAmount,
  // @ts-expect-error TS(7031) FIXME: Binding element 'secondaryCurrencyCode' implicitly... Remove this comment to see the full error message
  secondaryCurrencyCode
}) => {
  const intl = useIntl()
  if (secondaryAmount == null || secondaryCurrencyCode == null) {
    return (
      <span id={id} style={{ fontWeight: "bold", ...style }}>
        <FormattedNumber
          value={primaryAmount}
          style="currency"
          currency={primaryCurrencyCode}
          currencyDisplay="narrowSymbol"
        />
      </span>
    )
  }
  return (
    <span id={`${id}-with-secondary`} style={{ fontWeight: "bold", ...style }}>
      <FormattedMessage
        defaultMessage="{primaryAmount} <span>({secondaryAmount})</span>"
        values={{
          primaryAmount: intl.formatNumber(primaryAmount, {
            style: "currency",
            currency: primaryCurrencyCode,
            currencyDisplay: "narrowSymbol"
          }),
          secondaryAmount: intl.formatNumber(secondaryAmount, {
            style: "currency",
            currency: secondaryCurrencyCode,
            currencyDisplay: "narrowSymbol"
          }),
          span: (spanContent) => <span className="black-60">{spanContent}</span>
        }}
        description={"Amount of a transaction in a primary and secondary currency"}
      />
    </span>
  )
}

// @ts-expect-error TS(7031) FIXME: Binding element 'reimbursement' implicitly has an ... Remove this comment to see the full error message
export const ReimbursementAmount = ({ reimbursement }) => {
  const [isAmountsModalOpen, toggleIsAmountsModalOpen] = useToggle(false)
  const intl = useIntl()
  const AmountsInfoModal = () => (
    <EmployeeOnlyModalContainer onClose={toggleIsAmountsModalOpen}>
      <Modal
        titleText={intl.formatMessage({
          defaultMessage: "What is this amount?"
        })}
        onExit={toggleIsAmountsModalOpen}
        underlayClickExits={true}
      >
        <Container paddingLeftRight="huge" alignItems="center">
          <H2>
            <FormattedMessage defaultMessage="What is this amount?" />
          </H2>
          <Spacer height={1.5} />
          <BodySmall>
            <FormattedMessage defaultMessage="The number in parentheses is the currency you paid in." />
          </BodySmall>
          <Spacer height={3} />
        </Container>
      </Modal>
    </EmployeeOnlyModalContainer>
  )

  const displayAdjustedAmount =
    reimbursement.adjustmentDatetime &&
    reimbursement.originalAmount &&
    reimbursement.originalAmount !== reimbursement.amount

  const displayAmountInMerchantCurrency = reimbursement.currencyCode !== reimbursement.merchantCurrency
  const companyCurrencyCode = useSelector(getCompanyCurrencyCode)
  const pendingDisplayAmount = intl
    .formatNumber(0.0, {
      style: "currency",
      currency: companyCurrencyCode,
      currencyDisplay: "narrowSymbol"
    })
    .replaceAll("0", "-")
  return !reimbursement.isCardExpense &&
    (reimbursement.expenseState === ExpenseState.RECEIVED || reimbursement.expenseState === ExpenseState.ON_HOLD) ? (
    <H5>{pendingDisplayAmount}</H5>
  ) : (
    <>
      <div className="flex-column">
        <H5>
          {displayAdjustedAmount && (
            <>
              <TransactionAmounts
                id="adjusted-transaction-amount"
                primaryAmount={reimbursement.originalAmount / 100}
                primaryCurrencyCode={reimbursement.currencyCode}
                secondaryAmount={
                  displayAmountInMerchantCurrency ? reimbursement.originalAmountInMerchantCurrency / 100 : null
                }
                secondaryCurrencyCode={displayAmountInMerchantCurrency ? reimbursement.merchantCurrency : null}
                style={{ textDecoration: "line-through" }}
              />
              {/* @ts-expect-error TS(2322) FIXME: Type '{ name: string; color: "rgba(0, 0, 0, 0.4)";... Remove this comment to see the full error message */}
              <Icon name="rightArrow" color="rgba(0, 0, 0, 0.4)" size="10px" style={{ margin: "0 8px" }} />
            </>
          )}
          {/* @ts-expect-error TS(2741) FIXME: Property 'style' is missing in type '{ id: string;... Remove this comment to see the full error message */}
          <TransactionAmounts
            id="transaction-amount"
            primaryAmount={reimbursement.amount / 100}
            primaryCurrencyCode={reimbursement.currencyCode}
            secondaryAmount={displayAmountInMerchantCurrency ? reimbursement.amountInMerchantCurrency / 100 : null}
            secondaryCurrencyCode={displayAmountInMerchantCurrency ? reimbursement.merchantCurrency : null}
          />
        </H5>
      </div>
      {displayAmountInMerchantCurrency ? (
        <div className="flex-column">
          <button
            id="btn-merchant-amount-more-info"
            style={{
              margin: "0 4px",
              border: "none",
              display: "flex",
              borderRadius: "100%",
              padding: "4px"
            }}
            className="pointer bg-white hover-bg-light-gray"
            aria-label={intl.formatMessage({
              defaultMessage: "Learn more about these amounts"
            })}
            onClick={() => toggleIsAmountsModalOpen()}
          >
            {/* @ts-expect-error TS(2322) FIXME: Type '{ name: string; className: string; size: "12... Remove this comment to see the full error message */}
            <Icon name="helpCircle" className="black-60" size="12px" />
          </button>
        </div>
      ) : null}
      {isAmountsModalOpen && <AmountsInfoModal />}
    </>
  )
}
