import React, { FC } from "react"
import { CarrotCardModuleSharedProps } from "#/components/views/molecules/CarrotCardModuleContainer"
import { useBenefitEnrollment } from "#/components/context/enrollment/BenefitEnrollmentContext"
import { BenefitAvailabilityStatus } from "#/types/benefitEnrollmentStatus"
import { useHistory } from "react-router-dom"
import Locations from "#/components/views/home/navigation"
import { useIntl } from "react-intl"
import { Button } from "@carrotfertility/carotene-core"
import CardStates from "#/utils/CardStates"
import { CarrotCardModuleTemplate } from "./carrot-card-module-template"

const CarrotCardNeedsEnrollmentModule: FC<CarrotCardModuleSharedProps> = () => {
  const { availabilityStatus, status } = useBenefitEnrollment()
  const history = useHistory()
  const intl = useIntl()

  const onClickUpdate = (): void => history.push(Locations.Home.EnrollmentFlowActive)

  const isExpired = availabilityStatus === BenefitAvailabilityStatus.ENROLLMENT_PLAN_EXPIRED

  const expiredBodyMessage = status.cardLockedDueToNeedsEnrollmentDateTime
    ? intl.formatMessage(
        {
          defaultMessage:
            "Your Carrot Card and funds have been locked because your insurance plan info expired {formattedDate}"
        },
        {
          formattedDate: intl.formatDate(status.cardLockedDueToNeedsEnrollmentDateTime, {
            year: "numeric",
            month: "long",
            day: "numeric"
          })
        }
      )
    : intl.formatMessage({
        defaultMessage: "Your Carrot Card and funds have been locked because your insurance plan info expired"
      })

  const bodyMessage = isExpired
    ? expiredBodyMessage
    : intl.formatMessage({
        defaultMessage:
          "Your Carrot benefit has been updated. To access your funds, you'll need to share some information about your insurance plan."
      })

  const buttonLabel = isExpired
    ? intl.formatMessage({ defaultMessage: "Update plan info" })
    : intl.formatMessage({ defaultMessage: "Share plan info" })

  return (
    <CarrotCardModuleTemplate
      cardState={CardStates.NEEDS_ENROLLMENT}
      body={bodyMessage}
      links={[
        <Button
          key="carrot-card-plan-info"
          onClick={onClickUpdate}
          variant="outlined"
          color="secondary"
          sx={(theme) => ({ marginBlockStart: theme.tokens.spacing.md })}
        >
          {buttonLabel}
        </Button>
      ]}
    />
  )
}

export { CarrotCardNeedsEnrollmentModule }
