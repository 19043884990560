import { useQuery } from "@tanstack/react-query"
import buildCarrotApiRetryPolicy from "#/utils/CarrotApiRetryPolicy"
import ResponseError from "#/types/responseError"
import { reportErrorMessage } from "#/utils/ErrorReporting"
import { getDisabledSpecialistTileDisplay } from "#/lib/contentful/contentfulClient"
import { EntryWithoutUnresolvableLinks } from "#/utils/ContentTypes"
import { TypeTileDisplaySkeleton } from "#/types/contentful"

const disabledSpecialistDisplayQueryKey = "getDisabledSpecialistTileDisplay"

export function useGetDisabledSpecialistTileDisplay(locale: string) {
  return useQuery<EntryWithoutUnresolvableLinks<TypeTileDisplaySkeleton>, ResponseError>(
    [disabledSpecialistDisplayQueryKey],
    () => getDisabledSpecialistTileDisplay(locale),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      refetchOnReconnect: false,
      retry: buildCarrotApiRetryPolicy(3),
      onError: (error) => reportErrorMessage(`Failed to fetch specialist tile with error: ${error}`)
    }
  )
}
