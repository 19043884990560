import React from "react"
import { ArrowSubmitButton, ReimbursementDialogFraming, BackArrow } from "../shared/layouts"
import { Progress } from "#/components/views/modal/Progress"
import { useStateMachine } from "#/components/context/stateMachine/StateMachineV2"
import { useReimbursementContext } from "#/components/context/reimbursement/GetReimbursedContext"
import { FormattedMessage, useIntl } from "react-intl"
import { FormRadioButtonGroup } from "../../../services/common-forms"
import { Form, DialogActionsBar } from "@carrotfertility/carotene-core-x"
import { Box, DialogContentText, DialogTitle } from "@carrotfertility/carotene-core"
export const AboutThisExpenseRelatedToStepchildAdoption = (): JSX.Element => {
  const { send, back } = useStateMachine()
  const { state, updateStepchildAdoption } = useReimbursementContext()
  // @ts-expect-error TS(7006) FIXME: Parameter 'value' implicitly has an 'any' type.
  const onSubmit = (value): void => {
    updateStepchildAdoption(value.isStepchildAdoption)
    send("")
  }
  const intl = useIntl()

  return (
    <>
      <Progress
        numSteps={3}
        step={2}
        label={intl.formatMessage({ defaultMessage: "About This Expense" })}
        inactiveStepBackgroundColor="#DBE6EE"
      />
      <BackArrow onClick={back} />
      <Form defaultValues={{ isStepchildAdoption: state.isStepchildAdoption }} onSubmit={onSubmit}>
        <ReimbursementDialogFraming>
          <DialogTitle id="step-heading">
            <FormattedMessage defaultMessage="Is this related to adopting a spouse's child (stepparent adoption)?" />
          </DialogTitle>
          <Box
            sx={{ display: "flex", flexDirection: "column", gap: (theme) => theme.spacing(theme.tokens.spacing.lg) }}
          >
            <DialogContentText>
              <FormattedMessage
                defaultMessage="This helps us determine whether an expense is taxable so we can process reimbursements in a legally
            compliant way."
              />
            </DialogContentText>
            <FormRadioButtonGroup
              aria-labelledby="step-heading"
              name="isStepchildAdoption"
              controllerProps={{ rules: { required: intl.formatMessage({ defaultMessage: "Required" }) } }}
              options={[
                { label: intl.formatMessage({ defaultMessage: "Yes" }), value: "true" },
                { label: intl.formatMessage({ defaultMessage: "No" }), value: "false" }
              ]}
            />
          </Box>
        </ReimbursementDialogFraming>
        <DialogActionsBar
          primaryAction={
            <ArrowSubmitButton>
              <FormattedMessage defaultMessage="Continue" />
            </ArrowSubmitButton>
          }
        />
      </Form>
    </>
  )
}
