import React from "react"
import styled from "styled-components"
// @ts-expect-error TS(2307) FIXME: Cannot find module 'css/variables/_colors.scss' or... Remove this comment to see the full error message
import colors from "#/css/variables/_colors.scss"
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'loda... Remove this comment to see the full error message
import range from "lodash.range"
import { FlexContainer } from "@carrotfertility/carotene"
import { FontFamily } from "../../../css/fonts"

const ProgressBarSpacer = styled.div`
  inline-size: 6px;
`
const ProgressLabel = styled.div`
  font-family: ${FontFamily.gotham};
  margin-block-end: 0.5rem;
  display: inline-block;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: ${colors.darkBlue};
`

const FullProgressBar = styled.div<{ filled: boolean; inactiveStepBackgroundColor: string }>`
  flex: auto;
  background: ${(props) => (props.filled ? "#4984A8" : props.inactiveStepBackgroundColor)};
  border-radius: 4px;
  block-size: 4px;
`

// @ts-expect-error TS(7031) FIXME: Binding element 'label' implicitly has an 'any' ty... Remove this comment to see the full error message
const Progress = ({ inactiveStepBackgroundColor = "#FFFFFF", label, step, numSteps = 4 }) => (
  <>
    <ProgressLabel>{label}</ProgressLabel>
    <FlexContainer data-testid={"step number: " + step} stack="medium">
      {/* @ts-expect-error TS(7006) FIXME: Parameter 'index' implicitly has an 'any' type. */}
      {range(numSteps).map((index) => (
        <React.Fragment key={index}>
          <FullProgressBar inactiveStepBackgroundColor={inactiveStepBackgroundColor} filled={step > index} />
          {index < numSteps && <ProgressBarSpacer />}
        </React.Fragment>
      ))}
    </FlexContainer>
  </>
)

export { Progress }
