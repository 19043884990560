import actionTypes from "#/redux/actions/actionTypes"
import { reportError } from "#/utils/ErrorReporting"
import { signOut } from "./loginActions"
import { HttpErrors } from "#/utils/HttpErrors"
import { createRequestAction } from "./shared"
import { carrotClient } from "#/utils/CarrotClient"

const fetchUserInfo = () => async (dispatch: any) => {
  dispatch({
    type: actionTypes.FETCH_USER_INFO_REQUEST
  })

  let response

  try {
    response = await carrotClient.getCurrentUser()
  } catch (error) {
    if (error.name === HttpErrors.UNAUTHORIZED) {
      dispatch({
        type: actionTypes.SESSION_EXPIRED
      })
      return
    }

    error.message = "Error getting user info: " + error.message
    reportError(error)
    dispatch({
      type: actionTypes.FETCH_USER_INFO_FAILURE,
      response: true
    })
    return
  }

  await dispatch({
    type: actionTypes.FETCH_USER_INFO_SUCCESS,
    response
  })

  // @ts-expect-error TS(2339) FIXME: Property 'subsidy' does not exist on type 'UserInf... Remove this comment to see the full error message
  if (response.subsidy) {
    dispatch({
      type: actionTypes.FETCH_SUBSIDY_STATUS_SUCCESS,
      // @ts-expect-error TS(2339) FIXME: Property 'subsidy' does not exist on type 'UserInf... Remove this comment to see the full error message
      response: response.subsidy
    })
  }

  if (response.subsidies) {
    dispatch({
      type: actionTypes.FETCH_SUBSIDIES_SUCCESS,
      response: response.subsidies
    })
  }
}

const updateUserInfo = (userInfo: any) => async (dispatch: any) => {
  try {
    await carrotClient.updateUser(userInfo)
    await dispatch(fetchUserInfo())
  } catch (error) {
    if (error.name === HttpErrors.UNAUTHORIZED) {
      dispatch(signOut())
      return
    }
    throw error
  }
}

const updateAboutYouInfo = (aboutYouInfo: any) => async (dispatch: any) => {
  try {
    await carrotClient.updateAboutYou(aboutYouInfo)
    await dispatch(fetchUserInfo())
  } catch (error) {
    if (error.name === HttpErrors.UNAUTHORIZED) {
      dispatch(signOut())
      return
    }
    throw error
  }
}

const updateBenefitEnrollmentInfo = (userInfo: any) => async (dispatch: any) => {
  try {
    await carrotClient.updateUser(userInfo)
    await dispatch(getBenefitEnrollment())
  } catch (error) {
    if (error.name === HttpErrors.UNAUTHORIZED) {
      dispatch(signOut())
      return
    }
    throw error
  }
}

const getBenefitEnrollment = createRequestAction("FETCH_BENEFIT_ENROLLMENT", async function () {
  return await carrotClient.getBenefitEnrollment()
})

export { fetchUserInfo, updateUserInfo, updateAboutYouInfo, updateBenefitEnrollmentInfo }
