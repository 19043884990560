import { useQuery, UseQueryResult, useQueryClient } from "@tanstack/react-query"
import ResponseError from "../../../types/responseError"
import { Benefit } from "#/lib/carrot-api/types/Benefit"
import { carrotClient } from "#/utils/CarrotClient"

const ENDPOINT = "GetBenefit"

export const getBenefitCacheKey = (): (string | number)[] => [ENDPOINT]

export default function useBenefit(): UseQueryResult<Benefit, ResponseError> {
  return useQuery<Benefit, ResponseError>(getBenefitCacheKey(), () => carrotClient.getBenefit())
}

export function useCreateOverride(): (attributes: Benefit) => void {
  const queryClient = useQueryClient()
  return (attributes: Benefit) => {
    queryClient.setQueryDefaults(getBenefitCacheKey(), { staleTime: Infinity, cacheTime: Infinity })
    queryClient.setQueryData(getBenefitCacheKey(), attributes)
  }
}
