import { defineMessage, MessageDescriptor } from "react-intl"

export type InformationText = Record<
  string,
  {
    subText: MessageDescriptor | MessageDescriptor[]
    bulletPoints?: MessageDescriptor[]
    checkboxText: MessageDescriptor
  }
>

export const informationText: InformationText = {
  WNBA: {
    subText: [
      defineMessage({
        defaultMessage:
          "Carrot Card makes it easy to pay directly from your funds for eligible expenses such as fertility preservation, adoption, and gestational surrogacy services. A full list of eligible expenses can be found in your benefit guide."
      }),
      defineMessage({
        defaultMessage:
          "In addition to your Carrot benefit, WNBA offers fertility coverage through Cigna. This includes:"
      }),
      defineMessage({
        defaultMessage:
          "Care covered by Cigna is ineligible for coverage through Carrot. If you use your Carrot Card for care covered by Cigna, you’ll be required to return the funds to Carrot and file the claim with Cigna."
      })
    ],
    bulletPoints: [
      defineMessage({ defaultMessage: "Initial fertility consultations" }),
      defineMessage({
        defaultMessage: "Infertility-related care like in vitro fertilization (IVF) and intrauterine insemination (IUI)"
      }),
      defineMessage({
        defaultMessage:
          "Fertility preservation services when surgery, radiation, chemotherapy, or other medical treatment will directly or indirectly result in an impairment of fertility"
      })
    ],
    checkboxText: defineMessage({
      defaultMessage:
        "I’ve read the information above and understand that if I use my Carrot Card for ineligible care, I will be required to return the funds to Carrot and file a claim with Cigna."
    })
  },
  NBA: {
    subText: [
      defineMessage({
        defaultMessage:
          "Carrot Card makes it easy to pay directly from your funds for eligible expenses such as fertility preservation, adoption, and gestational surrogacy services. A full list of eligible expenses can be found in your benefit guide."
      }),
      defineMessage({
        defaultMessage:
          "In addition to your Carrot benefit, NBA offers fertility coverage through Cigna. This includes: "
      }),
      defineMessage({
        defaultMessage:
          "Care covered by Cigna is ineligible for coverage through Carrot. If you use your Carrot Card for care covered by Cigna, you’ll be required to return the funds to Carrot and file the claim with Cigna."
      })
    ],
    bulletPoints: [
      defineMessage({
        defaultMessage: "Initial fertility consultations"
      }),
      defineMessage({
        defaultMessage:
          "Elective or infertility-related fertility care like in vitro fertilization (IVF) and intrauterine insemination (IUI)"
      })
    ],
    checkboxText: defineMessage({
      defaultMessage:
        "I’ve read the information above and understand that if I use my Carrot Card for ineligible care, I will be required to return the funds to Carrot and file a claim with Cigna."
    })
  },
  RomanHealth: {
    subText: [
      defineMessage({
        defaultMessage:
          "In addition to Carrot, Ro offers fertility coverage through WINFertility (Anthem BlueCross BlueShield)."
      }),
      defineMessage({
        defaultMessage:
          "In accordance with the New York state mandate, this coverage includes three in vitro fertilization (IVF) cycles related to a medical diagnosis of infertility, including related services and medications. It also includes preservation services when surgery, radiation, chemotherapy, or other medical treatment will directly or indirectly result in an impairment of fertility. Please contact WINFertility with any questions about this coverage."
      }),
      defineMessage({
        defaultMessage:
          "If you pursue care and services covered by WINFertility, you must exhaust your WINFertility coverage before using Carrot funds for these expenses."
      })
    ],
    checkboxText: defineMessage({
      defaultMessage:
        "I understand that if I use my Carrot Card for care covered by WINFertility before exhausting my WINFertility coverage, I will be required to return the funds to Carrot and file a claim with WINFertility."
    })
  },
  Avenues: {
    subText: [
      defineMessage({
        defaultMessage:
          "In addition to your Carrot benefit, Avenues: The World School offers fertility coverage through Cigna."
      }),
      defineMessage({
        defaultMessage:
          "This includes initial fertility consultations and infertility-related care like in vitro fertilization (IVF) and intrauterine insemination (IUI). It also includes preservation services when surgery, radiation, chemotherapy, or other medical treatment will directly or indirectly result in an impairment of fertility."
      }),
      defineMessage({
        defaultMessage:
          "For care covered by Cigna, you must exhaust your Cigna coverage before using Carrot funds for these expenses."
      })
    ],

    checkboxText: defineMessage({
      defaultMessage:
        "I understand that if I use my Carrot Card for care covered by Cigna before exhausting my Cigna coverage, I will be required to return the funds to Carrot and file a claim with Cigna."
    })
  }
}
