import { useMedicalQuestionMenuItemWorkflow } from "#/components/carrot-plans/hooks/useMedicalQuestionMenuItemWorkflow"
import {
  medicalQuestionsSteps,
  medicalQuestionsWorkflows,
  Steps as MedicalQuestionsSteps
} from "#/components/carrot-plans/steps/medical-questions/workflows"
import { StateMachineProvider, StateMachineStepView } from "#/components/context/stateMachine/StateMachineV2"
import { RoutingFlowContainer } from "#/components/carrot-plans/shared/RoutingFlowContainer"
import React, { ReactElement } from "react"
import { useBodyClass } from "#/utils/Hooks"
import { useParams } from "react-router-dom"

export enum Sections {
  MEDICAL = "Medical"
}

export default function MedicalQuestionsMenuItemRoutingFlow(): ReactElement {
  useBodyClass("bg-warm-gray")
  const { journey } = useParams<{ journey: string }>()
  const desiredMedicalWorkflow = useMedicalQuestionMenuItemWorkflow(journey)

  const medicalQuestionsSection = {
    name: Sections.MEDICAL,
    initialStep: MedicalQuestionsSteps.WELCOME,
    desiredWorkflow: desiredMedicalWorkflow,
    steps: medicalQuestionsSteps,
    workflows: medicalQuestionsWorkflows
  }

  return (
    <StateMachineProvider
      initialSection={Sections.MEDICAL}
      stateMachineDescription={[medicalQuestionsSection]}
      onComplete={null}
    >
      <RoutingFlowContainer>
        <StateMachineStepView />
      </RoutingFlowContainer>
    </StateMachineProvider>
  )
}
