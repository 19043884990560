import { HelperText, Spacer } from "#/components/views/atoms/Atoms"
import React from "react"
import { SinglePathSVG } from "#/components/views/SVG"
import styled from "styled-components"
import { Text } from "@carrotfertility/carotene"

const StyledValidationMessage = styled.div`
  min-block-size: 1.5rem;
  margin-block-start: 2px;
  margin-block-end: 6px;
  margin-inline-start: 2px;
`

const StyledX = styled(SinglePathSVG)`
  margin-block-start: 1px;
  flex: none;
`

// @ts-expect-error TS(7031) FIXME: Binding element 'errors' implicitly has an 'any' t... Remove this comment to see the full error message
const ValidationMessage = ({ helperText = "", errors, name }) => {
  const valid = errors ? !errors[name] : true
  const showHelperText = valid && helperText
  return (
    <StyledValidationMessage>
      {!valid && (
        <div className="dib flex items-center invalid-red">
          <StyledX icon="x10" />
          <Spacer width={0.25} />
          <HelperText color="invalid-red">{errors[name].message}</HelperText>
        </div>
      )}
      {showHelperText && (
        <Text as="p" sx={{ marginBlockEnd: "16px" }} size="14px" color="black60">
          {helperText}
        </Text>
      )}
    </StyledValidationMessage>
  )
}

export { ValidationMessage }
