import React, { useState } from "react"
import { Link as ReactRouterLink, useLocation } from "react-router-dom"
import { SidebarFilters } from "./SidebarFilters"
import { SwitchViewButton } from "./SwitchViewButton"
import { SupportCenterLinks } from "#/utils/SupportCenterLinks"
import { Benefit } from "#/lib/carrot-api/types/Benefit"
import { FormattedMessage, useIntl } from "react-intl"
import {
  Box,
  Button,
  CloseIcon,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Drawer,
  FilterIcon,
  IconButton,
  Link,
  useMediaQuery,
  useTheme
} from "@carrotfertility/carotene-core"
import { getSupportCenterLink, goToHelpCenter } from "carrot-api/HelpCenterClient"
import { useTextDirection } from "#/services/locale/hooks/useTextDirection"
import { TextDirection } from "#/types/Locale"

const FilterButton = ({
  displaySidebarDrawer,
  setDisplaySidebarDrawer
}: {
  displaySidebarDrawer: boolean
  setDisplaySidebarDrawer: (inputValue: boolean) => void
}): JSX.Element => {
  const { pathname } = useLocation()
  const intl = useIntl()
  if (pathname.includes("providers")) {
    return (
      <Button
        size="medium"
        color="secondary"
        variant="outlined"
        aria-label={intl.formatMessage({ defaultMessage: "open filters panel" })}
        onClick={() => setDisplaySidebarDrawer(!displaySidebarDrawer)}
      >
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="center"
          alignItems="center"
          gap={(theme) => theme.spacing(theme.tokens.spacing.xs)}
        >
          <FilterIcon color="inherit" />
          <FormattedMessage defaultMessage="Filter" />
        </Box>
      </Button>
    )
  }
  return null
}

const CTAModal = (): JSX.Element => {
  const [open, setOpen] = React.useState(false)
  const { pathname } = useLocation()

  const intl = useIntl()

  if (pathname.includes("providers")) {
    return (
      <Box>
        <Button
          size="medium"
          variant="text"
          color="secondary"
          aria-label={intl.formatMessage({ defaultMessage: "open help modal" })}
          onClick={() => setOpen(true)}
          sx={{ cursor: "pointer" }}
        >
          <FormattedMessage defaultMessage="Can we help?" />
        </Button>
        <Dialog open={open} onClose={() => setOpen(false)} maxWidth="sm">
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end"
            }}
          >
            <IconButton
              aria-label={intl.formatMessage({
                defaultMessage: "close"
              })}
              onClick={() => setOpen(false)}
            >
              <CloseIcon />
            </IconButton>
          </Box>
          <DialogTitle>
            <FormattedMessage defaultMessage="Can we help?" />
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              <Link
                to="/talk-to-carrot/sendmessage/helpfindingprovider"
                component={ReactRouterLink}
                color="inherit"
                title={intl.formatMessage({ defaultMessage: "Contact us about finding a provider" })}
              >
                <FormattedMessage defaultMessage="Contact us" />
              </Link>
            </DialogContentText>
            <DialogContentText>
              <Link
                color="inherit"
                href={getSupportCenterLink(SupportCenterLinks.CARROT_PROVIDERS)}
                onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) =>
                  goToHelpCenter(event, SupportCenterLinks.CARROT_PROVIDERS)
                }
                target={"_blank"}
                title={intl.formatMessage({ defaultMessage: "Learn more about Carrot providers" })}
              >
                <FormattedMessage defaultMessage="Learn more about providers" />
              </Link>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </Box>
    )
  }

  return null
}
const FilterDrawer = ({ benefit }: { benefit: Benefit }): JSX.Element => {
  const [displaySidebarDrawer, setDisplaySidebarDrawer] = useState(false)
  const { search: geocodeQueryString } = useLocation()
  const params = new URLSearchParams(geocodeQueryString)
  const searchLocation = params.get("search")
  const theme = useTheme()
  const intl = useIntl()
  const isLessThanLarge = useMediaQuery(theme.breakpoints.down("lg"))
  const textDirection = useTextDirection()

  const helpAndFilterButton = isLessThanLarge ? (
    <Box display="flex" justifyContent="flex-end" flexDirection="row" alignItems="center" gap="small" key={"small"}>
      <CTAModal />
      <FilterButton setDisplaySidebarDrawer={setDisplaySidebarDrawer} displaySidebarDrawer={displaySidebarDrawer} />
    </Box>
  ) : null

  return (
    <>
      {helpAndFilterButton}
      <Drawer
        anchor={textDirection === TextDirection.LeftToRight ? "left" : "right"}
        open={displaySidebarDrawer}
        onClose={() => setDisplaySidebarDrawer(false)}
        variant="temporary"
        PaperProps={{
          sx: {
            backgroundColor: theme.palette.background.default,
            width: ["100vw", "100vw", "320px", "320px"]
          }
        }}
      >
        <Box
          display="flex"
          justifyContent="flex-end"
          paddingTop={(theme) => theme.spacing(theme.tokens.spacing.md)}
          paddingBottom={(theme) => theme.spacing(theme.tokens.spacing.sm)}
          paddingX={(theme) => theme.spacing(theme.tokens.spacing.md)}
          sx={(theme) => ({
            backgroundColor: theme.palette.background.default
          })}
        >
          <IconButton
            aria-label={intl.formatMessage({ defaultMessage: "close filter panel" })}
            onClick={() => setDisplaySidebarDrawer(false)}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <SwitchViewButton />
        <Divider
          sx={(theme) => ({
            margin: `${theme.spacing(theme.tokens.spacing.xl)} ${theme.spacing(theme.tokens.spacing.sm)}`
          })}
        />
        <SidebarFilters searchLocation={searchLocation} benefit={benefit} />
      </Drawer>
    </>
  )
}

export const ResponsiveDrawer = ({ benefit }: { benefit: Benefit }): JSX.Element => {
  const theme = useTheme()
  return useMediaQuery(theme.breakpoints.down("xl")) ? <FilterDrawer benefit={benefit} /> : null
}
