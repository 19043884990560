import React, { ReactElement, useEffect } from "react"
import { useStateMachine } from "../../../context/stateMachine/StateMachineV2"
import { useCheckRoutingInfoQueryCache } from "#/components/carrot-plans/shared/useQueryRoutingInfo"
import { useParams } from "react-router-dom"
import { AfabJourneyByUrlParam } from "#/components/carrot-plans/hooks/useMedicalQuestionMenuItemWorkflow"

const AfabSteps: { [key: string]: string } = {
  ASSISTED_REPRODUCTION: "PAST_FERTILITY_TREATMENT",
  PRESERVATION: "PAST_FERTILITY_TREATMENT",
  PREGNANT: "PREGNANT",
  TRY_PREGNANT: "TRY_PREGNANT",
  MENOPAUSE: "MENOPAUSE_DIAGNOSIS",
  LOW_TESTOSTERONE: "MENOPAUSE_DIAGNOSIS"
}

export default function WhatKindOfJourneyAfabDecisionStep(): ReactElement {
  const routingData = useCheckRoutingInfoQueryCache()
  const { journey: journeyParam } = useParams<{ journey: string }>()
  const journeyValue = journeyParam ? AfabSteps[AfabJourneyByUrlParam[journeyParam]] : AfabSteps[routingData?.journey]
  const { send } = useStateMachine()

  useEffect(() => {
    send(journeyValue)
  })
  return <></>
}
