import { Link, InternalIcon } from "@carrotfertility/carotene-core"
import React from "react"

// @ts-expect-error TS(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
export function ContentfulArrowLink(props) {
  return (
    <Link {...props} textAlign="start" endIcon={<InternalIcon color="primary" fontSize="small" />}>
      {props.children}
    </Link>
  )
}
