import React, { useEffect } from "react"
import { Button, DialogContentText } from "@carrotfertility/carotene-core"
import { FormattedMessage, useIntl } from "react-intl"
import { ConfirmationDialog } from "@carrotfertility/carotene-core-x"
import { getHeap } from "#/utils/heap"
import { updateAppUpdateModalSelection, UpdateModalValues } from "../modal/utils/localStorageUtils"
import { APP_STORE_URL } from "#/lib/carrot-api/MobileNativeClient"

export function UpdateAppDialog({ initialOpen }: { initialOpen: boolean }): JSX.Element {
  const intl = useIntl()

  const [open, setOpen] = React.useState(initialOpen)

  useEffect(() => {
    if (initialOpen) {
      getHeap().track("Update app dialog rendered")
    }
  }, [initialOpen])

  function handleClose() {
    updateAppUpdateModalSelection(UpdateModalValues.NotNow)
    setOpen(false)
  }

  function handleUpdate() {
    updateAppUpdateModalSelection(UpdateModalValues.Update)
    setOpen(false)
    window.location.href = APP_STORE_URL
  }

  return (
    <ConfirmationDialog
      aria-labelledby="update-app-dialog"
      title={intl.formatMessage({ defaultMessage: "Update your app" })}
      onClose={handleClose}
      open={open}
      primaryAction={
        <Button onClick={handleUpdate} data-button-id="update-app-dialog-update-button">
          <FormattedMessage defaultMessage="Update" />
        </Button>
      }
      secondaryAction={
        <Button
          color="secondary"
          variant="outlined"
          onClick={handleClose}
          data-button-id="update-app-dialog-not-now-button"
        >
          <FormattedMessage defaultMessage="Not now" />
        </Button>
      }
    >
      <DialogContentText>
        <FormattedMessage defaultMessage="Get the latest version for new features and more." />
      </DialogContentText>
    </ConfirmationDialog>
  )
}
