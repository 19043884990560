import React from "react"
import { useSelector } from "react-redux"
import { Box, DialogContentText, DialogTitle, Link } from "@carrotfertility/carotene-core"
import { IlloCircleCarrotCards } from "#/components/views/atoms/Illustrations"
import { getIsUsa } from "#/redux/reducers/userInfo"
import { FeatureGate } from "#/utils/FeatureGate"
import { FormattedMessage } from "react-intl"
import { DbgPaths } from "#/utils/Paths"

const RequestCardSuccess = () => {
  const isUsa = useSelector(getIsUsa)

  return isUsa ? <RequestPhysicalCardSuccess /> : <RequestVirtualCardSuccess />
}

const cardImage = (
  <Box
    sx={{
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      marginBlockStart: (theme) => theme.tokens.spacing.xl
    }}
  >
    <IlloCircleCarrotCards />
  </Box>
)

function RequestVirtualCardSuccess() {
  return (
    <div>
      {cardImage}
      <DialogTitle>
        <FormattedMessage defaultMessage="Your virtual card is ready to use." />
      </DialogTitle>
      <DialogContentText>
        <FormattedMessage defaultMessage="We’ve sent you an email and SMS confirmation. If you’re working with an eligible provider who accepts virtual cards, you can start using your card to pay for eligible care and services." />
      </DialogContentText>
      <Link
        target="_blank"
        href="/transactions#card-details"
        sx={{ display: "inline-block", marginBlockStart: (theme) => theme.tokens.spacing.md }}
      >
        <FormattedMessage defaultMessage="View card details" />
      </Link>
      <FeatureGate filter={(c) => c.isSupportCenterEnabled}>
        <br />
        <Link
          href={DbgPaths.CARROT_CARD}
          target="_blank"
          sx={{ display: "inline-block", marginBlockStart: (theme) => theme.tokens.spacing.md }}
        >
          <FormattedMessage defaultMessage="Learn more about Carrot Card" />
        </Link>
      </FeatureGate>
    </div>
  )
}

function RequestPhysicalCardSuccess() {
  return (
    <div>
      {cardImage}
      <DialogTitle>
        <FormattedMessage defaultMessage="You're all set" />
      </DialogTitle>
      <DialogContentText>
        <FormattedMessage defaultMessage="We're preparing to ship your Carrot Card. It should arrive within 1–2 weeks." />
      </DialogContentText>
    </div>
  )
}

export { RequestCardSuccess }
