import React, { useState, useContext } from "react"
import { ExpertBio } from "#/components/read/shared/ExpertBioMapper"
import { useIntl } from "react-intl"
import {
  Box,
  CloseIcon,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Stack
} from "@carrotfertility/carotene-core"

type ExpertBioModalProps = {
  isOpen: boolean
  onClose: () => void
  expertBio?: ExpertBio
}

type ExpertBioModalContextType = {
  isExpertBioModalOpen: boolean
  openExpertBioModal: ({ expertBio }: { expertBio: ExpertBio }) => void
  closeExpertBioModal: () => void
  expertBio?: ExpertBio
}

export const ExpertBioModalContext = React.createContext<ExpertBioModalContextType>(null)

export function ExpertBioModalContextProvider({ children }: { children: React.ReactNode }): JSX.Element {
  const openModal = ({ expertBio }: { expertBio: ExpertBio }): void => {
    setModalProps({ ...modalProps, isOpen: true, expertBio: expertBio })
  }

  const closeModal = (): void => {
    setModalProps({ ...modalProps, isOpen: false })
  }

  const [modalProps, setModalProps] = useState<ExpertBioModalProps>({
    isOpen: false,
    onClose: closeModal
  })

  return (
    <ExpertBioModalContext.Provider
      value={{
        isExpertBioModalOpen: modalProps.isOpen,
        openExpertBioModal: openModal,
        closeExpertBioModal: closeModal,
        ...modalProps
      }}
    >
      {children}
    </ExpertBioModalContext.Provider>
  )
}

export const ExpertBioModal = (): JSX.Element => {
  const intl = useIntl()
  const { isExpertBioModalOpen: isOpen, closeExpertBioModal: onClose, expertBio } = useContext(ExpertBioModalContext)

  const { headshot, name, description } = expertBio || {}

  return (
    <Dialog
      aria-labelledby="expert-bio-modal-title"
      aria-describedby="expert-bio-modal-description"
      onClose={onClose}
      open={isOpen}
    >
      <Box display="flex" justifyContent="flex-end">
        <IconButton
          aria-label={intl.formatMessage({ defaultMessage: "Close" })}
          onClick={onClose}
          data-analytics-id="expert-bio-modal-close-button"
        >
          <CloseIcon />
        </IconButton>
      </Box>

      <DialogTitle marginBottom={(theme) => theme.tokens.spacing.lg} id="expert-bio-modal-title">
        {name}
      </DialogTitle>
      <DialogContent>
        <Stack direction="row" gap={(theme) => theme.spacing(theme.tokens.spacing.md)}>
          <Box
            component="img"
            src={headshot?.file?.url}
            alt=""
            height="80px"
            width="80px"
            borderRadius={(theme) => theme.tokens.borderRadius.md}
          />
          <DialogContentText id="expert-bio-modal-description">{description}</DialogContentText>
        </Stack>
      </DialogContent>
    </Dialog>
  )
}
