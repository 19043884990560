import TalkToCarrotTilePage from "#/components/talk-to-carrot/TalkToCarrotTilePage"
import { getTilePageById } from "#/lib/contentful/contentfulClient"
import { TilePageRoutes } from "#/lib/contentful/components/TilePageRoutes"
import React, { ReactElement, ReactNode, useContext, useEffect, useState } from "react"
import { Redirect, Route, useHistory, useLocation, Link as ReactRouterLink } from "react-router-dom"
import { TileOrPhoneSupportActions } from "#/components/dynamic-content/util/dynamicContent"
import { Paths } from "#/utils/Paths"
import { TalkToCarrotContext } from "./TalkToCarrotContext"
import { Breadcrumbs, Breadcrumb } from "@carrotfertility/carotene-core"
import { RootBreadcrumb, SmallHeaderOverride } from "./util/talkToCarrotMolecules"
import { TTCTilePageElement } from "#/lib/contentful/components/TTCTilePageElement"
import { TalkToCarrotLegal } from "./TalkToCarrotLegal"
import { FormattedMessage, useIntl } from "react-intl"
import { PageBreadcrumbHeader } from "#/features/global-ui/page-header"
import { PageLayout } from "#/features/global-ui/page-layout"
import { Switch } from "#/services/routing/Switch"
import { useParams } from "react-router"

type WrapperProps = {
  children: ReactNode
  breadcrumbs: ReactElement<typeof Breadcrumb>[]
}

const Wrapper = ({ children, breadcrumbs }: WrapperProps): JSX.Element => (
  <PageLayout
    footer={<TalkToCarrotLegal />}
    header={<PageBreadcrumbHeader breadcrumbs={<Breadcrumbs>{breadcrumbs}</Breadcrumbs>} />}
  >
    <SmallHeaderOverride>{children}</SmallHeaderOverride>
  </PageLayout>
)

const LocationBreadcrumb = ({
  value,
  isCurrentPage = false
}: {
  value: string
  baseUrl: string
  isCurrentPage?: boolean
}): JSX.Element => {
  const intl = useIntl()
  const title = intl.formatDisplayName(value, { type: "region" })
  return (
    <Breadcrumb currentPage={isCurrentPage} component={ReactRouterLink} to={`${Paths.TALK_TO_CARROT}/location`}>
      {title}
    </Breadcrumb>
  )
}

const TalkToCarrotLocationRouter = (): JSX.Element => {
  const { locationSlug } = useParams<{ locationSlug: string }>()
  const { talkToCarrotLocationOptions, selectedLocationOption, setSelectedLocationOption } =
    useContext(TalkToCarrotContext)
  const history = useHistory()
  const intl = useIntl()
  const { locale } = intl

  const locationOption = talkToCarrotLocationOptions.find(({ slug }) => slug === locationSlug.toLowerCase())
  const { pageId, slug, value } = locationOption || {}
  const baseUrl = `${Paths.TALK_TO_CARROT}/${slug}/specialist`

  if (!selectedLocationOption || selectedLocationOption.slug !== locationOption.slug) {
    setSelectedLocationOption(locationOption)
  }

  interface LocationPageStateProps {
    routes: ReactElement
    landingPage: ReactElement<typeof TalkToCarrotTilePage>
  }

  const [landingPageProps, setLandingPageProps] = useState<LocationPageStateProps>(null)

  const actions: TileOrPhoneSupportActions = {
    gotoPageAction: (url) => {
      history.push(url)
    },
    callPhoneNumberAction: (e164FormatPhoneNumber) => {
      window.open(`tel:${e164FormatPhoneNumber}`, "_self")
    }
  }

  useEffect(() => {
    const loadRouter = async (): Promise<void> => {
      try {
        const locationLandingPage = await getTilePageById(pageId, locale)
        const routes = (
          <TilePageRoutes
            page={locationLandingPage}
            actions={actions}
            baseUrl={baseUrl}
            breadcrumbs={[
              <RootBreadcrumb key="root" />,
              <LocationBreadcrumb key="location" value={value} baseUrl={baseUrl} />,

              <Breadcrumb key="specialist" component={ReactRouterLink} to={baseUrl}>
                <FormattedMessage defaultMessage="Specialist" />
              </Breadcrumb>
            ].filter(Boolean)}
            Wrapper={Wrapper}
          />
        )
        const landingPage = (
          <Wrapper
            breadcrumbs={[
              <RootBreadcrumb key="root" />,
              <LocationBreadcrumb baseUrl={baseUrl} key="location" value={value} isCurrentPage={false} />,

              <Breadcrumb key="specialist" currentPage>
                <FormattedMessage defaultMessage="Specialist" />
              </Breadcrumb>
            ].filter(Boolean)}
          >
            <TTCTilePageElement page={locationLandingPage} baseUrl={baseUrl} />
          </Wrapper>
        )
        setLandingPageProps({ routes, landingPage })
      } catch {
        history.push(`${Paths.TALK_TO_CARROT}/error`)
      }
    }

    if (locationOption && !landingPageProps) {
      // noinspection JSIgnoredPromiseFromCall
      loadRouter()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const location = useLocation()

  const pageRouting = landingPageProps ? (
    <Switch>
      <Redirect
        from={`${baseUrl}/Newborn-care-and-parent-support/infant-sleep`}
        to={location.pathname.replace("/Newborn-care-and-parent-support/infant-sleep", "/Parenting/sleep-support")}
      />
      <Redirect
        from={`${baseUrl}/Newborn-care-and-parent-support/infant-care`}
        to={location.pathname.replace("/Newborn-care-and-parent-support/infant-care", "/Parenting/infant-health")}
      />
      <Redirect
        from={`${baseUrl}/Newborn-care-and-parent-support`}
        to={location.pathname.replace("/Newborn-care-and-parent-support", "/Parenting")}
      />
      <Route exact path={baseUrl} render={(): JSX.Element => landingPageProps.landingPage} />
      <Route render={(): JSX.Element => landingPageProps.routes} />
    </Switch>
  ) : null

  return <>{locationOption ? pageRouting : <Redirect from="/talk-to-carrot" to="/talk-to-carrot" />}</>
}

export default TalkToCarrotLocationRouter
