import React, { ReactNode } from "react"
import { FormattedMessage } from "react-intl"
import { Chip, Box, Typography, BoxProps } from "@carrotfertility/carotene-core"
import CardStates from "#/utils/CardStates"

const activeStatusChip = <Chip color="success" label={<FormattedMessage defaultMessage="Active" />} />
const shippedStatusChip = <Chip color="info" label={<FormattedMessage defaultMessage="Shipped" />} />
const lockedStatusChip = <Chip color="error" label={<FormattedMessage defaultMessage="Locked" />} />
const pendingStatusChip = <Chip color="info" label={<FormattedMessage defaultMessage="Requested" />} />

const carrotCardStatusByStates: Partial<Record<CardStates, ReactNode>> = {
  [CardStates.ACTIVE]: activeStatusChip,
  [CardStates.SHIPPED]: shippedStatusChip,
  [CardStates.LOCKED]: lockedStatusChip,
  [CardStates.DEDUCTIBLE_NOT_MET]: lockedStatusChip,
  [CardStates.NEEDS_ENROLLMENT]: lockedStatusChip,
  [CardStates.RESTRICTION_PERIOD]: lockedStatusChip,
  [CardStates.PENDING]: pendingStatusChip
}

const carrotCardBgColorByStates: Partial<Record<CardStates | "fallback", BoxProps["bgcolor"]>> = {
  [CardStates.ACTIVE]: (theme) => theme.palette.success.light,
  [CardStates.DEDUCTIBLE_NOT_MET]: (theme) => theme.palette.error.light,
  [CardStates.ELIGIBLE]: (theme) => theme.palette.background.default,
  [CardStates.ELIGIBLE_WITH_DEDUCTIBLE_NOT_MET]: (theme) => theme.palette.background.default,
  [CardStates.LOCKED]: (theme) => theme.palette.error.light,
  [CardStates.NEEDS_ENROLLMENT]: (theme) => theme.palette.error.light,
  [CardStates.PENDING]: (theme) => theme.palette.background.default,
  [CardStates.RESTRICTION_PERIOD]: (theme) => theme.palette.error.light,
  [CardStates.SHIPPED]: (theme) => theme.palette.background.default,
  fallback: (theme) => theme.palette.background.default
}

export type CarrotCardModuleTemplateProps = {
  cardState?: CardStates
  title?: ReactNode
  body: ReactNode
  links?: ReactNode[]
}

export function CarrotCardModuleTemplate({
  cardState,
  title = <FormattedMessage defaultMessage="Carrot Card®" />,
  body,
  links = []
}: CarrotCardModuleTemplateProps) {
  const statusChip = carrotCardStatusByStates[cardState] || null
  const bgcolor = carrotCardBgColorByStates[cardState] || carrotCardBgColorByStates.fallback

  return (
    <Box
      component="section"
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: (theme) => theme.spacing(theme.tokens.spacing.xs),
        borderRadius: (theme) => theme.tokens.borderRadius.md,
        padding: (theme) => theme.tokens.spacing.lg,
        bgcolor: bgcolor
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center"
        }}
      >
        <Box
          sx={{
            blockSize: 40,
            inlineSize: 40,
            backgroundColor: (theme) => theme.palette.background.paper,
            padding: (theme) => theme.tokens.spacing.xs,
            borderRadius: (theme) => theme.tokens.borderRadius.round
          }}
        >
          <img
            alt=""
            src="/images/icn-carrot-cards.png"
            srcSet="/images/icn-carrot-cards-2x.png"
            style={{
              height: 24,
              width: 24
            }}
          />
        </Box>
        {statusChip}
      </Box>
      <Typography variant="h4" component="h2">
        {title}
      </Typography>
      <Typography variant="body1">{body}</Typography>
      {links}
    </Box>
  )
}
