import React from "react"
import { Box, Stack, useTheme } from "@carrotfertility/carotene-core"
import { IlloUnderwaterPlants } from "#/components/views/atoms/Illustrations"
import { FormattedMessage } from "react-intl"

const NoInsurance = (): JSX.Element => {
  const theme = useTheme()

  return (
    <Stack
      height="275px"
      direction="column"
      borderRadius={(theme) => theme.tokens.borderRadius.md}
      justifyContent="center"
      alignItems="center"
      spacing={theme.spacing(theme.tokens.spacing.lg)}
      bgcolor={(theme) => theme.palette.background.default}
    >
      <Box>
        <IlloUnderwaterPlants />
      </Box>
      <Box>
        <FormattedMessage defaultMessage="You have told us that you do not have health insurance" />
      </Box>
    </Stack>
  )
}

export default NoInsurance
