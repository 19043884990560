import React from "react"
import { useTrackView } from "../../../utils/heap"
import { FormattedMessage, useIntl } from "react-intl"
import { RegistrationFlowLayout } from "./RegistrationLayouts"
import { RegistrationButtonFooter, SkipButton, ContinueButton } from "./RegistrationButtons"
import { RegistrationFlowTitle } from "./RegistrationFlowTitle"
import { Box, Typography, FormControlLabel, Checkbox, Stack, useTheme, Link } from "@carrotfertility/carotene-core"
import { usePrivacyNoticeUrl } from "#/utils/hooks/legal-notice-urls"
import { CountryCode } from "../../../utils/CountryCode"

const COUNTRY_DEFAULT_EMAIL_OPT_IN_LIST = [
  CountryCode.India,
  CountryCode.Mexico,
  CountryCode.Singapore,
  CountryCode.United_States
]

export const getIsDefaultEmailOptIn = (countryCode: string): boolean =>
  COUNTRY_DEFAULT_EMAIL_OPT_IN_LIST.includes(countryCode as CountryCode)

interface EmailOptInProps {
  isSubmitting: boolean
  emailOptIn: boolean
  setEmailOptIn: (newValue: boolean) => void
  onBack: () => void
  onComplete: () => void
}

export function EmailOptIn({
  isSubmitting,
  emailOptIn,
  setEmailOptIn,
  onBack,
  onComplete
}: EmailOptInProps): JSX.Element {
  useTrackView("EmailOptInRegFlow", "Email opt in screen")
  const theme = useTheme()
  const intl = useIntl()
  const header = intl.formatMessage({
    defaultMessage: "Stay updated"
  })
  const privacyNoticeUrl = usePrivacyNoticeUrl()

  return (
    <>
      <RegistrationFlowTitle title={header} />
      <RegistrationFlowLayout
        header={header}
        imageName="illo-paper-airplane"
        description={
          <Box>
            <Typography marginBottom={(theme) => theme.spacing(theme.tokens.spacing.lg)}>
              <FormattedMessage
                defaultMessage="We’ll share occasional updates about your Carrot benefit, such as new features and partnerships, as well as
           member-only expert resources."
              />
            </Typography>
          </Box>
        }
        interactiveElements={
          <>
            <FormControlLabel
              label={
                <Stack spacing={theme.spacing(theme.tokens.spacing.xs)}>
                  <Typography>
                    <FormattedMessage defaultMessage="Yes, keep me updated." />
                  </Typography>
                  <Typography>
                    <FormattedMessage
                      defaultMessage="We will protect your data and keep your information confidential in accordance with our <link>Privacy Notice</link>. You can unsubscribe at any time in your account settings."
                      values={{
                        link: (linkText) => (
                          <Link color="inherit" target="_blank" href={privacyNoticeUrl}>
                            {linkText}
                          </Link>
                        )
                      }}
                    />
                  </Typography>
                </Stack>
              }
              control={<Checkbox checked={emailOptIn} onChange={(e) => setEmailOptIn(e.target.checked)} />}
            />
            <RegistrationButtonFooter
              onBack={onBack}
              skipButton={
                <SkipButton onClick={onComplete}>
                  <FormattedMessage defaultMessage="Skip" />
                </SkipButton>
              }
              continueButton={
                <ContinueButton onClick={onComplete} disabled={isSubmitting}>
                  <FormattedMessage defaultMessage="Continue" />
                </ContinueButton>
              }
            />
          </>
        }
      />
    </>
  )
}
