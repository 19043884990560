import React, { useContext } from "react"
import { SendMessageFormContext } from "./SendMessage"
import { FormattedMessage } from "react-intl"
import { Box, Button, Divider, Stack, Typography, useTheme } from "@carrotfertility/carotene-core"
import { PiiText } from "#/services/tracking"

const ThankYou = (): JSX.Element => {
  const { tokens, palette } = useTheme()
  const { personalEmail, employeeEmail, partnerEmail, sentFromPartnerEmail } = useContext(SendMessageFormContext)

  function getIntlBodyText(): JSX.Element | string {
    if (sentFromPartnerEmail) {
      return (
        <FormattedMessage
          defaultMessage="We'll get back to you at {email}, your partner's email."
          values={{
            email: <PiiText>{partnerEmail}</PiiText>
          }}
        />
      )
    } else if (personalEmail) {
      return (
        <FormattedMessage
          defaultMessage="We'll get back to you at {email}, your preferred email."
          values={{
            email: <PiiText>{personalEmail}</PiiText>
          }}
        />
      )
    } else {
      return (
        <FormattedMessage
          defaultMessage="We'll get back to you at {email}, your account email."
          values={{
            email: <PiiText>{employeeEmail}</PiiText>
          }}
        />
      )
    }
  }

  return (
    <Box>
      <Typography variant="h3" color={palette.text.secondary} marginBottom={tokens.spacing.md}>
        <FormattedMessage defaultMessage="Message received!" />
      </Typography>
      <Typography variant="body1" color={palette.text.secondary} marginBottom={tokens.spacing.xxxl}>
        {getIntlBodyText()}
      </Typography>
      <Stack direction="row-reverse" marginBottom={tokens.spacing.lg}>
        <Button size="medium" color="primary" variant="contained" href="/">
          <FormattedMessage defaultMessage="Done" />
        </Button>
      </Stack>
      <Divider />
    </Box>
  )
}

export { ThankYou }
