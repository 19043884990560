import React from "react"
import { useGetMedicalInfo, useMedicalInfo } from "../../context/MedicalInfoContext"
import { useStateMachine } from "../../../context/stateMachine/StateMachineV2"
import { PeriodsPainfulDetails } from "./MedicalInfoOptions"
import {
  StepLayout,
  ButtonFooterLayout,
  ContinueButton,
  SkipButton,
  RoutingFlowCheckboxGroup,
  useConvertTranslatedLabel,
  RoutingFlowTitle
} from "#/components/carrot-plans/shared"
import { FormattedMessage, useIntl } from "react-intl"
import { Form } from "@carrotfertility/carotene-core-x"

type FormValues = {
  periodsPainfulDetails?: string[]
}

export default function PeriodsPainfulDetailsStep(): JSX.Element {
  const intl = useIntl()
  const { updateMedicalInfo, hasQuestionBeenSkipped } = useMedicalInfo()
  const { data: medicalInfo } = useGetMedicalInfo()
  const defaultValue = medicalInfo?.periodsPainfulDetails

  const { send } = useStateMachine(defaultValue || hasQuestionBeenSkipped("periodsPainfulDetails") ? "" : null)

  const options = useConvertTranslatedLabel(PeriodsPainfulDetails)

  async function onClickContinue(values: FormValues): Promise<void> {
    await updateMedicalInfo({ periodsPainfulDetails: values?.periodsPainfulDetails })
    send("")
  }

  async function onSkip(): Promise<void> {
    await updateMedicalInfo({ skipFields: ["periodsPainfulDetails"] })
    send("")
  }

  const header = intl.formatMessage({
    defaultMessage: "Do you also experience any of the following around your period?"
  })

  return (
    <>
      <RoutingFlowTitle title={header} />
      <StepLayout
        header={<span id="periodsPainfulDetails">{header}</span>}
        description={<FormattedMessage defaultMessage="Select any that apply." />}
        formElements={
          <Form
            defaultValues={{
              periodsPainfulDetails: defaultValue || []
            }}
            onSubmit={onClickContinue}
          >
            <RoutingFlowCheckboxGroup
              name="periodsPainfulDetails"
              aria-labelledby="periodsPainfulDetails-label"
              options={options}
            />
            <ButtonFooterLayout
              continueButton={
                <ContinueButton>
                  <FormattedMessage defaultMessage="Continue" />
                </ContinueButton>
              }
              skipButton={<SkipButton onClick={onSkip} />}
            />
          </Form>
        }
      />
    </>
  )
}
