import React, { useContext, useEffect } from "react"
import { Button, Box, Typography } from "@carrotfertility/carotene-core"
import { useStateMachine } from "../../../context/stateMachine/StateMachineContext"
import { Steps } from "../../workflow/steps"
import { BenefitEnrollmentFlowModalBackButton } from "../../shared"
import { MessageTextArea, SendMessageEmailInput } from "../../../talk-to-carrot/send-message/SendMessageForm"
import { SendMessageFormContext } from "../../../talk-to-carrot/send-message/SendMessage"
import { getHeap } from "../../../../utils/heap"
import { useFormContext } from "react-hook-form"
import { MessageSent } from "../MessageSent"
import { FormattedMessage, useIntl } from "react-intl"
import { useProgressTracker } from "../../../views/modal/ProgressTracker"

export function MemberHealthPlanNotListed(): JSX.Element {
  const { setNextStep } = useStateMachine(Steps.MEMBER_HEALTH_PLAN_NOT_LISTED, null)

  function onClickContinue(): void {
    setNextStep(Steps.MEMBER_HEALTH_PLAN_NOT_LISTED, "")
  }

  const { sent, onSubmit } = useContext(SendMessageFormContext)

  const sendSupportRequest = () => {
    onSubmit()
    getHeap().track("EnrollmentFlowSupportRequest", { EventName: "I'm not sure" })
  }

  const {
    formState: { isValid, isSubmitting }
  } = useFormContext()

  const intl = useIntl()

  const { showProgress } = useProgressTracker()
  useEffect(showProgress, [showProgress])

  return (
    <>
      {sent ? (
        <MessageSent onClick={onClickContinue} />
      ) : (
        <>
          <BenefitEnrollmentFlowModalBackButton />
          <Typography
            id="step-heading"
            variant="h2"
            color={(theme) => theme.palette.text.primary}
            paddingTop={(theme) => theme.tokens.spacing.md}
            paddingBottom={(theme) => theme.tokens.spacing.md}
          >
            <FormattedMessage defaultMessage="It looks like your provider isn't listed. That’s okay." />
          </Typography>
          <Typography
            color={(theme) => theme.palette.text.secondary}
            paddingBottom={(theme) => theme.tokens.spacing.xxl}
          >
            <FormattedMessage defaultMessage="Tell us the name of your health insurance provider. We'll look into it and get back to you." />
          </Typography>
          <SendMessageEmailInput showUpdateContactInfo={false} />
          <Box height={(theme) => theme.tokens.spacing.xl} />
          <MessageTextArea showPlaceholderText={false} rows="3" />
          <Box height="10rem" />
          <div className="flex flex-row justify-end">
            <Button
              color="primary"
              variant="contained"
              onClick={sendSupportRequest}
              disabled={!isValid || isSubmitting}
            >
              {intl.formatMessage({ defaultMessage: "Send" })}
            </Button>
          </div>
        </>
      )}
    </>
  )
}
