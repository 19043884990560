import { TileOrPhoneSupportActions } from "#/components/dynamic-content/util/dynamicContent"
import TalkToCarrotTilePage from "#/components/talk-to-carrot/TalkToCarrotTilePage"
import React, { ReactElement } from "react"
import { TalkToCarrotLanguagePage } from "../../../components/talk-to-carrot/TalkToCarrotLanguagePage"
import { EntryWithoutUnresolvableLinks } from "#/utils/ContentTypes"
import { TypeTalkToCarrotLanguagePageSkeleton } from "#/types/contentful"

type TTCTilePageElementProps = {
  page: EntryWithoutUnresolvableLinks<TypeTalkToCarrotLanguagePageSkeleton>
  actions: TileOrPhoneSupportActions
  baseUrl: string
}

const TTCLanguagePageElement = ({ baseUrl }: TTCTilePageElementProps): ReactElement<typeof TalkToCarrotTilePage> => {
  return <TalkToCarrotLanguagePage baseUrl={baseUrl} />
}

export { TTCLanguagePageElement }
