import { useQuery, UseQueryResult } from "@tanstack/react-query"
import { ThreadMessages } from "../inAppMessagingTypes"
import ResponseError from "../../../types/responseError"
import { carrotClient } from "#/utils/CarrotClient"
import { HttpStatusCodes } from "../../../utils/HttpStatusCodes"

export const THREAD_MESSAGES_CACHE_KEY = "InAppMessagingThread"

export default function useGetThreadMessages(selectedThreadId: number): UseQueryResult<ThreadMessages, ResponseError> {
  return useQuery<ThreadMessages, ResponseError>(
    [THREAD_MESSAGES_CACHE_KEY, selectedThreadId],
    () => carrotClient.getThreadMessages(selectedThreadId),
    {
      //prevents query from running on mount when there is no selectedThreadId
      enabled: !!selectedThreadId,
      staleTime: 60000,
      retry: (failureCount, error) => {
        switch (error.statusCode) {
          case HttpStatusCodes.UNAUTHORIZED:
          case HttpStatusCodes.BAD_REQUEST:
          case HttpStatusCodes.NOT_FOUND:
            return false
          default:
            return failureCount <= 0
        }
      }
    }
  )
}
