import React from "react"
import { BenefitConfiguration } from "#/types/benefitConfiguration"
import { useTrackView } from "../../../utils/heap"
import { FormattedMessage, defineMessage, useIntl } from "react-intl"
import { RegistrationFlowLayout } from "./RegistrationLayouts"
import { RegistrationButtonFooter, ContinueButton } from "./RegistrationButtons"
import {
  Typography,
  Checkbox,
  FormControlLabel,
  Stack,
  useTheme,
  Link,
  InfoIcon,
  Box,
  IconButton
} from "@carrotfertility/carotene-core"
import { RegistrationFlowTitle } from "./RegistrationFlowTitle"
import { usePrivacyNoticeUrl } from "#/utils/hooks/legal-notice-urls"
import { useToggle } from "#/utils/Hooks"
import { LgbtqDisclaimerModal } from "./LgbtqDisclaimerModal"

interface HealthConsentProps {
  isSubmitting: boolean
  healthConsent: boolean
  setHealthConsent: (newValue: boolean) => void
  onBack: () => void
  onComplete: () => void
  benefitConfiguration?: BenefitConfiguration
}

export function HealthConsent({
  isSubmitting,
  healthConsent,
  setHealthConsent,
  onBack,
  onComplete,
  benefitConfiguration
}: HealthConsentProps): JSX.Element {
  const { featureConfig } = benefitConfiguration
  const isLgbtqEnabled = featureConfig.isLgtbqEnabled
  const [showLgbtqDisclaimerModal, toggleShowLgbtqDisclaimerModal] = useToggle(false)
  const intl = useIntl()

  const topicsList = {
    health: defineMessage({
      defaultMessage: "health",
      description:
        "This appears in the context 'We may ask you for your Personal Data, which is data about your health'"
    }),
    healthAndSexualOrientation: defineMessage({
      defaultMessage: "health and sexual orientation",
      description:
        "This appears in the context 'We may ask you for your Personal Data, which is data about your health and sexual orientation'"
    })
  }
  const formattedTopic = isLgbtqEnabled
    ? intl.formatMessage(topicsList.healthAndSexualOrientation)
    : intl.formatMessage(topicsList.health)

  useTrackView("HealthConsentRegFlow", "Health consent screen")
  const theme = useTheme()

  const header = intl.formatMessage({
    defaultMessage: "Consent for processing your personal data"
  })
  const privacyNoticeUrl = usePrivacyNoticeUrl()

  return (
    <>
      <RegistrationFlowTitle title={header} />
      <RegistrationFlowLayout
        header={<FormattedMessage defaultMessage="Consent for processing your personal data" />}
        description={
          <Stack spacing={theme.spacing(theme.tokens.spacing.lg)}>
            <Typography>
              <FormattedMessage
                defaultMessage="During this registration process and as we provide services, Carrot Fertility, Inc. (located in the USA) may ask
        you for your Personal Data, which is data about your {formattedTopic}."
                values={{ formattedTopic }}
                description={"formattedTopic is either 'health' or 'health and sexual orientation'"}
              />
            </Typography>

            {!isLgbtqEnabled ? (
              <Box display="flex" alignItems="center">
                <IconButton
                  onClick={toggleShowLgbtqDisclaimerModal}
                  aria-label={intl.formatMessage({ defaultMessage: "LGBTQ disclaimer modal" })}
                  size="small"
                >
                  <InfoIcon />
                </IconButton>

                <Typography
                  paddingLeft={(theme) => theme.tokens.spacing.sm}
                  color={theme.palette.error.main}
                  variant="h5"
                >
                  <FormattedMessage
                    defaultMessage="Please be aware: disclosure of your sexual orientation 
                                    may put you at risk and is entirely voluntary."
                  />
                </Typography>
              </Box>
            ) : null}

            {showLgbtqDisclaimerModal && <LgbtqDisclaimerModal onExit={toggleShowLgbtqDisclaimerModal} />}

            <Typography>
              <FormattedMessage
                defaultMessage="In order to provide services, we need your consent to process your Personal Data. Our services include:
        providing information and recommendations, and processing reimbursements."
              />
            </Typography>

            <Typography>
              <FormattedMessage
                defaultMessage="You may withdraw consent at any time with effect for the future by contacting info@get-carrot.com.
           <link>Read More</link> on the type of data we collect, how we process that data, what it's used for, and why it matters
           that we're located in the USA."
                values={{
                  link: (linkText) => (
                    <Link color="inherit" target="_blank" href={privacyNoticeUrl}>
                      {linkText}
                    </Link>
                  )
                }}
              />
            </Typography>

            <Typography>
              <FormattedMessage
                defaultMessage="By giving consent, you confirm that you’ve read and understood the above explanation and additional disclosures
         made available to you. You agree to Carrot collecting and processing data regarding your
         {formattedTopic} as described."
                values={{ formattedTopic }}
                description={"formattedTopic is either 'health' or 'health and sexual orientation'"}
              />
            </Typography>

            <FormControlLabel
              required
              label={<FormattedMessage defaultMessage="Yes, I give my consent." />}
              control={<Checkbox checked={healthConsent} onChange={(e) => setHealthConsent(e.target.checked)} />}
            />
          </Stack>
        }
        interactiveElements={
          <RegistrationButtonFooter
            onBack={onBack}
            continueButton={
              <ContinueButton onClick={onComplete} disabled={!healthConsent || isSubmitting}>
                <FormattedMessage defaultMessage="Continue" />
              </ContinueButton>
            }
          />
        }
      />
    </>
  )
}
