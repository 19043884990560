import React, { useEffect } from "react"
import { useStateMachine } from "../../../context/stateMachine/StateMachineV2"
import { useCheckRoutingInfoQueryCache } from "#/components/carrot-plans/shared/useQueryRoutingInfo"

export default function GestationalCarrierDecisionStep(): JSX.Element {
  const { send } = useStateMachine()
  const routingData = useCheckRoutingInfoQueryCache()
  const gestationalCarrierIsPregnant = routingData?.journeyStage === "GESTATIONAL_PREGNANT"

  useEffect(() => {
    send(gestationalCarrierIsPregnant ? "YES" : "NO")
  })
  return <></>
}
