import React, { useContext } from "react"
import { FormattedMessage } from "react-intl"
import { Box, Link, Stack, Tile, Typography, useTheme } from "@carrotfertility/carotene-core"
import { ExpertBio } from "#/components/read/shared/ExpertBioMapper"
import { useSendMessagePath } from "./hooks/useSendMessagePath"
import {
  ExpertBioModal,
  ExpertBioModalContext,
  ExpertBioModalContextProvider
} from "#/components/expert-bios/expert-bio-modal"
import { useGetSpecialistChatMetadata } from "./hooks/useGetSpecialistChatMetadata"

const MAX_EXPERT_BIOS = 2

const TalkToCarrotExpertsSidebar = ({ expertBios }: { expertBios: ExpertBio[] }): JSX.Element => {
  return (
    <Tile color="default">
      <Heading />
      <IntroCopy />
      <ExpertBioList expertBios={expertBios} />
      <ClosingCopy />
      <SupportLink />
    </Tile>
  )
}

const Heading = () => {
  return (
    <Typography variant="h4" component="h3" color={(theme) => theme.palette.text.secondary}>
      <FormattedMessage defaultMessage="A few of our specialists" />
    </Typography>
  )
}

const IntroCopy = () => {
  return (
    <Typography
      color={(theme) => theme.palette.text.secondary}
      marginTop={(theme) => theme.spacing(theme.tokens.spacing.xs)}
      marginBottom={(theme) => theme.spacing(theme.tokens.spacing.lg)}
    >
      <FormattedMessage defaultMessage="You'll chat with the next available expert from a team that includes:" />
    </Typography>
  )
}

const toExpertBioIdString = (expertBios: ExpertBio[]) =>
  expertBios
    .map((bio) => bio.id)
    .sort()
    .join(",")

const ExpertBioList = React.memo(
  function ExpertBioList({ expertBios }: { expertBios: ExpertBio[] }) {
    const theme = useTheme()

    return (
      <ExpertBioModalContextProvider>
        <Stack
          spacing={theme.spacing(theme.tokens.spacing.md)}
          marginBottom={(theme) => theme.spacing(theme.tokens.spacing.lg)}
        >
          {[...expertBios]
            .sort(() => 0.5 - Math.random())
            .slice(0, MAX_EXPERT_BIOS)
            .map((expertBio) => (
              <ExpertBioItem key={expertBio.id} expertBio={expertBio} />
            ))}
        </Stack>
        <ExpertBioModal />
      </ExpertBioModalContextProvider>
    )
  },
  (oldProps, newProps) => toExpertBioIdString(oldProps.expertBios) === toExpertBioIdString(newProps.expertBios)
)

const ExpertBioItem = ({ expertBio }: { expertBio: ExpertBio }): JSX.Element => {
  const theme = useTheme()
  const { openExpertBioModal } = useContext(ExpertBioModalContext)
  const { specialistType } = useGetSpecialistChatMetadata()

  return (
    <Stack direction="row" spacing={theme.spacing(theme.tokens.spacing.xs)}>
      <Box
        component="img"
        borderRadius={(theme) => theme.tokens.borderRadius.md}
        src={expertBio.headshot.file.url}
        alt=""
        height={72}
        width={72}
      />
      <Typography variant="body2" color={(theme) => theme.palette.text.secondary}>
        <Link
          component="button"
          textAlign="start"
          color="inherit"
          data-analytics-id="talk-to-carrot-expert-bio-link"
          data-expert-id={expertBio.id}
          data-expert-slug={expertBio.slug}
          data-specialist-type={specialistType}
          onClick={() => {
            openExpertBioModal({ expertBio })
          }}
        >
          {expertBio.name}
        </Link>
      </Typography>
    </Stack>
  )
}

const ClosingCopy = () => {
  return (
    <Typography
      variant="body1"
      color={(theme) => theme.palette.text.secondary}
      marginBottom={(theme) => theme.spacing(theme.tokens.spacing.sm)}
    >
      <FormattedMessage defaultMessage="And more." />
    </Typography>
  )
}

const SupportLink = () => {
  const sendMessagePath = useSendMessagePath()
  const { specialistType } = useGetSpecialistChatMetadata()

  return (
    <>
      <Typography variant="body1" color={(theme) => theme.palette.text.secondary}>
        <FormattedMessage defaultMessage="Questions about scheduling?" />
      </Typography>
      <Link
        href={sendMessagePath}
        color="inherit"
        data-analytics-id="talk-to-carrot-experts-contact-link"
        data-specialist-type={specialistType}
      >
        <FormattedMessage defaultMessage="Contact us" />
      </Link>
    </>
  )
}

export { TalkToCarrotExpertsSidebar }
