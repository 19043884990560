import React from "react"
import { useToggle } from "#/utils/Hooks"
import { useHistory } from "react-router"
import { useIntl } from "react-intl"
import { NavItemWrapper } from "./MainNavigation"
import { useCurrentUser } from "#/components/context/user/UserContext"
import { AppLockedDownModal, LockedDownModalType } from "./nav-modals/AppLockedDownModal"
import { FindAProviderIcon, GatedIcon } from "@carrotfertility/carotene-core"

export function ProviderFinderNavItem({ onClick }: { onClick?: () => void }): JSX.Element {
  const [isAppLockedDownModalOpen, toggleAppLockedDownModal] = useToggle(false)
  const history = useHistory()
  const intl = useIntl()
  const { isInAppLockdown } = useCurrentUser()

  const onNavigateToProviderFinder = () => {
    if (isInAppLockdown) {
      toggleAppLockedDownModal()
    } else {
      history.push("/providerfinder")
      onClick()
    }
  }

  return (
    <>
      <NavItemWrapper
        onClick={onNavigateToProviderFinder}
        link={"/providerfinder"}
        button
        icon={<FindAProviderIcon />}
        text={intl.formatMessage({ defaultMessage: "Find a provider" })}
        extraNavIndicator={isInAppLockdown ? <GatedIcon color="primary" fontSize="small" /> : null}
      />

      {isAppLockedDownModalOpen && (
        <AppLockedDownModal
          onClose={toggleAppLockedDownModal}
          open={isAppLockedDownModalOpen}
          modalType={LockedDownModalType.ProviderFinder}
        />
      )}
    </>
  )
}
