import React, { useEffect, useState } from "react"
import { FlexContainer } from "@carrotfertility/carotene"
import { Typography } from "@carrotfertility/carotene-core"
import { CenteredLoadingIndicator } from "#/components/views/molecules/Molecules"
import { useStateMachine } from "#/components/context/stateMachine/StateMachineContext"
import useInitialSetup from "#/components/cmd-enrollment/hooks/useInitialSetup"
import { Steps } from "#/components/cmd-enrollment/workflow/steps"
import { useUserDeductible } from "#/components/context/user/DeductibleContext"
import { waitForMinimumDelay } from "../../../../utils/WaitForMinimumDelay"
import { useBenefitEnrollment } from "#/components/context/enrollment/BenefitEnrollmentContext"
import { FormattedMessage } from "react-intl"
import { useProgressTracker } from "../../../views/modal/ProgressTracker"

export default function PeekBenefitEnrollmentStep(): JSX.Element {
  const viewName = Steps.PEEK_BENEFIT_ENROLLMENT
  const [loading, setLoading] = useState(true)
  const { shouldSkipPeekEnrollment, deductibleFlowType } = useBenefitEnrollment()

  const { showProgress } = useProgressTracker()
  useEffect(showProgress, [showProgress])

  const { setNextStep } = useStateMachine(viewName, shouldSkipPeekEnrollment ? deductibleFlowType : null)
  const { retrieveDeductiblePreview, hraEligibility } = useUserDeductible()

  useInitialSetup(async () => {
    if (!shouldSkipPeekEnrollment) {
      await waitForMinimumDelay(retrieveDeductiblePreview(), 1000)
    }
    setLoading(false)
  })
  useEffect(() => {
    if (!loading) {
      const isHraIneligible =
        deductibleFlowType === "CMD" &&
        (hraEligibility.memberHraEligibility === false || hraEligibility.partnerHraEligibility === false)
      setNextStep(viewName, isHraIneligible ? "HRA_INELIGIBLE" : deductibleFlowType)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- See https://carrotfertility.atlassian.net/wiki/spaces/PE/pages/2050295461/Remove+Build+Warnings#react-hooks%2Fexhaustive-deps
  }, [retrieveDeductiblePreview, deductibleFlowType, hraEligibility, viewName, loading])
  return (
    <FlexContainer alignItems="center" direction="column" paddingTopBottom="large">
      <CenteredLoadingIndicator />
      <Typography
        id="step-heading"
        variant="h2"
        color={(theme) => theme.palette.text.primary}
        paddingBottom={(theme) => theme.tokens.spacing.lg}
      >
        <FormattedMessage defaultMessage="We're working on your request." />
      </Typography>
    </FlexContainer>
  )
}
