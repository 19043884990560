import { Modal, FlexContainer, Stack, Button, Text, TextLink, Image, Container } from "@carrotfertility/carotene"
import { useCurrentUser } from "#/components/context/user/UserContext"
import React from "react"
import { useHistory } from "react-router"
import { FormattedMessage } from "react-intl"

export const CarrotPlanRequiredModal = (): JSX.Element => {
  const { isInAppLockdown } = useCurrentUser()
  const history = useHistory()

  const onGoBack = (): void => history.goBack()

  const handleGetCarrotPlan = (): void => {
    history.push("/carrot-plans/about-you")
  }

  return (
    <Modal open={isInAppLockdown} onClose={null} fullScreenMobile>
      <FlexContainer
        direction="column"
        paddingLeftRight="huge"
        paddingTopBottom="giant"
        gap="small"
        alignItems="center"
      >
        <FlexContainer stack="tiny">
          <Image
            src={`/images/illo-underwater-desktop.png`}
            srcSet={`/images/illo-underwater-desktop-2x.png`}
            altText=""
            width={214}
            height={162}
          />
        </FlexContainer>
        <Stack direction="column" alignItems="center" spacing="none">
          <Text as="p" align="center">
            <FormattedMessage defaultMessage="To access this page, get your Carrot Plan." />
          </Text>
          <TextLink size="medium" href="/your-benefit-guide/about-your-benefit">
            <FormattedMessage defaultMessage="Learn about Carrot Plans" />
          </TextLink>
        </Stack>
        <Container maxWidth="388px">
          <Text as="p" align="center">
            <FormattedMessage defaultMessage="If you already requested a plan, you'll have access to this page as soon as it's ready." />
          </Text>
        </Container>
        <FlexContainer
          direction={["column", "row"]}
          alignItems={["end", null]}
          justifyContent={[null, "center"]}
          gap="tiny"
        >
          <Button onClick={onGoBack} variant="secondary">
            <FormattedMessage defaultMessage="Go back" />
          </Button>
          <Button onClick={handleGetCarrotPlan}>
            <FormattedMessage defaultMessage="Get Carrot Plan" />
          </Button>
        </FlexContainer>
      </FlexContainer>
    </Modal>
  )
}
