import { FormattedMessage, useIntl } from "react-intl"
import {
  Alert,
  Button,
  Container,
  DialogActions,
  DialogTitle,
  BackIcon,
  Link,
  Stack,
  Stepper,
  TextField,
  useTheme
} from "@carrotfertility/carotene-core"
import React, { useContext, useState } from "react"
import { useStateMachine } from "../../context/stateMachine/StateMachineV2"
import { UploadAdditionalDocumentsContext } from "../index"
import { carrotClient } from "../../../utils/CarrotClient"
import { Form, FormButton } from "@carrotfertility/carotene-core-x"
import { useTextDirection } from "#/services/locale/hooks/useTextDirection"

export const AnythingElseToShare = () => {
  const intl = useIntl()
  const theme = useTheme()
  const { back, send } = useStateMachine()

  const { expenseId, files } = useContext(UploadAdditionalDocumentsContext)
  const [memberNotes, setMemberNotes] = useState(null)
  const [hasError, setHasError] = useState(false)
  const textDirection = useTextDirection()

  const onSubmission = async () => {
    const fileGuids = files.map((file) => file.guid)
    try {
      setHasError(false)
      await carrotClient.uploadAdditionalFiles(expenseId, fileGuids, memberNotes)
      send("")
    } catch (e) {
      setHasError(true)
    }
  }

  return (
    <Form onSubmit={onSubmission}>
      <Container>
        <Stepper
          dir={textDirection}
          variant="progress"
          steps={4}
          activeStep={2}
          position="static"
          sx={{ padding: "8px 0" }}
        />
        <Stack spacing={theme.tokens.spacing.lg}>
          <DialogTitle id="modal-title">
            <FormattedMessage defaultMessage="Anything else you'd like to share?" />
          </DialogTitle>

          <TextField
            multiline
            placeholder={intl.formatMessage({ defaultMessage: "Type your message here..." })}
            onChange={(event) => setMemberNotes(event.target.value)}
            rows={4}
            size="medium"
          ></TextField>
          {hasError && (
            <Alert severity="error">
              <FormattedMessage
                defaultMessage="An unexpected error occurred. Please contact us at <link>support@get-carrot.com</link> for assistance."
                values={{
                  link: (linkContent) => (
                    <Link color="inherit" href="mailto:support@get-carrot.com">
                      {linkContent}
                    </Link>
                  )
                }}
              />
            </Alert>
          )}
          <DialogActions>
            <Container maxWidth="xl" sx={{ display: "flex", justifyContent: "space-between" }}>
              <Button variant="text" color="secondary" onClick={back} startIcon={<BackIcon />}>
                <FormattedMessage defaultMessage="Back" />
              </Button>
              <FormButton type="submit" color="primary" disabled={hasError}>
                <FormattedMessage defaultMessage="Submit" />
              </FormButton>
            </Container>
          </DialogActions>
        </Stack>
      </Container>
    </Form>
  )
}
