import { useQuery } from "@tanstack/react-query"
import buildCarrotApiRetryPolicy from "#/utils/CarrotApiRetryPolicy"
import ResponseError from "#/types/responseError"
import { reportErrorMessage } from "#/utils/ErrorReporting"
import { getTalkToCarrotSendMessageGuidance } from "#/lib/contentful/contentfulClient"
import { EntryWithoutUnresolvableLinks } from "#/utils/ContentTypes"
import { TypePageGuidanceSkeleton } from "#/types/contentful"

const sendMessageGuidance = "getSendMessageGuidance"

export function useGetSendMessageGuidance(locale: string) {
  return useQuery<EntryWithoutUnresolvableLinks<TypePageGuidanceSkeleton>, ResponseError>(
    [sendMessageGuidance],
    () => getTalkToCarrotSendMessageGuidance(locale),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      refetchOnReconnect: false,
      retry: buildCarrotApiRetryPolicy(3),
      onError: (error) => reportErrorMessage(`Failed to fetch send a message page guidance with error: ${error}`)
    }
  )
}
