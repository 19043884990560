import { UseMutationResult, useMutation } from "@tanstack/react-query"
import { CarrotRxExtendRegistration, CarrotRxExtendClient } from "./CarrotRxExtendClient"
import Settings from "#/utils/CarrotConfig"

const client = new CarrotRxExtendClient(Settings.CARROT_BACKEND_URL)

export default function useRegisterCarrotRxExtend(): UseMutationResult<unknown, Error, CarrotRxExtendRegistration> {
  const request = (registration: CarrotRxExtendRegistration) => client.registerCarrotRxExtend(registration)

  return useMutation<unknown, Error, CarrotRxExtendRegistration>({ mutationFn: request })
}
