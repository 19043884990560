import React from "react"
import { useStateMachine } from "../../../context/stateMachine/StateMachineV2"
import {
  hasQuestionBeenSkipped,
  useCheckRoutingInfoQueryCache,
  useUpdateRoutingInfo
} from "#/components/carrot-plans/shared/useQueryRoutingInfo"
import { StepLayout } from "#/components/carrot-plans/shared/StepLayout"
import { ButtonFooterLayout, ContinueButton, SkipButton } from "#/components/carrot-plans/shared/ButtonFooter"
import { FormattedMessage, useIntl } from "react-intl"
import { Form, FormTextField } from "@carrotfertility/carotene-core-x"
import { RoutingFlowTitle } from "#/components/carrot-plans/shared"

interface AgencyClinicStepProps {
  title: string
  skipped: boolean
  defaultValue?: string | null
  handleUpdate: (value: string) => void
  handleSkip: () => void
}

function AgencyClinicStep({
  title,
  skipped,
  handleUpdate,
  handleSkip,
  defaultValue
}: AgencyClinicStepProps): JSX.Element {
  const { send } = useStateMachine(Boolean(defaultValue) || skipped ? "" : null)
  const intl = useIntl()
  // @ts-expect-error TS(7006) FIXME: Parameter 'inputValue' implicitly has an 'any' typ... Remove this comment to see the full error message
  function sanitizeInputs(inputValue): string | null {
    // @ts-expect-error TS(7006) FIXME: Parameter 'char' implicitly has an 'any' type.
    return inputValue === null || inputValue.split("").every((char) => char === " ") || inputValue === ""
      ? null
      : inputValue
  }
  async function onSkip(): Promise<void> {
    await handleSkip()
    send("")
  }
  // @ts-expect-error TS(7006) FIXME: Parameter 'formValues' implicitly has an 'any' typ... Remove this comment to see the full error message
  async function onClickContinue(formValues): Promise<void> {
    const sanitizedInput = sanitizeInputs(formValues?.agencyClinic)
    if (sanitizedInput === null) {
      await onSkip()
    }
    await handleUpdate(sanitizedInput)
    send("")
  }

  return (
    <StepLayout
      header={title}
      formElements={
        <Form onSubmit={onClickContinue}>
          <FormTextField
            inputProps={{ "data-testid": "journey-attorney-agency-clinic" }}
            name="agencyClinic"
            label={intl.formatMessage({ defaultMessage: "Name(s)" })}
            defaultValue={defaultValue}
          />
          <ButtonFooterLayout
            continueButton={
              <ContinueButton>
                <FormattedMessage defaultMessage="Continue" />
              </ContinueButton>
            }
            skipButton={<SkipButton onClick={onSkip} />}
          />
        </Form>
      }
    />
  )
}

function NameOfAgencyStep(): JSX.Element {
  const { mutateAsync } = useUpdateRoutingInfo()
  const routingData = useCheckRoutingInfoQueryCache()
  const intl = useIntl()
  const header = intl.formatMessage({
    defaultMessage: "What is the name of your agency and/or attorney?"
  })

  return (
    <>
      <RoutingFlowTitle title={header} />
      <AgencyClinicStep
        handleUpdate={(value) => mutateAsync({ agencyAttorneyName: value })}
        handleSkip={() => mutateAsync({ skipFields: ["agencyAttorneyName"] })}
        defaultValue={routingData?.agencyAttorneyName}
        skipped={hasQuestionBeenSkipped("agencyAttorneyName", routingData)}
        title={header}
      />
    </>
  )
}

function NameOfClinicAgencyAttorneyStep(): JSX.Element {
  const { mutateAsync } = useUpdateRoutingInfo()
  const routingData = useCheckRoutingInfoQueryCache()
  const intl = useIntl()
  const header = intl.formatMessage({
    defaultMessage: "What is the name of your clinic, agency/consultancy, and/or attorney?"
  })

  return (
    <>
      <RoutingFlowTitle title={header} />
      <AgencyClinicStep
        handleUpdate={(value) => mutateAsync({ clinicAgencyAttorneyName: value })}
        handleSkip={() => mutateAsync({ skipFields: ["clinicAgencyAttorneyName"] })}
        defaultValue={routingData?.clinicAgencyAttorneyName}
        skipped={hasQuestionBeenSkipped("clinicAgencyAttorneyName", routingData)}
        title={header}
      />
    </>
  )
}

function NameOfClinicStep(): JSX.Element {
  const { mutateAsync } = useUpdateRoutingInfo()
  const routingData = useCheckRoutingInfoQueryCache()
  const intl = useIntl()
  const header = intl.formatMessage({
    defaultMessage: "What is the name of your clinic?"
  })

  return (
    <>
      <RoutingFlowTitle title={header} />
      <AgencyClinicStep
        handleUpdate={(value) => mutateAsync({ clinicName: value })}
        handleSkip={() => mutateAsync({ skipFields: ["clinicName"] })}
        defaultValue={routingData?.clinicName}
        skipped={hasQuestionBeenSkipped("clinicName", routingData)}
        title={header}
      />
    </>
  )
}
export { NameOfAgencyStep, NameOfClinicAgencyAttorneyStep, NameOfClinicStep }
