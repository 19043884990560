import React, { FC, useContext, useEffect, useState } from "react"
import { Redirect, Route, useLocation } from "react-router-dom"
import { SendMessage } from "./send-message/SendMessage"
import { TalkToCarrotContext } from "#/components/talk-to-carrot/TalkToCarrotContext"
import { createErrorBoundary } from "../../utils/createErrorBoundary"
import { ErrorPage as GlobalErrorPage } from "../views/app/ErrorPage"
import { ErrorPage as TalkToCarrotErrorPage } from "../../pages/talk-to-carrot/error"
import { Switch } from "#/services/routing/Switch"
import { useIntlSort } from "../../utils/hooks/useIntlSort"
import { PageOption } from "../dynamic-content/util/dynamicContent"
import { getTalkToCarrotLocations } from "../../lib/contentful/contentfulClient"
import { getTTCLocationOption } from "../../lib/contentful/utils/mappingUtils"
import { useIntl } from "react-intl"
import { useCurrentUser } from "../context/user/UserContext"
import { Paths } from "../../utils/Paths"
import {
  getAccountDeletionPath,
  getGetYourCarrotPlanPath,
  getHelpPath,
  getProviderEligibilityPath,
  getRefreshCarrotPlanPath,
  RootBreadcrumb
} from "./util/talkToCarrotMolecules"
import { HelpPage } from "./TalkToCarrotNeedHelp"
import { ProviderEligibility } from "../../pages/talk-to-carrot/provider-eligibility"
import { RefreshCarrotPlan } from "../../pages/talk-to-carrot/refresh-carrot-plan"
import { GetYourCarrotPlan } from "../../pages/talk-to-carrot/get-your-carrot-plan"
import { isMobileApp } from "carrot-api/MobileNativeClient"
import { DeleteAccount } from "../../pages/talk-to-carrot/delete-account"
import LockedDownRoute from "../views/app/LockedDownRoute"
import { TalkToCarrotExpertPage } from "./TalkToCarrotExpertPage"
import { TalkToCarrotLocationPicker } from "./TalkToCarrotLocationPicker"
import { TalkToCarrotLanguagePage } from "./TalkToCarrotLanguagePage"
import { TalkToCarrotSendAMessage } from "./TalkToCarrotSendAMessage"
import { TalkToCarrotCareNavPageRoutes } from "./TalkToCarrotCareNavPageRoutes"
import TalkToCarrotLocationRouter from "./TalkToCarrotLocationRouter"
import { FullScreenLoading } from "../read/shared/FullScreenLoading"

const COMPANY_GEO_PATH_SEGMENT = "company-geo-location"

export interface LocalizedPageOption extends PageOption {
  formattedLabel: string
}

const TalkToCarrotCompanyGeoResolver = (): JSX.Element => {
  const location = useLocation()
  const { companyGeoLocationOption, setSelectedLocationOption } = useContext(TalkToCarrotContext)
  setSelectedLocationOption(companyGeoLocationOption)
  const redirectPath = location.pathname.replace(COMPANY_GEO_PATH_SEGMENT, companyGeoLocationOption.slug)
  return <Redirect to={redirectPath} />
}

const TalkToCarrotRouter: FC = () => {
  const intl = useIntl()
  const {
    company: { countryCode }
  } = useCurrentUser()
  const [talkToCarrotLocationOptions, setTalkToCarrotLocationOptions] = useState<LocalizedPageOption[]>(null)
  const [companyGeoLocationOption, setCompanyGeoLocationOption] = useState<LocalizedPageOption>(null)
  const [selectedLocationOption, setSelectedLocationOption] = useState<LocalizedPageOption>(null)

  const { localeComparator } = useIntlSort()

  const formatLocations = (option: PageOption): LocalizedPageOption => {
    return {
      ...option,
      formattedLabel: intl.formatDisplayName(option.value, { type: "region" })
    }
  }

  const sortLocationOptions = (a: LocalizedPageOption, b: LocalizedPageOption): number => {
    const labelA = a.formattedLabel
    const labelB = b.formattedLabel
    return localeComparator(labelA, labelB)
  }

  useEffect(() => {
    const loadTalkToCarrotLocations = async (): Promise<void> => {
      const talkToCarrotLocations = await getTalkToCarrotLocations()
      const talkToCarrotLocationOptions = talkToCarrotLocations
        ?.map(getTTCLocationOption)
        .map(formatLocations)
        .filter(Boolean)
        .sort(sortLocationOptions)

      const location = talkToCarrotLocationOptions.find((opt) => opt.value === countryCode)
      setCompanyGeoLocationOption(location)
      setSelectedLocationOption(location)
      setTalkToCarrotLocationOptions(talkToCarrotLocationOptions)
    }

    if (!talkToCarrotLocationOptions) {
      loadTalkToCarrotLocations()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onSelectedLocationChanged = (locationOption: LocalizedPageOption): void => {
    setSelectedLocationOption(locationOption)
    setTalkToCarrotLocationOptions(
      locationOption
        ? [
            locationOption,
            ...talkToCarrotLocationOptions
              .filter(({ label }) => label !== locationOption.label)
              .sort(sortLocationOptions)
          ]
        : talkToCarrotLocationOptions
    )
  }

  if (talkToCarrotLocationOptions === null) {
    return <FullScreenLoading />
  }

  const TTCErrorBoundary = createErrorBoundary(GlobalErrorPage)
  return (
    <TTCErrorBoundary>
      <TalkToCarrotContext.Provider
        value={{
          talkToCarrotLocationOptions,
          companyGeoLocationOption,
          selectedLocationOption,
          setSelectedLocationOption
        }}
      >
        <Switch allowedRouters={[LockedDownRoute]}>
          <Route path="/talk-to-carrot/sendmessage/:topic" exact component={SendMessage} />
          <Redirect
            exact
            path="/talk-to-carrot/sendmessage/bookappointment/provider/:providerName"
            to="/providerfinder/requestappointment/:providerName"
          />
          <Route exact path={`${Paths.TALK_TO_CARROT}/error`} component={TalkToCarrotErrorPage} />
          <Route
            exact
            path={getHelpPath({ baseUrl: Paths.TALK_TO_CARROT })}
            render={(): JSX.Element => <HelpPage breadcrumbs={[<RootBreadcrumb key="rootBreadcrumb" />]} />}
          />
          <Route
            exact
            path={getProviderEligibilityPath({ baseUrl: Paths.TALK_TO_CARROT })}
            render={(): JSX.Element => <ProviderEligibility breadcrumbs={[<RootBreadcrumb key="rootBreadcrumb" />]} />}
          />
          <Route
            exact
            path={getRefreshCarrotPlanPath({ baseUrl: Paths.TALK_TO_CARROT })}
            render={(): JSX.Element => <RefreshCarrotPlan breadcrumbs={[<RootBreadcrumb key="rootBreadcrumb" />]} />}
          />
          <Route
            exact
            path={getGetYourCarrotPlanPath({ baseUrl: Paths.TALK_TO_CARROT })}
            render={(): JSX.Element => <GetYourCarrotPlan />}
          />
          {isMobileApp() && (
            <Route
              exact
              path={getAccountDeletionPath({ baseUrl: Paths.TALK_TO_CARROT })}
              render={(): JSX.Element => <DeleteAccount breadcrumbs={[<RootBreadcrumb key="rootBreadcrumb" />]} />}
            />
          )}
          <Route exact path={`${Paths.TALK_TO_CARROT}`} component={TalkToCarrotExpertPage} />
          <Route
            exact
            path={`${Paths.TALK_TO_CARROT}/location`}
            render={(): JSX.Element => (
              <TalkToCarrotLocationPicker
                talkToCarrotLocations={talkToCarrotLocationOptions}
                onSelectedLocationChanged={onSelectedLocationChanged}
              />
            )}
          />
          <LockedDownRoute
            exact
            path={`${Paths.TALK_TO_CARROT}/care-navigation/schedule-a-video-chat-or-call`}
            component={() => <TalkToCarrotLanguagePage baseUrl={`${Paths.TALK_TO_CARROT}/care-navigation`} />}
          />
          <Route
            exact
            path={`${Paths.TALK_TO_CARROT}/care-navigation/send-a-message`}
            component={TalkToCarrotSendAMessage}
          />
          <Route exact path={`${Paths.TALK_TO_CARROT}/care-navigation`} component={TalkToCarrotCareNavPageRoutes} />
          <Route
            path={`${Paths.TALK_TO_CARROT}/${COMPANY_GEO_PATH_SEGMENT}`}
            component={TalkToCarrotCompanyGeoResolver}
          />

          <Route path={`${Paths.TALK_TO_CARROT}/:locationSlug/specialist`} component={TalkToCarrotLocationRouter} />
          <Redirect
            from={`${Paths.TALK_TO_CARROT}/:locationSlug/care-navigation`}
            to="/talk-to-carrot/care-navigation"
          />
          <Redirect from="/ava-bracelet" to="/talk-to-carrot/sendmessage/ava-bracelet" />
          <Redirect from="/chat" to="/talk-to-carrot" />
          <Redirect from="*" to="/talk-to-carrot" />
        </Switch>
      </TalkToCarrotContext.Provider>
    </TTCErrorBoundary>
  )
}

export { TalkToCarrotRouter }
