import React, { ComponentType } from "react"
import { reportError } from "#/utils/ErrorReporting"

const createErrorBoundary = (component: ComponentType) =>
  class ErrorBoundary extends React.Component<React.PropsWithChildren, { hasError: boolean }> {
    state = {
      hasError: false
    }

    // @ts-expect-error TS(7006) FIXME: Parameter 'error' implicitly has an 'any' type.
    static getDerivedStateFromError(error) {
      reportError(error)
      return { hasError: true }
    }

    unsetError = () => {
      this.setState({
        hasError: false
      })
    }

    render() {
      if (this.state.hasError) {
        // @ts-expect-error TS(2769) FIXME: No overload matches this call.
        return React.createElement(component, { unsetError: this.unsetError })
      }

      return this.props.children
    }
  }

export { createErrorBoundary }
