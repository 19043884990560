import { isIOSNativeApp, isAndroidNativeApp } from "#/lib/carrot-api/MobileNativeClient"

const DevicePlatforms = {
  IOS: "iOS Native",
  ANDROID: "Android Native",
  WEB: "Web"
} as const

type DetermineDevicePlatformReturn = typeof DevicePlatforms[keyof typeof DevicePlatforms]

export function determineDevicePlatform(): DetermineDevicePlatformReturn {
  if (isIOSNativeApp()) {
    return DevicePlatforms.IOS
  }

  if (isAndroidNativeApp()) {
    return DevicePlatforms.ANDROID
  }

  return DevicePlatforms.WEB
}
