import { combineReducers } from "redux"
import actionTypes from "#/redux/actions/actionTypes"
import { createIsLoadedReducer, createReducer } from "./shared"
import moment from "moment"
import TestUserActionTypes from "#/redux/actions/testUserActionTypes"

const { FETCH_DEDUCTIBLE_STATUS_SUCCESS } = actionTypes
const { TOGGLE_EXTERNAL_DEDUCTIBLE } = TestUserActionTypes

const reducers = {}

// @ts-expect-error TS(2339) FIXME: Property 'loaded' does not exist on type '{}'.
reducers.loaded = createIsLoadedReducer("FETCH_DEDUCTIBLE_STATUS")

// @ts-expect-error TS(2339) FIXME: Property 'displayDeductibleBreakdown' does not exi... Remove this comment to see the full error message
reducers.displayDeductibleBreakdown = createReducer(null, {
  [FETCH_DEDUCTIBLE_STATUS_SUCCESS]: (state: any, action: any) => action.response.displayDeductibleBreakdown
})

// @ts-expect-error TS(2339) FIXME: Property 'sourceName' does not exist on type '{}'.
reducers.sourceName = createReducer(null, {
  [FETCH_DEDUCTIBLE_STATUS_SUCCESS]: (state: any, action: any) => action.response?.sourceName ?? null,
  [TOGGLE_EXTERNAL_DEDUCTIBLE]: (state: any) =>
    state === "ExternallyManagedDeductibleSource"
      ? "CarrotSpecificDeductibleSource"
      : "ExternallyManagedDeductibleSource"
})

// @ts-expect-error TS(2339) FIXME: Property 'amountAppliedToDeductible' does not exis... Remove this comment to see the full error message
reducers.amountAppliedToDeductible = createReducer(null, {
  [FETCH_DEDUCTIBLE_STATUS_SUCCESS]: (state: any, action: any) =>
    action.response?.deductibleSnapshot?.amountAppliedToDeductible ?? null
})

// @ts-expect-error TS(2339) FIXME: Property 'carrotAmountAppliedToDeductible' does no... Remove this comment to see the full error message
reducers.carrotAmountAppliedToDeductible = createReducer(null, {
  [FETCH_DEDUCTIBLE_STATUS_SUCCESS]: (state: any, action: any) =>
    action.response?.deductibleSnapshot?.carrotAmountAppliedToDeductible ?? null
})

// @ts-expect-error TS(2339) FIXME: Property 'externalAmountAppliedToDeductible' does ... Remove this comment to see the full error message
reducers.externalAmountAppliedToDeductible = createReducer(null, {
  [FETCH_DEDUCTIBLE_STATUS_SUCCESS]: (state: any, action: any) =>
    action.response?.deductibleSnapshot?.externalAmountAppliedToDeductible ?? null
})

// @ts-expect-error TS(2339) FIXME: Property 'deductibleAmount' does not exist on type... Remove this comment to see the full error message
reducers.deductibleAmount = createReducer(null, {
  [FETCH_DEDUCTIBLE_STATUS_SUCCESS]: (state: any, action: any) =>
    action.response?.deductibleSnapshot?.totalAmount ?? null
})

// @ts-expect-error TS(2339) FIXME: Property 'deductibleRemaining' does not exist on t... Remove this comment to see the full error message
reducers.deductibleRemaining = createReducer(null, {
  [FETCH_DEDUCTIBLE_STATUS_SUCCESS]: (state: any, action: any) =>
    action.response?.deductibleSnapshot?.amountRemaining ?? null
})

// @ts-expect-error TS(2339) FIXME: Property 'planStartDate' does not exist on type '{... Remove this comment to see the full error message
reducers.planStartDate = createReducer(null, {
  [FETCH_DEDUCTIBLE_STATUS_SUCCESS]: (state: any, action: any) =>
    action.response?.deductibleSnapshot?.planStartDate
      ? // @ts-expect-error TS(7009) FIXME: 'new' expression, whose target lacks a construct s... Remove this comment to see the full error message
        new moment(action.response?.deductibleSnapshot?.planStartDate)
      : null
})

// @ts-expect-error TS(2339) FIXME: Property 'planEndDate' does not exist on type '{}'... Remove this comment to see the full error message
reducers.planEndDate = createReducer(null, {
  [FETCH_DEDUCTIBLE_STATUS_SUCCESS]: (state: any, action: any) =>
    action.response?.deductibleSnapshot?.planEndDate
      ? // @ts-expect-error TS(7009) FIXME: 'new' expression, whose target lacks a construct s... Remove this comment to see the full error message
        new moment(action.response?.deductibleSnapshot?.planEndDate)
      : null
})

const deductibleStatus = combineReducers(reducers)

export const getSourceName = (state: any) => state.deductibleStatus.sourceName
export const getDeductibleRemaining = (state: any) => state.deductibleStatus.deductibleRemaining
export const getDeductibleStatus = (state: any) => state.deductibleStatus

export default deductibleStatus
