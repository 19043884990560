import { Container, FlexContainer, SimpleGrid, Divider } from "@carrotfertility/carotene"
import ArticleThumbnail from "./ArticleThumbnail"
import React, { useEffect } from "react"
import { useContentfulConditionalGate } from "../../lib/contentful/components/ContentfulConditionGate"
import slugify from "slugify"
import { useReadLearnLinks } from "./shared/ReadLearnLinks"
import { useWindowEvent } from "../../utils/hooks/useWindowEvent"
import { InternalIcon, Link, Typography } from "@carrotfertility/carotene-core"
import { FormattedMessage } from "react-intl"
import { Link as RouterLink, useLocation } from "react-router-dom"
import { EntryWithoutUnresolvableLinks } from "#/utils/ContentTypes"
import { TypeCategorySkeleton, TypeSubCategorySkeleton } from "#/types/contentful"

type SubcategorySectionProps = {
  subCategory: EntryWithoutUnresolvableLinks<TypeSubCategorySkeleton>
  category: EntryWithoutUnresolvableLinks<TypeCategorySkeleton>
  isLast: boolean
}

export function SubcategorySection({ subCategory, category, isLast }: SubcategorySectionProps): JSX.Element {
  const { subCategoryPageLink } = useReadLearnLinks({ category, subCategory })
  const location = useLocation()
  const [windowWidth, setWindowWidth] = React.useState({
    width: window.innerWidth
  })
  const maxNumOfVisibleArticles = 6

  const handleResize = () => {
    setWindowWidth({
      width: window.innerWidth
    })
  }

  // Added because useAnchorLinkScrollFix in App.tsx doesn't wait until the library data is loaded on a full page refresh
  useEffect(() => {
    const slug = slugify(subCategory.fields.navTitle ?? subCategory.fields.title)
    const id = location?.hash.replace("#", "")
    if (id === slug) {
      const element = document.getElementById(slug)
      if (element) {
        element.scrollIntoView()
      }
    }
  }, [location.hash, subCategory.fields.navTitle, subCategory.fields.title])

  useWindowEvent("resize", handleResize)
  const articleAndConditions = React.useMemo(
    () =>
      subCategory?.fields?.articles?.map((article) => ({
        element: (
          <ArticleThumbnail
            key={article?.sys?.id}
            contentType={article?.sys?.contentType?.sys?.id}
            article={article}
            category={category}
          />
        ),
        conditions: article?.fields?.conditions
      })),
    [category, subCategory?.fields?.articles]
  )
  const filteredArticles = useContentfulConditionalGate(articleAndConditions, true)

  const getStackSpacing = (): string => {
    if (isLast) return "none"
    return filteredArticles.length > maxNumOfVisibleArticles ? "large" : "huge"
  }

  return (
    <Container id={slugify(subCategory.fields.navTitle ?? subCategory.fields.title)}>
      <Divider paddingTopBottom="tiny" stack="small" />
      <FlexContainer stack={"medium"} direction={"row"} justifyContent={"space-between"}>
        <Typography variant="h5">{subCategory.fields.title}</Typography>
      </FlexContainer>
      <Container stack={getStackSpacing()}>
        <SimpleGrid columns={[1, 1, 1, windowWidth.width < 1200 ? 2 : 3]} spacing={"32px"}>
          {filteredArticles.slice(0, maxNumOfVisibleArticles)}
        </SimpleGrid>
        <FlexContainer justifyContent="flex-end" padding="24px 0 0 0">
          {filteredArticles.length > maxNumOfVisibleArticles ? (
            <Link
              endIcon={<InternalIcon fontSize="small" color="primary" />}
              to={subCategoryPageLink}
              component={RouterLink}
            >
              <FormattedMessage
                defaultMessage="See all {articleCount, number} resources"
                values={{ articleCount: filteredArticles.length }}
              />
            </Link>
          ) : null}
        </FlexContainer>
      </Container>
    </Container>
  )
}
