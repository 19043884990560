import React, { useEffect, useState } from "react"
import { useStateMachine } from "../../../context/stateMachine/StateMachineContext"
import { useUserDeductible } from "../../../context/user/DeductibleContext"
import { Steps } from "../../workflow/steps"
import { CenteredLoadingIndicator } from "../../../views/molecules/Molecules"
import { Box } from "@carrotfertility/carotene-core"
import { useCurrentUser } from "#/components/context/user/UserContext"

const enum CoverageTypeSelect {
  MEMBER_ONLY = "MEMBER_ONLY",
  MEMBER_DEPENDENTS_NOT_PARTNER = "MEMBER_DEPENDENTS_NOT_PARTNER",
  MEMBER_AND_PARTNER = "MEMBER_AND_PARTNER",
  MEMBER_PARTNER_DEPENDENTS = "MEMBER_PARTNER_DEPENDENTS",
  FAMILY = "FAMILY"
}

const viewName = Steps.UPDATE_PLAN_INFO

const determinePartnerCoverage = (
  memberCoverageTypeSelectValue: CoverageTypeSelect,
  memberCoverageStartDate: string,
  memberCoverageEndDate: string
) => {
  switch (memberCoverageTypeSelectValue) {
    case CoverageTypeSelect.MEMBER_ONLY:
    case CoverageTypeSelect.MEMBER_DEPENDENTS_NOT_PARTNER:
      return {
        determinedPartnerCoverageTypeSelectValue: null,
        determinedPartnerCoverageType: null,
        determinedPartnerCoverageStartDate: null,
        determinedPartnerCoverageEndDate: null
      }
    case CoverageTypeSelect.MEMBER_AND_PARTNER:
      return {
        determinedPartnerCoverageTypeSelectValue: CoverageTypeSelect.MEMBER_AND_PARTNER,
        determinedPartnerCoverageType: CoverageTypeSelect.FAMILY,
        determinedPartnerCoverageStartDate: memberCoverageStartDate,
        determinedPartnerCoverageEndDate: memberCoverageEndDate
      }
    case CoverageTypeSelect.MEMBER_PARTNER_DEPENDENTS:
      return {
        determinedPartnerCoverageTypeSelectValue: CoverageTypeSelect.MEMBER_PARTNER_DEPENDENTS,
        determinedPartnerCoverageType: CoverageTypeSelect.FAMILY,
        determinedPartnerCoverageStartDate: memberCoverageStartDate,
        determinedPartnerCoverageEndDate: memberCoverageEndDate
      }
  }
}

function UpdatePlanInfoStep(): JSX.Element {
  const { updateBenefitEnrollmentAnswers } = useUserDeductible()
  const { setNextStep } = useStateMachine(viewName, null)
  const [showLoadingIndicator, setShowLoadingIndicator] = useState(true)
  const { memberCoverageTypeSelectValue, memberCoverageStartDate, memberCoverageEndDate } = useCurrentUser()
  const {
    determinedPartnerCoverageTypeSelectValue,
    determinedPartnerCoverageType,
    determinedPartnerCoverageStartDate,
    determinedPartnerCoverageEndDate
  } = determinePartnerCoverage(memberCoverageTypeSelectValue, memberCoverageStartDate, memberCoverageEndDate)

  useEffect(() => {
    setShowLoadingIndicator(false)
    const updateAnswers = async () => {
      await updateBenefitEnrollmentAnswers({
        memberPlanSourceSelectValue: "EMPLOYER",
        memberHasHSA: false,
        memberHasHDHP: false,
        partnerCoverageType: determinedPartnerCoverageType,
        partnerCoverageTypeSelectValue: determinedPartnerCoverageTypeSelectValue,
        partnerCoverageStartDate: determinedPartnerCoverageStartDate,
        partnerCoverageEndDate: determinedPartnerCoverageEndDate
      })
      setNextStep(viewName, "")
    }

    updateAnswers()
  }, [
    updateBenefitEnrollmentAnswers,
    setNextStep,
    determinedPartnerCoverageType,
    determinedPartnerCoverageTypeSelectValue,
    determinedPartnerCoverageStartDate,
    determinedPartnerCoverageEndDate
  ])

  return <Box padding={(theme) => theme.tokens.spacing.xs}>{showLoadingIndicator && <CenteredLoadingIndicator />}</Box>
}

export { UpdatePlanInfoStep }
