import React from "react"
import { useStateMachine } from "../../../context/stateMachine/StateMachineV2"
import { Form } from "@carrotfertility/carotene-core-x"
import {
  useCheckRoutingInfoQueryCache,
  useUpdateRoutingInfo
} from "#/components/carrot-plans/shared/useQueryRoutingInfo"
import { defineMessage, FormattedMessage, useIntl } from "react-intl"
import {
  ButtonFooterLayout,
  ContinueButton,
  RoutingFlowTitle,
  StepLayout,
  useConvertTranslatedLabel
} from "#/components/carrot-plans/shared"
import { FormRadioButtonGroup } from "#/services/common-forms"
import { AMAB_AFAB_MEDICAL_PREFERENCE } from "./AmabAfabMedicalPreferenceStep"

const OPTIONS = [
  {
    label: defineMessage({ defaultMessage: "Yes" }),
    value: "PREF_UNAVAILABLE_FOR_JOURNEY"
  },
  {
    label: defineMessage({ defaultMessage: "No, I'd prefer not to answer health questions right now" }),
    value: AMAB_AFAB_MEDICAL_PREFERENCE.DECLINE_MEDICAL_QUESTIONS
  }
]
export default function MedicalPreferenceStep(): JSX.Element {
  const { mutateAsync } = useUpdateRoutingInfo()
  const routingData = useCheckRoutingInfoQueryCache()
  const defaultValue = routingData?.amabAfabMedicalPreference
  const { send } = useStateMachine(defaultValue ? "" : null)
  const intl = useIntl()
  const radioOptions = useConvertTranslatedLabel(OPTIONS)

  async function onClickContinue(formValues: { amabAfabMedicalPreference: string }): Promise<void> {
    await mutateAsync(formValues, { onSuccess: () => send("") })
  }
  const header = intl.formatMessage({
    defaultMessage: "Would you like to answer some health questions to help us support you better?"
  })

  return (
    <>
      <RoutingFlowTitle title={header} />
      <StepLayout
        header={<span id="medicalPreference-label">{header}</span>}
        formElements={
          <Form defaultValues={{ amabAfabMedicalPreference: defaultValue }} onSubmit={onClickContinue}>
            <FormRadioButtonGroup
              name="amabAfabMedicalPreference"
              options={radioOptions}
              aria-labelledby="medicalPreference-label"
              controllerProps={{ rules: { required: intl.formatMessage({ defaultMessage: "Required" }) } }}
            />
            <ButtonFooterLayout
              continueButton={
                <ContinueButton>
                  <FormattedMessage defaultMessage="Continue" />
                </ContinueButton>
              }
            />
          </Form>
        }
      />
    </>
  )
}
