import React, { ReactElement } from "react"
import { useStateMachine } from "../../../context/stateMachine/StateMachineV2"
import { useGetMedicalInfo, useMedicalInfo } from "../../context/MedicalInfoContext"
import { StepLayout } from "#/components/carrot-plans/shared/StepLayout"
import { ButtonFooterLayout, ContinueButton } from "#/components/carrot-plans/shared/ButtonFooter"
import { FormattedMessage, useIntl } from "react-intl"
import { Box } from "@carrotfertility/carotene-core"
import { Form } from "@carrotfertility/carotene-core-x"
import { RoutingFlowTitle } from "#/components/carrot-plans/shared"
import { useParams } from "react-router-dom"
import useCarrotPlan from "../../hooks/useCarrotPlan"
import { CarrotPlanStep } from "../../../../types/carrotPlanTypes"
import { AMAB_AFAB_MEDICAL_PREFERENCE_OPTIONS } from "../journey/AmabAfabMedicalPreferenceStep"

export default function WelcomeStep(): ReactElement {
  const intl = useIntl()
  const { data: currentCarrotPlan, isLoading: isCarrotPlanLoading } = useCarrotPlan()
  const { startMedicalInfo } = useMedicalInfo()
  const { data: medicalInfo } = useGetMedicalInfo()
  const { journey } = useParams<{ journey: string }>()

  const medicalInfoStartedFromMenuItem = journey != null
  const medicalInfoInProgress = currentCarrotPlan?.steps.some(
    (step: CarrotPlanStep) => step.carrotPlanStepId === medicalInfo?.medicalCarrotPlanStepId
  )

  const skipWelcomeScreenIfMedicalInfoInProgress = () => {
    const skip = ""
    // @ts-expect-error TS(7005) FIXME: Variable 'doNotSkip' implicitly has an 'any' type.
    const doNotSkip = null
    if (!isCarrotPlanLoading) {
      if (medicalInfoStartedFromMenuItem) {
        return medicalInfoInProgress ? skip : doNotSkip
      }
      return medicalInfo ? skip : doNotSkip
    }
  }

  const { send, workflowName } = useStateMachine(skipWelcomeScreenIfMedicalInfoInProgress())
  const amabAfabMedicalPreference = AMAB_AFAB_MEDICAL_PREFERENCE_OPTIONS.find((o) => o.workflow === workflowName).value

  async function onClickContinue(): Promise<void> {
    if (medicalInfoStartedFromMenuItem && !medicalInfoInProgress) {
      await startMedicalInfo({ fromMedicalMenuItem: true, amabAfabMedicalPreference })
    }
    send("")
  }

  const header = intl.formatMessage({
    defaultMessage: "Thanks. Any questions you choose to answer will help us guide you to the best care."
  })

  return (
    <>
      <RoutingFlowTitle title={header} />
      <Box display="flex" justifyContent="center" marginBottom={(theme) => theme.spacing(theme.tokens.spacing.lg)}>
        <Box
          component="img"
          src={`/images/illo-yir-telehealth.png`}
          srcSet={`/images/illo-yir-telehealth-2x.png`}
          alt=""
          marginBottom={(theme) => theme.spacing(theme.tokens.spacing.md)}
          width={228}
          height={228}
        />
      </Box>
      <StepLayout
        header={header}
        formElements={
          <Form onSubmit={onClickContinue}>
            <ButtonFooterLayout
              continueButton={
                <ContinueButton>
                  <FormattedMessage defaultMessage="Continue" />
                </ContinueButton>
              }
            />
          </Form>
        }
      />
    </>
  )
}
