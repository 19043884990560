import React, { useCallback, useEffect, useRef, ReactNode, useState } from "react"
import { useIntl } from "react-intl"
import { Box, CloseIcon, IconButton, Progress, useTheme } from "@carrotfertility/carotene-core"
import { CarrotDocumentType, getSignatureForDocument, SignatureCompleteEvent } from "carrot-api/signatureClient"
import { Title } from "#/services/page-title/Title"
import { Redirect, useHistory } from "react-router"
import { useGetMostRecentlyCompletedRouting } from "#/components/carrot-plans/hooks/useGetMostRecentlyCompletedRouting"
import { useCurrentUser } from "#/components/context/user/UserContext"
import { useGetIsExcludedFromMedicalRecordsConsent } from "#/services/user/hooks/useGetIsExcludedFromMedicalRecordsConsent"
import { CountryCode } from "#/utils/CountryCode"
import { useUpdateDocumentStatus } from "./utils/DocumentStatusApi"
import { determineDocumentAction } from "./utils/documentStatusHelper"
import { useUserRole } from "#/components/partner-access/hooks/usePartnerAccess"
import { MemberOnlySpaceNotice } from "#/components/partner-access/member-only-space-notice"
import { useMeetsInitialCriteriaToShowArtMedicalRecordsConsent } from "#/components/carrot-plans/steps/journey/useShouldShowArtMedicalRecordsConsent"

const PageContainerWithCloseButton = ({ children }: { children: ReactNode }) => {
  const history = useHistory()
  const intl = useIntl()
  const theme = useTheme()
  const header = intl.formatMessage({ defaultMessage: "Share some medical information" })

  return (
    <>
      <Title title={header} />
      <Box height="100vh" width="100vw">
        <Box
          display="flex"
          justifyContent="flex-end"
          paddingTop={theme.tokens.spacing.lg}
          paddingInlineEnd={theme.tokens.spacing.lg}
        >
          <IconButton aria-label={intl.formatMessage({ defaultMessage: "close" })} onClick={() => history.push("/")}>
            <CloseIcon fontSize={"medium"} color="secondary" />
          </IconButton>
        </Box>
        <Box marginTop={theme.tokens.spacing.xs} height="calc(100% - 40px)">
          {children}
        </Box>
      </Box>
    </>
  )
}

function MedicalRecordsCollection({ documentType }: { documentType: CarrotDocumentType }): JSX.Element {
  const intl = useIntl()
  const [frameSrc, setFrameSrc] = useState("/blank.html")
  const [docuSignIsReady, setDocuSignIsReady] = useState(false)
  const history = useHistory()
  const { isPartnerAccessingAccount } = useUserRole()
  const { data: routingData, isLoading: isRoutingInfoLoading } = useGetMostRecentlyCompletedRouting()
  const shouldShowArtMedicalRecordsConsent = useMeetsInitialCriteriaToShowArtMedicalRecordsConsent({ routingData })
  const { company } = useCurrentUser()
  const { countryCode: companyCountryCode, companyId, parentCompanyId } = company
  const getIsExcludedFromMedicalRecordsConsent = useGetIsExcludedFromMedicalRecordsConsent()
  const isExcludedFromMedicalRecordsConsent = getIsExcludedFromMedicalRecordsConsent({
    companyId,
    parentCompanyId
  })
  const signatureIdRef = useRef<number | null>(null)
  const { mutateAsync: updateDocumentStatus } = useUpdateDocumentStatus()

  const handleWindowEvent = useCallback(
    async (event: SignatureCompleteEvent) => {
      if (event?.data?.documentType === documentType) {
        const documentAction = determineDocumentAction(event)
        await updateDocumentStatus({
          documentType,
          documentStatusRequest: { signatureId: signatureIdRef.current, documentAction }
        })

        window.removeEventListener("message", handleWindowEvent)
        history.push("/")
      }
    },
    [documentType, history, updateDocumentStatus]
  )

  const showArtConsentFormConditions =
    documentType === CarrotDocumentType.ArtMedicalRecordsConsentForm &&
    !isRoutingInfoLoading &&
    shouldShowArtMedicalRecordsConsent

  const showPregnancyConsentFormConditions =
    documentType === CarrotDocumentType.PregnancyMedicalRecordsConsentForm &&
    !isRoutingInfoLoading &&
    routingData?.journey === "PREGNANT" &&
    companyCountryCode === CountryCode.United_States &&
    !isExcludedFromMedicalRecordsConsent &&
    !isPartnerAccessingAccount

  useEffect(() => {
    const showDocument = async () => {
      window.addEventListener("message", handleWindowEvent)
      const envelopeSignatureInfo = await getSignatureForDocument(documentType)
      signatureIdRef.current = envelopeSignatureInfo.signatureId
      setFrameSrc(envelopeSignatureInfo.docuSignUrl)
      setDocuSignIsReady(true)
    }
    if (documentType && (showArtConsentFormConditions || showPregnancyConsentFormConditions)) {
      showDocument()
    }
    return () => window.removeEventListener("message", handleWindowEvent)
  }, [showArtConsentFormConditions, showPregnancyConsentFormConditions, documentType, handleWindowEvent])

  if (isPartnerAccessingAccount) {
    return (
      <PageContainerWithCloseButton>
        <MemberOnlySpaceNotice />
      </PageContainerWithCloseButton>
    )
  }

  if (!isRoutingInfoLoading) {
    if (
      (documentType === CarrotDocumentType.ArtMedicalRecordsConsentForm && !showArtConsentFormConditions) ||
      (documentType === CarrotDocumentType.PregnancyMedicalRecordsConsentForm && !showPregnancyConsentFormConditions)
    ) {
      return <Redirect to="/" />
    }
  }

  return (
    <PageContainerWithCloseButton>
      {docuSignIsReady && (showArtConsentFormConditions || showPregnancyConsentFormConditions) ? (
        <iframe
          title={intl.formatMessage({ defaultMessage: "Authorization Form" })}
          style={{ width: "100%", height: "100%" }}
          src={frameSrc}
          allowFullScreen
        />
      ) : (
        <Box display="flex" alignItems="center" justifyContent="center" height="100vh">
          <Progress size={32} />
        </Box>
      )}
    </PageContainerWithCloseButton>
  )
}

export function ArtMedicalRecordsConsentPage() {
  return <MedicalRecordsCollection documentType={CarrotDocumentType.ArtMedicalRecordsConsentForm} />
}

export function PregnancyMedicalRecordsConsentPage() {
  return <MedicalRecordsCollection documentType={CarrotDocumentType.PregnancyMedicalRecordsConsentForm} />
}
