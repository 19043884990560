import React, { useEffect } from "react"
import { useStateMachine } from "../../../context/stateMachine/StateMachineV2"
import { useGetMedicalInfo } from "#/components/carrot-plans/context/MedicalInfoContext"

enum Steps {
  MEDICAL = "MEDICAL_ANYTHING_ELSE",
  JOURNEY = "JOURNEY_ANYTHING_ELSE"
}

export default function AnythingElseDecisionStep(): JSX.Element {
  const { data: medicalInfo } = useGetMedicalInfo()
  const isCarrotPlanMedicalQuestions = medicalInfo?.isFromCarrotPlanMenuItem
  const nextStep = isCarrotPlanMedicalQuestions ? Steps.MEDICAL : Steps.JOURNEY
  const { send } = useStateMachine()
  useEffect(() => {
    send(nextStep)
  })
  return <></>
}
