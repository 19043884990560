import React, { ReactNode, useState } from "react"
import { Autocomplete, Box, Progress, TextField, Theme, Typography } from "@carrotfertility/carotene-core"
import { useIntl } from "react-intl"
import { getPiiProps } from "#/services/tracking"
import { useUserContext } from "#/pages/provider-finder/context/UserContext"
import {
  TYPEAHEAD_PROVIDERS_QUERY_KEY,
  useGetTypeaheadProviders
} from "#/pages/provider-finder/hooks/useGetTypeaheadProviders"
import { findMatchedSubstring } from "#/pages/provider-finder/utils/findMatchedSubstring"
import { LabelWithIconTooltip } from "./LabelWithIconTooltip"
// eslint-disable-next-line no-restricted-imports
import { SxProps } from "@mui/system"

export function KeywordAutocomplete({
  tooltipTitle,
  label,
  keyword,
  popupIcon,
  startAdornment,
  sx,
  setProviderNameValue,
  setTypeaheadLocationSearch
}: {
  tooltipTitle?: ReactNode
  label?: string
  keyword?: string
  popupIcon: ReactNode
  startAdornment: ReactNode
  sx?: SxProps<Theme>
  setProviderNameValue: (input: string) => void
  setTypeaheadLocationSearch?: (input: string) => void
}): JSX.Element {
  const [open, setOpen] = useState(false)
  const [userValue, setUserValue] = useState(null)

  const [currentInputValue, setCurrentInputValue] = useState(null)

  const { companyCountryCode } = useUserContext()
  const debounceRef = React.useRef(null)

  const intl = useIntl()
  const placeholderText = intl.formatMessage({
    defaultMessage: "Clinic name, agency, legal service..."
  })

  const {
    data: providerPredictions,
    isLoading: isProviderPredictionsLoading,
    refetch
  } = useGetTypeaheadProviders(currentInputValue, companyCountryCode)

  return (
    <Autocomplete
      id="keyword-search-autocomplete"
      sx={sx}
      autoComplete
      clearOnEscape
      filterOptions={(x) => x}
      getOptionLabel={(option) => (typeof option === "string" ? option : option.name)}
      isOptionEqualToValue={(option, value) => option.name === value.name}
      loading={isProviderPredictionsLoading}
      loadingText={
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="100%"
          padding={(theme) => theme.spacing(theme.tokens.spacing.xxl)}
          sx={{ grow: 1 }}
        >
          <Progress />
        </Box>
      }
      noOptionsText={null}
      options={isProviderPredictionsLoading ? [] : providerPredictions}
      popupIcon={popupIcon}
      value={keyword || userValue || currentInputValue}
      open={open}
      onClose={() => {
        setOpen(false)
      }}
      onChange={(event, newUserValue) => {
        setUserValue(newUserValue)
        setProviderNameValue(newUserValue?.name || newUserValue || "")
        if (newUserValue?.address && setTypeaheadLocationSearch) {
          setTypeaheadLocationSearch(newUserValue.address)
        }
        if (newUserValue) {
          setOpen(false)
        }
      }}
      onInputChange={(event, newInputValue, reason) => {
        setCurrentInputValue(newInputValue)
        setProviderNameValue(newInputValue)
        if (debounceRef.current) {
          clearTimeout(debounceRef.current)
        }
        if (reason === "reset") {
          setOpen(false)
          return
        }
        debounceRef.current = setTimeout(() => {
          if (newInputValue) {
            refetch({
              queryKey: [TYPEAHEAD_PROVIDERS_QUERY_KEY, newInputValue, companyCountryCode]
            })
            setOpen(true)
          }
        }, 500)
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label && <LabelWithIconTooltip {...{ tooltipTitle, label }} />}
          fullWidth
          placeholder={placeholderText}
          InputProps={{
            ...params.InputProps,
            startAdornment: startAdornment
          }}
          defaultValue={keyword || null}
        />
      )}
      renderOption={(props, providerPrediction) => {
        const { name, uuid } = providerPrediction
        const matchedSubstringDetails = name
        const { length, offset } = findMatchedSubstring(name, currentInputValue) || { length: 0, offset: 0 }
        const beforeMatchedSubstring = matchedSubstringDetails ? name.slice(0, offset) : name
        const matchedSubstring = matchedSubstringDetails ? name.slice(offset, offset + length) : ""
        const afterMatchedSubstring = matchedSubstringDetails ? name.slice(offset + length) : ""
        return (
          <Box
            component="li"
            key={`${name}-${uuid}`}
            sx={{ "::before": { display: "none" } }} // main.scss puts styles we don't want here.
          >
            <Box {...getPiiProps()} {...props} component="span">
              <Typography variant="body1" color={(theme) => theme.palette.text.primary} component="span">
                {beforeMatchedSubstring}
                <Typography variant="inherit" fontWeight="bold" component="span">
                  {matchedSubstring}
                </Typography>
                {afterMatchedSubstring}
              </Typography>
            </Box>
          </Box>
        )
      }}
    />
  )
}
