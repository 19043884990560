import React, { FC } from "react"
import { CarrotCardModuleSharedProps } from "#/components/views/molecules/CarrotCardModuleContainer"
import { FormattedMessage } from "react-intl"
import { DbgPaths } from "#/utils/Paths"
import { CarrotCardModuleTemplate } from "./carrot-card-module-template"
import CardStates from "#/utils/CardStates"
import { Link, InternalIcon } from "@carrotfertility/carotene-core"

const CarrotCardEligibleWithDeductibleModule: FC<CarrotCardModuleSharedProps> = () => (
  <CarrotCardModuleTemplate
    cardState={CardStates.ELIGIBLE_WITH_DEDUCTIBLE_NOT_MET}
    body={
      <FormattedMessage defaultMessage="As soon as your deductible is met, you'll be eligible for a Carrot Card, which makes it easy to pay directly from your account." />
    }
    links={[
      <Link
        key="carrot-card-how-to"
        endIcon={<InternalIcon fontSize="small" color="primary" />}
        href={DbgPaths.CARROT_CARD}
      >
        <FormattedMessage defaultMessage="Learn how Carrot Card works" />
      </Link>
    ]}
  />
)

export { CarrotCardEligibleWithDeductibleModule }
