import React from "react"
import { useStateMachine } from "../../../context/stateMachine/StateMachineV2"
import { Form, FormDatePicker } from "@carrotfertility/carotene-core-x"
import {
  hasQuestionBeenSkipped,
  useCheckRoutingInfoQueryCache,
  useUpdateRoutingInfo
} from "#/components/carrot-plans/shared/useQueryRoutingInfo"
import { FormattedMessage, useIntl } from "react-intl"
import {
  ButtonFooterLayout,
  ContinueButton,
  RoutingFlowTitle,
  SkipButton,
  StepLayout
} from "#/components/carrot-plans/shared"
import { dayjs, Typography } from "@carrotfertility/carotene-core"
import { validateOptionalDate } from "#/services/common-forms"

export default function DeliveryDateStep(): JSX.Element {
  const { mutateAsync } = useUpdateRoutingInfo()
  const routingData = useCheckRoutingInfoQueryCache()
  const defaultValue = routingData?.deliveryDate == null ? null : routingData?.deliveryDate

  const { send } = useStateMachine(defaultValue || hasQuestionBeenSkipped("deliveryDate", routingData) ? "" : null)
  const intl = useIntl()

  async function onSkip() {
    await mutateAsync(
      {
        skipFields: ["deliveryDate"]
      },
      { onSuccess: () => send("") }
    )
  }
  async function onClickContinue({ deliveryDate }: { deliveryDate?: dayjs.Dayjs }): Promise<void> {
    if (deliveryDate?.isValid()) {
      await mutateAsync({ deliveryDate: deliveryDate.format("YYYY-MM-DD") })
      send("")
    } else {
      await onSkip()
    }
  }

  const header = intl.formatMessage({
    defaultMessage: "When did the birth take place?",
    description: "This relates to a pregnancy delivery date (birth)"
  })

  return (
    <>
      <RoutingFlowTitle title={header} />
      <StepLayout
        header={header}
        description={
          <Typography>
            <FormattedMessage defaultMessage="This information will help us provide you with the best possible support." />
          </Typography>
        }
        formElements={
          <>
            <Form defaultValues={{ deliveryDate: dayjs(defaultValue) }} onSubmit={onClickContinue}>
              <FormDatePicker
                required={false}
                name="deliveryDate"
                maxDate={dayjs()}
                controllerProps={{
                  rules: {
                    validate: (value: dayjs.Dayjs) => {
                      const result = validateOptionalDate(value)
                      if (typeof result !== "boolean") {
                        return intl.formatMessage(result)
                      }
                      return result
                    }
                  }
                }}
                label={intl.formatMessage({
                  defaultMessage: "Delivery date",
                  description: "This relates to a pregnancy delivery date (birth)"
                })}
              />
              <ButtonFooterLayout
                continueButton={
                  <ContinueButton>
                    <FormattedMessage defaultMessage="Continue" />
                  </ContinueButton>
                }
                skipButton={<SkipButton onClick={onSkip} />}
              />
            </Form>
          </>
        }
      />
    </>
  )
}
