import React, { useEffect, useState } from "react"
import { FormattedMessage } from "react-intl"
import { Box, Progress, Stack, Theme, Typography, useMediaQuery, useTheme } from "@carrotfertility/carotene-core"
import { BodyWithSidebarLayout } from "../../features/global-ui/page-layout"
import { TalkToCarrotExpertsSidebar } from "./TalkToCarrotExpertsSidebar"
import { mapContentToExpertBio } from "#/components/read/shared/ExpertBioMapper"
import { InlineWidget, useCalendlyEventListener } from "react-calendly"
import { useSelector } from "react-redux"
import { getEmployeeId } from "#/redux/reducers/userInfo"
import { CALENDLY_PRIMARY_COLOR } from "../../lib/contentful/utils/mappingUtils"
import { getHeap } from "../../utils/heap"
import { useGetSpecialistChatMetadata } from "./hooks/useGetSpecialistChatMetadata"
import { TalkToCarrotCalendlyError } from "./TalkToCarrotCalendlyError"
import { useFlags } from "launchdarkly-react-client-sdk"
import { useTrackExperimentEvent } from "../views/register/hooks/useExperiments"
import { TypeCalendlyLinkSkeleton } from "#/types/contentful"
import { EntryWithoutUnresolvableLinks } from "#/utils/ContentTypes"
import {
  TimeTapScheduler,
  TimeTapSpecialistConfirmation,
  useReportToHeapTimeTapChatScheduled
} from "#/components/time-tap/TimeTapScheduler"
import { useAppointmentSchedulingSystem } from "#/components/time-tap/useGetAppointmentSchedulingSystem"

const FAILSAFE_MINIMUM_LOAD_TIME_MS = 10000
const FAILSAFE_LOAD_TIME_SCALING_FACTOR = 5

const LoadingSpinner = () => (
  <Box
    display="flex"
    alignItems="center"
    justifyContent="center"
    sx={{
      inlineSize: "calc(100% - 10rem)",
      marginBlockStart: "50%",
      marginInlineStart: "5rem",
      padding: "2rem"
    }}
  >
    <Progress size="3rem" />
  </Box>
)

type CalendlyLinkEntry = EntryWithoutUnresolvableLinks<TypeCalendlyLinkSkeleton>

export const TalkToCarrotScheduleSpecialistChat = ({
  calendlyLink
}: {
  calendlyLink: CalendlyLinkEntry
}): React.ReactElement => {
  const theme = useTheme()
  const { calendlyErrorDetectionThreshold } = useFlags()
  const isMobileView = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"))
  const employeeId = useSelector(getEmployeeId)
  const { country, specialistType, language } = useGetSpecialistChatMetadata()
  const { trackExperimentEvent } = useTrackExperimentEvent()
  const [isCalendlyWidgetLoading, setIsCalendlyWidgetLoading] = useState<boolean>(true)
  const [isEventTypeViewed, setIsEventTypedViewed] = useState<boolean>(false)
  const [showChatConfirmation, setShowChatConfirmation] = useState(false)
  const {
    fields: { url, expertBios, reasonId, locationId, timeTapEnvironment },
    sys: { id }
  } = calendlyLink
  const { isTimeTapForOtherChats: isTimeTap } = useAppointmentSchedulingSystem(calendlyLink)

  const isCalendlyWidgetError = !isTimeTap && !isCalendlyWidgetLoading && !isEventTypeViewed

  const minimumLoadTimeMs = calendlyErrorDetectionThreshold?.minimumLoadTimeMs ?? FAILSAFE_MINIMUM_LOAD_TIME_MS
  const pageLoadTimeScalingFactor =
    calendlyErrorDetectionThreshold?.pageLoadTimeScalingFactor ?? FAILSAFE_LOAD_TIME_SCALING_FACTOR

  useCalendlyEventListener({
    onEventScheduled: () => {
      if (calendlyLink) {
        getHeap().track("Talk to carrot specialist chat scheduled", {
          "Calendly chat id": id,
          "Specialist chat country": country,
          "Specialist chat type": specialistType,
          "Specialist chat language": language
        })
        trackExperimentEvent("talk-to-carrot-expert-chat-scheduled")
      }
    },
    onEventTypeViewed: () => {
      setIsCalendlyWidgetLoading(false)
      setIsEventTypedViewed(true)
    }
  })
  useReportToHeapTimeTapChatScheduled(
    setShowChatConfirmation,
    {
      eventName: "Talk to carrot specialist chat scheduled",
      properties: {
        "Chat id": id,
        "Specialist chat country": country,
        "Specialist chat type": specialistType,
        "Specialist chat language": language
      }
    },
    Boolean(calendlyLink)
  )

  useEffect(() => {
    if (isTimeTap) return
    const entry = performance.getEntriesByType("navigation")[0] as PerformanceNavigationTiming
    const loadTime = entry.loadEventEnd - entry.domContentLoadedEventStart
    const timeoutId = setTimeout(
      () => {
        setIsCalendlyWidgetLoading(false)
      },
      loadTime > minimumLoadTimeMs ? loadTime * pageLoadTimeScalingFactor : minimumLoadTimeMs
    )

    return () => {
      clearTimeout(timeoutId)
    }
  }, [isTimeTap, minimumLoadTimeMs, pageLoadTimeScalingFactor])

  const expertsSidebar =
    expertBios?.length > 0 ? <TalkToCarrotExpertsSidebar expertBios={expertBios.map(mapContentToExpertBio)} /> : null

  const TimeTapSpecialistsScheduler = (
    <Box sx={{ blockSize: isMobileView ? "50vh" : "60vh" }}>
      {showChatConfirmation ? (
        <TimeTapSpecialistConfirmation />
      ) : (
        <TimeTapScheduler reasonId={reasonId} locationId={locationId} timeTapEnvironment={timeTapEnvironment} />
      )}
    </Box>
  )

  const SchedulerWidget = isTimeTap ? (
    TimeTapSpecialistsScheduler
  ) : (
    <InlineWidget
      url={url}
      utm={{ utmCampaign: employeeId }}
      pageSettings={{
        primaryColor: CALENDLY_PRIMARY_COLOR
      }}
      styles={{
        border: `${theme.tokens.borderWidth.md} solid ${theme.palette.border.light}`,
        padding: `${theme.spacing(theme.tokens.spacing.xxxs)}`,
        borderRadius: `${theme.borderRadius(theme.tokens.borderRadius.md)}px`,
        height: isMobileView ? "50vh" : "60vh",
        scrollBehavior: "auto"
      }}
      LoadingSpinner={LoadingSpinner}
    />
  )

  return isCalendlyWidgetError ? (
    <TalkToCarrotCalendlyError />
  ) : (
    <BodyWithSidebarLayout sidebar={expertsSidebar}>
      <Stack gap={theme.spacing(theme.tokens.spacing.md)}>
        {!showChatConfirmation ? (
          <>
            <Typography variant="h1" sx={{ display: "none" }}>
              <FormattedMessage defaultMessage="Schedule a specialist chat" />
            </Typography>
            <Typography variant="h3" component="h2" aria-hidden>
              <FormattedMessage defaultMessage="Find a time that works best for you" />
            </Typography>
          </>
        ) : null}
        {SchedulerWidget}
      </Stack>
    </BodyWithSidebarLayout>
  )
}
