import React from "react"
import { useTrackView } from "../../../utils/heap"
import { PiiText } from "#/services/tracking"
import { useTheme, Typography, Stack, Box } from "@carrotfertility/carotene-core"
import { FormattedMessage, defineMessage, useIntl } from "react-intl"
import { RegistrationFlowLayout } from "./RegistrationLayouts"
import { ContinueButton, RegistrationButtonFooter } from "./RegistrationButtons"
import { RegistrationFlowTitle } from "./RegistrationFlowTitle"
import { Benefit } from "#/lib/carrot-api/types/Benefit"

interface NiceToSeeYouProps {
  companyDisplayName?: string
  onComplete: () => void
  benefit: Benefit
}

const WelcomeScreenStepsCopy = {
  STEP_ONE: defineMessage({ defaultMessage: "Create your account" }),
  STEP_TWO: defineMessage({ defaultMessage: "Learn about your benefit and the resources available to you" }),
  STEP_THREE: defineMessage({
    defaultMessage: "Tell us about your journey to get a personalized Carrot Plan and unlock full access"
  })
}

const SupportOptions = {
  FERTILITY_CARE: defineMessage({ defaultMessage: "fertility care" }),
  FAMILY_BUILDING: defineMessage({ defaultMessage: "family building" }),
  HORMONAL_HEALTH: defineMessage({ defaultMessage: "hormonal health" })
}

export function NiceToSeeYou({ companyDisplayName, onComplete, benefit }: NiceToSeeYouProps): JSX.Element {
  useTrackView("WelcomeScreenRegFlow", "Welcome to Carrot in screen")
  const intl = useIntl()
  const header = intl.formatMessage({ defaultMessage: "Welcome to Carrot" })

  return (
    <>
      <RegistrationFlowTitle title={header} />
      <RegistrationFlowLayout
        header={header}
        imageName="illo-welcome"
        description={
          <>
            <Greeting companyDisplayName={companyDisplayName} benefit={benefit} />
            <WelcomeStepIntro />
            <Box marginLeft={(theme) => theme.spacing(theme.tokens.spacing.none)}>
              <IconListItem iconName={"icn-user"}>{intl.formatMessage(WelcomeScreenStepsCopy.STEP_ONE)}</IconListItem>
              <IconListItem iconName={"icn-lightbulb-color"}>
                {intl.formatMessage(WelcomeScreenStepsCopy.STEP_TWO)}
              </IconListItem>
              <IconListItem iconName={"icn-account-settings"}>
                {intl.formatMessage(WelcomeScreenStepsCopy.STEP_THREE)}
              </IconListItem>
            </Box>
          </>
        }
        interactiveElements={
          <>
            <RegistrationButtonFooter
              continueButton={
                <ContinueButton onClick={onComplete}>
                  <FormattedMessage defaultMessage="Continue" />
                </ContinueButton>
              }
            />
          </>
        }
      />
    </>
  )
}

const useFormattedSupportedJourneysList = (benefit: Benefit) => {
  const journeys = benefit?.journeys
  const intl = useIntl()

  const fertilityCareSupported =
    journeys?.fertilityCare?.isAppSupportEnabled || journeys?.fertilityPreservation?.isAppSupportEnabled
  const familyBuildingSupported = journeys?.pregnancy?.isAppSupportEnabled || journeys?.parenting?.isAppSupportEnabled
  const hormonalHealthSupported =
    journeys?.menopause?.isAppSupportEnabled ||
    journeys?.lowTestosterone?.isAppSupportEnabled ||
    journeys?.genderAffirmingCare?.isAppSupportEnabled

  const supportedJourneys = [
    { supported: fertilityCareSupported, option: SupportOptions.FERTILITY_CARE },
    { supported: familyBuildingSupported, option: SupportOptions.FAMILY_BUILDING },
    { supported: hormonalHealthSupported, option: SupportOptions.HORMONAL_HEALTH }
  ]

  const supportedJourneyOptions = supportedJourneys
    .filter((journey) => journey.supported)
    .map((journey) => journey.option)

  const listOfSupportOptions = intl.formatList(
    supportedJourneyOptions.map((option) => intl.formatMessage(option)),
    { type: "conjunction" }
  )

  return {
    listOfSupportOptions
  }
}

function Greeting({ companyDisplayName, benefit }: { companyDisplayName: string; benefit: Benefit }): JSX.Element {
  const { listOfSupportOptions } = useFormattedSupportedJourneysList(benefit)

  return (
    <Typography>
      <FormattedMessage
        defaultMessage="<PiiText>{companyDisplayName}</PiiText> has partnered with Carrot, a benefit designed to support {listOfSupportOptions}."
        values={{
          companyDisplayName: companyDisplayName,
          listOfSupportOptions,
          PiiText: (textContent) => <PiiText>{textContent}</PiiText>
        }}
        description={
          "'listOfSupportOptions' is a locale-aware formatted list of support options joined with commas and the word 'and'. For example, 'fertility care and family building' or 'fertility care, family building, and hormonal health'"
        }
      />
    </Typography>
  )
}

function WelcomeStepIntro() {
  return (
    <Typography
      marginTop={(theme) => theme.spacing(theme.tokens.spacing.md)}
      marginBottom={(theme) => theme.spacing(theme.tokens.spacing.md)}
    >
      <FormattedMessage defaultMessage="Get started in a few simple steps:" />
    </Typography>
  )
}

function IconListItem({ iconName, children }: { iconName: string; children: any }): JSX.Element {
  const theme = useTheme()
  return (
    <Box paddingBottom={(theme) => theme.spacing(theme.tokens.spacing.xs)}>
      <Stack direction="row" alignItems={"center"} spacing={theme.spacing(theme.tokens.spacing.md)}>
        <Box
          component="img"
          src={`/images/${iconName}.png`}
          srcSet={`/images/${iconName}-2x.png`}
          alt=""
          height={32}
          width={32}
        />
        <Typography>{children}</Typography>
      </Stack>
    </Box>
  )
}
