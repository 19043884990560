import { HttpErrors } from "#/utils/HttpErrors"
import actionTypes from "#/redux/actions/actionTypes"

export const createRequestAction = (actionTypeRoot: any, requestFn: any) => {
  return () => async (dispatch: any) => {
    let response

    try {
      response = await requestFn()
    } catch (error) {
      if (error.name === HttpErrors.UNAUTHORIZED) {
        dispatch({
          type: actionTypes.SESSION_EXPIRED
        })
        return
      }

      dispatch({
        type: `${actionTypeRoot}_FAILURE`
      })

      throw error
    }

    dispatch({
      type: `${actionTypeRoot}_SUCCESS`,
      response
    })
  }
}
