import { useQuery, UseQueryResult } from "@tanstack/react-query"
import { IvfRequirementsInfo } from "carrot-api/types/IvfRequirementsInfo"
import ResponseError from "../../../../types/responseError"
import { carrotClient } from "#/utils/CarrotClient"
import { HttpStatusCodes } from "../../../../utils/HttpStatusCodes"

export function useIvfRequirementsInfo(): UseQueryResult<IvfRequirementsInfo, ResponseError> {
  return useQuery<IvfRequirementsInfo, ResponseError>(
    ["GetIvfRequirementsInfoForCurrentUser"],
    () => carrotClient.getIvfRequirementsInfo(),
    {
      retry: (failureCount, error) => {
        switch (error.statusCode) {
          case HttpStatusCodes.UNAUTHORIZED:
          case HttpStatusCodes.FORBIDDEN:
          case HttpStatusCodes.BAD_REQUEST:
          case HttpStatusCodes.NOT_FOUND:
            return false
          default:
            return failureCount <= 3
        }
      }
    }
  )
}
