import React, { FC } from "react"
import { useToggle } from "../../../utils/Hooks"
import { ContentfulContentEntry, RecursiveContentfulContentRenderer } from "../content-renderer"
import { ContentfulContentProp } from "./shared-types"
import { ContentfulDialog } from "../shared/contentful-modal"
import { ContentfulArrowLink } from "../shared/contentful-arrow-link"
import { ModalContentContext } from "../shared/modal-content-context"
import { EntryWithoutUnresolvableLinks } from "#/utils/ContentTypes"
import { TypeModalSkeleton } from "#/types/contentful"

export const Modal: FC<ContentfulContentProp<EntryWithoutUnresolvableLinks<TypeModalSkeleton>>> = ({ content }) => {
  const {
    fields: { linkText, content: internalContent, title }
  } = content
  const [showModal, toggleShowModal] = useToggle(false)

  return (
    <>
      <ContentfulArrowLink component="button" onClick={toggleShowModal}>
        {linkText}
      </ContentfulArrowLink>
      <ContentfulDialog title={title} open={showModal} onClose={toggleShowModal}>
        <ModalContentContext.Provider value={{ isModalContent: true }}>
          <RecursiveContentfulContentRenderer contents={internalContent as ContentfulContentEntry[]} />
        </ModalContentContext.Provider>
      </ContentfulDialog>
    </>
  )
}
