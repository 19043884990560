import React from "react"
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography
} from "@carrotfertility/carotene-core"
import {
  FormattedProviderTypesList,
  getAvailableParentIdsByBenefitConfig,
  PARENT_ID_MAPPING,
  PARENT_ID_TO_PROVIDER_COUNT_MAPPING
} from "../../utils/parentIdMapping"
import { useGetHasProviderTypeInCountry } from "../../hooks/useGetHasProviderTypeInCountry"
import { useUserContext } from "../../context/UserContext"
import { FormattedMessage, useIntl } from "react-intl"
import { useIntlSort } from "../../../../utils/hooks/useIntlSort"
import { Benefit } from "#/lib/carrot-api/types/Benefit"
import { FertilityWraparoundMessageForDropdown } from "../sidebar/FertilityWraparoundMessage"

type ProviderTypeFilterDropdownProps = {
  providerTypes: string[]
  setProviderTypes: React.Dispatch<React.SetStateAction<string[]>>
  benefit: Benefit
}

export function ProviderTypeFilterDropdown({
  providerTypes,
  setProviderTypes,
  benefit
}: ProviderTypeFilterDropdownProps): JSX.Element {
  const intl = useIntl()
  const { localeComparator } = useIntlSort()
  const availableParentIds = getAvailableParentIdsByBenefitConfig(benefit)
  const { companyCountryCode } = useUserContext()
  const { data: hasProviderTypes, isLoading: isLoadingHasProviderTypes } =
    useGetHasProviderTypeInCountry(companyCountryCode)
  const hasFertilityWraparoundEnabled = benefit.providerFinder.showFertilityWrapAroundMessage

  const handleChange = (event: SelectChangeEvent<typeof providerTypes>) => {
    const {
      target: { value }
    } = event
    if (value[value.length - 1] === "all") {
      setProviderTypes(options)
      return
    }
    if (value[value.length - 1] === "none") {
      setProviderTypes([])
      return
    }
    setProviderTypes(typeof value === "string" ? value.split(",") : value)
  }
  const options = availableParentIds
    .filter((parentId) => {
      const providerType = PARENT_ID_TO_PROVIDER_COUNT_MAPPING[parentId]
      return hasProviderTypes && hasProviderTypes[providerType]
    })
    .sort((parentId1, parentId2) => {
      const parentFullDisplayName1 = intl.formatMessage(PARENT_ID_MAPPING[parentId1].combinedDisplayName)
      const parentFullDisplayName2 = intl.formatMessage(PARENT_ID_MAPPING[parentId2].combinedDisplayName)
      return localeComparator(parentFullDisplayName1, parentFullDisplayName2)
    })
  const optionsElements = options.map((parentId) => {
    const { combinedDisplayName } = PARENT_ID_MAPPING[parentId]
    const text = intl.formatMessage(combinedDisplayName)

    return (
      <MenuItem value={parentId} key={parentId}>
        <Box display="flex" flexDirection="column">
          <FormControlLabel
            htmlFor="provider-type-dropdown-select"
            control={<Checkbox checked={providerTypes.indexOf(parentId) > -1} />}
            label={text}
          />
        </Box>
      </MenuItem>
    )
  })

  if (isLoadingHasProviderTypes) {
    return null
  }

  return (
    <FormControl fullWidth>
      <InputLabel id={"provider-type-dropdown-select-label"}>
        <FormattedMessage defaultMessage="Provider type" />
      </InputLabel>
      <Select
        multiple
        labelId="provider-type-dropdown-select-label"
        id="provider-type-dropdown-select"
        value={providerTypes}
        onChange={handleChange}
        renderValue={FormattedProviderTypesList}
      >
        {providerTypes.length ? (
          <MenuItem value="none" key="none">
            <Typography variant="body2" fontWeight="bold">
              <FormattedMessage defaultMessage="Unselect All" />
            </Typography>
          </MenuItem>
        ) : (
          <MenuItem value="all" key="all">
            <Typography variant="body2" fontWeight="bold">
              <FormattedMessage defaultMessage="Select All" />
            </Typography>
          </MenuItem>
        )}
        {optionsElements}
        {hasFertilityWraparoundEnabled && <FertilityWraparoundMessageForDropdown />}
      </Select>
    </FormControl>
  )
}
