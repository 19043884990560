import { ProviderFinder } from "#/pages/provider-finder"
import React from "react"
import { useSelector } from "react-redux"
import { ProviderFinderErrorReportingProvider } from "#/pages/provider-finder/context/ErrorReportingContext"
import { UserContextProvider } from "#/pages/provider-finder/context/UserContext"
import { ConfigContextProvider } from "#/pages/provider-finder/context/ConfigContext"
// should decouple the following imports in the long run
import Settings from "../../../../utils/CarrotConfig"
import { useAuthentication } from "../../../context/authentication/AuthenticationProvider"
import { getCompanyId, getCountryCode } from "#/redux/reducers/companyInfo"
import { CompanyContextProvider } from "#/pages/provider-finder/context/CompanyContextProvider"

export const ProviderFinderAdapter = (): JSX.Element => {
  const companyCountryCode = useSelector(getCountryCode)
  const companyId = useSelector(getCompanyId)

  const { isLoggedIn, handleSessionExpiration } = useAuthentication()

  return (
    <ConfigContextProvider config={Settings}>
      <ProviderFinderErrorReportingProvider>
        <UserContextProvider
          companyCountryCode={companyCountryCode}
          isLoggedIn={isLoggedIn}
          handleSessionExpiration={handleSessionExpiration}
        >
          <CompanyContextProvider companyId={companyId}>
            <ProviderFinder />
          </CompanyContextProvider>
        </UserContextProvider>
      </ProviderFinderErrorReportingProvider>
    </ConfigContextProvider>
  )
}
