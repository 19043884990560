import { useQuery } from "@tanstack/react-query"
import { getCheckout } from "#/lib/carrot-api/avaClient"
import ResponseError from "../../../types/responseError"
import buildCarrotApiRetryPolicy from "../../../utils/CarrotApiRetryPolicy"
import useQueryExpirationHandler from "../../context/authentication/hooks/useQueryExpirationHandler"

export default function useAvaCheckoutLink() {
  const AvaCheckoutLinkQuery = useQuery<string, ResponseError>(["getCheckout"], () => getCheckout(), {
    retry: buildCarrotApiRetryPolicy(3)
  })
  useQueryExpirationHandler(AvaCheckoutLinkQuery)
  return {
    ...AvaCheckoutLinkQuery
  }
}
