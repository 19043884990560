import React, { useState } from "react"
import {
  Box,
  Checkbox,
  Divider,
  IconButton,
  InfoIcon,
  Link,
  Typography,
  MenuIcon,
  Menu,
  MenuItem,
  ClickAwayListener,
  Tooltip,
  checkboxClasses,
  ForwardIcon
} from "@carrotfertility/carotene-core"
import { CarrotPlan, CarrotPlanStep } from "../../types/carrotPlanTypes"
import CarrotPlansTourModal from "./CarrotPlansTourModal"
import { useToggle } from "#/utils/Hooks"
import useCarrotPlanMenuItems, { CarrotPlanStepMenuItem } from "../carrot-plans/hooks/useCarrotPlanMenuItems"
import { Error } from "../views/Error"
import { reportErrorMessage } from "../../utils/ErrorReporting"
import { useQueryClient } from "@tanstack/react-query"
import { getCarrotPlanQueryKey } from "#/components/carrot-plans/hooks/useCarrotPlan"
import useUpdatePlanStep from "../carrot-plans/hooks/useUpdatePlanStep"
import { FormattedMessage, useIntl } from "react-intl"
import moment from "moment"

type CarrotPlanHeroProps = {
  plan: CarrotPlan
}

type PlanStepProps = {
  carrotPlanStepMenuItem: CarrotPlanStepMenuItem
  employeeId: string | number
}

interface StepContentProps extends Omit<PlanStepProps, "employeeId">, React.HTMLAttributes<HTMLSpanElement> {
  stepCompleted: boolean
}

type StepCheckmarkProps = {
  carrotPlanStepMenuItem: CarrotPlanStepMenuItem
  stepCompleted: boolean
  handleStepCompleted: (stepId: number) => void
}

export default function CarrotPlanHero({ plan }: CarrotPlanHeroProps): JSX.Element {
  const [showTourModal, toggleShowTourModal] = useToggle(false)
  const intl = useIntl()
  const { locale } = intl

  const {
    data: carrotPlanStepMenuItems,
    isLoading: menuItemsLoading,
    error: menuItemsError
  } = useCarrotPlanMenuItems(plan.steps, locale)

  if (menuItemsError) {
    reportErrorMessage(
      `Unable to retrieve Carrot Plan menu items from Contentful for employee ${plan.employeeId}, error: ${menuItemsError}`
    )
    return <Error />
  }
  const createdAtUtcDate: Date = moment(plan.createdAtUtc).toDate()

  return (
    <>
      {!menuItemsLoading ? (
        <Box
          id="carrot-plans-hero"
          width="100%"
          display="flex"
          component="article"
          gap={(theme) => theme.spacing(theme.tokens.spacing.lg)}
          flexDirection="column"
          borderRadius={(theme) => theme.tokens.borderRadius.md}
          bgcolor={(theme) => theme.palette.info.dark}
          color={(theme) => theme.palette.secondary.contrastText}
          padding={(theme) => theme.spacing(theme.tokens.spacing.lg)}
        >
          <Box alignItems="center" display="flex" flexDirection="row" justifyContent="space-between">
            <Box display="flex" gap={(theme) => theme.spacing(theme.tokens.spacing.xxs)} alignItems="center">
              <Typography variant="h3" component="h2" color="inherit">
                <FormattedMessage defaultMessage="Your Carrot Plan" />
              </Typography>
              <IconButton
                aria-haspopup="dialog"
                color="inherit"
                onClick={toggleShowTourModal}
                aria-label={intl.formatMessage({ defaultMessage: "How to use Carrot Plans" })}
              >
                <InfoIcon />
              </IconButton>
            </Box>
            <CarrotPlansTourModal open={showTourModal} onClose={toggleShowTourModal} />

            <Typography variant="body2" color="inherit" data-testid="carrot-plans-hero">
              <FormattedMessage
                defaultMessage="Updated {formattedDate}"
                values={{
                  formattedDate: intl.formatDate(createdAtUtcDate, {
                    year: "numeric",
                    month: "long",
                    day: "numeric"
                  })
                }}
              />
            </Typography>
          </Box>
          <Box
            component="ul"
            display="flex"
            gap={(theme) => theme.spacing(theme.tokens.spacing.lg)}
            flexDirection="column"
            sx={{ "li::before": { display: "none" } }}
          >
            {carrotPlanStepMenuItems.map((stepMenuItem) => (
              <Box
                component="li"
                display="flex"
                gap={(theme) => theme.spacing(theme.tokens.spacing.lg)}
                flexDirection="column"
                key={stepMenuItem?.carrotPlanStepId}
                id={`cp-menuitem-${stepMenuItem?.menuItemId}`}
              >
                <Divider role="presentation" />
                <PlanStep employeeId={plan?.employeeId} carrotPlanStepMenuItem={stepMenuItem} />
              </Box>
            ))}
          </Box>
        </Box>
      ) : null}
    </>
  )
}

export function getStepMenuItemDisplayText(carrotPlanStepMenuItem: CarrotPlanStepMenuItem): JSX.Element | string {
  const displayText = carrotPlanStepMenuItem?.displayText

  if (carrotPlanStepMenuItem?.readLearnArticleText) {
    const readLearnArticleText = carrotPlanStepMenuItem?.readLearnArticleText
    if (readLearnArticleText) {
      return displayText?.replace(/\[(.+?)]/g, readLearnArticleText)
    }
  } else if (carrotPlanStepMenuItem?.entryId && !carrotPlanStepMenuItem?.readLearnArticleText) {
    return (
      <FormattedMessage defaultMessage="Check out our Carrot Academy resources for more information about your journey" />
    )
  }
  return displayText
}

function StepContent({ carrotPlanStepMenuItem: step, stepCompleted, ...otherProps }: StepContentProps): JSX.Element {
  const showLink = React.useMemo(
    () => Boolean(step?.url) && !(step?.isLocked && stepCompleted),
    [step?.isLocked, step?.url, stepCompleted]
  )

  if (showLink) {
    return (
      <Link
        color="inherit"
        underline="hover"
        sx={{
          display: "flex",
          alignItems: "center",
          "&:hover": {
            textDecorationThickness: "2px",
            textDecorationLine: "underline",
            textDecorationColor: "inherit"
          }
        }}
        href={step?.url}
        id={`cp-item-${step?.carrotPlanStepId}-label`}
      >
        {getStepMenuItemDisplayText(step)}
        <ForwardIcon />
      </Link>
    )
  }
  return (
    <Typography id={`cp-item-${step?.carrotPlanStepId}-label`} color="inherit" {...otherProps}>
      {step?.displayText}
    </Typography>
  )
}

function StepCheckmark({
  carrotPlanStepMenuItem: step,
  stepCompleted,
  handleStepCompleted
}: StepCheckmarkProps): JSX.Element {
  const [open, setOpen] = React.useState(false)
  const handleTooltipClose = () => {
    setOpen(false)
  }
  const handleTooltipOpen = () => {
    setOpen(!open)
  }
  return step?.isLocked ? (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <Tooltip
        onClose={handleTooltipClose}
        open={open}
        title={
          stepCompleted ? (
            <FormattedMessage defaultMessage="We checked this one for you" />
          ) : (
            <FormattedMessage defaultMessage="We'll check this one for you when it's done" />
          )
        }
      >
        <Checkbox
          sx={{
            color: "inherit",
            [`&.${checkboxClasses.checked}`]: {
              color: "inherit"
            },
            "&:hover": { color: "inherit" }
          }}
          readOnly
          checked={stepCompleted}
          onClick={handleTooltipOpen}
          inputProps={{ "aria-labelledby": `cp-item-${step?.carrotPlanStepId}-label` }}
        />
      </Tooltip>
    </ClickAwayListener>
  ) : (
    <Checkbox
      sx={{
        color: "inherit",
        [`&.${checkboxClasses.checked}`]: {
          color: "inherit"
        },
        "&:hover": { color: "inherit" }
      }}
      checked={stepCompleted}
      onChange={() => handleStepCompleted(step?.carrotPlanStepId)}
      inputProps={{ "aria-labelledby": `cp-item-${step?.carrotPlanStepId}-label` }}
    />
  )
}

function PlanStep({ carrotPlanStepMenuItem: step, employeeId }: PlanStepProps): JSX.Element {
  const [stepCompleted, setStepCompleted] = useState(!!step?.completedAtUtc)
  const query = useQueryClient()
  const [markedNotRelevant, setMarkedNotRelevant] = useState(!!step?.markedNotRelevantAtUtc)
  const { mutate } = useUpdatePlanStep()

  const handleStepCompleted = React.useCallback(
    (stepId: number): void => {
      setStepCompleted(!stepCompleted)
      mutate(
        { stepId, completed: !stepCompleted },
        { onSuccess: () => query.invalidateQueries(getCarrotPlanQueryKey()) }
      )
    },
    [mutate, query, stepCompleted]
  )

  const handleNotRelevant = React.useCallback(
    (stepId: number): void => {
      setMarkedNotRelevant(!markedNotRelevant)
      mutate(
        { stepId, markedNotRelevant: !markedNotRelevant },
        { onSuccess: () => query.invalidateQueries(getCarrotPlanQueryKey()) }
      )
    },
    [markedNotRelevant, mutate, query]
  )

  return (
    <Box display="flex" justifyContent="space-between">
      <Box
        display="flex"
        flexDirection="row"
        gap={(theme) => theme.spacing(theme.tokens.spacing.md)}
        alignItems="center"
        sx={{ opacity: markedNotRelevant ? "70%" : null }}
      >
        <StepCheckmark {...{ carrotPlanStepMenuItem: step, stepCompleted, handleStepCompleted }} />
        <StepContent {...{ carrotPlanStepMenuItem: step, stepCompleted, employeeId }} />
      </Box>
      {!step?.isLocked && (
        <StepMenu
          id={`cp-item-${step?.carrotPlanStepId}-menu`}
          role="menu"
          aria-labelledby={`cp-item-${step?.carrotPlanStepId}-menu-button`}
          markedNotRelevant={markedNotRelevant}
          stepId={step?.carrotPlanStepId}
          step={step}
          handleNotRelevant={handleNotRelevant}
        />
      )}
    </Box>
  )
}

type StepMenuProps = {
  markedNotRelevant: boolean
  stepId: number
  step: CarrotPlanStep
  handleNotRelevant: (stepId: number) => void
} & React.HTMLAttributes<HTMLDivElement>

function StepMenu({ markedNotRelevant, stepId, step, handleNotRelevant }: StepMenuProps): JSX.Element {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const showNotRelevant = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const intl = useIntl()

  return (
    <Box>
      <IconButton
        id={`cp-item-${step?.carrotPlanStepId}-menu-button`}
        aria-haspopup="true"
        aria-controls={showNotRelevant ? `cp-item-${step?.carrotPlanStepId}-menu` : null}
        aria-label={intl.formatMessage({ defaultMessage: "Carrot Plan item menu" })}
        onClick={handleClick}
        color="inherit"
      >
        <MenuIcon />
      </IconButton>
      <Menu
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center"
        }}
        open={showNotRelevant}
        onClose={handleClose}
        anchorEl={anchorEl}
      >
        <MenuItem
          onClick={() => {
            handleNotRelevant(stepId)
            handleClose()
          }}
        >
          {markedNotRelevant ? (
            <FormattedMessage defaultMessage="This is relevant to me" />
          ) : (
            <FormattedMessage defaultMessage="This is not relevant to me" />
          )}
        </MenuItem>
      </Menu>
    </Box>
  )
}
