import { SendMessage } from "#/components/talk-to-carrot/send-message/SendMessage"
import React, { FC } from "react"
import { MemberConnectFailure, MemberConnectKnownOutage, PartnerConnectFailure } from "./Failure"

interface PartnerProcessConnectInsuranceFailureContainerProps {
  onExit: () => void
}

const PartnerProcessConnectInsuranceFailContainer: FC<PartnerProcessConnectInsuranceFailureContainerProps> = () => (
  <SendMessage modalTopic={"ConnectInsuranceError"} BenefitEnrollmentView={PartnerConnectFailure} />
)

interface ProcessConnectInsuranceFailureContainerProps {
  onExit: () => void
}

const ProcessConnectInsuranceFailContainer: FC<ProcessConnectInsuranceFailureContainerProps> = () => (
  <SendMessage modalTopic={"ConnectInsuranceError"} BenefitEnrollmentView={MemberConnectFailure} />
)

const ProcessConnectInsuranceKnownOutageContainer: FC<ProcessConnectInsuranceFailureContainerProps> = () => (
  <SendMessage modalTopic={"ConnectInsuranceError"} BenefitEnrollmentView={MemberConnectKnownOutage} />
)

export {
  ProcessConnectInsuranceFailContainer,
  PartnerProcessConnectInsuranceFailContainer,
  ProcessConnectInsuranceKnownOutageContainer
}
