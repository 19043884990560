import React from "react"
import { useStateMachine } from "#/components/context/stateMachine/StateMachineV2"
import { useGetPartnerInviteStatus, useSendInvite } from "#/components/partner-access/hooks/usePartnerAccess"
import { Stack, Typography, useTheme } from "@carrotfertility/carotene-core"
import { FormattedMessage, useIntl } from "react-intl"
import { Form, FormCheckbox } from "@carrotfertility/carotene-core-x"
import { ButtonFooterLayout, ContinueButton, RoutingFlowTitle, StepLayout } from "#/components/carrot-plans/shared"
import PartnerAccessConsent from "#/components/partner-access/PartnerAccessConsent"

export default function AboutYourPartnerAccessInviteStep(): JSX.Element {
  const intl = useIntl()
  const { mutate: sendInvite } = useSendInvite()
  const { hasInviteBeenSent } = useGetPartnerInviteStatus()
  const { send } = useStateMachine()

  async function onClickContinue(formValues: { isConsentChecked: boolean }): Promise<void> {
    if (formValues?.isConsentChecked && !hasInviteBeenSent) {
      try {
        sendInvite(formValues?.isConsentChecked)
      } catch (error) {
        reportError(error)
      }
    }
    send("")
  }

  const theme = useTheme()

  const header = intl.formatMessage({
    defaultMessage: "Would you like to invite your partner to access your account?"
  })

  return (
    <>
      <RoutingFlowTitle title={header} />
      <StepLayout
        header={header}
        description={
          <Stack spacing={theme.spacing(theme.tokens.spacing.lg)}>
            <PartnerAccessConsent isInvitationSent={false} />
            <Typography>
              <FormattedMessage defaultMessage="You can also add or remove partner access later from your account settings page." />
            </Typography>
          </Stack>
        }
        formElements={
          <Form onSubmit={onClickContinue}>
            <FormCheckbox
              name="isConsentChecked"
              checked={hasInviteBeenSent}
              disabled={hasInviteBeenSent}
              label={
                <FormattedMessage
                  defaultMessage="Yes, I would like to invite my partner. I give my consent for them to access my account as described
                above."
                />
              }
            />
            <Typography marginTop={(theme) => theme.spacing(theme.tokens.spacing.lg)} fontStyle="italic">
              <FormattedMessage defaultMessage="You may withdraw this consent at any time." />
            </Typography>
            <ButtonFooterLayout
              continueButton={
                <ContinueButton>
                  <FormattedMessage defaultMessage="Continue" />
                </ContinueButton>
              }
            />
          </Form>
        }
      />
    </>
  )
}
