import React from "react"
import { CountryCode } from "#/utils/CountryCode"
import { isEEACountry } from "#/services/countries/eu-countries"
import { Steps } from "#/pages/request-carrot-card-flow/workflows/steps"
import { useStateMachine } from "#/components/context/stateMachine/StateMachineV2"
import RequestCardWrapper, { RequestCardWrapperProps } from "#/pages/request-carrot-card-flow/requestCardWrapper"
import { DialogTitle, DialogContentText } from "@carrotfertility/carotene-core"
import { FormattedMessage } from "react-intl"
import { unexpectedServerErrorAlert } from "#/pages/request-carrot-card-flow/requestCardWrapper/unexpected-error-alert"

export enum IssuingRegion {
  Australia,
  EEA,
  Canada,
  Singapore,
  UK,
  Unknown
}

export function getIssuingRegion(countryCode: CountryCode) {
  if (isEEACountry(countryCode)) {
    return IssuingRegion.EEA
  }
  switch (countryCode) {
    case CountryCode.Canada:
      return IssuingRegion.Canada
    case CountryCode.United_Kingdom:
      return IssuingRegion.UK
    case CountryCode.Singapore:
      return IssuingRegion.Singapore
    case CountryCode.Australia:
      return IssuingRegion.Australia
  }
  return IssuingRegion.Unknown
}

type AirwallexUserAgreementTemplateProps = Pick<RequestCardWrapperProps, "disableContinue"> & {
  userAgreementChecks: React.ReactNode | React.ReactNode[]
  isError?: boolean
  title?: React.ReactNode | React.ReactNode[]
  description?: React.ReactNode | React.ReactNode[]
  ["data-testid"]: string
}

export function AirwallexUserAgreementTemplate({
  disableContinue,
  userAgreementChecks,
  isError,
  title = <FormattedMessage defaultMessage="Review terms" />,
  description = (
    <FormattedMessage defaultMessage="To receive a Carrot Card, you need to read and accept the card issuer’s terms." />
  ),
  ...otherProps
}: AirwallexUserAgreementTemplateProps) {
  const { canGoBack } = useStateMachine()

  return (
    <RequestCardWrapper
      goBackToStepName={Steps.CONFIRM_PHONE_NUMBER}
      showBackButton={canGoBack}
      showContinueButton
      disableContinue={disableContinue}
    >
      <DialogTitle data-testid={otherProps["data-testid"]}>{title}</DialogTitle>
      <DialogContentText marginBottom={(theme) => theme.tokens.spacing.xl}>{description}</DialogContentText>
      {userAgreementChecks}
      {isError && unexpectedServerErrorAlert}
    </RequestCardWrapper>
  )
}

export async function attemptRequest(requestFunction: () => Promise<unknown>) {
  try {
    await requestFunction()
  } catch {
    /* Do nothing */
  }
}
