import { useLocaleContext } from "#/components/context/user/LocaleContext"
import React, { useEffect, useState } from "react"
import { useIntl } from "react-intl"
import { Box, Button, DownIcon, Menu, MenuItem } from "@carrotfertility/carotene-core"
import { PreferredLocaleChangedDialog } from "./register/PreferredLocaleChangedDialog"
import { Locale, SupportedLocale } from "../../types/Locale"
import { useAllLocales } from "#/services/locale/hooks/useAllLocales"

interface UnauthPreferredLocaleProps {
  showModal?: boolean
}

function UnauthPreferredLocale({ showModal }: UnauthPreferredLocaleProps): React.ReactElement {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const { setLocale } = useLocaleContext()
  const intl = useIntl()
  const locale: SupportedLocale = Locale.getLocaleFromString(intl.locale)
  const [modalOpen, setModalOpen] = React.useState(false)
  const [sortedLocaleStrings, setSortedLocaleStrings] = React.useState<string[]>([])

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = (selectedLocaleString?: string) => {
    // this gets passed a SyntheticEvent if user clicks out of dropdown, don't change locale in that case
    if (selectedLocaleString && typeof selectedLocaleString === "string") {
      setLocale(Locale.getLocaleFromString(selectedLocaleString))
      if (showModal) {
        setModalOpen(true)
      }
    }
    setAnchorEl(null)
  }

  const { data: allLocales, isLoading } = useAllLocales()

  useEffect(() => {
    if (allLocales) {
      setSortedLocaleStrings([
        SupportedLocale.English_UnitedStates.toString(),
        ...allLocales.filter((localeString) => localeString !== SupportedLocale.English_UnitedStates.toString()).sort()
      ])
    }
  }, [allLocales])

  return (
    <>
      {!isLoading && (
        <Box>
          <Button
            variant="outlined"
            color="secondary"
            id="show-menu"
            aria-label={intl.formatMessage({ defaultMessage: "Open menu" })}
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup={true}
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
          >
            {locale.displayName}
            <DownIcon />
          </Button>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "show-menu"
            }}
          >
            {sortedLocaleStrings.map((localeString) => {
              const label = Locale.getDisplayNameFromString(localeString)
              return (
                <MenuItem key={localeString} value={label} onClick={() => handleClose(localeString)}>
                  {label}
                </MenuItem>
              )
            })}
          </Menu>
          {/* For contexts like Registration, we want to show a modal giving user more info about language selection */}
          {showModal && (
            <PreferredLocaleChangedDialog preferredLocale={locale} modalOpen={modalOpen} setModalOpen={setModalOpen} />
          )}
        </Box>
      )}
    </>
  )
}

export { UnauthPreferredLocale }
