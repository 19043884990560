import React from "react"
import { Stack, Button, useTheme, Box, Typography } from "@carrotfertility/carotene-core"
import { Container } from "@carrotfertility/carotene"
import { useHistory } from "react-router-dom"
import { FormattedMessage } from "react-intl"

// @ts-expect-error TS(7031) FIXME: Binding element 'needsBankInfo' implicitly has an ... Remove this comment to see the full error message
function BankInfoOptionButtons({ needsBankInfo, onExit }) {
  return needsBankInfo ? (
    <>
      <Container padding="none" stack="large">
        <Typography color={(theme) => theme.palette.text.secondary} paddingTop={(theme) => theme.tokens.spacing.xs}>
          <FormattedMessage defaultMessage="Next, update your bank info so we can direct your reimbursement funds to the right account." />
        </Typography>
      </Container>
      <ButtonBarNeedsBankInfo onExit={onExit} />
    </>
  ) : (
    <ButtonBarDoesntNeedBankInfo onExit={onExit} />
  )
}

// @ts-expect-error TS(7031) FIXME: Binding element 'onExit' implicitly has an 'any' t... Remove this comment to see the full error message
const ButtonBarNeedsBankInfo = ({ onExit }) => {
  const history = useHistory()
  const theme = useTheme()

  const handleUpdateBankDetailsClick = () => {
    onExit()
    history.push("/bank-details")
  }

  return (
    <>
      <Stack
        justifyContent={"flex-end"}
        flexWrap={"wrap"}
        direction={{ md: "row", xs: "column" }}
        useFlexGap
        spacing={theme.spacing(theme.tokens.spacing.sm)}
      >
        <Button sx={{ textWrap: "no-wrap" }} color="secondary" variant="outlined" onClick={onExit}>
          <FormattedMessage defaultMessage="I'll do this later" />
        </Button>
        <Button sx={{ textWrap: "no-wrap" }} color="primary" variant="contained" onClick={handleUpdateBankDetailsClick}>
          <FormattedMessage defaultMessage="Update bank info" />
        </Button>
      </Stack>
    </>
  )
}

// @ts-expect-error TS(7031) FIXME: Binding element 'onExit' implicitly has an 'any' t... Remove this comment to see the full error message
const ButtonBarDoesntNeedBankInfo = ({ onExit }) => (
  <Box display={"flex"} justifyContent={"flex-end"}>
    <Button onClick={onExit} color="primary" variant="contained">
      <FormattedMessage defaultMessage="Close" />
    </Button>
  </Box>
)

export { BankInfoOptionButtons }
