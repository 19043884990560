import React, { ReactElement, useEffect } from "react"
import { useStateMachine } from "../../../context/stateMachine/StateMachineV2"
import { useCheckRoutingInfoQueryCache } from "#/components/carrot-plans/shared/useQueryRoutingInfo"
import { useParams } from "react-router-dom"
import { AmabJourneyByUrlParam } from "#/components/carrot-plans/hooks/useMedicalQuestionMenuItemWorkflow"

enum AmabSteps {
  LOW_TESTOSTERONE = "LOW_TESTOSTERONE",
  PRESERVATION = "NOT_LOW_T",
  ASSISTED_REPRODUCTION = "NOT_LOW_T",
  TRY_PREGNANT = "NOT_LOW_T"
}

export default function WhatKindOfJourneyAmabDecisionStep(): ReactElement {
  const routingData = useCheckRoutingInfoQueryCache()
  const { journey: journeyParam } = useParams<{ journey: string }>()
  // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  const journeyValue = journeyParam ? AmabSteps[AmabJourneyByUrlParam[journeyParam]] : AmabSteps[routingData?.journey]
  const { send } = useStateMachine()

  useEffect(() => {
    send(journeyValue)
  })
  return <></>
}
