import React, { useEffect, useState } from "react"
import { createErrorBoundary } from "#/utils/createErrorBoundary"
import { ModalErrorStep } from "#/components/views/account/phoneNumberUpdate/ModalErrorStep"
import { UpdatePhoneNumberCompletedStep } from "#/components/views/account/phoneNumberUpdate/UpdatePhoneNumberCompletedStep"
import { UpdatePhoneNumberFormStep } from "#/components/views/account/phoneNumberUpdate/UpdatePhoneNumberFormStep"
import EmployeeOnlyModalContainer from "../../../employee-only-containers/EmployeeOnlyModalContainer"
import { useIntl } from "react-intl"
import { Box, CloseIcon, Dialog, IconButton } from "@carrotfertility/carotene-core"

export type ResendState = "READY" | "SENDING" | "SENT"

const VerificationFormStepComponents = {
  FORM: UpdatePhoneNumberFormStep,
  COMPLETED: UpdatePhoneNumberCompletedStep,
  FAILED: ModalErrorStep
} as const

export type VerificationFormStep = keyof typeof VerificationFormStepComponents

const UpdatePhoneNumberErrorBoundary = createErrorBoundary(ModalErrorStep)

interface UpdatePhoneNumberModalProps {
  onClose: () => void
  open: boolean
}

export function UpdatePhoneNumberModal({ onClose, open }: UpdatePhoneNumberModalProps) {
  const intl = useIntl()
  const [verificationFormStep, setVerificationFormStep] = useState<VerificationFormStep>("FORM")

  useEffect(() => {
    if (open) {
      setVerificationFormStep("FORM")
    }
  }, [open])

  const Step = VerificationFormStepComponents[verificationFormStep]

  return (
    <EmployeeOnlyModalContainer onClose={onClose} open={open}>
      <Dialog scroll="body" open={open} onClose={onClose}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end"
          }}
        >
          <IconButton aria-label={intl.formatMessage({ defaultMessage: "Close" })} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        <UpdatePhoneNumberErrorBoundary>
          <Step onClose={onClose} setVerificationFormStep={setVerificationFormStep} isAnnouncementModal={false} />
        </UpdatePhoneNumberErrorBoundary>
      </Dialog>
    </EmployeeOnlyModalContainer>
  )
}
