import { useQuery } from "@tanstack/react-query"
import buildCarrotApiRetryPolicy from "#/utils/CarrotApiRetryPolicy"
import ResponseError from "#/types/responseError"
import { reportErrorMessage } from "#/utils/ErrorReporting"
import { getTalkToCarrotSendMessageDisplay } from "#/lib/contentful/contentfulClient"
import { EntryWithoutUnresolvableLinks } from "#/utils/ContentTypes"
import { TypeTileDisplaySkeleton } from "#/types/contentful"

const sendMessageDisplay = "getSendMessageDisplay"

export function useGetSendMessageDisplay(locale: string) {
  return useQuery<EntryWithoutUnresolvableLinks<TypeTileDisplaySkeleton>, ResponseError>(
    [sendMessageDisplay],
    () => getTalkToCarrotSendMessageDisplay(locale),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      refetchOnReconnect: false,
      retry: buildCarrotApiRetryPolicy(3),
      onError: (error) => reportErrorMessage(`Failed to fetch send message tile display with error: ${error}`)
    }
  )
}
