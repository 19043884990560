import React, { useContext } from "react"
import { RequestCardContext } from "#/pages/request-carrot-card-flow"
import { getIssuingRegion, IssuingRegion } from "./agreement-helpers"
import { AirwallexAustraliaUserAgreement } from "./regional-variants/australia"
import { AirwallexCanadaUserAgreement } from "./regional-variants/canada"
import { AirwallexSingaporeUserAgreement } from "./regional-variants/singapore"
import { AirwallexEEAUserAgreement } from "./regional-variants/eea"
import { AirwallexUnitedKingdomUserAgreement } from "./regional-variants/uk"

export function AirwallexUserAgreement() {
  const { companyCountryCode } = useContext(RequestCardContext)

  switch (getIssuingRegion(companyCountryCode)) {
    case IssuingRegion.Australia:
      return <AirwallexAustraliaUserAgreement />
    case IssuingRegion.Canada:
      return <AirwallexCanadaUserAgreement />
    case IssuingRegion.Singapore:
      return <AirwallexSingaporeUserAgreement />
    case IssuingRegion.EEA:
      return <AirwallexEEAUserAgreement />
    case IssuingRegion.UK:
      return <AirwallexUnitedKingdomUserAgreement />
  }
  return null
}
