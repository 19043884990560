import React, { useContext, useReducer } from "react"
import { CarrotErrorCodes } from "#/utils/CarrotErrors"
import { reportError } from "#/utils/ErrorReporting"
import { isHttpError } from "#/utils/HttpErrors"
import { useSignUpFlow } from "../../context/signup/SignUpFlowContext"
import { getHeap } from "../../../utils/heap"
import { redirectToSamlExternalLogin } from "../../../utils/RedirectToSamlExternalLogin"
import { useIntl } from "react-intl"
import { carrotClient } from "#/utils/CarrotClient"
import { dayjs } from "@carrotfertility/carotene-core"

interface SignUpFindAccountReducerState {
  errorMessage: string
  inputsEnabled: boolean
  shake: boolean
  showTryAgain: boolean
}

interface SignUpFindAccountProviderProps extends SignUpFindAccountReducerState {
  onSubmit: ({
    email,
    firstName,
    lastName,
    dateOfBirth
  }: {
    email: string | string[]
    firstName: string
    lastName: string
    dateOfBirth: dayjs.Dayjs
  }) => Promise<void>
}

const SignUpFindAccountContext = React.createContext<SignUpFindAccountProviderProps>(null)

export const useSignUpFindAccount = () => useContext(SignUpFindAccountContext)

const actionTypes = {
  error: "ERROR",
  tryAgainMessage: "TRY_AGAIN_MESSAGE",
  start: "START_REQUEST"
}

function signUpFindAccountReducer(
  state: SignUpFindAccountReducerState,
  action: { type: string; payload?: { errorCode: string } }
): SignUpFindAccountReducerState {
  switch (action.type) {
    case actionTypes.error: {
      return {
        ...state,
        inputsEnabled: true,
        shake: true,
        errorMessage: action.payload.errorCode
      }
    }
    case actionTypes.tryAgainMessage: {
      return {
        ...state,
        showTryAgain: true,
        inputsEnabled: true,
        shake: true,
        errorMessage: null
      }
    }
    case actionTypes.start: {
      return {
        ...state,
        inputsEnabled: false,
        shake: false
      }
    }
    default: {
      throw new Error(`Unhandled type: ${action.type}`)
    }
  }
}

export function SignUpFindAccountProvider({
  children,
  onEmailFound,
  onEmailNotFound,
  onMultipleEmployeesFound,
  onEmployeeAlreadyRegistered,
  onEmployeeHasSamlAccount
}: {
  children: React.ReactNode | React.ReactNode[]
  onEmailFound: (email: string) => void
  onEmailNotFound: (memberInfo: {
    email: string
    dateOfBirth: dayjs.Dayjs
    firstName: string
    lastName: string
  }) => void
  onMultipleEmployeesFound: () => void
  onEmployeeAlreadyRegistered: () => void
  onEmployeeHasSamlAccount: (email: string) => void
}) {
  const [state, dispatch] = useReducer(signUpFindAccountReducer, {
    errorMessage: null,
    inputsEnabled: true,
    shake: false,
    showTryAgain: false
  })
  const { handleSaveMemberInfoInMemory, saveMultipleEmployeesFound } = useSignUpFlow()

  const setFormError = (errorCode: string) => dispatch({ type: actionTypes.error, payload: { errorCode } })
  const showTryAgain = () => dispatch({ type: actionTypes.tryAgainMessage })
  const startRequest = () => dispatch({ type: actionTypes.start })

  const setErrorState = (errorCode: string) => {
    setFormError(errorCode)
  }

  const { locale } = useIntl()

  const onSubmit = async ({
    email,
    firstName,
    lastName,
    dateOfBirth
  }: {
    email: string
    firstName: string
    lastName: string
    dateOfBirth: dayjs.Dayjs
  }) => {
    getHeap().track("NoEmailOnboarding", { EventName: "submit name & DOB for signup" })
    startRequest()
    let memberInfo
    try {
      memberInfo = await carrotClient.signUpFirstLastDateOfBirth(
        email,
        firstName,
        lastName,
        dateOfBirth.format("YYYY-MM-DD"),
        locale
      )
    } catch (error) {
      if (isHttpError(error)) {
        const { code } = await error.response.json()
        if (code === CarrotErrorCodes.EMPLOYEE_NOT_FOUND) {
          handleSaveMemberInfoInMemory({ email, dateOfBirth, firstName, lastName })
          showTryAgain()
          return
        }
        if (code === CarrotErrorCodes.MULTIPLE_EMPLOYEES_FOUND) {
          handleSaveMemberInfoInMemory({ email, dateOfBirth, firstName, lastName })
          saveMultipleEmployeesFound()
          onMultipleEmployeesFound()
          return
        }
        setErrorState(code)
        return
      }

      reportError(error)
      setErrorState(CarrotErrorCodes.UNEXPECTED_ERROR)
      return
    }

    if (memberInfo.samlIdpEntityId && memberInfo.existingEmail) {
      onEmployeeHasSamlAccount(memberInfo.existingEmail)
      return
    } else if (memberInfo.samlIdpEntityId && memberInfo.ssoOnly && !memberInfo.existingEmail) {
      // SSO Enabled No Email Onboarding member must authenticate before providing their email.
      redirectToSamlExternalLogin(memberInfo.samlExternalLoginUrl, memberInfo.samlIdpEntityId, false)
      return
    }

    if (memberInfo.existingEmail) {
      if (!memberInfo.employeeAlreadyRegistered) {
        onEmailFound(memberInfo.existingEmail)
      } else {
        handleSaveMemberInfoInMemory({ email: memberInfo.existingEmail })
        onEmployeeAlreadyRegistered()
      }
    } else {
      onEmailNotFound({ email, dateOfBirth, firstName, lastName })
    }
  }

  return (
    <SignUpFindAccountContext.Provider value={{ ...state, onSubmit }}>{children}</SignUpFindAccountContext.Provider>
  )
}
