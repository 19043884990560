import { useStateMachine } from "#/components/context/stateMachine/StateMachineV2"
import React from "react"
import { Sections } from ".."
import { Stack, Stepper, useTheme } from "@carrotfertility/carotene-core"
import { useIntl } from "react-intl"
import { useTextDirection } from "#/services/locale/hooks/useTextDirection"
interface RoutingFlowTopSectionProps {
  showProgressTracker: boolean
}

function RoutingFlowStepper({ steps, activeStep }: { steps: string[]; activeStep: string }) {
  const theme = useTheme()
  const activeStepIndex = steps.indexOf(activeStep)
  const textDirection = useTextDirection()

  return (
    <Stack data-testid={"step number: " + activeStepIndex} spacing={theme.spacing(theme.tokens.spacing.lg)}>
      <Stepper
        dir={textDirection}
        variant="progress"
        steps={steps.length + 1}
        position="static"
        activeStep={activeStepIndex}
      />
    </Stack>
  )
}

export function RoutingFlowTopSection({ showProgressTracker }: RoutingFlowTopSectionProps): JSX.Element {
  const { sectionName } = useStateMachine()
  const theme = useTheme()
  const intl = useIntl()
  const aboutYouStep = intl.formatMessage({ defaultMessage: "About You" })
  const journeyStep = intl.formatMessage({ defaultMessage: "Your journey" })

  return (
    <Stack
      spacing={theme.spacing(theme.tokens.spacing.lg)}
      margin={(theme) => theme.spacing(theme.tokens.spacing.lg, theme.tokens.spacing.none)}
    >
      {showProgressTracker && (
        <RoutingFlowStepper
          steps={[aboutYouStep, journeyStep]}
          activeStep={sectionName === Sections.ABOUT_YOU ? aboutYouStep : journeyStep}
        />
      )}
    </Stack>
  )
}
