import React from "react"
import { Moment } from "moment"
import { useSelector } from "react-redux"
import { getDeductibleStatus } from "#/redux/reducers/deductibleStatus"
import { getCurrencySymbol } from "#/redux/reducers/companyInfo"

export type UseReimbursementStatusReturn = {
  planStartDate?: Moment
  planEndDate?: Moment
  deductibleAmount?: number
  externalAmountAppliedToDeductible?: number
  amountAppliedToDeductible?: number
  carrotAmountAppliedToDeductible?: number
  deductibleRemaining?: number
  currencySymbol?: string
  reimbursementPercent?: number
  companyName?: string
  expensePlanCategory?: string
  expensePlanCategoryNameMessageKey?: string
}

const ReimbursementStatusContext = React.createContext<UseReimbursementStatusReturn>({})

const ReimbursementStatusProvider = ({
  children
}: {
  children: React.ReactNode | React.ReactNodeArray
}): JSX.Element => {
  const deductibleStatus = useSelector(getDeductibleStatus)
  deductibleStatus.currencySymbol = useSelector(getCurrencySymbol)
  return <ReimbursementStatusContext.Provider value={deductibleStatus}>{children}</ReimbursementStatusContext.Provider>
}

export { ReimbursementStatusProvider }
