import { SelectOptionType } from "@carrotfertility/carotene"
import getSymbolFromCurrency from "currency-symbol-map"
import CurrencyCodesToAllowedDecimalPlacesMap from "../../../utils/CurrencyCode"

export const formatCurrencies = (allowedCurrencies: string[]): SelectOptionType[] => {
  return allowedCurrencies?.map((currencyCode) => {
    if (getSymbolFromCurrency(currencyCode?.toUpperCase()) !== undefined) {
      return {
        value: `${currencyCode?.toUpperCase()}`,
        label: `${getSymbolFromCurrency(currencyCode?.toUpperCase())} ${currencyCode?.toUpperCase()}`
      }
    } else {
      return { value: `${currencyCode?.toUpperCase()}`, label: `${currencyCode?.toUpperCase()}` }
    }
  })
}

export const getDefaultCurrency = (allowedCurrencies: string[], currencyCode: string): string =>
  allowedCurrencies?.includes(currencyCode) ? `${currencyCode}` : "USD"

export const getFormattedAmount = (amount: any, currencyCode: any): string => {
  // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  const digits = CurrencyCodesToAllowedDecimalPlacesMap[currencyCode.toUpperCase()]
  const amountFromLowestDenomination = (amount / 10 ** digits).toFixed(digits)
  return `${getSymbolFromCurrency(
    currencyCode?.toUpperCase()
  )}${amountFromLowestDenomination} ${currencyCode?.toUpperCase()}`
}
