import { useQuery, UseQueryResult, useQueryClient } from "@tanstack/react-query"
import ResponseError from "../../../types/responseError"
import { useAuthentication } from "#/components/context/authentication/AuthenticationProvider"
import { HttpErrors } from "#/utils/HttpErrors"
import { carrotClient } from "#/utils/CarrotClient"

const ENDPOINT = "GetLegacyBenefit"

const loadLegacyBenefit = async (handleSessionExpiration: () => void): Promise<LegacyBenefit> => {
  try {
    return carrotClient.getBenefitConfiguration()
  } catch (error) {
    if (error.name === HttpErrors.UNAUTHORIZED || error.name === HttpErrors.FORBIDDEN) {
      handleSessionExpiration()
    }
    return null
  }
}

const getBenefitCacheKey = (): (string | number)[] => [ENDPOINT]

export default function useGetLegacyBenefit(): UseQueryResult<LegacyBenefit, ResponseError> {
  const { handleSessionExpiration } = useAuthentication()
  return useQuery<LegacyBenefit, ResponseError>(getBenefitCacheKey(), () => loadLegacyBenefit(handleSessionExpiration))
}

export function useCreateOverride(): (attributes: LegacyBenefit) => void {
  const queryClient = useQueryClient()
  return (attributes: LegacyBenefit) => {
    queryClient.setQueryDefaults(getBenefitCacheKey(), { staleTime: Infinity, cacheTime: Infinity })
    queryClient.setQueryData(getBenefitCacheKey(), attributes)
  }
}

export type LegacyBenefit = {
  features: {
    isBankInfoEnabled: boolean
    isGrossUpEnabled: boolean
    isPartnerCredentialAccessEnabled: boolean
    isCustomCoverageContentEnabled: boolean
    isPartnerEnabled: boolean
  }
  coverages: {
    areLegalPartnersEligible: boolean
    isCopayEnabled: boolean
    isMemberAndPartnerEligible: boolean
    isPartnerEligibleIfOnCustomerMedicalPlan: boolean
  }
}
