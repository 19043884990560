import React, { useEffect } from "react"
import { useStateMachine } from "../../../context/stateMachine/StateMachineV2"
import { useCheckRoutingInfoQueryCache } from "#/components/carrot-plans/shared/useQueryRoutingInfo"

export default function WhereAreYouInJourneyIVFDecisionStep(): JSX.Element {
  const routingData = useCheckRoutingInfoQueryCache()
  const journeyStageValue = routingData?.journeyStage
  const memberSelectsValue = [
    "ALREADY_STARTED_IUI_CYCLE",
    "ALREADY_STARTED_IVF_CYCLE",
    "RECENTLY_COMPLETED_IUI_CYCLE",
    "RECENTLY_COMPLETED_IVF_CYCLE",
    "GETTING_READY_START_IVF"
  ].includes(journeyStageValue)
    ? journeyStageValue
    : "OTHER_ANSWERS"

  const { send } = useStateMachine()
  useEffect(() => {
    send(memberSelectsValue)
  })

  return <></>
}
