import React from "react"
import { Typography } from "@carrotfertility/carotene-core"
import { useIntl } from "react-intl"
import { FormCheckbox, FormSelect, FormTextField, useWatch } from "@carrotfertility/carotene-core-x"
import { CareReceiverRelationToMember, FormSelectValue } from "./utils/doulaAttestationTypes"
import { DoulaAttestationFormPartnerConfirmationAlert } from "./DoulaAttestationFormPartnerConfirmationAlert"
import { DoulaConditions } from "./utils/doulaAttestationConditions"
import { useCurrentUser } from "#/components/context/user/UserContext"

type Option = { label: string; value: string }

function renderLabelWithPlaceholder(options: Option[], placeholder: string) {
  // eslint-disable-next-line react/display-name
  return (value: any): React.ReactNode => {
    const option = options.find((option) => option.value === value)
    return option ? (
      option.label
    ) : (
      <Typography component="span" color={(theme) => theme.palette.placeholder}>
        {placeholder}
      </Typography>
    )
  }
}
export function DoulaAttestationCareReceiverFormSelect({
  doulaConditions
}: {
  doulaConditions: DoulaConditions
}): JSX.Element {
  const intl = useIntl()
  const {
    noPartnersEligible,
    anyLegalPartnerEligible,
    anyPartnerOnMedicalPlanEligible,
    onlyLegalPartnerOnMedicalPlanEligible
  } = doulaConditions
  const careReceiverRelationToMember = useWatch({ name: "careReceiverRelationToMember" })
  const partnerReceivingServicesSelected = careReceiverRelationToMember && careReceiverRelationToMember !== "Me"
  const currentUser = useCurrentUser()

  const careReceiverRelationToMemberOptions: FormSelectValue<CareReceiverRelationToMember>[] =
    anyLegalPartnerEligible || onlyLegalPartnerOnMedicalPlanEligible
      ? [
          { label: intl.formatMessage({ defaultMessage: "Me" }), value: "Me" },
          {
            label: intl.formatMessage({ defaultMessage: "My spouse or legal partner" }),
            value: "MySpouseOrLegalPartner"
          }
        ]
      : [
          { label: intl.formatMessage({ defaultMessage: "Me" }), value: "Me" },
          { label: intl.formatMessage({ defaultMessage: "My partner" }), value: "MyPartner" }
        ]

  return noPartnersEligible ? (
    <FormCheckbox
      name="isMemberReceivingServices"
      controllerProps={{ rules: { required: "Required" } }}
      label={intl.formatMessage({ defaultMessage: "I am the person receiving services" })}
      required={true}
    />
  ) : (
    <>
      <FormSelect
        name="careReceiverRelationToMember"
        renderValue={renderLabelWithPlaceholder(
          careReceiverRelationToMemberOptions,
          intl.formatMessage({ defaultMessage: "Select option" })
        )}
        options={careReceiverRelationToMemberOptions}
        label={intl.formatMessage({ defaultMessage: "Who is receiving doula services?" })}
        fullWidth
        controllerProps={{
          rules: {
            required: "Please select one."
          }
        }}
      />
      {partnerReceivingServicesSelected && (
        <>
          {(anyPartnerOnMedicalPlanEligible || onlyLegalPartnerOnMedicalPlanEligible) && (
            <DoulaAttestationFormPartnerConfirmationAlert
              anyPartnerOnMedicalPlanEligible={anyPartnerOnMedicalPlanEligible}
              segmentName={currentUser?.company?.companyDisplayName}
            />
          )}
          <FormTextField
            name="partnerName"
            defaultValue={currentUser?.partnerFullName}
            label={intl.formatMessage({ defaultMessage: "Partner's Name" })}
            registerOptions={{ required: "Required" }}
          />
        </>
      )}
    </>
  )
}
