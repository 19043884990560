import React, { ReactNode } from "react"
import { MessageDescriptor, defineMessage, useIntl } from "react-intl"
import { Typography } from "@carrotfertility/carotene-core"

type TopicMap = {
  [key: string]: {
    heading: MessageDescriptor
    description?: MessageDescriptor
    descriptionDescriptorValues?: Record<string, (values: string[]) => JSX.Element | string>
  }
}

const defaultDescriptionDescriptorValues: Record<string, (values: string[]) => JSX.Element> = {
  text: (value) => (
    <Typography variant="body1" color={(theme) => theme.palette.text.primary}>
      {value}
    </Typography>
  )
}

const topicMap: TopicMap = {
  helpfindingprovider: {
    heading: defineMessage({ defaultMessage: "Get help finding a provider" }),
    description: defineMessage({
      defaultMessage:
        "<text>Describe your ideal provider below, and we'll reach out to you directly with personalized recommendations.</text>"
    })
  },
  stolen: {
    heading: defineMessage({ defaultMessage: "Report lost or stolen card" }),
    description: defineMessage({
      defaultMessage:
        "<text>Was your card lost or stolen? Please include your address in the message below and we'll send you a replacement.</text>"
    })
  },
  "ava-bracelet": {
    heading: defineMessage({ defaultMessage: "Ava Ovulation Tracking Bracelet" }),
    description: defineMessage({
      defaultMessage:
        "<text>Are you interested in either ordering an Ava Bracelet or learning more? Send us a message.</text>"
    })
  },
  legacy: {
    heading: defineMessage({ defaultMessage: "Legacy Sperm Testing Kit" }),
    description: defineMessage({
      defaultMessage:
        "<text>Are you interested in either ordering a Legacy sperm testing kit or learning more? Send us a message.</text>"
    })
  },
  lockedcardsupport: {
    heading: defineMessage({ defaultMessage: "Locked Carrot Card" }),
    description: defineMessage({
      defaultMessage:
        "<text>Our Carrot Care Team is here to answer your questions about how Carrot works, choosing a provider, how to start your journey, or any other benefits related topics.</text>"
    })
  },
  "insurance-coverage": {
    heading: defineMessage({ defaultMessage: "Let's talk about your health insurance info" }),
    description: defineMessage({
      defaultMessage: "<text>Tell us what you aren't sure about or what we can help clarify.</text>"
    })
  },
  "send-a-message-page": {
    heading: defineMessage({ defaultMessage: "Send a message" }),
    description: defineMessage({
      defaultMessage:
        "<text>Share your questions about logistics, making the most of your benefit, choosing a provider, and the next steps in your journey.</text>"
    })
  }
}

// HACK - There is a use case where the "send-a-message-page" content needs to be accessed as strings
//        so that it can be transformed into IPageGuidance so as to emulate content from Contentful.
topicMap["send-a-message-page-as-string"] = {
  ...topicMap["send-a-message-page"],
  descriptionDescriptorValues: {
    text: (value) => (value?.length > 0 ? value[0] : "")
  }
}

export type SendMessageDescription = string | ReactNode | (string | ReactNode)[]

export type SendMessageTranslator = {
  getTranslationsForTopic: (topic: string) => {
    heading: string
    description: SendMessageDescription
  }
}

export function useSendMessageTranslator(): SendMessageTranslator {
  const intl = useIntl()

  return {
    getTranslationsForTopic: (topic: string) => {
      const mapping = topicMap[topic]
      if (!mapping) {
        return null
      }
      return {
        heading: intl.formatMessage(mapping.heading),
        description: intl.formatMessage(
          mapping.description,
          mapping.descriptionDescriptorValues ?? defaultDescriptionDescriptorValues
        )
      }
    }
  }
}
