import React, { useState } from "react"
import { AdapterDayjs, Button, DialogContentText, Link, LocalizationProvider } from "@carrotfertility/carotene-core"
import { FormattedMessage, useIntl } from "react-intl"
import {
  FormButton,
  FormOnSubmitHandler,
  FormTextField,
  InfoDialog,
  ShortFormDialog
} from "@carrotfertility/carotene-core-x"
import { DoulaAttestationFormValues } from "./utils/doulaAttestationTypes"
import { useSendDoulaAttestation } from "./utils/doulaAttestationApi"
import { useIntlValidator } from "#/utils/hooks/useIntlValidator"
import { emailValidation } from "#/utils/Regexes"
import { DoulaAttestationSuccessDialog } from "./DoulaAttestationSuccessDialog"
import { DoulaAttestationFormDatePicker } from "./DoulaAttestationFormDatePicker"
import { DoulaAttestationFormGuidance } from "./DoulaAttestationFormGuidance"
import { DoulaAttestationFormAlert } from "./DoulaAttestationFormAlert"
import { DoulaAttestationCareReceiverFormSelect } from "./DoulaAttestationCareReceiverFormSelect"
import { getDoulaAttestationConditions } from "./utils/doulaAttestationConditions"
import useGetLegacyBenefit from "#/services/member-benefit/hooks/useGetLegacyBenefit"

export function DoulaAttestationFormModal({
  open,
  setOpen,
  onClose
}: {
  open: boolean
  setOpen: (value: boolean) => void
  onClose?: () => void
}): JSX.Element {
  const intl = useIntl()
  const { mutateAsync, isSuccess, isError, reset } = useSendDoulaAttestation()
  const [doulaEmail, setDoulaEmail] = useState("")
  const { data: benefit, isFetched } = useGetLegacyBenefit()
  const doulaConditions = isFetched && benefit && getDoulaAttestationConditions(benefit)

  const intlEmailValidation = useIntlValidator(emailValidation)

  const closeDialog = () => {
    setOpen(false)
    reset()
    if (onClose) {
      onClose()
    }
  }

  const onSubmitHandler: FormOnSubmitHandler<DoulaAttestationFormValues> = async (formFields) => {
    const requestBody = {
      doulaName: formFields.doulaName,
      doulaEmail: formFields.doulaEmail,
      isMemberReceivingServices:
        formFields.careReceiverRelationToMember === "Me" || formFields.isMemberReceivingServices,
      partnerName: formFields.partnerName || null,
      dueDate: formFields.dueDate.format("YYYY-MM-DD"),
      careReceiverRelationToMember: formFields.careReceiverRelationToMember || "Me"
    }
    try {
      await mutateAsync(requestBody, {
        onSuccess: () => {
          setDoulaEmail(requestBody.doulaEmail)
        }
      })
    } catch {
      // https://github.com/react-hook-form/react-hook-form/issues/9821
      // ignored - we use the isError state from useSendDoulaAttestation() to handle errors
    }
  }

  if (isSuccess) {
    return <DoulaAttestationSuccessDialog doulaEmail={doulaEmail} open={open} onClose={closeDialog} />
  }
  if (!benefit && isFetched) {
    return (
      <InfoDialog
        title={intl.formatMessage({ defaultMessage: "Something's not working" })}
        open={open}
        onClose={closeDialog}
        primaryAction={
          <Button onClick={closeDialog}>
            <FormattedMessage defaultMessage="Close" />
          </Button>
        }
      >
        <DialogContentText>
          <FormattedMessage
            defaultMessage="Please try again. If the problem persists, <link>contact us</link>."
            values={{
              link: (linkText) => (
                <Link color="inherit" target="_blank" href={"/talk-to-carrot/care-navigation"}>
                  {linkText}
                </Link>
              )
            }}
          />
        </DialogContentText>
      </InfoDialog>
    )
  }

  return (
    isFetched && (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <ShortFormDialog
          title={intl.formatMessage({ defaultMessage: "Send attestation form" })}
          open={open}
          onClose={closeDialog}
          onSubmit={onSubmitHandler}
          slotProps={{
            form: {
              defaultValues: {
                isMemberReceivingServices: false
              }
            }
          }}
          secondaryAction={
            <FormButton variant="outlined" color="secondary" onClick={closeDialog}>
              <FormattedMessage defaultMessage="Cancel" />
            </FormButton>
          }
          primaryAction={
            <FormButton type="submit">
              <FormattedMessage defaultMessage="Send to doula" />
            </FormButton>
          }
        >
          <DoulaAttestationFormGuidance />
          <DoulaAttestationFormAlert isError={isError} doulaConditions={doulaConditions} />
          <DoulaAttestationCareReceiverFormSelect doulaConditions={doulaConditions} />
          <DoulaAttestationFormDatePicker />
          <FormTextField
            name="doulaName"
            label={intl.formatMessage({ defaultMessage: "Doula's Name" })}
            registerOptions={{ required: "Required" }}
          />
          <FormTextField
            name="doulaEmail"
            label={intl.formatMessage({ defaultMessage: "Doula's Email" })}
            registerOptions={{ required: "Required", validate: intlEmailValidation }}
          />
        </ShortFormDialog>
      </LocalizationProvider>
    )
  )
}
