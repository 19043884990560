import React from "react"
import { Box, Stack, Typography, useTheme } from "@carrotfertility/carotene-core"
import { FormattedMessage } from "react-intl"
import { ZendeskArrowLink } from "#/components/shared/components/ZendeskArrowLink"

export function HelpCenterModule(): JSX.Element {
  const theme = useTheme()
  return (
    <Box
      id="home-sidebar-help-center-module"
      padding={(theme) => theme.spacing(theme.tokens.spacing.lg)}
      bgcolor={(theme) => theme.palette.background.default}
      borderRadius={(theme) => theme.tokens.borderRadius.md}
    >
      <Stack spacing={theme.spacing(theme.tokens.spacing.md)}>
        <Typography variant="h4" component="h2">
          <FormattedMessage defaultMessage="Get answers to frequently asked questions" />
        </Typography>
        <ZendeskArrowLink>
          <FormattedMessage defaultMessage="Visit Help Center" />
        </ZendeskArrowLink>
      </Stack>
    </Box>
  )
}
