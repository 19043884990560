import { TypeCategory, TypeContentBlockSkeleton, TypeSubCategorySkeleton } from "#/types/contentful"
import ContentTypes, { EntryWithoutUnresolvableLinks, ReadLearnArticleEntry } from "#/utils/ContentTypes"

type CategoryEntry = TypeCategory<"WITHOUT_UNRESOLVABLE_LINKS">
export type EntryWithCategory = {
  article?: ReadLearnArticleEntry
  category: CategoryEntry
  subCategory: EntryWithoutUnresolvableLinks<TypeSubCategorySkeleton>
  content?: EntryWithoutUnresolvableLinks<TypeContentBlockSkeleton>
  contentType: string
}

export function getEntryWithCategory(entryId: string, data: CategoryEntry[], contentType: string): EntryWithCategory[] {
  if (contentType === ContentTypes.SUB_CATEGORY) {
    return getSubCategory(data, entryId)
  } else if (contentType === ContentTypes.CONTENT_BLOCK) {
    return getContentBlock(data, entryId)
  } else {
    return getArticleVideoGuideGroup(data, entryId)
  }
}

function getSubCategory(data: CategoryEntry[], entryId: string): EntryWithCategory[] {
  const result: EntryWithCategory[] = []
  for (const category of data) {
    for (const subCategory of category.fields.subCategories) {
      result.push({
        category,
        subCategory,
        contentType: subCategory.sys.contentType.sys.id
      })
    }
  }
  return result.filter((entry: any) => entry.subCategory.sys.id === entryId)
}

function getContentBlock(data: any, entryId: string): EntryWithCategory[] {
  const result: EntryWithCategory[] = []
  for (const category of data) {
    for (const subCategory of category.fields.subCategories) {
      for (const article of subCategory.fields.articles) {
        if (article.fields.content) {
          for (const content of article.fields.content) {
            result.push({ category, subCategory, article, content, contentType: content.sys.contentType.sys.id })
          }
        }
      }
    }
  }
  return result.filter((entry: any) => entry.content.sys.id === entryId)
}

function getArticleVideoGuideGroup(data: CategoryEntry[], entryId: string): EntryWithCategory[] {
  const result: EntryWithCategory[] = []
  for (const category of data) {
    for (const subCategory of category.fields.subCategories) {
      for (const article of subCategory.fields.articles) {
        result.push({
          article,
          category,
          subCategory,
          contentType: article.sys.contentType.sys.id
        })
      }
    }
  }
  return result.filter((entry: any) => entry.article.sys.id === entryId)
}
