import React from "react"
import { Typography, LinkProps, Box } from "@carrotfertility/carotene-core"
import useAvaCheckoutLink from "#/components/carrot-plans/hooks/useAvaCheckoutLink"
import {
  isZendeskLink,
  parseZendeskFragmentFromUrl,
  getSupportCenterLink,
  goToHelpCenter
} from "#/lib/carrot-api/HelpCenterClient"
import { reportErrorMessage } from "#/utils/ErrorReporting"
import { ContentfulArrowLink } from "../shared/contentful-arrow-link"
import { Link as ReactRouterLink } from "react-router-dom"
import { FormattedMessage } from "react-intl"
import { isRelativeLink } from "#/services/markdown/elements"

// @ts-expect-error TS(7031) FIXME: Binding element 'children' implicitly has an 'any'... Remove this comment to see the full error message
export function ArrowLink({ children, href, title, ...otherProps }) {
  if (!href && !otherProps["data-noalerts"]) {
    reportErrorMessage(`Empty href found in Markdown with title ${title} and children ${JSON.stringify(children)}`)
  }
  if (isZendeskLink(href)) {
    const zdLinkFragment = parseZendeskFragmentFromUrl(href)

    return (
      <Box>
        <ContentfulArrowLink
          href={getSupportCenterLink(zdLinkFragment)}
          onClick={(event: React.MouseEvent<HTMLAnchorElement>) => goToHelpCenter(event, zdLinkFragment)}
          target={"_blank"}
          {...{ ...otherProps, title }}
        >
          {children}
        </ContentfulArrowLink>
      </Box>
    )
  }
  const linkProps = isRelativeLink(href)
    ? ({ target: "_self", component: ReactRouterLink, to: href, title } as LinkProps<ReactRouterLink>)
    : ({ target: "_blank", href, title } as LinkProps<"a">)

  return (
    <Box>
      <ContentfulArrowLink {...{ ...otherProps, ...linkProps }}>{children}</ContentfulArrowLink>
    </Box>
  )
}
// @ts-expect-error TS(7031) FIXME: Binding element 'children' implicitly has an 'any'... Remove this comment to see the full error message
export function AvaArrowLink({ children }) {
  const { data: avaCheckoutLandingPageUrl, isLoading } = useAvaCheckoutLink()
  return (
    <Box>
      {isLoading ? (
        <Typography variant="body2">
          <FormattedMessage defaultMessage="Loading..." />
        </Typography>
      ) : (
        <ContentfulArrowLink href={avaCheckoutLandingPageUrl} target="_blank">
          {children}
        </ContentfulArrowLink>
      )}
    </Box>
  )
}
