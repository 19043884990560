import { BenefitEnrollmentFlowModalBackButton, BenefitEnrollmentModalContinueButton } from "../shared"
import { IlloUnderwaterPlants } from "../../views/atoms/Illustrations"
import React, { useContext, useEffect } from "react"
import { Steps } from "../workflow/steps"
import { getHeap } from "../../../utils/heap"
import { SendMessageFormContext } from "../../talk-to-carrot/send-message/SendMessage"
import { useFormContext } from "react-hook-form"
import { useStateMachine } from "#/components/context/stateMachine/StateMachineContext"
import { Box, Button, Typography, Stack } from "@carrotfertility/carotene-core"
import { FormattedMessage, useIntl } from "react-intl"
import { MessageSent } from "./MessageSent"
import { GetWorkflow } from "./BenefitEnrollmentFlow"
import { Workflows as BenefitEnrollmentWorkflows } from "../workflow/workflows"
import { useProgressTracker } from "../../views/modal/ProgressTracker"

const MemberIneligible = (): JSX.Element => {
  const { onSubmit, sent, employeeEmail, personalEmail } = useContext(SendMessageFormContext)
  const { gotoPreviousStep } = useStateMachine(viewName, null)
  const { register, setValue } = useFormContext()

  const { showProgress } = useProgressTracker()
  useEffect(showProgress, [showProgress])

  const sendSupportRequest = (): void => {
    onSubmit()
    getHeap().track("EnrollmentFlowSupportRequest", { EventName: "Member EMD Ineligible" })
  }
  const email = personalEmail ?? employeeEmail
  const intl = useIntl()
  const body = personalEmail
    ? intl.formatMessage(
        { defaultMessage: "We'll look into this and get back to you at {email}, your preferred email" },
        { email: email }
      )
    : intl.formatMessage(
        { defaultMessage: "We'll look into this and get back to you at {email}, your account email" },
        { email: email }
      )

  useEffect(() => {
    setValue("defaultMessage", "This member is not on their employer's health plan.")
    setValue("email", email)
    // eslint-disable-next-line react-hooks/exhaustive-deps -- See https://carrotfertility.atlassian.net/wiki/spaces/PE/pages/2050295461/Remove+Build+Warnings#react-hooks%2Fexhaustive-deps
  }, [])

  return sent ? (
    <MessageSent close={true} body={body} header={intl.formatMessage({ defaultMessage: "We'll take it from here." })} />
  ) : (
    <Stack
      sx={{
        display: "flex"
      }}
    >
      <Box alignSelf="center" paddingBottom={(theme) => theme.spacing(theme.tokens.spacing.lg)}>
        <IlloUnderwaterPlants />
      </Box>
      <Typography
        id="step-heading"
        variant="h2"
        color={(theme) => theme.palette.text.primary}
        paddingBottom={(theme) => theme.spacing(theme.tokens.spacing.md)}
      >
        <FormattedMessage defaultMessage="It looks like you may not be eligible for Carrot." />
      </Typography>
      <Typography
        color={(theme) => theme.palette.text.secondary}
        paddingBottom={(theme) => theme.spacing(theme.tokens.spacing.xxl)}
      >
        <FormattedMessage defaultMessage="You need to be enrolled in your employer's health insurance plan to use Carrot. If you are, please edit your answer to the previous question." />
      </Typography>
      <input hidden {...register("email")} />
      <input hidden {...register("defaultMessage")} />
      <Box display="flex" gap={4} alignSelf="center">
        <Button variant="outlined" color="secondary" onClick={gotoPreviousStep}>
          <FormattedMessage defaultMessage="Edit your answer" />
        </Button>
        <Button variant="contained" onClick={sendSupportRequest}>
          <FormattedMessage defaultMessage="Get help" />
        </Button>
      </Box>
    </Stack>
  )
}

const viewName = Steps.PARTNER_INELIGIBLE
const PartnerIneligible = (): JSX.Element => {
  const { setNextStep } = useStateMachine(viewName, null)
  const workflowType = GetWorkflow()

  const { showProgress } = useProgressTracker()
  useEffect(showProgress, [showProgress])

  return (
    <Stack
      sx={{
        display: "flex"
      }}
    >
      <Box paddingBottom={(theme) => theme.spacing(theme.tokens.spacing.xxxl)}>
        <BenefitEnrollmentFlowModalBackButton />
      </Box>
      <Box alignSelf="center" paddingBottom={(theme) => theme.spacing(theme.tokens.spacing.lg)}>
        <IlloUnderwaterPlants />
      </Box>
      <Typography
        id="step-heading"
        variant="h2"
        color={(theme) => theme.palette.text.primary}
        paddingBottom={(theme) => theme.spacing(theme.tokens.spacing.md)}
      >
        {workflowType === BenefitEnrollmentWorkflows.CMD_NON_HDHP ? (
          <FormattedMessage defaultMessage="Please note that partner expenses aren’t eligible." />
        ) : (
          <FormattedMessage defaultMessage="It looks like your partner's expenses aren't eligible." />
        )}
      </Typography>
      <Typography
        color={(theme) => theme.palette.text.secondary}
        paddingBottom={(theme) => theme.spacing(theme.tokens.spacing.lg)}
      >
        {workflowType === BenefitEnrollmentWorkflows.CMD_NON_HDHP ? (
          <FormattedMessage
            defaultMessage={
              "If you have a partner, their expenses aren’t eligible based on what you’ve shared. " +
              "Partners need to be enrolled in your employer's health insurance plan in order to use Carrot."
            }
          />
        ) : (
          <FormattedMessage defaultMessage="They need to be on your employer's health plan in order to use Carrot." />
        )}
      </Typography>
      <BenefitEnrollmentModalContinueButton onClick={() => setNextStep(viewName, "")} />
    </Stack>
  )
}

export { MemberIneligible, PartnerIneligible }
