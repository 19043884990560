import React from "react"
import { Form, FormSelectOption, useWatch } from "@carrotfertility/carotene-core-x"
import useAboutYou from "../../hooks/useAboutYou"
import { useToggle } from "../../../../utils/Hooks"
import { useStateMachine } from "#/components/context/stateMachine/StateMachineV2"
import useMutationAboutYou from "../../hooks/useMutationAboutYou"
import { FormattedMessage, useIntl } from "react-intl"
import { useGetGenderIdentityOptions } from "#/services/common-forms/GenderIdentityOptions"
import {
  ButtonFooterLayout,
  ContinueButton,
  FormTextFieldWithCharacterCount,
  RoutingFlowDialog,
  RoutingFlowModalIcon,
  RoutingFlowTitle,
  SkipButton,
  StepLayout
} from "#/components/carrot-plans/shared"
import { Stack, useTheme } from "@carrotfertility/carotene-core"
import { FormRadioButtonGroup } from "#/services/common-forms"

function OptionalPartnerAnotherGenderIdentityTextField() {
  const selectedValue = useWatch({ name: "partnerGenderIdentity" })
  if (selectedValue !== "ANOTHER_GENDER_IDENTITY") {
    return null
  }
  return (
    <FormTextFieldWithCharacterCount
      name="partnerGenderIdentityOther"
      helperText={<FormattedMessage defaultMessage="Optional details for another gender identity" />}
    />
  )
}

export default function AboutYourPartnerGenderIdentityStep(): JSX.Element {
  const intl = useIntl()
  const { data, updateAboutYou, hasQuestionBeenSkipped } = useAboutYou()
  const [showPartnerGenderIdentityModal, togglePartnerGenderIdentityModal] = useToggle(false)
  const { mutateAsync } = useMutationAboutYou()
  const genderIdentityOptions: FormSelectOption[] = useGetGenderIdentityOptions()
  const theme = useTheme()

  const { send } = useStateMachine(
    data?.partnerGenderIdentity || hasQuestionBeenSkipped("partnerGenderIdentity") ? "" : null
  )

  async function onSkip(): Promise<void> {
    await updateAboutYou({ partnerGenderIdentity: "skip", partnerGenderIdentityOther: "nullOut" })
    send("")
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'formValues' implicitly has an 'any' typ... Remove this comment to see the full error message
  async function onClickContinue(formValues): Promise<void> {
    if (formValues?.partnerGenderIdentity) {
      await mutateAsync({
        ...formValues,
        partnerGenderIdentityOther: formValues.partnerGenderIdentityOther || "nullOut"
      })
      send("")
    } else {
      await onSkip()
    }
  }
  const header = intl.formatMessage({
    defaultMessage: "What’s your partner’s gender identity?"
  })

  return (
    <>
      <RoutingFlowTitle title={header} />
      <StepLayout
        header={<span id="partnerGenderIdentity-label">{header}</span>}
        iconButton={
          <RoutingFlowModalIcon
            onClick={togglePartnerGenderIdentityModal}
            aria-label={intl.formatMessage({ defaultMessage: "Why do you need to know their gender identity?" })}
          />
        }
        formElements={
          <Form
            defaultValues={{
              partnerGenderIdentity: data?.partnerGenderIdentity,
              partnerGenderIdentityOther: data?.partnerGenderIdentityOther
            }}
            onSubmit={onClickContinue}
          >
            <Stack spacing={theme.spacing(theme.tokens.spacing.sm)}>
              <FormRadioButtonGroup
                name="partnerGenderIdentity"
                options={genderIdentityOptions}
                aria-labelledby="partnerGenderIdentity-label"
              />
              <OptionalPartnerAnotherGenderIdentityTextField />
            </Stack>
            <ButtonFooterLayout
              continueButton={
                <ContinueButton>
                  <FormattedMessage defaultMessage="Continue" />
                </ContinueButton>
              }
              skipButton={<SkipButton onClick={onSkip} />}
            />
          </Form>
        }
      />
      <RoutingFlowDialog
        open={showPartnerGenderIdentityModal}
        onClose={togglePartnerGenderIdentityModal}
        title={intl.formatMessage({ defaultMessage: "Why do you need to know their gender identity?" })}
        description={intl.formatMessage({
          defaultMessage:
            "This is the gender that they identify as, which may or may not be the same as their sex. This information helps us provide guidance and suggest relevant resources, and is never shared with your benefit sponsor or theirs."
        })}
      />
    </>
  )
}
