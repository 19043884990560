import React from "react"
import { useGetMedicalInfo, useMedicalInfo } from "../../context/MedicalInfoContext"
import { useStateMachine } from "../../../context/stateMachine/StateMachineV2"
import { useMedicalInfoWorkflow } from "../../hooks/useMedicalInfoFlow"
import { MaleFamilyConditionsOptions, FemaleFamilyConditionsOptions } from "./MedicalInfoOptions"
import {
  ButtonFooterLayout,
  ContinueButton,
  StepLayout,
  SkipButton,
  RoutingFlowCheckboxGroup,
  FormTextFieldWithCharacterCount,
  useConvertTranslatedLabel,
  RoutingFlowTitle
} from "#/components/carrot-plans/shared"
import { FormattedMessage, defineMessage, useIntl } from "react-intl"
import { Form, useWatch } from "@carrotfertility/carotene-core-x"
import { Stack, useTheme } from "@carrotfertility/carotene-core"

type FormValues = {
  familyConditions?: string[]
  familyConditionsOther?: string
}

const OPTIONAL_ANSWER_KEY = "otherFamilyConditions"

function OptionalInfoInput() {
  const selectedValues = useWatch({ name: "familyConditions" })
  if (!selectedValues.includes(OPTIONAL_ANSWER_KEY)) {
    return null
  }
  return (
    <FormTextFieldWithCharacterCount
      name="familyConditionsOther"
      helperText={<FormattedMessage defaultMessage="Optional details about other family history" />}
    />
  )
}

const OPTIONAL_SELECTION = {
  label: defineMessage({ defaultMessage: "Something else you'd like us to know about" }),
  value: OPTIONAL_ANSWER_KEY
}

export default function FamilyConditionsStep(): JSX.Element {
  const intl = useIntl()
  const theme = useTheme()
  const { IsAMAB, IsAFAB } = useMedicalInfoWorkflow()
  const { updateMedicalInfo, hasQuestionBeenSkipped } = useMedicalInfo()
  const { data: medicalInfo } = useGetMedicalInfo()
  const defaultFamilyConditionsValues = IsAMAB
    ? medicalInfo?.maleFamilyConditions
    : IsAFAB
    ? medicalInfo?.femaleFamilyConditions
    : ""
  const defaultFamilyConditionsOtherValue = medicalInfo?.familyConditionsOther
  const defaultFamilyConditionsValuesWithOther = [
    ...(defaultFamilyConditionsValues || []),
    defaultFamilyConditionsOtherValue ? OPTIONAL_ANSWER_KEY : ""
  ].filter(Boolean)
  const buttonOptions = IsAMAB ? MaleFamilyConditionsOptions : IsAFAB ? FemaleFamilyConditionsOptions : null
  const { send } = useStateMachine(
    defaultFamilyConditionsValues || defaultFamilyConditionsOtherValue || hasQuestionBeenSkipped("familyConditions")
      ? ""
      : null
  )

  const options = useConvertTranslatedLabel([...buttonOptions, OPTIONAL_SELECTION])

  async function onClickContinue(values: FormValues) {
    const selected = values?.familyConditions.filter((value) => value !== OPTIONAL_ANSWER_KEY)
    const medicalInfoPayload = {
      familyConditionsOther: values?.familyConditionsOther || ""
    }

    if (IsAMAB) {
      // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      medicalInfoPayload["maleFamilyConditions"] = selected
    } else if (IsAFAB) {
      // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      medicalInfoPayload["femaleFamilyConditions"] = selected
    }

    await updateMedicalInfo(medicalInfoPayload)
    send("")
  }

  async function onSkip(): Promise<void> {
    await updateMedicalInfo({ skipFields: ["familyConditions"] })
    send("")
  }

  const header = intl.formatMessage({
    defaultMessage: "Has a member of your immediate family experienced any of these conditions?"
  })

  return (
    <>
      <RoutingFlowTitle title={header} />
      <StepLayout
        header={<span id="familyConditions-label">{header}</span>}
        description={<FormattedMessage defaultMessage="Select any that apply." />}
        formElements={
          <Form
            defaultValues={{
              familyConditions: defaultFamilyConditionsValuesWithOther,
              familyConditionsOther: defaultFamilyConditionsOtherValue
            }}
            onSubmit={onClickContinue}
          >
            <Stack spacing={theme.spacing(theme.tokens.spacing.sm)}>
              <RoutingFlowCheckboxGroup
                name="familyConditions"
                aria-labelledby="familyConditions-label"
                options={options}
              />
              <OptionalInfoInput />
            </Stack>
            <ButtonFooterLayout
              continueButton={
                <ContinueButton>
                  <FormattedMessage defaultMessage="Continue" />
                </ContinueButton>
              }
              skipButton={<SkipButton onClick={onSkip} />}
            />
          </Form>
        }
      />
    </>
  )
}
