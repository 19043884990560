import { useAuthentication } from "../AuthenticationProvider"
import { useEffect } from "react"
import { HttpStatusCodes } from "../../../../utils/HttpStatusCodes"
import { validateUrl } from "#/utils/Regexes"
import { reportErrorMessage } from "#/utils/ErrorReporting"
import Settings from "#/utils/CarrotConfig"
import queryString from "query-string"

// @ts-expect-error TS(7006) FIXME: Parameter 'query' implicitly has an 'any' type.
export default function useQueryExpirationHandler(query): void {
  const { handleSessionExpiration } = useAuthentication()
  const returnUrl = queryString.parse(window.location.search)["returnUrl"] as string
  const adminUrl = Settings.ABALONE_URL

  useEffect(() => {
    if (query.isError && query.error.statusCode === HttpStatusCodes.UNAUTHORIZED) {
      handleSessionExpiration()
    }
    if (query.isError && query.error.statusCode === HttpStatusCodes.FORBIDDEN) {
      // If no returnUrl is present, no need to redirect or Sentry
      if (!returnUrl) return

      const returnUrlIsAllowed = validateUrl(returnUrl)

      if (!returnUrlIsAllowed) {
        reportErrorMessage(
          `returnUrl provided at login is not allowed: ${returnUrl} | window.location: ${window.location}`
        )
        window.location.href = adminUrl
        return
      }

      window.location.href = returnUrl
    }
  }, [query.isError, returnUrl, adminUrl, query.error, handleSessionExpiration])
}
