import { combineReducers } from "redux"
import actionTypes from "#/redux/actions/actionTypes"
import { createReducer } from "#/redux/reducers/shared"
import { CountryCode } from "#/utils/CountryCode"
import { RootState } from "#/redux/reducers/index"
import TestUserActionTypes from "#/redux/actions/testUserActionTypes"
import { CompanyInfo } from "#/lib/carrot-api/types/UserInfo"

const { FETCH_USER_INFO_SUCCESS } = actionTypes

const reducers = {
  medicalPlanStartDate: createReducer<string>(null, {
    [FETCH_USER_INFO_SUCCESS]: (state: any, action: any) => action.response.company.medicalPlanStartDate
  }),

  medicalPlanEndDate: createReducer<string>(null, {
    [FETCH_USER_INFO_SUCCESS]: (state: any, action: any) => action.response.company.medicalPlanEndDate
  }),

  isAvaActive: createReducer<boolean>(null, {
    [FETCH_USER_INFO_SUCCESS]: (state: any, action: any) => action.response.company.isAvaActive,
    [TestUserActionTypes.TOGGLE_IS_AVA_ACTIVE]: (state: any) => !state
  }),

  carrotLite: createReducer<boolean>(null, {
    [FETCH_USER_INFO_SUCCESS]: (state: any, action: any) => action.response.company.carrotLite,
    [TestUserActionTypes.TOGGLE_IS_CARROT_LITE]: (state: any) => !state
  }),

  countryCode: createReducer<CountryCode>(null, {
    [FETCH_USER_INFO_SUCCESS]: (state: any, action: any) => action.response.company.countryCode,
    [TestUserActionTypes.UPDATE_COUNTRY_CODE]: (state: any, action: any) => {
      return action.response.countryCode
    }
  }),

  companyId: createReducer<number>(null, {
    [FETCH_USER_INFO_SUCCESS]: (state: any, action: any) => action.response.company.companyId
  }),

  parentCompanyOrCompanyName: createReducer<string>(null, {
    [FETCH_USER_INFO_SUCCESS]: (state: any, action: any) => action.response.company.parentCompanyOrCompanyName
  }),

  companyDisplayName: createReducer<string>(null, {
    [FETCH_USER_INFO_SUCCESS]: (state: any, action: any) => action.response.company.companyDisplayName
  }),

  companyName: createReducer<string>(null, {
    [FETCH_USER_INFO_SUCCESS]: (state: any, action: any) => action.response.company.companyName
  }),

  parentCompanyName: createReducer<string>(null, {
    [FETCH_USER_INFO_SUCCESS]: (state: any, action: any) => action.response.company.parentCompanyName
  }),

  currencySymbol: createReducer<string>(null, {
    [FETCH_USER_INFO_SUCCESS]: (state: any, action: any) => action.response.company.currencySymbol
  }),

  isSamlActive: createReducer<boolean>(null, {
    [FETCH_USER_INFO_SUCCESS]: (state: any, action: any) => action.response.company.isSamlActive,
    [TestUserActionTypes.TOGGLE_IS_SAML_ACTIVE]: (state: any) => !state
  }),

  supportsDeductiblePlans: createReducer<boolean>(null, {
    [FETCH_USER_INFO_SUCCESS]: (state: any, action: any) => action.response.company.supportsDeductiblePlans,
    [TestUserActionTypes.TOGGLE_SUPPORTS_DEDUCTIBLE_PLANS]: (state: any) => !state
  }),

  hasIvfRequirements: createReducer<boolean>(null, {
    [FETCH_USER_INFO_SUCCESS]: (state: any, action: any) => action.response.company.hasIvfRequirements,
    [TestUserActionTypes.TOGGLE_ARE_COVERAGE_IVF_REQUIREMENTS_ENABLED]: (state: any) => !state
  }),

  hasACA: createReducer<boolean>(null, {
    [FETCH_USER_INFO_SUCCESS]: (state: any, action: any) => action.response.company.hasACA,
    [TestUserActionTypes.TOGGLE_HAS_ACA]: (state: any) => !state
  }),

  launchDate: createReducer<string>(null, {
    [FETCH_USER_INFO_SUCCESS]: (state: any, action: any) => action.response.company.launchDate
  }),

  currencyCode: createReducer<string>(null, {
    [FETCH_USER_INFO_SUCCESS]: (state: any, action: any) => action.response.company.currencyCode
  }),

  isMiraActive: createReducer<boolean>(null, {
    [FETCH_USER_INFO_SUCCESS]: (state: any, action: any) => action.response.company.isMiraActive,
    [TestUserActionTypes.TOGGLE_IS_MIRA_ACTIVE]: (state: any) => !state
  }),

  parentCompanyId: createReducer<number>(null, {
    [FETCH_USER_INFO_SUCCESS]: (state: any, action: any) => action.response.company.parentCompanyId
  }),

  isExcludedFromMetrics: createReducer<boolean>(null, {
    [FETCH_USER_INFO_SUCCESS]: (state: any, action: any) => action.response.company.isExcludedFromMetrics
  })
}

type CompanyInfoReducers = typeof reducers
const companyInfo = combineReducers<CompanyInfoReducers>(reducers)

export const getCompanyInfo = (state: RootState): CompanyInfo => state.companyInfo
export const getCompanyId = (state: RootState): number => state.companyInfo.companyId
export const getCarrotLite = (state: RootState): boolean => state.companyInfo.carrotLite
export const getCurrencySymbol = (state: RootState): string => state.companyInfo.currencySymbol
export const getCompanyCurrencyCode = (state: RootState): string => state.companyInfo.currencyCode
export const getCountryCode = (state: RootState): CountryCode => state.companyInfo.countryCode
export const getParentCompanyOrCompanyName = (state: RootState): string => state.companyInfo.parentCompanyOrCompanyName
export const getCompanyDisplayName = (state: RootState): string => state.companyInfo.companyDisplayName
export const getParentCompanyId = (state: RootState): number => state.companyInfo.parentCompanyId
export const getCompanyName = (state: RootState): string => state.companyInfo.companyName
export const getLaunchDate = (state: RootState): string => state.companyInfo.launchDate
export const getCurrencyCode = (state: RootState): string => state.companyInfo.currencyCode
export const getIsExcludedFromMetrics = (state: RootState): boolean => state.companyInfo.isExcludedFromMetrics

export default companyInfo
