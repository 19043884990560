import React from "react"
import { FormCheckbox } from "@carrotfertility/carotene-core-x"
import { useUserRole } from "#/components/partner-access/hooks/usePartnerAccess"
import { useIntl } from "react-intl"

export function PushNotificationSettings(): JSX.Element {
  const { isPartnerAccessingAccount } = useUserRole()
  const intl = useIntl()

  return (
    <FormCheckbox
      data-id="push-notifications-opt-in"
      disabled={isPartnerAccessingAccount}
      name="pushNotificationOptIn"
      label={intl.formatMessage({
        defaultMessage: "Push notifications"
      })}
    />
  )
}
