import React from "react"
import { useGetPhoneSupportNumberForUser } from "#/components/dynamic-content/hooks/useQueryPhoneSupport"
import { Link, Typography } from "@carrotfertility/carotene-core"

const PhoneSupportNumberText = (): JSX.Element => {
  const { data: phoneSupportNumber, isLoading } = useGetPhoneSupportNumberForUser()

  if (isLoading) {
    return null
  }
  return (
    <Typography
      component="span"
      fontSize="inherit"
      fontWeight={(theme) => theme.typography.fontWeightBold}
      color={(theme) => theme.palette.text.tertiary}
      whiteSpace="nowrap"
    >
      {phoneSupportNumber.internationalFormatPhoneNumber}
    </Typography>
  )
}

const PhoneSupportNumberLink = (): JSX.Element => {
  const { data: phoneSupportNumber, isLoading } = useGetPhoneSupportNumberForUser()

  if (isLoading) {
    return null
  }
  return (
    <Typography whiteSpace="nowrap">
      <Link color="inherit" href={`tel:${phoneSupportNumber.e164FormatPhoneNumber}`}>
        {phoneSupportNumber.internationalFormatPhoneNumber}
      </Link>
    </Typography>
  )
}

export { PhoneSupportNumberText, PhoneSupportNumberLink }
