import React from "react"
import useInsuranceDetails from "./context/useInsuranceDetails"
import InsurancePlanInformationTable from "./plan-information-table"
import { CMDTableContainer } from "#/components/views/reimbursements/educationmodal/CMDTable"
import { useCurrentUser } from "#/components/context/user/UserContext"
import { useHistory } from "react-router-dom"
import InsuranceExpired from "./InsuranceExpired"
import Locations from "#/components/views/home/navigation"
import NoInsurance from "./NoInsurance"
import { COMPANY_DEDUCTIBLE_STATUS } from "#/components/context/user/DeductibleContext"
import { useToggle } from "#/utils/Hooks"
import { EraseYourAnswersModal } from "#/components/cmd-enrollment/EraseYourAnswersModal"
import { FormattedMessage, defineMessage, useIntl } from "react-intl"
import {
  Box,
  Typography,
  UnorderedList,
  UnorderedListItem,
  Divider,
  Stack,
  Link,
  IconButton,
  InfoIcon,
  Dialog,
  CloseIcon,
  DialogTitle,
  DialogContentText,
  DialogContent,
  useTheme
} from "@carrotfertility/carotene-core"

// @ts-expect-error TS(7031) FIXME: Binding element 'onExit' implicitly has an 'any' t... Remove this comment to see the full error message
const QleExplanationMessage = ({ onExit }): JSX.Element => {
  const listOptions = [
    defineMessage({ defaultMessage: "loss of coverage" }),
    defineMessage({ defaultMessage: "household changes" }),
    defineMessage({ defaultMessage: "changes in residence" }),
    defineMessage({ defaultMessage: "and more", description: "final list option" })
  ]
  const intl = useIntl()
  const theme = useTheme()

  return (
    <Dialog aria-labelledby="qle-modal-title" onClose={onExit} open={true}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end"
        }}
      >
        <IconButton aria-label={intl.formatMessage({ defaultMessage: "Close" })} onClick={onExit}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Stack spacing={theme.spacing(theme.tokens.spacing.xs)}>
        <DialogTitle
          id="qle-modal-title"
          component="h2"
          variant="h2"
          paddingBottom={(theme) => theme.spacing(theme.tokens.spacing.xs)}
        >
          <FormattedMessage defaultMessage="Qualifying life event" />
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <FormattedMessage defaultMessage="A qualifying life event (QLE) allows you to enroll in health insurance outside the open enrollment period. QLEs include:" />
          </DialogContentText>
          <UnorderedList>
            {listOptions.map((textItem, index) => (
              <UnorderedListItem key={index}>{intl.formatMessage(textItem)}</UnorderedListItem>
            ))}
          </UnorderedList>
        </DialogContent>
      </Stack>
    </Dialog>
  )
}

const InsuranceExpiredMessage = () => (
  <>
    <InsuranceExpired />
  </>
)

const DeductibleInfoMessage = () => (
  <>
    <Typography>
      <FormattedMessage defaultMessage="You and your partner will need to meet annual deductibles before you can be reimbursed for qualified medical expenses." />
    </Typography>
  </>
)

const QleResetMessage = (): JSX.Element => {
  const [showEraseAnswersModal, toggleEraseAnswersModal] = useToggle(false)
  const [showQleExplanationMessage, toggleQleExplanationMessage] = useToggle(false)
  const intl = useIntl()

  return (
    <Typography>
      <FormattedMessage
        defaultMessage="Has your plan changed due to a qualifying life event<tooltip></tooltip>, such as getting married or gaining a dependent? <link>Update plan info.</link>"
        values={{
          tooltip: () => (
            <IconButton
              aria-label={intl.formatMessage({ defaultMessage: "Learn more about qualifying life events" })}
              onClick={toggleQleExplanationMessage}
              color="secondary"
              size="small"
            >
              <InfoIcon />
            </IconButton>
          ),
          link: (linkContent) => (
            <Link color="inherit" component="button" onClick={toggleEraseAnswersModal}>
              {linkContent}
            </Link>
          )
        }}
      />

      {showEraseAnswersModal && <EraseYourAnswersModal onExit={toggleEraseAnswersModal} />}
      {showQleExplanationMessage && <QleExplanationMessage onExit={toggleQleExplanationMessage} />}
    </Typography>
  )
}

const NoInsuranceMessage = (): JSX.Element => (
  <>
    <NoInsurance />
  </>
)

const DeductibleTables = (): JSX.Element => {
  return (
    <>
      <CMDTableContainer />
    </>
  )
}

const HealthPlanTable = (): JSX.Element => {
  const userInfo = useCurrentUser()
  const { insurancePlanDetailsData, insuranceExpired, memberHistory } = useInsuranceDetails()
  const userHasPartner =
    insuranceExpired && memberHistory ? memberHistory.memberHasLegalPartner : userInfo.memberHasLegalPartner
  const [showEraseAnswersModal, toggleEraseAnswersModal] = useToggle(false)

  return (
    <>
      <Stack marginY={(theme) => theme.spacing(theme.tokens.spacing.xl)}>
        <Divider />
      </Stack>
      <Typography variant="h2">
        <FormattedMessage defaultMessage="About your health plan" />
      </Typography>
      <Stack marginY={(theme) => theme.spacing(theme.tokens.spacing.xl)}>
        <Typography>
          <FormattedMessage
            defaultMessage="Need to make a correction? <link>Update your answers.</link>"
            values={{
              link: (linkContent) => (
                <Link color="inherit" component="button" onClick={toggleEraseAnswersModal}>
                  {linkContent}
                </Link>
              )
            }}
          />

          {showEraseAnswersModal && <EraseYourAnswersModal onExit={toggleEraseAnswersModal} />}
        </Typography>
      </Stack>
      <InsurancePlanInformationTable
        hasPartner={userHasPartner}
        planData={insurancePlanDetailsData}
        insuranceExpired={insuranceExpired}
      />
    </>
  )
}

const InsuranceDetailsTable = (): JSX.Element => {
  const userInfo = useCurrentUser()
  const { insuranceProviderDetailsData, insuranceExpired, memberHistory } = useInsuranceDetails()
  const userHasPartner =
    insuranceExpired && memberHistory ? memberHistory.memberHasLegalPartner : userInfo.memberHasLegalPartner
  const history = useHistory()
  const handleEditInsuranceAnswers = (): void => history.push(Locations.Home.InsuranceEditEnrollmentActive)
  return (
    <>
      <Stack marginY={(theme) => theme.spacing(theme.tokens.spacing.xl)}>
        <Divider />
      </Stack>
      <Typography variant="h2">
        <FormattedMessage defaultMessage="Connecting to your insurance provider" />
      </Typography>
      <Stack marginY={(theme) => theme.spacing(theme.tokens.spacing.xl)}>
        <Typography>
          <FormattedMessage
            defaultMessage="Need to make a correction? <link>Update your answers.</link>"
            values={{
              link: (linkContent) => (
                <Link color="inherit" component="button" onClick={handleEditInsuranceAnswers}>
                  {linkContent}
                </Link>
              )
            }}
          />
        </Typography>
      </Stack>
      <InsurancePlanInformationTable
        hasPartner={userHasPartner}
        planData={insuranceProviderDetailsData}
        insuranceExpired={insuranceExpired}
      />
    </>
  )
}

const InsuranceDetails = (): JSX.Element => {
  const {
    needsBenefitEnrollment,
    insuranceExpired,
    hasDeductible,
    hasDeductibleRemaining,
    partnerPlanRequiresInsuranceProviderInfo,
    memberPlanRequiresInsuranceProviderInfo,
    hasInsurance,
    companyDeductibleStatus,
    memberHistory
  } = useInsuranceDetails()

  const isEnrolled = !needsBenefitEnrollment
  const theme = useTheme()

  return (
    <Box maxWidth="640px">
      <Stack marginBottom={(theme) => theme.spacing(theme.tokens.spacing.xxl)}>
        <Typography variant="h2">
          <FormattedMessage defaultMessage="About your insurance" />
        </Typography>
      </Stack>
      <Stack
        spacing={theme.spacing(theme.tokens.spacing.lg)}
        paddingBottom={(theme) => theme.spacing(theme.tokens.spacing.md)}
      >
        {insuranceExpired && <InsuranceExpiredMessage />}
        {hasDeductibleRemaining && isEnrolled && <DeductibleInfoMessage />}
        {isEnrolled && <QleResetMessage />}
        {!hasInsurance && <NoInsuranceMessage />}
      </Stack>
      {!insuranceExpired && hasDeductible && <DeductibleTables />}
      {(insuranceExpired || (isEnrolled && hasInsurance)) && (
        <>
          <HealthPlanTable />
          {(partnerPlanRequiresInsuranceProviderInfo ||
            memberPlanRequiresInsuranceProviderInfo ||
            (insuranceExpired && memberHistory && (memberHistory.memberHasHDHP || memberHistory.partnerHasHDHP)) ||
            companyDeductibleStatus === COMPANY_DEDUCTIBLE_STATUS.EXTERNAL) && <InsuranceDetailsTable />}
        </>
      )}
    </Box>
  )
}

export default InsuranceDetails
