import React, { useState } from "react"
import PropTypes from "prop-types"
import { Typography } from "@carrotfertility/carotene-core"
import { FormattedMessage } from "react-intl"

import { useDidMount } from "../../../utils/Hooks"
import { Body } from "../atoms/Bodies"
import { Spacer } from "../atoms/Atoms"
import { MerchantDeclineLoading } from "./MerchantDeclineLoading"
import { useHistory, useParams } from "react-router-dom"
import { carrotClient } from "#/utils/CarrotClient"

const defaultState = {
  isLoading: true
}

export const MerchantApproved = () => {
  // @ts-expect-error TS(2339) FIXME: Property 'token' does not exist on type '{}'.
  const { token } = useParams()
  const [state, setState] = useState(defaultState)
  const history = useHistory()

  useDidMount(() => {
    const approveMerchant = async () => {
      try {
        await carrotClient.approveMerchant(token)
        setState({
          ...state,
          isLoading: false
        })
      } catch {
        history.push("/confirm/error")
      }
    }

    approveMerchant().then()
  })
  return state.isLoading ? (
    <MerchantDeclineLoading />
  ) : (
    <>
      <Spacer height={2} />
      <Typography variant="h3">
        <FormattedMessage defaultMessage="Thanks for confirming" />
      </Typography>
      <Spacer height={1} />
      <Body>
        <FormattedMessage defaultMessage="Your Carrot Card should now work with this provider — please try again." />
      </Body>
    </>
  )
}

MerchantApproved.propTypes = {
  token: PropTypes.string
}
