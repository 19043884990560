import React from "react"
import { Route, Redirect } from "react-router-dom"
import InboundMemberPaymentsCheckoutPage from "./InboundMemberPaymentsCheckoutPage"
import InboundMemberPaymentsStatusPage from "./InboundMemberPaymentsStatusPage"
import { InboundMemberPaymentsAmountFormPage } from "./InboundMemberPaymentsAmountFormPage"
import { Switch } from "#/services/routing/Switch"

export function InboundMemberPayments(): JSX.Element {
  return (
    <Switch>
      <Route exact path="/make-a-payment" component={InboundMemberPaymentsAmountFormPage} />
      <Route exact path="/make-a-payment/checkout" component={InboundMemberPaymentsCheckoutPage} />
      <Route exact path="/make-a-payment/status" component={InboundMemberPaymentsStatusPage} />
      <Redirect to="/make-a-payment/" />
    </Switch>
  )
}
