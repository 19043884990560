import { useQuery, UseQueryResult } from "@tanstack/react-query"
import buildCarrotApiRetryPolicy from "#/utils/CarrotApiRetryPolicy"
import { HasCarrotPartnerResponse } from "../types/HasCarrotPartnerResponse"
import ResponseError from "../types/ResponseError"
import { useProviderFinderClient } from "./useProviderFinderClient"

const CARROT_PARTNER_QUERY_KEY = "GetHasCarrotPartner"

export function useGetHasCarrotPartner(
  companyCountryCode: string
): UseQueryResult<HasCarrotPartnerResponse, ResponseError> {
  const client = useProviderFinderClient()

  return useQuery<HasCarrotPartnerResponse, ResponseError>(
    [CARROT_PARTNER_QUERY_KEY, companyCountryCode],
    async () => {
      const response = await client.getHasCarrotPartner(companyCountryCode)
      return response ? response : null
    },
    {
      refetchOnWindowFocus: false,
      retry: buildCarrotApiRetryPolicy(3)
    }
  )
}
