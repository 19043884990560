import React from "react"
import { EnrollmentModal } from "./EnrollmentModal"
import BenefitEnrollmentFlow from "./steps/BenefitEnrollmentFlow"

// @ts-expect-error TS(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
function BenefitEnrollmentFlowContainer(props): JSX.Element {
  return (
    <EnrollmentModal {...props}>
      <BenefitEnrollmentFlow />
    </EnrollmentModal>
  )
}

export { BenefitEnrollmentFlowContainer }
