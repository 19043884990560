import { Box, Button } from "@carrotfertility/carotene-core"
import React, { useEffect, useState } from "react"
import { FormattedMessage } from "react-intl"
import { useSelector } from "react-redux"
import { getEmployeeId } from "#/redux/reducers/userInfo"
import { getHeap } from "#/utils/heap"
import { PopupModal, useCalendlyEventListener } from "react-calendly"
import { CALENDLY_PRIMARY_COLOR } from "../../../lib/contentful/utils/mappingUtils"
import {
  DialogTimeTapScheduler,
  useReportToHeapTimeTapChatScheduled,
  TimeTapEnvironment,
  TimeTapConfirmationDialog
} from "#/components/time-tap/TimeTapScheduler"
import { useAppointmentSchedulingCheck } from "#/components/time-tap/useGetAppointmentSchedulingSystem"

export const FindASessionButton = ({
  sessionPickerUrl,
  groupSlugId,
  reasonId,
  locationId,
  timeTapEnvironment
}: {
  sessionPickerUrl: string
  groupSlugId: string
  reasonId: number
  locationId?: number
  timeTapEnvironment?: TimeTapEnvironment
}): JSX.Element => {
  const employeeId = useSelector(getEmployeeId)
  const [showChatConfirmation, setShowChatConfirmation] = useState(false)
  const [open, setOpen] = useState(false)
  const { isTimeTapForOtherChats } = useAppointmentSchedulingCheck(reasonId, sessionPickerUrl)
  useCalendlyEventListener({
    onEventScheduled: () => {
      getHeap().track("Group chat scheduled", {
        "Group Slug ID": groupSlugId
      })
    }
  })

  useReportToHeapTimeTapChatScheduled(
    setShowChatConfirmation,
    {
      eventName: "Group chat scheduled",
      properties: {
        "Group Slug ID": groupSlugId
      }
    },
    Boolean(groupSlugId)
  )
  const CalendlyWidget = (): JSX.Element => {
    useEffect(() => {
      const handleEsc = (event: any): void => {
        if (event.keyCode === 27) {
          setOpen(false)
        }
      }
      window.addEventListener("keydown", handleEsc)

      return () => {
        window.removeEventListener("keydown", handleEsc)
      }
    }, [])

    return (
      <PopupModal
        url={sessionPickerUrl}
        utm={{ utmCampaign: employeeId, utmSource: groupSlugId }}
        onModalClose={() => setOpen(false)}
        open={open}
        rootElement={document.getElementById("groups-find-group-session-calendly-widget")}
        pageSettings={{
          primaryColor: CALENDLY_PRIMARY_COLOR
        }}
      />
    )
  }

  return (
    <>
      <Button id="find-group-session-button" onClick={() => setOpen(true)}>
        <FormattedMessage defaultMessage="Find a session" />
      </Button>
      <Box id="groups-find-group-session-calendly-widget">
        {isTimeTapForOtherChats ? (
          <>
            <DialogTimeTapScheduler
              reasonId={reasonId}
              locationId={locationId}
              open={open && !showChatConfirmation}
              setOpen={setOpen}
              timeTapEnvironment={timeTapEnvironment}
            />
            <TimeTapConfirmationDialog
              open={showChatConfirmation}
              onClose={() => {
                setOpen(false)
                setShowChatConfirmation(false)
              }}
              chatType="GROUPS"
            />
          </>
        ) : (
          <CalendlyWidget />
        )}
      </Box>
    </>
  )
}
