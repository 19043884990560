import React from "react"
import { ProviderHomepage } from "./HomePage"
import { ResultsPage } from "./ResultsPage"
import { Redirect, Route } from "react-router-dom"
import { providerFinderRootPath } from "./utils/providerFinderSearchUrl"
import { SearchTypeContextProvider } from "./context/SearchTypeContext"
import { BroadAreaContextProvider } from "./context/BroadAreaContext"
import { MapViewContextProvider } from "./context/MapViewContext"
import { ErrorPage } from "./ErrorPage"
import useGetBenefit from "./hooks/useGetBenefit"
import { Loading } from "./components/Loading"
import { ResponsiveDrawer } from "./components/sidebar/ResponsiveDrawer"
import { Switch } from "#/services/routing/Switch"
import { ProviderRecommendationRequestForm } from "./components/provider-recommendation/ProviderRecommendationRequestForm"

const PRO_REDIRECT = "/your-benefit-guide/provider-eligibility"
const CORE_REDIRECT = "/your-benefit-guide/support"

export function ProviderFinder(): JSX.Element {
  const { data: benefit, isLoading, isError } = useGetBenefit()

  if (isLoading) {
    return <Loading />
  }
  if (isError) {
    return <ErrorPage />
  }
  if (!benefit.providerFinder.showProviderFinder) {
    const redirect = benefit.program.isCarrotLite ? CORE_REDIRECT : PRO_REDIRECT
    return <Redirect to={redirect} />
  }
  return (
    <SearchTypeContextProvider>
      <MapViewContextProvider>
        <BroadAreaContextProvider>
          <ResponsiveDrawer benefit={benefit} />
          <Switch>
            <Route
              exact
              strict
              path={[`${providerFinderRootPath}/search-by-name`, providerFinderRootPath]}
              component={() => <ProviderHomepage benefit={benefit} />}
            />
            <Route
              exact
              path={`${providerFinderRootPath}/providers`}
              component={() => <ResultsPage benefit={benefit} />}
            />
            <Route exact path={`${providerFinderRootPath}/error`} component={ErrorPage} />
            <Route
              exact
              path={`${providerFinderRootPath}/find-obgyn`}
              component={() => <ProviderRecommendationRequestForm providerRequested="OBGYN" />}
            />
            <Route
              exact
              path={`${providerFinderRootPath}/find-pediatrician`}
              component={() => <ProviderRecommendationRequestForm providerRequested="Pediatrician" />}
            />
            {/*Catch all redirect*/}
            <Redirect to={providerFinderRootPath} />
          </Switch>
        </BroadAreaContextProvider>
      </MapViewContextProvider>
    </SearchTypeContextProvider>
  )
}
