import React from "react"
import { useSelector } from "react-redux"
import { App } from "#/components/views/app/App"
import { Loading } from "#/components/views/Loading"
import { getUserInfoLoaded } from "#/redux/reducers/userInfo"
import { getIsBenefitConfigurationLoaded } from "#/redux/reducers/benefitConfiguration"
import { BenefitEnrollmentProvider } from "#/components/context/enrollment/BenefitEnrollmentContext"
import { DeductibleProvider } from "#/components/context/user/DeductibleContext"
import { MemberPoseWarningMessage } from "../../member-pose/MemberPoseWarningMessage"
import { ConnectInsuranceContextProvider } from "../../context/enrollment/ConnectInsuranceContext"
import { useCurrentUser, UserContextProvider } from "#/components/context/user/UserContext"
import { useBenefitEnrollmentStatus } from "../../context/enrollment/hooks/useBenefitEnrollmentStatus"
import useEmailVerification from "../../EmailVerification/useEmailVerification"
import { ReactQueryDevtools } from "@tanstack/react-query-devtools"
import Settings from "../../../utils/CarrotConfig"
import { useLocaleContext } from "#/components/context/user/LocaleContext"
import { useUserRole } from "../../partner-access/hooks/usePartnerAccess"
import * as Sentry from "@sentry/browser"
import { setHeapPartnerInfo } from "../../../utils/heap"
import { useGetZendeskMessagingWidgetSettings } from "../../talk-to-carrot/hooks/useGetZendeskMessagingWidgetSettings"
import { Locale } from "#/types/Locale"

declare global {
  interface Window {
    zE: any
  }
}

function useLaunchDarklyLoaded(): ReturnType<typeof React.useState<boolean>> {
  const { loadLDFlagsForUser } = useCurrentUser()
  const [ldClientLoaded, setLDClientLoaded] = React.useState(false)
  React.useEffect(() => {
    const timeout = setTimeout(() => {
      // eslint-disable-next-line no-console
      console.warn("Launch darkly failed to load in 3 seconds. Moving on")
      setLDClientLoaded(true)
    }, 3000)
    async function loadLDClient() {
      const loaded = await loadLDFlagsForUser()
      if (loaded) {
        clearTimeout(timeout)
        setLDClientLoaded(true)
      }
    }
    loadLDClient()
    return () => {
      clearTimeout(timeout)
    }
  }, [loadLDFlagsForUser])
  return [ldClientLoaded, setLDClientLoaded]
}

// @ts-expect-error TS(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
function AppLoadingContainer(props): JSX.Element {
  const [launchDarklyLoaded] = useLaunchDarklyLoaded()
  const { initialLoading: benefitEnrollmentStatusLoading } = useBenefitEnrollmentStatus()
  const isUserInfoLoaded = useSelector(getUserInfoLoaded)
  const isBenefitConfigurationLoaded = useSelector(getIsBenefitConfigurationLoaded)
  const { isLoading: emailVerificationInitialLoading } = useEmailVerification()
  const { isUserRoleLoading } = useUserRole()

  const isDataLoaded = [
    !benefitEnrollmentStatusLoading,
    isUserInfoLoaded,
    isBenefitConfigurationLoaded,
    launchDarklyLoaded,
    !emailVerificationInitialLoading,
    !isUserRoleLoading
  ].every((i) => i)

  return isDataLoaded ? props.children : <Loading />
}

// @ts-expect-error TS(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
function BlockingAppContext(props): JSX.Element {
  return <UserContextProvider>{props.children}</UserContextProvider>
}

// @ts-expect-error TS(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
function AppContext(props): JSX.Element {
  const { locale: userLocale } = useCurrentUser()
  const { setLocale } = useLocaleContext()
  React.useEffect(() => {
    if (userLocale) {
      setLocale(Locale.getLocaleFromString(userLocale))
    }
  }, [userLocale, setLocale])

  const { isUserRoleLoading, isPartnerAccessingAccount: isPartnerAccount } = useUserRole()
  if (!isUserRoleLoading) {
    setHeapPartnerInfo(isPartnerAccount)
    Sentry?.setContext("Partner Access", {
      isPartnerAccount
    })
  }

  const { data: zendeskMessagingWidgetSettings, isLoading: isGetZendeskMessagingWidgetSettingsLoading } =
    useGetZendeskMessagingWidgetSettings()
  if (!isGetZendeskMessagingWidgetSettingsLoading) {
    window.zE("messenger", "loginUser", function (callback: (token: string) => void) {
      callback(zendeskMessagingWidgetSettings?.messagingJwtToken)
    })
    window.zE("messenger:set", "locale", zendeskMessagingWidgetSettings?.zendeskLocale)
  }

  return (
    <DeductibleProvider>
      <BenefitEnrollmentProvider>
        <ConnectInsuranceContextProvider>{props.children}</ConnectInsuranceContextProvider>
      </BenefitEnrollmentProvider>
    </DeductibleProvider>
  )
}

function AppContainer(): JSX.Element {
  return (
    <BlockingAppContext>
      <AppLoadingContainer>
        <AppContext>
          <MemberPoseWarningMessage />
          <App />
          {Settings.SHOW_REACT_QUERY_DEVTOOLS ? (
            <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
          ) : null}
        </AppContext>
      </AppLoadingContainer>
    </BlockingAppContext>
  )
}

export { AppContainer }
