import { QueryObserverResult, RefetchOptions, RefetchQueryFilters, useQuery } from "@tanstack/react-query"
import { HttpStatusCodes } from "#/utils/HttpStatusCodes"
import ResponseError from "../../../../types/responseError"
import buildCarrotApiRetryPolicy from "../../../../utils/CarrotApiRetryPolicy"
import useQueryExpirationHandler from "../../../context/authentication/hooks/useQueryExpirationHandler"
import { reportErrorMessage } from "../../../../utils/ErrorReporting"
import { carrotClient } from "#/utils/CarrotClient"

const ENDPOINT = "GetChannelId"

export function getChannelIdQueryKey(): string[] {
  return [ENDPOINT]
}

export type UseChannelIdReturn = {
  data: number
  error: ResponseError
  isError: boolean
  isLoading: boolean
  refetch: <TPageData>(
    options?: RefetchOptions & RefetchQueryFilters<TPageData>
  ) => Promise<QueryObserverResult<number, ResponseError>>
  hasChannelId: boolean
}

export default function useChannelId(): UseChannelIdReturn {
  const channelIdQuery = useQuery<number, ResponseError>(getChannelIdQueryKey(), () => carrotClient.getChannelId(), {
    refetchOnWindowFocus: false,
    retry: buildCarrotApiRetryPolicy(3),
    onError: (error) => {
      if (error.statusCode === HttpStatusCodes.NOT_FOUND) {
        return null
      }
      reportErrorMessage(`Could not load channelID for current employee. Error: ${error}`)
    }
  })
  useQueryExpirationHandler(channelIdQuery)
  const { data, isLoading, isError, error, refetch } = channelIdQuery
  return {
    data,
    error,
    isLoading,
    isError,
    refetch,
    hasChannelId: Boolean(channelIdQuery.data)
  }
}
