import React, { useContext, useState } from "react"
import { Alert, Box, Button, DialogContentText, DialogTitle } from "@carrotfertility/carotene-core"
import { Form, FormOnSubmitHandler, FormTextField } from "@carrotfertility/carotene-core-x"
import { useStateMachine } from "#/components/context/stateMachine/StateMachineV2"
import { RequestCardContext } from "#/pages/request-carrot-card-flow"
import RequestCardWrapper from "#/pages/request-carrot-card-flow/requestCardWrapper"
import { sleep } from "#/utils/Helpers"
import { useDidMount } from "#/utils/Hooks"
import { FormattedMessage, useIntl } from "react-intl"
import { HttpErrors } from "#/utils/HttpErrors"
import { getCountryCallingCode } from "libphonenumber-js"
import { carrotClient } from "#/utils/CarrotClient"
import { unexpectedServerErrorAlert } from "../requestCardWrapper/unexpected-error-alert"

const DefaultVerifyPhoneNumberState = {
  isResendLinkVisible: false,
  showResendHelperText: false,
  isOtherError: false,
  isIncorrectCode: false
}

const VerifyPhoneNumber = () => {
  const { phoneNumber, phoneCountryCode, shouldSeeUserAgreement } = useContext(RequestCardContext)
  const [state, setState] = useState(DefaultVerifyPhoneNumberState)
  const intl = useIntl()

  const { canGoBack, send } = useStateMachine()

  useDidMount(() => {
    enableResend()
  })

  const enableResend = async () => {
    await sleep(4000)
    setState({ ...state, isResendLinkVisible: true, showResendHelperText: false })
  }

  const handleResendClick = async () => {
    resendVerification()
    setState({ ...state, isResendLinkVisible: false, showResendHelperText: true })
    enableResend()
  }

  const resendVerification = async () => {
    const callingCode = getCountryCallingCode(phoneCountryCode)
    const phoneNumberWithCountryCode = `${callingCode}${phoneNumber}`
    try {
      await carrotClient.startPhoneVerification(phoneNumberWithCountryCode, phoneCountryCode)
    } catch (error) {
      setState({ ...state, isOtherError: true, isIncorrectCode: false })
    }
    return true
  }

  const onSubmission: FormOnSubmitHandler<{
    verificationCode: string
  }> = async (formValues) => {
    try {
      setState({ ...state, isOtherError: false, isIncorrectCode: false })
      const callingCode = getCountryCallingCode(phoneCountryCode)
      const phoneNumberWithCountryCode = `${callingCode}${phoneNumber}`
      await carrotClient.verifyPhoneNumber(phoneNumberWithCountryCode, formValues.verificationCode, phoneCountryCode)
      send(shouldSeeUserAgreement ? "YES_AGREEMENT" : "NO_AGREEMENT")
    } catch (e) {
      if (e.name === HttpErrors.NOT_FOUND) {
        setState({ ...state, isOtherError: false, isIncorrectCode: true })
      } else {
        setState({ ...state, isOtherError: true, isIncorrectCode: false })
      }
    }
  }

  const callingCode = getCountryCallingCode(phoneCountryCode)
  const formattedNumber = phoneNumber
    ? `+${callingCode} ${phoneNumber}`
    : intl.formatMessage({ defaultMessage: "your number" })

  const { isResendLinkVisible, isOtherError, isIncorrectCode, showResendHelperText } = state

  return (
    <Form onSubmit={onSubmission}>
      <RequestCardWrapper showBackButton={canGoBack} showContinueButton>
        <DialogTitle>
          <FormattedMessage defaultMessage="Confirm your phone number" />
        </DialogTitle>
        <DialogContentText marginBottom={(theme) => theme.tokens.spacing.xl}>
          <FormattedMessage
            defaultMessage="Enter the 6-digit verification code we sent to {formattedNumber}."
            values={{
              formattedNumber
            }}
          />
        </DialogContentText>
        <Box sx={{ display: "flex", flexDirection: "column", gap: (theme) => theme.spacing(theme.tokens.spacing.lg) }}>
          {isIncorrectCode && (
            <Alert severity="error">
              <FormattedMessage defaultMessage="Incorrect code" />
            </Alert>
          )}
          <FormTextField
            name={"verificationCode"}
            label={intl.formatMessage({ defaultMessage: "Verification Code" })}
            error={state.isIncorrectCode}
            required
            helperText={
              showResendHelperText
                ? intl.formatMessage({ defaultMessage: "A new code has been sent to your phone number." })
                : ""
            }
            registerOptions={{
              required: intl.formatMessage({ defaultMessage: "Required" })
            }}
          />
          {isResendLinkVisible ? (
            <Box display="flex" justifyContent="space-between">
              <Button
                variant="outlined"
                onClick={handleResendClick}
                color="secondary"
                size="medium"
                style={{ justifyContent: "flex-start" }}
              >
                <FormattedMessage defaultMessage="Resend code" />
              </Button>
            </Box>
          ) : (
            <DialogContentText>
              <FormattedMessage defaultMessage="Code sent to {phoneNumber}." values={{ phoneNumber }} />
            </DialogContentText>
          )}
          {isOtherError && unexpectedServerErrorAlert}
        </Box>
      </RequestCardWrapper>
    </Form>
  )
}

export { VerifyPhoneNumber }
