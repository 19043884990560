import { DbgPaths } from "../../../../../utils/Paths"
import React, { FC } from "react"
import { FormattedMessage } from "react-intl"
import { TextLink } from "@carrotfertility/carotene"
import { UnorderedListItem } from "@carrotfertility/carotene-core"
import { useSelector } from "react-redux"
import { getSubsidies } from "#/redux/reducers/subsidies"
import { CategoryCoverage, getCategoryCoverage } from "../../../../../utils/CategoryCoverage"
import useBenefit from "../../../../dynamic-content/hooks/useGetBenefit"
import { getCountryCode } from "#/redux/reducers/companyInfo"
import useCompanyComplianceConfigLineItemFilter from "../../hooks/useGetCompanyComplianceConfigLineItemFilter"
import { getBenefitConfiguration } from "#/redux/reducers/benefitConfiguration"
import { CategorySections } from "../PayingForCareModal"

const OtherExpensesHeader: FC = () => {
  const coverage = getCategoryCoverage(useSelector(getSubsidies))
  const hasBullets = useCoverageBulletList(coverage).length > 0

  if (coverage.medication) {
    return (
      <p>
        <FormattedMessage
          defaultMessage="This category includes all <link>eligible expenses</link> except medications."
          values={{
            link: (linkContent) => (
              <TextLink size="medium" target="_blank" href={DbgPaths.CARROT_ELIGIBLE}>
                {linkContent}
              </TextLink>
            )
          }}
        />
      </p>
    )
  }
  if (hasBullets) {
    if (coverage.qme) {
      return (
        <p>
          <FormattedMessage
            defaultMessage="This category includes the following when you work with a <link>Carrot-eligible provider</link>:"
            values={{
              link: (linkContent) => (
                <TextLink size="medium" target="_blank" href={DbgPaths.PROVIDER_ELIGIBILITY}>
                  {linkContent}
                </TextLink>
              )
            }}
          />
        </p>
      )
    } else {
      return (
        <p>
          <FormattedMessage defaultMessage="This category includes eligible expenses for the following journeys:" />
        </p>
      )
    }
  } else {
    return (
      <p>
        <FormattedMessage
          defaultMessage="This includes any <link>eligible expenses</link> not covered by another category."
          values={{
            link: (linkContent) => (
              <TextLink size="medium" target="_blank" href={DbgPaths.CARROT_ELIGIBLE}>
                {linkContent}
              </TextLink>
            )
          }}
        />
      </p>
    )
  }
}

const OtherExpensesListItems: FC = () => {
  const coverage = getCategoryCoverage(useSelector(getSubsidies))
  return <>{useCoverageBulletList(coverage)}</>
}

function useCoverageBulletList(coverage: CategoryCoverage) {
  const { data: benefitInfo } = useBenefit()
  const country = useSelector(getCountryCode)
  const { data: lineItemFilter } = useCompanyComplianceConfigLineItemFilter()
  const { coverageConfig } = useSelector(getBenefitConfiguration)
  const preservation = benefitInfo.journeys.fertilityPreservation
  const fertilityCare = benefitInfo.journeys.fertilityCare
  const gcCare = benefitInfo.journeys.gestationalCarrier
  const adoptionCare = benefitInfo.journeys.adoption
  const pregnancyCoverage = benefitInfo.journeys.pregnancy
  const hasElectiveFertilityLineItemFilter = lineItemFilter === "ELECTIVE_FERTILITY"
  const menopause = benefitInfo.journeys.menopause
  const lowTestosterone = benefitInfo.journeys.lowTestosterone
  const gacCare = benefitInfo.journeys.genderAffirmingCare

  const elements: React.JSX.Element[] = []

  if (coverage.medication) {
    return elements
  }

  if (coverage.qme) {
    if (coverageConfig.isMemberAndPartnerEligible && !coverageConfig.areLegalPartnersEligible) {
      elements.push(
        <UnorderedListItem>
          <p className="lh-copy">
            <FormattedMessage
              defaultMessage="Any <link>eligible care and services</link> received by the member’s <link>Carrot-eligible partner</link>, if not legally married or tax-dependent"
              values={{
                link: (linkContent) => (
                  <TextLink size="medium" target="_blank" href={DbgPaths.CARROT_ELIGIBLE}>
                    {linkContent}
                  </TextLink>
                )
              }}
            />
          </p>
        </UnorderedListItem>
      )
    }
    if (fertilityCare.eligibleExpensesDetail.isElectiveCareCovered && !hasElectiveFertilityLineItemFilter) {
      elements.push(
        <UnorderedListItem>
          <p className="lh-copy">
            <FormattedMessage
              defaultMessage="Eligible <link>fertility care</link> expenses not related to an infertility diagnosis issued by a fertility doctor"
              values={{
                link: (linkContent) => (
                  <TextLink size="medium" target="_blank" href={DbgPaths.FERTILITY_COVERAGE}>
                    {linkContent}
                  </TextLink>
                )
              }}
            />
          </p>
        </UnorderedListItem>
      )
    }
    if (fertilityCare.eligibleExpensesDetail.hasDonorAssistance) {
      if (hasElectiveFertilityLineItemFilter) {
        elements.push(
          <UnorderedListItem>
            <p className="lh-copy">
              <FormattedMessage
                defaultMessage="Eligible <link>donor assistance</link> expenses related to fertility care"
                values={{
                  link: (linkContent) => (
                    <TextLink size="medium" target="_blank" href={DbgPaths.FERTILITY_COVERAGE}>
                      {linkContent}
                    </TextLink>
                  )
                }}
              />
            </p>
          </UnorderedListItem>
        )
      } else {
        elements.push(
          <UnorderedListItem>
            <p className="lh-copy">
              <FormattedMessage
                defaultMessage="Eligible <link>donor assistance</link> expenses related to fertility care, with or without an infertility diagnosis"
                values={{
                  link: (linkContent) => (
                    <TextLink size="medium" target="_blank" href={DbgPaths.FERTILITY_COVERAGE}>
                      {linkContent}
                    </TextLink>
                  )
                }}
              />
            </p>
          </UnorderedListItem>
        )
      }
    }
    if (preservation.eligibleExpensesDetail.isElectivePreservationCovered && !hasElectiveFertilityLineItemFilter) {
      elements.push(
        <UnorderedListItem>
          <p className="lh-copy">
            <FormattedMessage
              defaultMessage="Eligible <link>fertility preservation</link> expenses not related to an infertility diagnosis or medical necessity, as determined by a fertility doctor"
              values={{
                link: (linkContent) => (
                  <TextLink size="medium" target="_blank" href={DbgPaths.PRESERVATION_COVERAGE}>
                    {linkContent}
                  </TextLink>
                )
              }}
            />
          </p>
        </UnorderedListItem>
      )
    }
    if (
      preservation.eligibleExpensesDetail.isElectivePreservationCovered &&
      preservation.eligibleExpensesDetail.isMedicallyNecessaryPreservationCovered
    ) {
      if (hasElectiveFertilityLineItemFilter) {
        elements.push(
          <UnorderedListItem>
            <p className="lh-copy">
              <FormattedMessage
                defaultMessage="Eligible <link>fertility preservation</link> storage expenses after the first year"
                values={{
                  link: (linkContent) => (
                    <TextLink size="medium" target="_blank" href={DbgPaths.PRESERVATION_COVERAGE}>
                      {linkContent}
                    </TextLink>
                  )
                }}
              />
            </p>
          </UnorderedListItem>
        )
      } else {
        elements.push(
          <UnorderedListItem>
            <p className="lh-copy">
              <FormattedMessage
                defaultMessage="Eligible <link>fertility preservation</link> storage expenses after the first year, with or without an infertility diagnosis or medical necessity"
                values={{
                  link: (linkContent) => (
                    <TextLink size="medium" target="_blank" href={DbgPaths.PRESERVATION_COVERAGE}>
                      {linkContent}
                    </TextLink>
                  )
                }}
              />
            </p>
          </UnorderedListItem>
        )
      }
    }
    if (benefitInfo.journeys.adoption.hasEligibleExpenses && !coverage.adoption) {
      elements.push(
        <UnorderedListItem>
          <p className="lh-copy">
            <FormattedMessage
              defaultMessage="Eligible <link>adoption</link> expenses"
              values={{
                link: (linkContent) => (
                  <TextLink size="medium" target="_blank" href={DbgPaths.ADOPTION_COVERAGE}>
                    {linkContent}
                  </TextLink>
                )
              }}
            />
          </p>
        </UnorderedListItem>
      )
    }
    if (benefitInfo.journeys.gestationalCarrier.hasEligibleExpenses && !coverage.gc) {
      elements.push(
        <UnorderedListItem>
          <p className="lh-copy">
            <FormattedMessage
              defaultMessage="Eligible <link>gestational surrogacy</link> expenses, including related donor assistance"
              values={{
                link: (linkContent) => (
                  <TextLink size="medium" target="_blank" href={DbgPaths.GESTATIONAL_SURROGACY_COVERAGE}>
                    {linkContent}
                  </TextLink>
                )
              }}
            />
          </p>
        </UnorderedListItem>
      )
    }
    if (pregnancyCoverage.hasEligibleExpenses && !coverage.pregnancy) {
      elements.push(
        <UnorderedListItem>
          <p className="lh-copy">
            <FormattedMessage
              defaultMessage="Eligible <link>pregnancy and postpartum</link> expenses"
              values={{
                link: (linkContent) => (
                  <TextLink size="medium" target="_blank" href={DbgPaths.PREGNANCY_COVERAGE}>
                    {linkContent}
                  </TextLink>
                )
              }}
            />
          </p>
        </UnorderedListItem>
      )
    }
    if (menopause.hasEligibleExpenses) {
      if (["US", "AU"].includes(country)) {
        elements.push(
          <UnorderedListItem>
            <p className="lh-copy">
              <FormattedMessage
                defaultMessage="Eligible Eight Sleep and Perfectly Snug products for <link>menopause</link>"
                values={{
                  link: (linkContent) => (
                    <TextLink size="medium" target="_blank" href={DbgPaths.MENOPAUSE}>
                      {linkContent}
                    </TextLink>
                  )
                }}
              />
            </p>
          </UnorderedListItem>
        )
      } else if (["GB", "DE"].includes(country)) {
        elements.push(
          <UnorderedListItem>
            <p className="lh-copy">
              <FormattedMessage
                defaultMessage="Eligible Eight Sleep products for <link>menopause</link>"
                values={{
                  link: (linkContent) => (
                    <TextLink size="medium" target="_blank" href={DbgPaths.MENOPAUSE}>
                      {linkContent}
                    </TextLink>
                  )
                }}
              />
            </p>
          </UnorderedListItem>
        )
      }
    }
  } else {
    if (
      !coverage.preservation &&
      (preservation.eligibleExpensesDetail.isMedicallyNecessaryPreservationCovered ||
        preservation.eligibleExpensesDetail.isElectivePreservationCovered)
    ) {
      elements.push(
        <UnorderedListItem>
          <p className="lh-copy">
            <FormattedMessage
              defaultMessage="<link>Fertility preservation</link>"
              values={{
                link: (linkContent) => (
                  <TextLink size="medium" target="_blank" href={DbgPaths.PRESERVATION_COVERAGE}>
                    {linkContent}
                  </TextLink>
                )
              }}
            />
          </p>
        </UnorderedListItem>
      )
    }
    if (!coverage.fertility) {
      if (
        fertilityCare.eligibleExpensesDetail.isCoveredWithInfertilityDiagnosis ||
        fertilityCare.eligibleExpensesDetail.isElectiveCareCovered
      ) {
        if (fertilityCare.eligibleExpensesDetail.hasDonorAssistance) {
          elements.push(
            <UnorderedListItem>
              <p className="lh-copy">
                <FormattedMessage
                  defaultMessage="<link>Fertility care</link>, including related donor assistance"
                  values={{
                    link: (linkContent) => (
                      <TextLink size="medium" target="_blank" href={DbgPaths.FERTILITY_COVERAGE}>
                        {linkContent}
                      </TextLink>
                    )
                  }}
                />
              </p>
            </UnorderedListItem>
          )
        } else {
          elements.push(
            <UnorderedListItem>
              <p className="lh-copy">
                <FormattedMessage
                  defaultMessage="<link>Fertility care</link>"
                  values={{
                    link: (linkContent) => (
                      <TextLink size="medium" target="_blank" href={DbgPaths.FERTILITY_COVERAGE}>
                        {linkContent}
                      </TextLink>
                    )
                  }}
                />
              </p>
            </UnorderedListItem>
          )
        }
      } else if (fertilityCare.eligibleExpensesDetail.hasDonorAssistance) {
        elements.push(
          <UnorderedListItem>
            <p className="lh-copy">
              <FormattedMessage
                defaultMessage="<link>Donor assistance</link> related to fertility care"
                values={{
                  link: (linkContent) => (
                    <TextLink size="medium" target="_blank" href={DbgPaths.FERTILITY_COVERAGE}>
                      {linkContent}
                    </TextLink>
                  )
                }}
              />
            </p>
          </UnorderedListItem>
        )
      }
    }
    if (!coverage.gc && gcCare.hasEligibleExpenses) {
      elements.push(
        <UnorderedListItem>
          <p className="lh-copy">
            <FormattedMessage
              defaultMessage="<link>Gestational surrogacy</link>"
              values={{
                link: (linkContent) => (
                  <TextLink size="medium" target="_blank" href={DbgPaths.GESTATIONAL_SURROGACY_COVERAGE}>
                    {linkContent}
                  </TextLink>
                )
              }}
            />
          </p>
        </UnorderedListItem>
      )
    }
    if (!coverage.adoption && adoptionCare.hasEligibleExpenses) {
      elements.push(
        <UnorderedListItem>
          <p className="lh-copy">
            <FormattedMessage
              defaultMessage="<link>Adoption</link>"
              values={{
                link: (linkContent) => (
                  <TextLink size="medium" target="_blank" href={DbgPaths.ADOPTION_COVERAGE}>
                    {linkContent}
                  </TextLink>
                )
              }}
            />
          </p>
        </UnorderedListItem>
      )
    }
    if (!coverage.pregnancy && pregnancyCoverage.hasEligibleExpenses) {
      elements.push(
        <UnorderedListItem>
          <p className="lh-copy">
            <FormattedMessage
              defaultMessage="<link>Pregnancy and postpartum</link>"
              values={{
                link: (linkContent) => (
                  <TextLink size="medium" target="_blank" href={DbgPaths.PREGNANCY_COVERAGE}>
                    {linkContent}
                  </TextLink>
                )
              }}
            />
          </p>
        </UnorderedListItem>
      )
    }
    if (!coverage.mlt) {
      if (menopause.hasEligibleExpenses) {
        elements.push(
          <UnorderedListItem>
            <p className="lh-copy">
              <FormattedMessage
                defaultMessage="<link>Menopause</link>"
                values={{
                  link: (linkContent) => (
                    <TextLink size="medium" target="_blank" href={DbgPaths.MENOPAUSE}>
                      {linkContent}
                    </TextLink>
                  )
                }}
              />
            </p>
          </UnorderedListItem>
        )
      }
      if (lowTestosterone.hasEligibleExpenses) {
        elements.push(
          <UnorderedListItem>
            <p className="lh-copy">
              <FormattedMessage
                defaultMessage="<link>Low testosterone</link>"
                values={{
                  link: (linkContent) => (
                    <TextLink size="medium" target="_blank" href={DbgPaths.LOW_TESTOSTERONE}>
                      {linkContent}
                    </TextLink>
                  )
                }}
              />
            </p>
          </UnorderedListItem>
        )
      }
    }
    if (!coverage.gac && gacCare.hasEligibleExpenses) {
      elements.push(
        <UnorderedListItem>
          <p className="lh-copy">
            <FormattedMessage
              defaultMessage="<link>Gender-affirming care</link>"
              values={{
                link: (linkContent) => (
                  <TextLink size="medium" target="_blank" href={DbgPaths.GENDER_AFFIRMING_CARE}>
                    {linkContent}
                  </TextLink>
                )
              }}
            />
          </p>
        </UnorderedListItem>
      )
    }
  }
  return elements
}

export function getOtherExpensesSections(): CategorySections {
  return { header: <OtherExpensesHeader />, listItems: <OtherExpensesListItems />, includeSub: true }
}
