import React from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { Card, InternalIcon, Link, Typography } from "@carrotfertility/carotene-core"
import { Paths } from "#/utils/Paths"
import { Link as RouterLink } from "react-router-dom"

export const RefreshCarrotPlanModule = () => {
  const intl = useIntl()
  return (
    <Card
      id="home-sidebar-carrot-plan-module"
      slotProps={{ typography: { component: "h3" } }}
      title={intl.formatMessage({ defaultMessage: "Has anything changed?" })}
      primaryAction={
        <Link
          to={Paths.REFRESH_CARROT_PLAN}
          component={RouterLink}
          endIcon={<InternalIcon fontSize="small" color="primary" />}
        >
          <FormattedMessage defaultMessage="Get new suggestions" />
        </Link>
      }
    >
      <Typography>
        <FormattedMessage defaultMessage="Send us an update and we'll refresh your Carrot Plan with new suggestions." />
      </Typography>
    </Card>
  )
}
