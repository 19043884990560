import { Box, Stack, Typography, useTheme } from "@carrotfertility/carotene-core"
import { FormattedMessage, MessageDescriptor, useIntl } from "react-intl"
import React, { useEffect } from "react"
import {
  WhatKindOfJourneyHeaderText,
  JustForNowLiteText,
  JustForNowProText,
  AccessCareText,
  JustForNowLiteTextExperiment,
  JustForNowProTextExperiment
} from "../../carrot-plans/steps/journey/WhatKindOfJourneyStep"
import { RegistrationFlowLayout } from "./RegistrationLayouts"
import { SkipButton, ContinueButton, RegistrationButtonFooter } from "./RegistrationButtons"
import { getHeap, HeapBoolean, useTrackView } from "#/utils/heap"
import { Form } from "@carrotfertility/carotene-core-x"
import { useConvertTranslatedLabel } from "#/services/common-forms/useConvertTranslatedLabel"
import { RegistrationFlowTitle } from "./RegistrationFlowTitle"
import { FormRadioButtonGroup } from "#/services/common-forms"
import ConditionalSomethingElseTextField from "../../carrot-plans/shared/ConditionalSomethingElseTextField"
import { useFlags } from "launchdarkly-react-client-sdk"

interface WhatKindOfJourneyProps {
  journey: string | null
  setJourney: (journey: string) => void
  somethingElseJourney: string | null
  setSomethingElseJourney: (somethingElseJourney: string) => void
  journeyOptions: { label: MessageDescriptor; value: string; journeyMessage: MessageDescriptor }[]
  isCarrotLite: boolean
  skipped: boolean
  setSkipped: (skipped: boolean) => void
  onBack: () => void
  onComplete: () => void
}

function WhatKindOfJourneyInformationalText(isCarrotLite: boolean) {
  const intl = useIntl()
  const theme = useTheme()
  const { requireJourneySelectionInReg } = useFlags()

  let justForNowText = requireJourneySelectionInReg ? JustForNowProTextExperiment : JustForNowProText
  if (isCarrotLite) {
    justForNowText = requireJourneySelectionInReg ? JustForNowLiteTextExperiment : JustForNowLiteText
  }

  return (
    <Stack spacing={theme.spacing(theme.tokens.spacing.md)}>
      <Typography>{intl.formatMessage(justForNowText)}</Typography>
      <Typography>{intl.formatMessage(AccessCareText)}</Typography>
    </Stack>
  )
}

export function WhatKindOfJourney({
  setJourney,
  setSomethingElseJourney,
  journeyOptions,
  isCarrotLite,
  setSkipped,
  onBack,
  onComplete,
  journey,
  somethingElseJourney
}: WhatKindOfJourneyProps): JSX.Element {
  useTrackView("JourneySelectionRegFlow", "Registration journey selection screen")
  const theme = useTheme()
  const intl = useIntl()
  const radioOptions = useConvertTranslatedLabel(journeyOptions).map(({ value, label }) => ({ value, label }))
  const { requireJourneySelectionInReg } = useFlags()

  useEffect(() => {
    getHeap().track("What kind of Journey", {
      "Is journey selection in reg required": HeapBoolean.from(requireJourneySelectionInReg)
    })
  }, [requireJourneySelectionInReg])

  const onSubmit = (formValues: { journey?: any; somethingElseJourney?: any }) => {
    getHeap().track("JourneySelectionRegFlow", {
      "Is journey selection in reg required": HeapBoolean.from(requireJourneySelectionInReg)
    })
    if (formValues.journey?.length) {
      setJourney(formValues.journey)
      setSkipped(false)
    } else {
      setJourney(null)
      setSkipped(true)
    }
    setSomethingElseJourney(formValues.somethingElseJourney)
    onComplete()
  }

  const header = intl.formatMessage(WhatKindOfJourneyHeaderText)

  return (
    <>
      <RegistrationFlowTitle title={header} />
      <RegistrationFlowLayout
        header={<span id="what-journey-label">{intl.formatMessage(WhatKindOfJourneyHeaderText)}</span>}
        description={WhatKindOfJourneyInformationalText(isCarrotLite)}
        interactiveElements={
          <Form onSubmit={(formValues) => onSubmit(formValues)} defaultValues={{ journey, somethingElseJourney }}>
            <Box marginTop={(theme) => theme.spacing(theme.tokens.spacing.lg)}>
              <Stack spacing={theme.spacing(theme.tokens.spacing.sm)}>
                <FormRadioButtonGroup
                  name="journey"
                  options={radioOptions}
                  aria-labelledby="what-journey-label"
                  controllerProps={
                    requireJourneySelectionInReg
                      ? { rules: { required: intl.formatMessage({ defaultMessage: "Required" }) } }
                      : null
                  }
                />
                <ConditionalSomethingElseTextField />
              </Stack>
              <RegistrationButtonFooter
                onBack={onBack}
                skipButton={
                  requireJourneySelectionInReg ? null : (
                    <SkipButton onClick={() => onSubmit({})}>
                      <FormattedMessage defaultMessage="Skip for now" />
                    </SkipButton>
                  )
                }
                continueButton={
                  <ContinueButton>
                    <FormattedMessage defaultMessage="Continue" />
                  </ContinueButton>
                }
              />
            </Box>
          </Form>
        }
      />
    </>
  )
}
