import React from "react"
import { Box, Progress, Typography } from "@carrotfertility/carotene-core"
import { FormattedMessage } from "react-intl"

export const MerchantDeclineLoading = () => (
  <div>
    <div className="tc pt5">
      <Typography variant="h3">
        <FormattedMessage defaultMessage="Still loading, just a moment…" />
      </Typography>
    </div>

    <Box display="flex" justifyContent="center" alignItems="center" sx={{ blockSize: "100%" }}>
      <Progress />
    </Box>
  </div>
)
