import { useDidMount } from "#/utils/Hooks"

type WindowEvent = keyof WindowEventMap

const useWindowEvent = (eventName: WindowEvent, func: EventListener) =>
  useDidMount(() => {
    window.addEventListener(eventName, func)

    return () => {
      window.removeEventListener(eventName, func)
    }
  })

export { useWindowEvent }
