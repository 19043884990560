// eslint-disable-next-line no-restricted-imports
import { getCarrotUserId, getEmployeeId, getIsPosingAsMember, getPreferredLocale } from "#/redux/reducers/userInfo"
import {
  getCompanyId,
  getCountryCode,
  getIsExcludedFromMetrics,
  getParentCompanyId
} from "#/redux/reducers/companyInfo"
import { Middleware } from "redux"
import { DefaultRootState } from "react-redux"
import {
  addHeapUserProperties,
  HeapBoolean,
  HeapProperties,
  HeapUserPropertyName,
  setHeapPosingAsMember,
  unsetHeapPosingAsMember
} from "../../utils/heap"

const heapUserPropertySelectorsToWatch = {
  [HeapUserPropertyName.EmployeeId]: getEmployeeId,
  [HeapUserPropertyName.CarrotUserId]: getCarrotUserId,
  [HeapUserPropertyName.CountryCode]: getCountryCode,
  [HeapUserPropertyName.CompanyId]: getCompanyId,
  [HeapUserPropertyName.ParentCompanyId]: getParentCompanyId,
  [HeapUserPropertyName.CompanyIsExcludedFromMetrics]: (state: any) =>
    HeapBoolean.from(getIsExcludedFromMetrics(state)),
  [HeapUserPropertyName.PreferredLocale]: getPreferredLocale
} as const

const detectStateChanges = (previousState: DefaultRootState, nextState: DefaultRootState): HeapProperties => {
  const modifiedValues: HeapProperties = {}
  const heapPropertiesNamesToWatch = Object.keys(heapUserPropertySelectorsToWatch)

  for (const heapPropertyName of heapPropertiesNamesToWatch) {
    // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    const selector = heapUserPropertySelectorsToWatch[heapPropertyName]
    const previousValue = selector(previousState)
    const nextValue = selector(nextState)

    if (previousValue !== nextValue && nextValue !== null) {
      // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      modifiedValues[heapPropertyName] = nextValue
    }
  }

  return modifiedValues
}

const addChangedHeapUserProperties = (changedHeapProperties: HeapProperties): void => {
  const haveAnyValuesChanged = Object.keys(changedHeapProperties).length > 0

  if (haveAnyValuesChanged) {
    addHeapUserProperties(changedHeapProperties)
  }
}

const heapMiddleware: Middleware = (store) => (next) => (action) => {
  const previousState: DefaultRootState = store.getState()
  const result = next(action)
  const nextState: DefaultRootState = store.getState()

  const isPosingAsMember = getIsPosingAsMember(nextState)

  if (isPosingAsMember) {
    const employeeId = getEmployeeId(nextState)
    setHeapPosingAsMember(employeeId)
  } else {
    unsetHeapPosingAsMember()

    const changedHeapProperties = detectStateChanges(previousState, nextState)
    addChangedHeapUserProperties(changedHeapProperties)
  }

  return result
}

export { heapMiddleware }
