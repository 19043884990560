import { useQuery } from "@tanstack/react-query"
import buildCarrotApiRetryPolicy from "#/utils/CarrotApiRetryPolicy"
import ResponseError from "#/types/responseError"
import { reportErrorMessage } from "#/utils/ErrorReporting"
import { getScheduleAVideoChatOrCallDisplay } from "#/lib/contentful/contentfulClient"
import { EntryWithoutUnresolvableLinks } from "#/utils/ContentTypes"
import { TypeTileDisplaySkeleton } from "#/types/contentful"

const scheduleAVideoCallOrChatDisplay = "getScheduleAVideoChatOrCall"

export function useGetScheduleAVideoChatOrCallDisplay(locale: string) {
  return useQuery<EntryWithoutUnresolvableLinks<TypeTileDisplaySkeleton>, ResponseError>(
    [scheduleAVideoCallOrChatDisplay],
    () => getScheduleAVideoChatOrCallDisplay(locale),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      refetchOnReconnect: false,
      retry: buildCarrotApiRetryPolicy(3),
      onError: (error) => reportErrorMessage(`Failed to fetch video chat or call tile with error: ${error}`)
    }
  )
}
