import React, { FC, useContext } from "react"
import { useFormContext } from "react-hook-form"
import { Button, Typography, Box } from "@carrotfertility/carotene-core"
import { useEnrollmentFlowModal } from "../EnrollmentModal"
import { getHeap } from "../../../utils/heap"
import { useStateMachine } from "../../context/stateMachine/StateMachineContext"
import { SendMessageFormContext } from "../../talk-to-carrot/send-message/SendMessage"
import { SendMessageEmailInput, MessageTextArea } from "../../talk-to-carrot/send-message/SendMessageForm"
import { BenefitEnrollmentFlowModalBackButton } from "../shared"
import { Steps } from "../workflow/steps"
import { FormattedMessage, useIntl } from "react-intl"
import { MessageSent } from "./MessageSent"

interface TalkToCarrotStepProps {
  onExit: () => void
}
const ViewName = Steps.TALK_TO_CARROT

const TalkToCarrotStep: FC<TalkToCarrotStepProps> = () => {
  const { sent, onSubmit } = useContext(SendMessageFormContext)
  const {
    formState: { isValid, isSubmitting }
  } = useFormContext()
  const { onExit } = useEnrollmentFlowModal()
  const intl = useIntl()

  useStateMachine(ViewName, null)

  const sendSupportRequest = () => {
    onSubmit()
    getHeap().track("EnrollmentFlowSupportRequest", { EventName: "I'm not sure" })
  }

  return (
    <>
      {sent ? (
        <MessageSent close={true} onClick={() => onExit()} />
      ) : (
        <>
          <Box paddingBottom={(theme) => theme.spacing(theme.tokens.spacing.xl)}>
            <BenefitEnrollmentFlowModalBackButton />
          </Box>
          <Typography
            id="step-heading"
            variant="h2"
            color={(theme) => theme.palette.text.primary}
            paddingTop={(theme) => theme.tokens.spacing.md}
          >
            <FormattedMessage defaultMessage="It looks like you weren't sure how to answer the last question. That's okay." />
          </Typography>
          <Typography
            color={(theme) => theme.palette.text.secondary}
            paddingTop={(theme) => theme.tokens.spacing.md}
            paddingBottom={(theme) => theme.tokens.spacing.xxl}
          >
            <FormattedMessage defaultMessage="But we still need this information in order to reimburse you. Tell us what you weren’t sure about about or what we can help clarify. The more detail you can provide, the better." />
          </Typography>
          <SendMessageEmailInput showUpdateContactInfo={false} />
          <Box height={(theme) => theme.tokens.spacing.xl} />
          <MessageTextArea showPlaceholderText={false} rows="3" />
          <Box paddingBottom={(theme) => theme.spacing(theme.tokens.spacing.xl)} />
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Button variant="contained" onClick={sendSupportRequest} disabled={!isValid || isSubmitting}>
              {intl.formatMessage({ defaultMessage: "Send" })}
            </Button>
          </Box>
        </>
      )}
    </>
  )
}

export { TalkToCarrotStep }
