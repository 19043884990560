import React from "react"
import { FormattedMessage } from "react-intl"
import { Box, DialogContentText, DialogTitle, Stepper } from "@carrotfertility/carotene-core"
import { IlloCoinsAndBills } from "../../views/atoms/Illustrations"
import { useTextDirection } from "#/services/locale/hooks/useTextDirection"

export const UploadAdditionalDocumentsSuccess = () => {
  const textDirection = useTextDirection()
  return (
    <Box>
      <Stepper
        dir={textDirection}
        variant="progress"
        steps={4}
        activeStep={3}
        position="static"
        sx={{ padding: "8px 0" }}
      />
      <Box display="flex" justifyContent="center" paddingY={(theme) => theme.spacing(theme.tokens.spacing.lg)}>
        <IlloCoinsAndBills />
      </Box>
      <DialogTitle id="modal-title">
        <FormattedMessage defaultMessage="You're all set" />
      </DialogTitle>
      <DialogContentText id="modal-description">
        <FormattedMessage defaultMessage="We've received your updated request and will review it as soon as possible. We'll notify you when it's approved or if we need any additional information." />
      </DialogContentText>
    </Box>
  )
}
