import React, { useEffect, useState } from "react"
import { RegisterError } from "#/components/views/register/RegisterError"
import { NiceToSeeYou } from "#/components/views/register/NiceToSeeYou"
import { SetPassword } from "#/components/views/register/SetPassword"
import { AcknowledgeTerms } from "#/components/views/register/AcknowledgeTerms"
import { HealthConsent } from "#/components/views/register/HealthConsent"
import { EmailOptIn, getIsDefaultEmailOptIn } from "./EmailOptIn"
import { PersonalEmail } from "./PersonalEmail"
import { PreferredName } from "./PreferredName"
import { PersonalEmailVerification } from "./PersonalEmailVerification"
import { getHeap } from "#/utils/heap"
import { RegisteringLoadingIndicator } from "./RegistrationLoadingIndicator"
import { useLDClient } from "launchdarkly-react-client-sdk"
import { reportError } from "../../../utils/ErrorReporting"
import { WhatKindOfJourney } from "./WhatKindOfJourney"
import { useEnabledJourneyOptions } from "../../carrot-plans/hooks/useJourneyOptions"
import { useTrackExperimentEvent } from "./hooks/useExperiments"
import { useIntl } from "react-intl"
import { useLocaleContext } from "../../context/user/LocaleContext"
import queryString from "query-string"
import { RouteComponentProps, useLocation } from "react-router"
import { PhoneNumberCollection } from "./PhoneNumberCollection"
import { BenefitConfiguration } from "#/types/benefitConfiguration"
import { RegistrationComplete } from "./RegistrationComplete"
import { PregnancyMedicalRecordsCollection } from "./PregnancyMedicalRecordsCollection"
import { dayjs } from "@carrotfertility/carotene-core"
import { Locale, SupportedLocale } from "../../../types/Locale"
import { LaunchDarklyUser } from "../../../types/launchDarklyUser"
import { useGetIsExcludedFromMedicalRecordsConsent } from "../../../services/user/hooks/useGetIsExcludedFromMedicalRecordsConsent"
import { MemberActions } from "../../../services/memberActionTracking/memberActions"
import useUpdateRegisteringMemberActionTracking from "../../../services/memberActionTracking/useUpdateRegisteringMemberActionTracking"
import { Benefit } from "#/lib/carrot-api/types/Benefit"
import { carrotClient } from "#/utils/CarrotClient"
import { Paths } from "#/utils/Paths"
import { useHistory } from "react-router-dom"

interface RegisteringUser {
  firstName: string
  preferredName: string
  email: string
  companyDisplayName: string
  companyId: number
  parentCompanyId: number
  hasSaml: boolean
  benefitConfiguration: BenefitConfiguration
  benefit: Benefit
  isUsa: boolean
  countryCode: string
  ssoOnly: boolean
}

enum STEPS {
  LOADING,
  INTRO,
  SET_PASSWORD,
  PERSONAL_EMAIL,
  PREFERRED_NAME,
  ACKNOWLEDGE_TERMS,
  HEALTH_CONSENT,
  EMAIL_OPT_IN,
  ERROR,
  PERSONAL_EMAIL_VERIFICATION,
  WHAT_KIND_OF_JOURNEY,
  PHONE_NUMBER_COLLECTION,
  REGISTRATION_COMPLETED,
  PREGNANCY_MEDICAL_RECORDS_COLLECTION
}

export interface RegistrationJourney {
  journeyType: string
  somethingElseJourney: string
  skipped: boolean
}

interface MatchParams {
  guid: string
}

type Props = RouteComponentProps<MatchParams>

const RegisterWizard = (props: Props) => {
  const [step, setStep] = useState(STEPS.LOADING)
  const [firstName, setFirstName] = useState(null)
  const [guid, setGuid] = useState(null)
  const [email, setEmail] = useState(null)
  const [emailOptIn, setEmailOptIn] = useState(false)
  const [companyId, setCompanyId] = useState(0)
  const [parentCompanyId, setParentCompanyId] = useState(0)
  const [companyDisplayName, setCompanyDisplayName] = useState(null)
  const [isCarrotLite, setIsCarrotLite] = useState(null)
  const [password, setPassword] = useState(null)
  const [readPolicy, setReadPolicy] = useState(false)
  const [readTerms, setReadTerms] = useState(false)
  const [healthConsent, setHealthConsent] = useState(false)
  const [hasSaml, setHasSaml] = useState(false)
  const [ssoOnly, setSsoOnly] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [benefitConfiguration, setBenefitConfiguration] = useState(null)
  const [benefit, setBenefit] = useState(null)
  const [personalEmail, setPersonalEmail] = useState(null)
  const [preferredName, setPreferredName] = useState(null)
  const [isUsa, setIsUsa] = useState(null)
  const [countryCode, setCountryCode] = useState(null)
  const [journey, setJourney] = useState(null)
  const [somethingElseJourney, setSomethingElseJourney] = useState(null)
  const [skipped, setSkipped] = useState(null)
  const [phoneNumber, setPhoneNumber] = useState(null)
  const [phoneNumberCountryCode, setPhoneNumberCountryCode] = useState("US")
  const [dbFormattedPhoneNumber, setDbFormattedPhoneNumber] = useState(null)
  const [allowsMarketingTexts, setAllowsMarketingTexts] = useState(false)
  const ldClient = useLDClient()
  const { journeyOptions } = useEnabledJourneyOptions(benefit)
  const intl = useIntl()
  const { locale } = intl
  const { trackExperimentEvent } = useTrackExperimentEvent()
  const { setLocale } = useLocaleContext()
  const location = useLocation()
  const getIsExcludedFromMedicalRecordsConsent = useGetIsExcludedFromMedicalRecordsConsent()
  const { mutate: updateRegistrationMemberActions } = useUpdateRegisteringMemberActionTracking()
  const history = useHistory()

  const langFromParams = React.useMemo(() => {
    return queryString.parse(location.search).lang
  }, [location.search])

  const registrationJourney: RegistrationJourney = {
    journeyType: journey,
    somethingElseJourney: somethingElseJourney,
    skipped: skipped
  }

  useEffect(() => {
    if (typeof langFromParams === "string" && SupportedLocale.isSupportedLocale(langFromParams)) {
      setLocale(Locale.getLocaleFromString(langFromParams))
    }
  }, [setLocale, langFromParams])

  useEffect(() => {
    async function fetchRegisteringUser(): Promise<void> {
      await carrotClient.logout()
      const guidFromParams = props.match.params.guid
      setGuid(guidFromParams)
      const registeringUser: RegisteringUser = await carrotClient.getRegisteringUser(guidFromParams)
      if (!registeringUser) {
        setStep(STEPS.ERROR)
        return
      }
      if (!registeringUser.email) {
        history.push("/signup")
      } else {
        setEmail(registeringUser.email)
      }
      setFirstName(registeringUser.firstName)
      setCompanyDisplayName(registeringUser.companyDisplayName)
      setCompanyId(registeringUser.companyId)
      setParentCompanyId(registeringUser.parentCompanyId)
      setIsCarrotLite(registeringUser.benefit.program.isCarrotLite)
      setHasSaml(registeringUser.hasSaml)
      setSsoOnly(registeringUser.ssoOnly)
      setBenefitConfiguration(registeringUser.benefitConfiguration)
      setBenefit(registeringUser.benefit)
      setIsUsa(registeringUser.isUsa)
      setCountryCode(registeringUser.countryCode)
      setPreferredName(registeringUser.preferredName)
      setEmailOptIn(getIsDefaultEmailOptIn(registeringUser.countryCode))
      setStep(STEPS.INTRO)
      setPhoneNumberCountryCode(registeringUser.countryCode)
    }

    fetchRegisteringUser().catch((error) => reportError(error))
  }, [history, props.match.params.guid])

  useEffect(() => {
    async function loadLDFlagsForRegisteringUser(
      memberEmail: string,
      memberCompanyId: number,
      memberParentCompanyId?: number,
      companyCountryCode?: string
    ): Promise<void> {
      if (ldClient && memberEmail && memberCompanyId) {
        await ldClient.waitUntilReady()

        const ldUser: LaunchDarklyUser = {
          key: memberEmail,
          email: memberEmail,
          custom: {
            companyId: memberCompanyId.toString(),
            countryCode: companyCountryCode,
            isPartner: false,
            isEnrolled: false,
            isEngaged: false
          }
        }

        if (memberParentCompanyId && memberParentCompanyId !== 0) {
          ldUser.custom["parentCompanyId"] = memberParentCompanyId?.toString()
        }

        await ldClient.identify(ldUser)
      }
    }

    loadLDFlagsForRegisteringUser(email, companyId, parentCompanyId, countryCode).catch((error) => reportError(error))
  }, [ldClient, email, companyId, parentCompanyId, countryCode])

  async function createUser(): Promise<void> {
    if (!isSubmitting) {
      try {
        setIsSubmitting(true)

        await carrotClient.createAccount({
          guid,
          password,
          emailOptIn,
          agreeToTerms: readTerms,
          agreeToPolicy: readPolicy,
          agreeToHealthData: healthConsent,
          personalEmail,
          preferredName,
          registrationJourney,
          phoneNumber: dbFormattedPhoneNumber,
          phoneNumberCountryCode,
          allowsMarketingTexts,
          localTime: dayjs().format("YYYY-MM-DD HH:mm:ss.SSS"),
          // eslint-disable-next-line no-restricted-syntax -- We include the user's preferredLocale in the request to create their account. Carrot-app's LocaleService will then determine if they can use the selected language once authenticated.
          preferredLocale: locale
        })
      } catch {
        window.location.href = Paths.HOME
      }
      await trackExperimentEvent("registration-complete")
      getHeap().track("MemberRegistrationCompleted", {
        EventName: "MemberRegistrationCompleted"
      })
      if (isUsa && journey === "PREGNANT" && !getIsExcludedFromMedicalRecordsConsent({ companyId, parentCompanyId })) {
        setStep(STEPS.PREGNANCY_MEDICAL_RECORDS_COLLECTION)
      } else {
        await setPersonalEmailVerificationOrRegistrationComplete()
      }
    }
  }

  async function sendVerificationEmailAndSetStep(): Promise<void> {
    try {
      setIsSubmitting(true)
      await carrotClient.sendPersonalEmailVerificationEmail()
    } catch {
      window.location.href = Paths.HOME
    }
    setStep(STEPS.PERSONAL_EMAIL_VERIFICATION)
  }

  function onCompleteSetPassword(password: string): void {
    setPassword(password)
    setStep(STEPS.PERSONAL_EMAIL)
  }

  function onSkipNullOutPersonalEmail(): void {
    setPersonalEmail(null)
    setStepAfterEmail()
  }

  async function onCompleteIntro(): Promise<void> {
    await trackExperimentEvent("registration-welcome-continue")
    updateRegistrationMemberActions({
      registrationGuid: guid,
      memberActionTaken: MemberActions.REGISTRATION_WELCOME_CONTINUE
    })
    setFirstAccountCreationStep()
  }

  function setFirstAccountCreationStep(): void {
    setStep(hasSaml && ssoOnly ? STEPS.PERSONAL_EMAIL : STEPS.SET_PASSWORD)
  }

  function setStepAfterEmail(): void {
    !preferredName ? setStep(STEPS.PREFERRED_NAME) : setStep(STEPS.PHONE_NUMBER_COLLECTION)
  }

  async function setPersonalEmailVerificationOrRegistrationComplete(): Promise<void> {
    if (!personalEmail) {
      setStep(STEPS.REGISTRATION_COMPLETED)
    } else {
      await sendVerificationEmailAndSetStep()
    }
  }

  switch (step) {
    case STEPS.INTRO:
      return <NiceToSeeYou companyDisplayName={companyDisplayName} onComplete={onCompleteIntro} benefit={benefit} />
    case STEPS.SET_PASSWORD:
      return <SetPassword email={email} onBack={() => setStep(STEPS.INTRO)} onComplete={onCompleteSetPassword} />
    case STEPS.PERSONAL_EMAIL:
      return (
        <PersonalEmail
          personalEmail={personalEmail || ""}
          email={email}
          setPersonalEmail={setPersonalEmail}
          onBack={() => (!hasSaml || !ssoOnly ? setStep(STEPS.SET_PASSWORD) : setStep(STEPS.INTRO))}
          onComplete={setStepAfterEmail}
          onSkip={onSkipNullOutPersonalEmail}
        />
      )
    case STEPS.PREFERRED_NAME:
      return (
        <PreferredName
          {...{ setPreferredName, preferredName }}
          onBack={() => setStep(STEPS.PERSONAL_EMAIL)}
          onComplete={() => setStep(STEPS.PHONE_NUMBER_COLLECTION)}
        />
      )
    case STEPS.PHONE_NUMBER_COLLECTION:
      return (
        <PhoneNumberCollection
          {...{
            setPhoneNumber,
            phoneNumber,
            setPhoneNumberCountryCode,
            phoneNumberCountryCode,
            setAllowsMarketingTexts,
            setDbFormattedPhoneNumber,
            allowsMarketingTexts,
            isUsa
          }}
          onBack={() => setStep(STEPS.PREFERRED_NAME)}
          onComplete={() => setStep(STEPS.WHAT_KIND_OF_JOURNEY)}
        />
      )
    case STEPS.ACKNOWLEDGE_TERMS:
      return (
        <AcknowledgeTerms
          {...{ firstName, preferredName, readPolicy, setReadPolicy, readTerms, setReadTerms }}
          onBack={() => setStep(STEPS.WHAT_KIND_OF_JOURNEY)}
          onComplete={() => setStep(STEPS.HEALTH_CONSENT)}
        />
      )
    case STEPS.HEALTH_CONSENT:
      return (
        <HealthConsent
          isSubmitting={isSubmitting}
          healthConsent={healthConsent}
          setHealthConsent={setHealthConsent}
          benefitConfiguration={benefitConfiguration}
          onBack={() => setStep(STEPS.ACKNOWLEDGE_TERMS)}
          onComplete={getIsDefaultEmailOptIn(countryCode) ? createUser : () => setStep(STEPS.EMAIL_OPT_IN)}
        />
      )
    case STEPS.EMAIL_OPT_IN:
      return (
        <EmailOptIn
          isSubmitting={isSubmitting}
          emailOptIn={emailOptIn}
          setEmailOptIn={setEmailOptIn}
          onBack={() => setStep(STEPS.HEALTH_CONSENT)}
          onComplete={createUser}
        />
      )
    case STEPS.PERSONAL_EMAIL_VERIFICATION:
      return (
        <PersonalEmailVerification
          personalEmail={personalEmail}
          onComplete={() => setStep(STEPS.REGISTRATION_COMPLETED)}
        />
      )
    case STEPS.WHAT_KIND_OF_JOURNEY:
      return (
        <WhatKindOfJourney
          journey={journey}
          setJourney={setJourney}
          somethingElseJourney={somethingElseJourney}
          setSomethingElseJourney={setSomethingElseJourney}
          skipped={skipped}
          setSkipped={setSkipped}
          journeyOptions={journeyOptions}
          isCarrotLite={isCarrotLite}
          onBack={() => setStep(STEPS.PHONE_NUMBER_COLLECTION)}
          onComplete={() => setStep(STEPS.ACKNOWLEDGE_TERMS)}
        />
      )
    case STEPS.PREGNANCY_MEDICAL_RECORDS_COLLECTION:
      return <PregnancyMedicalRecordsCollection onComplete={setPersonalEmailVerificationOrRegistrationComplete} />
    case STEPS.REGISTRATION_COMPLETED:
      return <RegistrationComplete />
    case STEPS.ERROR:
      return <RegisterError />
    default:
      return <RegisteringLoadingIndicator />
  }
}

export default RegisterWizard
