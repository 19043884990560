import React from "react"
import Dropzone from "react-dropzone"
import { Box, Button, Typography } from "@carrotfertility/carotene-core"
import { FlexRowCenter, Spacer } from "#/components/views/atoms/Atoms"
import { BodyLarge, BodySmall } from "#/components/views/atoms/Bodies"
import { CircleImage } from "#/components/views/atoms/images/CircleImage"
import { AcceptableFileExtensions } from "#/components/views/reimbursements/upload/AcceptableFileExtensions"
import { FormattedMessage } from "react-intl"
import Settings from "../../../../utils/CarrotConfig"

// @ts-expect-error TS(7031) FIXME: Binding element 'dragging' implicitly has an 'any'... Remove this comment to see the full error message
const CloudImage = ({ dragging, small }) => (
  <CircleImage
    size={small ? 54 : 96}
    padding={small ? 12 : 18}
    className="animate-all-slow"
    backgroundColor={dragging ? "coral" : "cornflower-blue"}
    iconName="icn-cloud-upload"
  />
)

const FileSizeLimitLabel = () => {
  const maxFileSize = Settings.MAX_FILE_SIZE / 1000000
  return (
    <div style={{ maxWidth: 309 }}>
      <Spacer height={0.25} />
      <BodySmall color="black-60">
        <FormattedMessage
          defaultMessage="Upload jpg, jpeg, png, pdf, HEIC, doc, docx, txt, xlsx, and zip files. The maximum file size we accept is {maxFileSize} MB."
          values={{ maxFileSize }}
        />
      </BodySmall>
    </div>
  )
}

// @ts-expect-error TS(7031) FIXME: Binding element 'isDragActive' implicitly has an '... Remove this comment to see the full error message
const DropzoneIllustration = ({ isDragActive, small }) => (
  <div className="flex flex-column justify-center items-center h-100">
    <FlexRowCenter>
      <CloudImage dragging={isDragActive} small={small} />
    </FlexRowCenter>
    <Spacer height={1} />
    <BodyLarge>
      <span className="dn db-ns">
        {isDragActive ? (
          <FormattedMessage defaultMessage="Drop files to upload" />
        ) : (
          <FormattedMessage defaultMessage="Drag files here or click to browse" />
        )}
      </span>
      <span className="dn-ns db">
        <FormattedMessage defaultMessage="Tap to browse files" />
      </span>
    </BodyLarge>
    {!isDragActive ? <FileSizeLimitLabel /> : <Spacer height={1.3} />}
  </div>
)

// @ts-expect-error TS(7031) FIXME: Binding element 'isDragActive' implicitly has an '... Remove this comment to see the full error message
const NewDropzoneIllustration = ({ isDragActive }) => (
  <Box
    sx={{
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      paddingBlockStart: isDragActive ? (theme) => theme.tokens.spacing.xl : 0,
      paddingBlockEnd: isDragActive ? (theme) => theme.tokens.spacing.xxxl : 0,
      gap: (theme) => theme.spacing(theme.tokens.spacing.md)
    }}
  >
    {isDragActive ? (
      <Typography variant="h5" component="p">
        <FormattedMessage defaultMessage="Drop files to upload" />
      </Typography>
    ) : (
      <>
        <Typography variant="h5" component="p">
          <FormattedMessage defaultMessage="Drag and drop files here" />
        </Typography>
        <Typography variant="body2">
          <FormattedMessage defaultMessage="or" />
        </Typography>
        <Button
          sx={{ inlineSize: "fit-content", alignSelf: "center" }}
          variant="outlined"
          size="medium"
          color="secondary"
        >
          <FormattedMessage defaultMessage="Add files" />
        </Button>
      </>
    )}
  </Box>
)

// @ts-expect-error TS(7031) FIXME: Binding element 'small' implicitly has an 'any' ty... Remove this comment to see the full error message
const UploadDropzoneWithIllustration = ({ small, onDropAccepted, onDropRejected, showNew = false }) => {
  return (
    <Dropzone
      multiple
      onDropAccepted={onDropAccepted}
      onDropRejected={onDropRejected}
      accept={AcceptableFileExtensions}
    >
      {({ getRootProps, getInputProps, isDragActive }) => (
        <div
          className={`
          bg-light-gray w-100 br3 pointer no-outline tc lh-solid ph3 animate-all-slow ${small ? "pv35" : "pv5"} ${
            isDragActive ? "ba bw1 b--carrot-orange" : "ba bw1 b--moon-gray"
          }`}
          {...getRootProps()}
        >
          {isDragActive ? <div className="fullscreen-dropzone-overlay" /> : ""}

          <input id="upload-files-input" data-testid="upload-files-input-test" {...getInputProps()} />

          {showNew ? (
            <NewDropzoneIllustration isDragActive={isDragActive} />
          ) : (
            <DropzoneIllustration isDragActive={isDragActive} small={small} />
          )}
        </div>
      )}
    </Dropzone>
  )
}

export { UploadDropzoneWithIllustration }
