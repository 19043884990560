import React from "react"
import { connect } from "react-redux"
import Root from "#/components/views/Root"
import { getIsLoggedIn, getLoadingIsLoggedIn } from "#/redux/reducers/application"
import { fetchReduxData } from "#/redux/actions/loginActions"
import { ScrollToTop } from "../views/ScrollToTop"

class InternalRootContainer extends React.Component {
  componentDidMount() {
    // @ts-expect-error TS(2339) FIXME: Property 'fetchReduxData' does not exist on type '... Remove this comment to see the full error message
    this.props.fetchReduxData()
  }

  render() {
    return (
      <ScrollToTop>
        {/* @ts-expect-error TS(2322) FIXME: Type '{ loggedIn: any; loadingIsLoggedIn: any; }' ... Remove this comment to see the full error message */}
        <Root loggedIn={this.props.isLoggedIn} loadingIsLoggedIn={this.props.loadingIsLoggedIn} />
      </ScrollToTop>
    )
  }
}

// @ts-expect-error TS(7006) FIXME: Parameter 'state' implicitly has an 'any' type.
const mapStateToProps = (state) => ({
  isLoggedIn: getIsLoggedIn(state),
  loadingIsLoggedIn: getLoadingIsLoggedIn(state)
})

const RootContainer = connect(mapStateToProps, {
  fetchReduxData
})(InternalRootContainer)

export default RootContainer
