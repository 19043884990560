/* eslint formatjs/no-literal-string-in-jsx: off -- translation planned for Q1 2024. See https://carrotfertility.atlassian.net/wiki/spaces/PROD/pages/2190215916/WS+Support+E2E+Translation */

import React, { useEffect, useState } from "react"
import { Text, Container, FlexContainer, SelectOptionType } from "@carrotfertility/carotene"
import useGeneratePaymentIntent from "../hooks/useGeneratePaymentIntent"
import { getDefaultCurrency, formatCurrencies } from "../utils/currency-helper"
import CurrencyWarning from "./InboundMemberPaymentsCurrencyWarning"
import { useHistory } from "react-router"
import { useStripeContext } from "#/components/context/stripePayments/StripePaymentsContext"
import { Autocomplete, Box, Button, Progress, TextField } from "@carrotfertility/carotene-core"
import { Controller, useForm } from "react-hook-form"
import { PaymentIntentArgs } from "../types/PaymentIntentArgs"
import redX from "../resources/images/red-x.svg"

const InboundMemberPaymentsForm = (): JSX.Element => {
  const { isLoading: paymentIntentsLoading, mutate } = useGeneratePaymentIntent()
  const {
    companyId,
    employeeId,
    email,
    companyCurrencyCode,
    currencyCodesToAllowedDecimalPlacesMap,
    selectedCurrencyCode,
    setSelectedCurrencyCode,
    amount,
    setAmount
  } = useStripeContext()
  const history = useHistory()
  const [formattedCurrencies, setFormattedCurrencies] = useState<SelectOptionType[]>()
  const [amountPlaceholder, setAmountPlaceholder] = useState<string>()
  const [errorMessage, setErrorMessage] = useState<string | null>(null)

  //Sets the default currency to the company's currency
  useEffect(() => {
    const currencyCodes = Object.keys(currencyCodesToAllowedDecimalPlacesMap)
    setFormattedCurrencies(formatCurrencies(currencyCodes))
    if (!selectedCurrencyCode) {
      const defaultCurrency = getDefaultCurrency(currencyCodes, companyCurrencyCode)
      setSelectedCurrencyCode(defaultCurrency)
    }
  }, [currencyCodesToAllowedDecimalPlacesMap, companyCurrencyCode, setSelectedCurrencyCode, selectedCurrencyCode])

  //Sets the amount placeholder to the correct number of digits
  useEffect((): void => {
    if (!selectedCurrencyCode) {
      return
    }
    const digits = currencyCodesToAllowedDecimalPlacesMap[selectedCurrencyCode]
    //Maybe overly complicated, but this will work with 3 digit currencies too.
    setAmountPlaceholder(digits === 0 ? "0" : `0.${"0".repeat(digits)}`)
  }, [currencyCodesToAllowedDecimalPlacesMap, selectedCurrencyCode])

  const onSubmit = (data: any): void => {
    const paymentIntentArgs: PaymentIntentArgs = {
      employee_id: employeeId,
      company_id: companyId,
      email: email,
      currency_code: data.currency_code?.value ?? selectedCurrencyCode,
      amount: data.amount
    }

    mutate(
      { ...paymentIntentArgs },
      {
        onSuccess: (data) => {
          history.push({
            pathname: `/make-a-payment/checkout`,
            state: {
              clientSecret: data?.client_secret,
              paymentIntentAmount: data?.amount,
              paymentIntentCurrencyCode: data?.currency_code
            }
          })
        },
        onError: (error) => {
          if (error.code === "parameter_invalid_integer" || error.code === "amount_too_small") {
            setErrorMessage("Amount must be or convert to at least $0.50 USD.")
          } else {
            error.errorMessage
              ? setErrorMessage(error.errorMessage)
              : setErrorMessage("We encountered an issue. Please refresh and try again.")
          }
        }
      }
    )
  }

  const formatCurrencyInputFromCurrencyCode = (currencyInputText: any, currencyCode: any): string => {
    if (currencyInputText == null) return ""
    // eslint-disable-next-line prefer-const
    let [integer, decimals] = currencyInputText.toString().split(".")
    decimals = !decimals ? 0 : decimals

    let formattedCurrencyValue = currencyInputText
    const digits = currencyCodesToAllowedDecimalPlacesMap[currencyCode]
    if (decimals?.length >= digits) {
      formattedCurrencyValue = integer + (digits > 0 ? "." + decimals.substring(0, digits) : "")
    }
    setAmount(formattedCurrencyValue)
  }

  const { handleSubmit, control } = useForm({ defaultValues: { currency_code: selectedCurrencyCode, amount: amount } })

  if (formattedCurrencies && !paymentIntentsLoading) {
    return (
      <>
        <Container stack="medium">
          <Text> Enter the currency and amount found in your email from Carrot. </Text>
        </Container>
        <Container width="330px">
          <form noValidate onSubmit={handleSubmit(onSubmit)}>
            <Controller
              control={control}
              name="currency_code"
              render={({ field: { onChange } }) => (
                <Autocomplete
                  onChange={(_, value: SelectOptionType) => {
                    const newCurrencyCode = value?.value.toString()
                    setSelectedCurrencyCode(newCurrencyCode)
                    formatCurrencyInputFromCurrencyCode(amount, newCurrencyCode)
                    onChange(value)
                  }}
                  data-testid="about-you-internationalCountry"
                  defaultValue={formattedCurrencies?.find((currency) => currency.value === selectedCurrencyCode)}
                  options={formattedCurrencies}
                  renderInput={(params) => <TextField {...params} label="Currency Code" required />}
                  sx={{ marginBlockEnd: "25px" }}
                />
              )}
            />
            <CurrencyWarning companyCurrencyCode={companyCurrencyCode} selectedCurrencyCode={selectedCurrencyCode} />
            <Controller
              control={control}
              name="amount"
              render={({ field: { onChange } }) => (
                <TextField
                  name="amount"
                  type="number"
                  label="Amount"
                  placeholder={amountPlaceholder}
                  required
                  value={amount}
                  onChange={(event) => {
                    formatCurrencyInputFromCurrencyCode(event.target.value, selectedCurrencyCode)
                    onChange(event)
                  }}
                />
              )}
            />
            {errorMessage ? (
              <Text sx={{ paddingBlockStart: "0px", marginBlockEnd: "10px" }} variant="error">
                <img src={redX} alt="redX" />
                {" " + errorMessage}
              </Text>
            ) : null}
            <FlexContainer sx={{ paddingBlockStart: "15px" }}>
              <Button type="submit">{"Continue"}</Button>
            </FlexContainer>
          </form>
        </Container>
      </>
    )
  } else {
    return (
      <Box display="flex" justifyContent="center" alignItems="center">
        <Progress />
      </Box>
    )
  }
}

export default InboundMemberPaymentsForm
