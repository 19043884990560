import React from "react"
import { useHistory } from "react-router-dom"
import { Spacer } from "#/components/views/atoms/Atoms"
import { RoundedRectangle } from "#/components/views/atoms/roundedrectangle/RoundedRectangle"
import { CircleImage } from "#/components/views/atoms/images/CircleImage"
import { BodySmall } from "#/components/views/atoms/Bodies"
import { FormattedMessage } from "react-intl"
import { Box, Button, Typography } from "@carrotfertility/carotene-core"

const NeedsMemberUploadsModule = ({ hasMultipleExpensesNeedingUploads }: NeedsMemberUploadsModuleProps) => {
  const history = useHistory()

  return (
    <RoundedRectangle id="home-sidebar-needs-member-upload-module" backgroundColor="mint-alternate">
      <CircleImage iconName="icn-documents" backgroundColor="white" />
      <Spacer height={0.5} />
      <Typography variant="h6">
        <FormattedMessage defaultMessage="Expense update" />
      </Typography>
      <Spacer height={0.5} />
      <BodySmall color="black">
        {hasMultipleExpensesNeedingUploads ? (
          <FormattedMessage defaultMessage="We need additional documents to process your expenses." />
        ) : (
          <FormattedMessage defaultMessage="We need additional documents to process your expense." />
        )}
      </BodySmall>
      <Box marginTop={(theme) => theme.spacing(theme.tokens.spacing.lg)}>
        <Button variant="contained" color="secondary" onClick={() => history.push("/messages")}>
          {hasMultipleExpensesNeedingUploads ? (
            <FormattedMessage defaultMessage="View messages" />
          ) : (
            <FormattedMessage defaultMessage="View message" />
          )}
        </Button>
      </Box>
    </RoundedRectangle>
  )
}

type NeedsMemberUploadsModuleProps = {
  hasMultipleExpensesNeedingUploads: boolean
}

export { NeedsMemberUploadsModule }
