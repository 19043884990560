import React, { ReactElement } from "react"
import { useTrackView } from "../../../utils/heap"
import { FormattedMessage, useIntl } from "react-intl"
import { RegistrationFlowLayout } from "./RegistrationLayouts"
import { Box, HideIcon, IconButton, InputAdornment, ShowIcon, TextField } from "@carrotfertility/carotene-core"
import { RegistrationButtonFooter, ContinueButton } from "./RegistrationButtons"
import { Form, FormTextField, useFormContext } from "@carrotfertility/carotene-core-x"
import { RegistrationFlowTitle } from "./RegistrationFlowTitle"
import { validatePassword } from "#/services/common-forms"
import { CarrotErrorCodes, getErrorMessageFromCode } from "../../../utils/CarrotErrors"

interface SetPasswordProps {
  email?: string
  onBack: () => void
  onComplete: (password: string) => void
}

function SetPasswordContinueButton(): ReactElement {
  const {
    formState: { isValid, isDirty }
  } = useFormContext()

  return (
    <ContinueButton disabled={!isValid || !isDirty}>
      <FormattedMessage defaultMessage="Continue" />
    </ContinueButton>
  )
}

export function SetPassword({ email, onBack, onComplete }: SetPasswordProps): ReactElement {
  useTrackView("SetPasswordRegFlow", "Set password screen")
  const intl = useIntl()
  const [showPassword, setShowPassword] = React.useState(false)

  const onSubmit = (formData: { password: string }) => {
    onComplete(formData.password)
  }

  const isPasswordValid = async (password: string) => {
    if (!password) return true

    const isInValidMessage = await validatePassword(password)
    if (isInValidMessage) {
      return intl.formatMessage(isInValidMessage)
    }
    return true
  }

  const header = intl.formatMessage({ defaultMessage: "Create a password" })

  return (
    <>
      <RegistrationFlowTitle title={header} />
      <RegistrationFlowLayout
        header={header}
        description={
          <Box
            marginTop={(theme) => theme.spacing(theme.tokens.spacing.md)}
            marginBottom={(theme) => theme.spacing(theme.tokens.spacing.lg)}
          >
            <TextField label={<FormattedMessage defaultMessage="account email" />} defaultValue={email} disabled />
          </Box>
        }
        interactiveElements={
          <Form onSubmit={onSubmit} mode="onChange">
            <FormTextField
              name="password"
              data-testid="set-password-input"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label={intl.formatMessage({ defaultMessage: "toggle password visibility" })}
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end"
                    >
                      {showPassword ? <HideIcon /> : <ShowIcon />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
              registerOptions={{
                required: true,
                validate: isPasswordValid,
                minLength: {
                  message: intl.formatMessage(getErrorMessageFromCode(CarrotErrorCodes.PASSWORD_MIN_LENGTH)),
                  value: 10
                },
                maxLength: {
                  message: intl.formatMessage(getErrorMessageFromCode(CarrotErrorCodes.PASSWORD_MAX_LENGTH)),
                  value: 64
                }
              }}
              label={intl.formatMessage({ defaultMessage: "Password" })}
              type={showPassword ? "text" : "password"}
            />
            <RegistrationButtonFooter onBack={onBack} continueButton={<SetPasswordContinueButton />} />
          </Form>
        }
      />
    </>
  )
}
