import React, { ReactElement } from "react"
import { useSignUp } from "./context/SignUpContext"
import { AppAccessPageLayout } from "#/components/access-page/AppAccessPageLayout"
import { Typography, Stack, Divider, useTheme, Box, Alert, Link } from "@carrotfertility/carotene-core"
import { AppAccessPageCard, AppAccessPageCardFooter } from "#/components/access-page/AppAccessPageCard"
import { UnauthPreferredLocale } from "#/components/views/UnauthPreferredLocale"
import { emailValidation } from "#/utils/Regexes"
import { useIntlValidator } from "#/utils/hooks/useIntlValidator"
import { FormattedMessage, useIntl } from "react-intl"
import { getErrorMessageFromCode } from "../../utils/CarrotErrors"
import { Form, FormButton, FormTextField } from "@carrotfertility/carotene-core-x"
import { EmployeeSupportUrl } from "#/utils/EmployeeSupportLink"
import { usePrivacyNoticeUrl } from "#/utils/hooks/legal-notice-urls"
import { useFlags } from "launchdarkly-react-client-sdk"
import ConditionalSiteMaintenanceAlert from "../site-maintenance/ConditionalSiteMaintenanceAlert"

const EmailInput = ({ enabled, email }: { enabled: boolean; email: string }): ReactElement => {
  const intlEmailValidation = useIntlValidator(emailValidation)
  const intl = useIntl()

  return (
    <FormTextField
      name="email"
      disabled={!enabled}
      inputProps={{ readOnly: !enabled, disabled: false }}
      registerOptions={{
        required: intl.formatMessage({ defaultMessage: "Required" }),
        validate: intlEmailValidation
      }}
      defaultValue={email}
      id="email"
      label={intl.formatMessage({ defaultMessage: "Email" })}
      type="email"
    />
  )
}

const PrivacyDisclaimer = (): ReactElement => {
  const privacyNoticeUrl = usePrivacyNoticeUrl()
  const { testPrivacyDisclaimerOnSignupPage } = useFlags()

  return testPrivacyDisclaimerOnSignupPage ? (
    <Typography fontStyle="italic" variant="body2" align="center">
      <FormattedMessage
        defaultMessage="Your privacy matters. Carrot does not share details about your care with your benefit sponsor. Learn more about the data we share for limited purposes, such as payroll and taxes, in our <link>Privacy Policy.</link>"
        values={{
          link: (linkText) => (
            <Link href={privacyNoticeUrl} target="_blank" variant="body2" color="inherit">
              {linkText}
            </Link>
          )
        }}
      />
    </Typography>
  ) : null
}

export const SignUpFooter = (): ReactElement => {
  const intl = useIntl()
  return (
    <AppAccessPageCardFooter
      links={[
        {
          id: "access-page-sign-in-link",
          to: "/",
          linkText: intl.formatMessage({ defaultMessage: "Already have an account? Sign in" })
        },
        {
          id: "access-page-offer-carrot-learn-more-link",
          to: "https://www.get-carrot.com/landing-pages/for-employees-request-fertility-benefits-at-your-company?utm_medium=app&utm_source=cf&utm_campaign=dg",
          linkText: intl.formatMessage({ defaultMessage: "Request Carrot at your company" }),
          external: true
        }
      ]}
    />
  )
}

const SignUpPage = (): ReactElement => {
  const { shake, inputsEnabled, showSubmittingLabel, username, onSubmit, errorMessage } = useSignUp()
  const theme = useTheme()
  const intl = useIntl()

  // @ts-expect-error TS(7006) FIXME: Parameter 'errorCode' implicitly has an 'any' type... Remove this comment to see the full error message
  const getTranslatedErrorMessage = (errorCode) => {
    if (errorCode === "MEMBER_INACTIVE") {
      return intl.formatMessage(getErrorMessageFromCode(errorCode), {
        link: (string) => (
          <Link color="inherit" href={EmployeeSupportUrl} target={"_blank"}>
            {string}
          </Link>
        )
      })
    }
    return intl.formatMessage(getErrorMessageFromCode(errorCode))
  }

  return (
    <AppAccessPageLayout title={intl.formatMessage({ defaultMessage: "Sign up" })}>
      <AppAccessPageCard shake={shake}>
        <Box marginBottom={(theme) => theme.spacing(theme.tokens.spacing.md)}>
          {errorMessage ? (
            <Alert severity="error">{getTranslatedErrorMessage(errorMessage)}</Alert>
          ) : (
            <Box textAlign="center">
              <Typography variant="h1">
                <FormattedMessage defaultMessage="Sign up for Carrot" />
              </Typography>
              <Typography>
                <FormattedMessage defaultMessage="Enter your company email address or the address your company uses for you." />
              </Typography>
            </Box>
          )}
        </Box>

        <ConditionalSiteMaintenanceAlert />

        <Form reValidateMode="onSubmit" onSubmit={onSubmit}>
          <Stack divider={<Divider />} spacing={theme.spacing(theme.tokens.spacing.lg)}>
            <Stack
              spacing={theme.spacing(theme.tokens.spacing.lg)}
              display="flex"
              alignItems="center"
              marginBottom={theme.spacing(theme.tokens.spacing.xl)}
            >
              <EmailInput enabled={inputsEnabled} email={username} />
              <PrivacyDisclaimer />
              <Box display="block">
                <FormButton type="submit" disabled={showSubmittingLabel}>
                  <FormattedMessage defaultMessage="Continue" />
                </FormButton>
              </Box>
              <Stack spacing={theme.spacing(theme.tokens.spacing.lg)} display="block">
                <UnauthPreferredLocale />
              </Stack>
            </Stack>
            <SignUpFooter />
          </Stack>
        </Form>
      </AppAccessPageCard>
    </AppAccessPageLayout>
  )
}

export default SignUpPage
