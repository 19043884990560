import React from "react"
import { useRequestCarrotCard } from "../request-carrot-card"
import { Form, FormCheckbox } from "@carrotfertility/carotene-core-x"
import { AirwallexUserAgreementTemplate, attemptRequest, IssuingRegion } from "../agreement-helpers"
import { FormattedMessage } from "react-intl"
import { Link } from "@carrotfertility/carotene-core"
import { useStateMachine } from "#/components/context/stateMachine/StateMachineV2"

export function AirwallexSingaporeUserAgreement() {
  const { send } = useStateMachine()
  const requestCardMutation = useRequestCarrotCard({
    onSuccess: () => send("")
  })

  return (
    <Form
      onSubmit={() => attemptRequest(requestCardMutation.mutateAsync)}
      defaultValues={{ airwallexUserAgreement: false }}
    >
      <AirwallexUserAgreementTemplate
        data-testid={`issuing-region-${IssuingRegion.Singapore}`}
        disableContinue={["airwallexUserAgreement"]}
        userAgreementChecks={[
          <FormCheckbox
            name={"airwallexUserAgreement"}
            key="airwallex-user-agreement"
            label={
              <FormattedMessage
                values={{
                  spendManagementTermsLink: (linkContent) => (
                    <Link
                      href="https://www.airwallex.com/sg/terms/cards-and-spend-management-terms"
                      target="_blank"
                      color="inherit"
                    >
                      {linkContent}
                    </Link>
                  )
                }}
                defaultMessage="I consent to the <spendManagementTermsLink>cards and spend management terms</spendManagementTermsLink>."
              />
            }
            required
          />
        ]}
        isError={requestCardMutation.isError}
      />
    </Form>
  )
}
