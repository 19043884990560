import { combineReducers } from "redux"
import actionTypes from "#/redux/actions/actionTypes"
import { createReducer } from "./shared"
import { RootState } from "."
import TestUserActionTypes from "#/redux/actions/testUserActionTypes"

const reducers = {
  isLoggedIn: createReducer<boolean>(false, {
    [actionTypes.FETCH_SIGN_OUT_SUCCESS]: () => false,
    [actionTypes.SIGN_IN]: () => true,
    [actionTypes.SESSION_EXPIRED]: () => false,
    [actionTypes.FETCH_IS_LOGGED_IN_SUCCESS]: () => true,
    [actionTypes.FETCH_IS_LOGGED_IN_FAILURE]: () => false
  }),
  loadingIsLoggedIn: createReducer<boolean>(false, {
    [actionTypes.FETCH_IS_LOGGED_IN_REQUEST]: () => true,
    [actionTypes.FETCH_IS_LOGGED_IN_SUCCESS]: () => false,
    [actionTypes.FETCH_IS_LOGGED_IN_FAILURE]: () => false
  }),
  dragCount: createReducer(0, {
    [actionTypes.DRAG_ENTER]: (state: any) => state + 1,
    [actionTypes.DRAG_LEAVE]: (state: any) => state - 1,
    [actionTypes.DRAG_RESET]: () => 0
  }),
  isDynamicContentToggleToolOpen: createReducer<boolean>(false, {
    [TestUserActionTypes.TOGGLE_DYNAMIC_CONTENT_TOGGLES_TOOL]: (state: any) => !state
  })
}

type Application = typeof reducers

const application = combineReducers<Application>(reducers)

export default application

export const getIsLoggedIn = (state: RootState) => state.application.isLoggedIn
export const getLoadingIsLoggedIn = (state: RootState) => state.application.loadingIsLoggedIn
export const getIsDynamicContentToggleToolOpen = (state: RootState): boolean =>
  state.application.isDynamicContentToggleToolOpen
