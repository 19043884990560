import React, { useEffect } from "react"

import { Container, FlexContainer } from "@carrotfertility/carotene"
import { BlueBottomBackground } from "./components/BlueBottomBackground"
import { LogoHeaderBar } from "./components/LogoHeaderBar"
import { GuideParser } from "./GuideParser"
import { GuideRouter } from "./GuideRouter"
import { createErrorBoundary } from "../../utils/createErrorBoundary"
import { ErrorPage } from "../../components/views/app/ErrorPage"
import useGetCategories from "../../components/read/shared/useGetCategories"
import { useConditionDepsStatus } from "#/services/contentful/hooks/use-contentful-conditions"
import { MemberActions } from "#/services/memberActionTracking/memberActions"
import useUpdateMemberActionTracking from "#/services/memberActionTracking/useUpdateMemberActionTracking"
import { CenteredLoadingIndicator } from "#/components/views/molecules/Molecules"

const ImmersiveGuide = (): JSX.Element => {
  const GuideErrorBoundary = createErrorBoundary(ErrorPage)

  const { mutate: updateMemberActions } = useUpdateMemberActionTracking()

  useEffect(() => {
    updateMemberActions(MemberActions.READ_AND_LEARN_VIEWED)
  }, [updateMemberActions])

  const conditionDeps = useConditionDepsStatus()
  const { isLoading: isCategoriesLoading } = useGetCategories()
  if (conditionDeps.isError) {
    throw conditionDeps.error
  }

  return (
    <GuideErrorBoundary>
      <Container minHeight="100vh" background={"lightBlue"} sx={{ boxSizing: "border-box", position: "relative" }}>
        <LogoHeaderBar />
        <FlexContainer justifyContent="center" width="100%" padding={["medium", "medium", "medium", "0 0 128px 0"]}>
          <Container
            borderRadius="8px"
            width={["100%", "100%", "900px"]}
            minHeight={["60vh", "60vh", "auto", "auto"]}
            height={["auto", "auto", "auto", "700px"]}
            background={"white"}
            sx={{ zIndex: 1 }}
          >
            {conditionDeps.isLoading || isCategoriesLoading ? (
              <CenteredLoadingIndicator
                style={{
                  position: "relative",
                  top: "50%",
                  transform: "translateY(-50%)"
                }}
              />
            ) : (
              <GuideParser GuideComponent={GuideRouter} />
            )}
          </Container>
        </FlexContainer>
        <BlueBottomBackground />
      </Container>
    </GuideErrorBoundary>
  )
}

export { ImmersiveGuide }
