import React, { useEffect, useState } from "react"
import AddEditEmailAddress from "./AddEditEmailAddress"
import { FormattedMessage, useIntl } from "react-intl"
import EmployeeOnlyModalContainer from "#/components/employee-only-containers/EmployeeOnlyModalContainer"
import {
  Box,
  Button,
  CloseIcon,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton
} from "@carrotfertility/carotene-core"

type AddEditEmailAddressModalProps = {
  open: boolean
  closeModal: () => void
}

export default function AddEmailAddressModal({ open, closeModal }: AddEditEmailAddressModalProps): JSX.Element {
  const intl = useIntl()
  const [showEmailAdded, setShowEmailAdded] = useState(false)

  useEffect(() => {
    if (open) {
      setShowEmailAdded(false)
    }
  }, [open])

  function onClose() {
    closeModal()
  }

  return (
    <EmployeeOnlyModalContainer onClose={onClose} open={open}>
      <Dialog
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        maxWidth="md"
        open={open}
        onClose={onClose}
      >
        {showEmailAdded ? (
          <>
            <DialogTitle id="modal-title">
              <FormattedMessage defaultMessage="Email address added" />
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="modal-description">
                <FormattedMessage defaultMessage="Please check your inbox and click the verification link to enable us to communicate with you at this address." />
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={onClose}>
                <FormattedMessage defaultMessage="Close" />
              </Button>
            </DialogActions>
          </>
        ) : (
          <>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end"
              }}
            >
              <IconButton aria-label={intl.formatMessage({ defaultMessage: "Close" })} onClick={onClose}>
                <CloseIcon />
              </IconButton>
            </Box>
            <AddEditEmailAddress
              setShowActionTaken={setShowEmailAdded}
              title={intl.formatMessage({ defaultMessage: "Add an email address" })}
            />
          </>
        )}
      </Dialog>
    </EmployeeOnlyModalContainer>
  )
}
