import React, { useEffect } from "react"
import { useStateMachine } from "#/components/context/stateMachine/StateMachineV2"
import { useCurrentUser } from "#/components/context/user/UserContext"

export const InternationalAddressUpdateDecision = (): JSX.Element => {
  const { send } = useStateMachine()
  const { isUsa } = useCurrentUser()
  useEffect(() => {
    if (isUsa) {
      send("NO")
    } else {
      send("YES")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- See https://carrotfertility.atlassian.net/wiki/spaces/PE/pages/2050295461/Remove+Build+Warnings#react-hooks%2Fexhaustive-deps
  }, [])

  return <></>
}
