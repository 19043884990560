const RegisteringUserModel = class {
  carrotLite: any
  companyName: any
  coverageAmount: any
  coveragePercent: any
  currencySymbol: any
  email: any
  firstName: any
  hasSaml: any
  constructor(
    // @ts-expect-error TS(7006) FIXME: Parameter 'firstName' implicitly has an 'any' type... Remove this comment to see the full error message
    firstName = null,
    // @ts-expect-error TS(7006) FIXME: Parameter 'email' implicitly has an 'any' type.
    email = null,
    // @ts-expect-error TS(7006) FIXME: Parameter 'companyName' implicitly has an 'any' ty... Remove this comment to see the full error message
    companyName = null,
    // @ts-expect-error TS(7006) FIXME: Parameter 'coveragePercent' implicitly has an 'any... Remove this comment to see the full error message
    coveragePercent = null,
    // @ts-expect-error TS(7006) FIXME: Parameter 'coverageAmount' implicitly has an 'any'... Remove this comment to see the full error message
    coverageAmount = null,
    // @ts-expect-error TS(7006) FIXME: Parameter 'currencySymbol' implicitly has an 'any'... Remove this comment to see the full error message
    currencySymbol = null,
    // @ts-expect-error TS(7006) FIXME: Parameter 'carrotLite' implicitly has an 'any' typ... Remove this comment to see the full error message
    carrotLite = null,
    // @ts-expect-error TS(7006) FIXME: Parameter 'hasSaml' implicitly has an 'any' type.
    hasSaml = null
  ) {
    this.firstName = firstName
    this.email = email
    this.companyName = companyName
    this.coveragePercent = coveragePercent
    this.coverageAmount = coverageAmount
    this.currencySymbol = currencySymbol
    this.carrotLite = carrotLite
    this.hasSaml = hasSaml
  }
}

const ReimbursementDetailsModel = class {
  carrotCardCardId: any
  carrotCardProvider: any
  deductible: any
  insuranceSync: any
  subsidy: any
  constructor(
    // @ts-expect-error TS(7006) FIXME: Parameter 'insuranceSync' implicitly has an 'any' ... Remove this comment to see the full error message
    insuranceSync = null,
    // @ts-expect-error TS(7006) FIXME: Parameter 'subsidy' implicitly has an 'any' type.
    subsidy = null,
    // @ts-expect-error TS(7006) FIXME: Parameter 'deductible' implicitly has an 'any' typ... Remove this comment to see the full error message
    deductible = null,
    // @ts-expect-error TS(7006) FIXME: Parameter 'carrotCardProvider' implicitly has an '... Remove this comment to see the full error message
    carrotCardProvider = null,
    // @ts-expect-error TS(7006) FIXME: Parameter 'carrotCardCardId' implicitly has an 'an... Remove this comment to see the full error message
    carrotCardCardId = null
  ) {
    this.insuranceSync = insuranceSync
    this.subsidy = subsidy
    this.deductible = deductible
    this.carrotCardProvider = carrotCardProvider
    this.carrotCardCardId = carrotCardCardId
  }
}

const MerchantValidModel = class {
  companyAmount: any
  companyCurrencyCode: any
  isApproved: any
  merchantAmount: any
  merchantCurrencyCode: any
  merchantName: any
  constructor(
    // @ts-expect-error TS(7006) FIXME: Parameter 'isApproved' implicitly has an 'any' typ... Remove this comment to see the full error message
    isApproved = null,
    // @ts-expect-error TS(7006) FIXME: Parameter 'companyAmount' implicitly has an 'any' ... Remove this comment to see the full error message
    companyAmount = null,
    // @ts-expect-error TS(7006) FIXME: Parameter 'companyCurrencyCode' implicitly has an ... Remove this comment to see the full error message
    companyCurrencyCode = null,
    // @ts-expect-error TS(7006) FIXME: Parameter 'merchantAmount' implicitly has an 'any'... Remove this comment to see the full error message
    merchantAmount = null,
    // @ts-expect-error TS(7006) FIXME: Parameter 'merchantCurrencyCode' implicitly has an... Remove this comment to see the full error message
    merchantCurrencyCode = null,
    // @ts-expect-error TS(7006) FIXME: Parameter 'merchantName' implicitly has an 'any' t... Remove this comment to see the full error message
    merchantName = null
  ) {
    this.isApproved = isApproved
    this.companyAmount = companyAmount
    this.companyCurrencyCode = companyCurrencyCode
    this.merchantAmount = merchantAmount
    this.merchantCurrencyCode = merchantCurrencyCode
    this.merchantName = merchantName
  }
}

export { RegisteringUserModel, MerchantValidModel, ReimbursementDetailsModel }
