import React, { FC, FormEvent, useEffect, useRef } from "react"
import CardStates from "#/utils/CardStates"
import { connect } from "react-redux"
import { activateCard } from "#/redux/actions"
import { withRouter } from "react-router-dom"
import { RouteComponentProps } from "react-router"
import {
  getCardState,
  getIsActivateError,
  getIsActivateSubmitting,
  getEmployeeId,
  getHasCardholderId,
  getIsCarrotCardEligible
} from "#/redux/reducers/userInfo"
import { reportErrorMessage } from "../../../utils/ErrorReporting"
import { History } from "history"
import Activate from "../../views/activate/Activate"

interface StateProps extends RouteComponentProps {
  isActivateSubmitting: boolean
  isActivateError: boolean
  cardState: string
  employeeId: number
  isCCEligible: boolean
  hasCardholderId: boolean
  history: History
  activateCard: (employeeId: number) => void
}
const ActivateContainer: FC<StateProps> = ({
  isActivateSubmitting,
  isActivateError,
  cardState,
  employeeId,
  isCCEligible,
  hasCardholderId,
  history,
  activateCard
}) => {
  useEffect(() => {
    if (isCCEligible) {
      if (cardState === CardStates.ACTIVE || cardState === CardStates.LOCKED) {
        history.push("/reimbursements/activate/done")
      } else if (cardState === CardStates.ELIGIBLE && !hasCardholderId) {
        history.push("/transactions")
      } else if (cardState === CardStates.SHIPPED || cardState === CardStates.ELIGIBLE) {
        history.push("/reimbursements/activate")
      } else {
        if (cardState === CardStates.INELIGIBLE) {
          reportErrorMessage(
            `Member with employee ID: ${employeeId} has card state of ${cardState} but their company has Carrot Card.`
          )
        }
        history.push("/transactions")
      }
    } else if (!isCCEligible) {
      if (cardState !== CardStates.INELIGIBLE) {
        reportErrorMessage(
          `Member with employee ID: ${employeeId} has card state of ${cardState} but their company doesn't have Carrot Card.`
        )
      }
      history.push("/transactions")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- Replaced componentDidMount and this should only run once.
  }, [])

  const initialCardState = useRef(cardState)
  useEffect(() => {
    if (cardState !== initialCardState.current && cardState === CardStates.ACTIVE) {
      history.push("/reimbursements/activate/success")
    }
  }, [cardState, history])

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault()
    activateCard(employeeId)
  }

  return <Activate error={isActivateError} onSubmit={handleSubmit} submitting={isActivateSubmitting} />
}

const mapStateToProps = (state: StateProps) => ({
  isActivateSubmitting: getIsActivateSubmitting(state),
  isActivateError: getIsActivateError(state),
  cardState: getCardState(state),
  employeeId: getEmployeeId(state),
  isCCEligible: getIsCarrotCardEligible(state),
  hasCardholderId: getHasCardholderId(state)
})

const ActivateContainerConnector = connect(mapStateToProps, { activateCard })(ActivateContainer)
// exported to unit test without withRouter
export { ActivateContainerConnector }

export default withRouter(ActivateContainerConnector)
