import React from "react"
import { Form, FormButton, FormTextField, FormOnSubmitHandler } from "@carrotfertility/carotene-core-x"
import { useCurrentUser } from "../context/user/UserContext"
import { emailValidationCaroteneCore } from "../../utils/Regexes"
import { isHttpError } from "../../utils/HttpErrors"
import { CarrotErrorCodes, getErrorMessageFromCode } from "../../utils/CarrotErrors"
import { getHeap } from "../../utils/heap"
import useEmailVerification from "./useEmailVerification"
import { FormattedMessage, useIntl } from "react-intl"
import { useIntlValidator } from "#/utils/hooks/useIntlValidator"
import { DialogActions, DialogContent, DialogContentText, DialogTitle } from "@carrotfertility/carotene-core"

type AddEditEmailAddressModalProps = {
  title: string
  setShowActionTaken: (actionTaken: boolean) => void
  showRemoveEmail?: boolean
  onRemove?: (event: any) => Promise<void>
  removeDisabled?: boolean
}

export default function AddEditEmailAddress({
  title,
  setShowActionTaken,
  showRemoveEmail,
  onRemove,
  removeDisabled
}: AddEditEmailAddressModalProps): JSX.Element {
  const { email, personalEmail, partnerEmail } = useCurrentUser()
  const { updatePersonalEmail, sendVerificationEmail } = useEmailVerification()
  const intl = useIntl()
  const intlEmailValidation = useIntlValidator(emailValidationCaroteneCore)

  function validateEmailIsDuplicateWithinAccount(
    newPersonalEmail: string,
    currentPersonalEmail: string,
    primaryEmail: string
  ): string | boolean {
    const sanitizedNewPersonalEmail = newPersonalEmail.toLowerCase()
    if (
      sanitizedNewPersonalEmail === currentPersonalEmail ||
      sanitizedNewPersonalEmail === primaryEmail ||
      sanitizedNewPersonalEmail === partnerEmail
    ) {
      getHeap().track("DuplicateEmail", { EventName: "Attempted to use non-unique email" })
      return intl.formatMessage({ defaultMessage: "That email address is already associated with your account." })
    }
    return intlEmailValidation(newPersonalEmail)
  }

  const onSubmit: FormOnSubmitHandler<{ personalEmail: string }> = async (formValues, formMethods) => {
    const sanitizedPersonalEmail = formValues?.personalEmail.toLowerCase()
    if (formValues?.personalEmail) {
      try {
        await updatePersonalEmail(sanitizedPersonalEmail)
        await sendVerificationEmail()
        setShowActionTaken(true)
      } catch (err) {
        const { code } = await err.response.json()
        if (isHttpError(err)) {
          if (code === CarrotErrorCodes.PERSONAL_EMAIL_ALREADY_EXISTS) {
            formMethods.setError("personalEmail", {
              type: "custom",
              message: intl.formatMessage({ defaultMessage: "That email address is already in use" })
            })
            getHeap().track("DuplicateEmail", { EventName: "Attempted to use non-unique email" })
            return
          }
        } else {
          const errorMessage = getErrorMessageFromCode(code)
          formMethods.setError("personalEmail", {
            type: "custom",
            message: intl.formatMessage(errorMessage)
          })
        }
      }
    }
  }
  return (
    <>
      <DialogTitle id="modal-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="modal-description">
          <FormattedMessage defaultMessage="We'll send a verification link to your inbox." />
        </DialogContentText>
        <Form onSubmit={onSubmit}>
          <FormTextField
            name="personalEmail"
            label={intl.formatMessage({ defaultMessage: "Email address" })}
            defaultValue={personalEmail}
            registerOptions={{
              required: intl.formatMessage({ defaultMessage: "Required" }),
              validate: (newPersonalEmail) =>
                validateEmailIsDuplicateWithinAccount(newPersonalEmail, personalEmail, email)
            }}
          />
          <DialogActions>
            {showRemoveEmail && (
              <FormButton
                variant="outlined"
                color="secondary"
                disabled={removeDisabled}
                onClick={(event) => onRemove(event)}
              >
                <FormattedMessage defaultMessage="Remove" />
              </FormButton>
            )}
            <FormButton type="submit">
              <FormattedMessage defaultMessage="Send Verification" />
            </FormButton>
          </DialogActions>
        </Form>
      </DialogContent>
    </>
  )
}
