import { CountryCode } from "#/utils/CountryCode"
import React, { useContext } from "react"

type UserContextValues = {
  companyCountryCode: CountryCode
  isLoggedIn: boolean
  handleSessionExpiration: () => void
}

const UserContext = React.createContext<UserContextValues>(null)
export const useUserContext = (): UserContextValues => useContext(UserContext)

type UserContextProviderProps = {
  children: React.ReactNode
  companyCountryCode: CountryCode
  isLoggedIn: boolean
  handleSessionExpiration: () => void
}

export function UserContextProvider({
  children,
  companyCountryCode,
  isLoggedIn,
  handleSessionExpiration
}: UserContextProviderProps): JSX.Element {
  return (
    <UserContext.Provider
      value={{
        companyCountryCode,
        isLoggedIn,
        handleSessionExpiration
      }}
    >
      {children}
    </UserContext.Provider>
  )
}
