import React, { ReactNode, useEffect, useMemo } from "react"
import ReadLearnCategory from "./ReadLearnCategory"
import { useContentfulConditionalGate } from "../../lib/contentful/components/ContentfulConditionGate"
import { FormattedMessage } from "react-intl"
import { SimpleGrid } from "@carrotfertility/carotene"
import { Redirect, useHistory } from "react-router"
import slugify from "slugify"
import { PageLayout } from "#/features/global-ui/page-layout"
import { PageHeader, PageHeaderGlyph } from "#/features/global-ui/page-header"
import { getOrderTagId, sortByJourneySpecificOrder } from "../../lib/contentful/utils/mappingUtils"
import { useGetRegistrationJourney } from "../carrot-plans/hooks/useGetRegistrationJourney"
import { journeyOrderMapping, Journey } from "#/lib/contentful/utils/journeyOrderMapping"
import {
  Box,
  Button,
  Link,
  Stack,
  Theme,
  Tile,
  Typography,
  useMediaQuery,
  useTheme
} from "@carrotfertility/carotene-core"
import { mapContentToCategory } from "./shared/CategoryMapper"
import { getHeap } from "../../pages/groups/utils/heap"
import { useGetMostRecentlyCompletedRouting } from "#/components/carrot-plans/hooks/useGetMostRecentlyCompletedRouting"
import { EntryWithoutUnresolvableLinks } from "#/utils/ContentTypes"
import { TypeCategorySkeleton } from "#/types/contentful"
import { useFlags } from "launchdarkly-react-client-sdk"
import { CarrotAcademySearch } from "./CarrotAcademySearch"

type ReadLearnCategoryGridProps = {
  categories: EntryWithoutUnresolvableLinks<TypeCategorySkeleton>[]
}

export const ReadLearnHomeMasterLayout = ({ children }: { children: ReactNode }): JSX.Element => {
  const { showCarrotAcademySearch } = useFlags()
  const theme = useTheme()
  return (
    <PageLayout
      header={
        <PageHeader
          startGlyph={
            <PageHeaderGlyph src="/images/icn-lightbulb-color.png" srcSet={"/images/icn-lightbulb-color-2x.png"} />
          }
          pageTitle={<FormattedMessage defaultMessage="Carrot Academy" />}
        />
      }
    >
      {showCarrotAcademySearch ? (
        <Stack
          sx={(theme) => ({ marginBlockEnd: theme.spacing(theme.tokens.spacing.xl) })}
          spacing={theme.spacing(theme.tokens.spacing.xxs)}
        >
          <CarrotAcademySearch size="medium" />
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Link target="_blank" href="https://carrot.research.net/r/YT7T5XC">
              <FormattedMessage defaultMessage="Share feedback about search" />
            </Link>
          </Box>
        </Stack>
      ) : null}
      {children}
    </PageLayout>
  )
}

export default function ReadLearnLandingPageLayout({ categories }: ReadLearnCategoryGridProps): JSX.Element {
  const isAboveLgBreakpoint = useMediaQuery((theme: Theme) => theme.breakpoints.up("lg"))

  const history = useHistory()
  const { data: routingData, isLoading: isRoutingInfoLoading } = useGetMostRecentlyCompletedRouting()
  const { data: registrationJourneyResult, isLoading: isRegistrationJourneyLoading } = useGetRegistrationJourney()
  const allJourneyDataIsLoaded = !isRoutingInfoLoading && !isRegistrationJourneyLoading
  const journey = allJourneyDataIsLoaded ? routingData?.journey ?? registrationJourneyResult?.journey : null

  useEffect(() => {
    if (journey) {
      getHeap().track("R&L Journey Banner Rendered", {
        "Member Journey": journey
      })
    }
  }, [journey])

  const mappedCategories = categories?.map(mapContentToCategory)

  const highlightedCategory = useMemo(() => {
    return journey && journeyOrderMapping[journey].value
      ? mappedCategories.find((category) => journeyOrderMapping[journey].value === getOrderTagId(category))
      : null
  }, [mappedCategories, journey])

  const gridCategories = useMemo(() => {
    return highlightedCategory
      ? mappedCategories.filter((category) => category.id !== highlightedCategory.id)
      : mappedCategories
  }, [highlightedCategory, mappedCategories])

  const sortedCategories = useMemo(() => {
    return gridCategories.sort((a, b) => {
      if (journey) {
        return sortByJourneySpecificOrder(journey as Journey, a, b)
      }
      return a.order - b.order
    })
  }, [gridCategories, journey])

  const categoriesAndConditions = useMemo(() => {
    return sortedCategories.map((category) => ({
      element: <ReadLearnCategory key={`id-${category?.navTitle ?? category?.title}`} category={category} />,
      conditions: category?.conditions,
      category
    }))
  }, [sortedCategories])

  const highlightedCategoryDescriptionAndConditions = useMemo(() => {
    return highlightedCategory?.description
      ? highlightedCategory.description.map((description) => ({
          element: <Typography>{description?.fields?.body}</Typography>,
          conditions: description?.fields?.conditions,
          description
        }))
      : []
  }, [highlightedCategory])

  const readAndLearnNonHighlightedCategories = useContentfulConditionalGate(categoriesAndConditions, true)
  const readAndLearnHighlightedCategory = useContentfulConditionalGate(
    highlightedCategoryDescriptionAndConditions,
    true
  )

  if (
    allJourneyDataIsLoaded &&
    readAndLearnNonHighlightedCategories.length === 1 &&
    readAndLearnHighlightedCategory.length === 0
  ) {
    const matchingCategory = categoriesAndConditions.find(
      (categoryTuple) => categoryTuple.element === readAndLearnNonHighlightedCategories[0]
    ).category
    return <Redirect to={`/read/${slugify(matchingCategory.navTitle)}`} />
  }

  return (
    <ReadLearnHomeMasterLayout>
      {journey && journeyOrderMapping[journey].value && (
        <Tile
          color="info"
          sx={{ marginBlockEnd: (theme) => theme.tokens.spacing.xxl }}
          data-analytics-id="read-and-learn-journey-banner"
          data-member-journey={journey}
        >
          <Stack direction={"row"} gap={(theme) => theme.tokens.spacing.xxl}>
            <Box>
              <Box component="img" width="36px" height="36px" src={highlightedCategory?.icon?.file?.url} alt="" />
              <Stack
                gap={(theme) => theme.tokens.spacing.xxs}
                marginTop={(theme) => theme.tokens.spacing.md}
                marginBottom={(theme) => theme.tokens.spacing.lg}
              >
                <Typography variant="h2">{highlightedCategory?.title}</Typography>
                {readAndLearnHighlightedCategory}
              </Stack>
              <Button
                color="secondary"
                onClick={() => {
                  history.push(`/read/${slugify(highlightedCategory?.navTitle)}`)
                }}
                data-analytics-id="read-and-learn-journey-banner-start-learning-button"
              >
                <FormattedMessage defaultMessage="Start learning" />
              </Button>
            </Box>
            {isAboveLgBreakpoint && <Box component="img" src={`/images/learning-resources-stack.svg`} alt="" />}
          </Stack>
        </Tile>
      )}
      <SimpleGrid spacing={["medium", "medium", "32px"]} columns={[1, 2, 2, 3]}>
        {allJourneyDataIsLoaded && readAndLearnNonHighlightedCategories}
      </SimpleGrid>
    </ReadLearnHomeMasterLayout>
  )
}
