import { useQueryClient, QueryObserver, QueryObserverResult } from "@tanstack/react-query"
import { getChannelIdQueryKey } from "../../components/home/quick-links/hooks/useGetChannelId"
import {
  addHeapUserProperties,
  HeapProperties,
  HeapUserPropertyName,
  setHeapPosingAsMember,
  unsetHeapPosingAsMember
} from "../heap"
import { carrotClient } from "../CarrotClient"
import { useCurrentUser } from "../../components/context/user/UserContext"

const currentHeapPropertyValues: HeapProperties = {
  [HeapUserPropertyName.ChannelId]: null
}

export default function useHeapTrackingReactQuery() {
  const queryClient = useQueryClient()
  const { isPosingAsMember, employeeId } = useCurrentUser()
  const observer = new QueryObserver(queryClient, {
    queryKey: getChannelIdQueryKey(),
    queryFn: () => carrotClient.getChannelId(),
    refetchOnWindowFocus: false
  })

  observer.subscribe((query: QueryObserverResult<number>) => {
    if (isPosingAsMember) {
      setHeapPosingAsMember(employeeId.toString())
    } else {
      unsetHeapPosingAsMember()

      if (query.status === "loading") {
        currentHeapPropertyValues[HeapUserPropertyName.ChannelId] = null
      }
      if (query.status === "success" && currentHeapPropertyValues[HeapUserPropertyName.ChannelId] !== query.data) {
        currentHeapPropertyValues[HeapUserPropertyName.ChannelId] = query.data
        addHeapUserProperties(currentHeapPropertyValues)
      }
    }
  })
}
