import React from "react"
import { NotSmall, Small } from "./Responsive"
import { H1, H2 } from "@carrotfertility/carotene"
import { useIntl } from "react-intl"

// @ts-expect-error TS(7031) FIXME: Binding element 'children' implicitly has an 'any'... Remove this comment to see the full error message
const SwitchH1ToH2 = ({ children }) => (
  <>
    <NotSmall>
      <H1>{children}</H1>
    </NotSmall>
    <Small>
      <H2>{children}</H2>
    </Small>
  </>
)

const LeftBackArrowImage = () => {
  const intl = useIntl()
  return (
    <>
      <img
        src="/images/left-carrot-medium.svg"
        alt={intl.formatMessage({ defaultMessage: "carrot", description: "left back arrow alt text" })}
        style={{
          marginLeft: "-2px",
          width: "1rem",
          height: "1rem",
          position: "absolute"
        }}
      />
    </>
  )
}

export { SwitchH1ToH2, LeftBackArrowImage }
