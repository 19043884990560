import React from "react"
import SignUpGetHelpPage from "../components/signup-get-help"
import { SignUpGetHelpProvider } from "../components/signup-get-help/context/SignUpGetHelpContext"

const SignUpGetHelp = (): JSX.Element => {
  return (
    <SignUpGetHelpProvider>
      <SignUpGetHelpPage />
    </SignUpGetHelpProvider>
  )
}

export default SignUpGetHelp
