import React from "react"
import { useRequestCarrotCard } from "../request-carrot-card"
import { Form, FormCheckbox } from "@carrotfertility/carotene-core-x"
import { AirwallexUserAgreementTemplate, attemptRequest, IssuingRegion } from "../agreement-helpers"
import { FormattedMessage } from "react-intl"
import { Link } from "@carrotfertility/carotene-core"
import { useStateMachine } from "#/components/context/stateMachine/StateMachineV2"

export function AirwallexCanadaUserAgreement() {
  const { send } = useStateMachine()
  const requestCardMutation = useRequestCarrotCard({
    onSuccess: () => send("")
  })

  return (
    <Form
      onSubmit={() => attemptRequest(requestCardMutation.mutateAsync)}
      defaultValues={{ airwallexUserAgreement: false, airwallexPaperlessNotificationsAgreement: false }}
    >
      <AirwallexUserAgreementTemplate
        data-testid={`issuing-region-${IssuingRegion.Canada}`}
        disableContinue={["airwallexUserAgreement", "airwallexPaperlessNotificationsAgreement"]}
        userAgreementChecks={[
          <FormCheckbox
            name={"airwallexUserAgreement"}
            key="user-agreement"
            label={
              <FormattedMessage
                values={{
                  cardHolderLink: (linkContent) => (
                    <Link
                      href="https://www.airwallex.com/ca/terms/cardholder-commercial"
                      target="_blank"
                      color="inherit"
                    >
                      {linkContent}
                    </Link>
                  ),
                  privacyPolicyLink: (linkContent) => (
                    <Link
                      href="https://www.airwallex.com/ca/terms/privacy-policy#scope-of-policy"
                      target="_blank"
                      color="inherit"
                    >
                      {linkContent}
                    </Link>
                  )
                }}
                defaultMessage="I consent to the <cardHolderLink>cardholder terms</cardHolderLink> and <privacyPolicyLink>privacy policy</privacyPolicyLink>."
              />
            }
            required
          />,
          <FormCheckbox
            key="paperless-notifications"
            name={"airwallexPaperlessNotificationsAgreement"}
            label={
              <FormattedMessage
                defaultMessage="I consent to paperless notifications and accept the <paperlessCommsLink>electronic communications consent agreement</paperlessCommsLink>."
                values={{
                  paperlessCommsLink: (linkContent) => (
                    <Link
                      href="https://www.airwallex.com/ca/terms/airwallex-electronic-communication-consent"
                      target="_blank"
                      color="inherit"
                    >
                      {linkContent}
                    </Link>
                  )
                }}
              />
            }
            required
          />
        ]}
        isError={requestCardMutation.isError}
      />
    </Form>
  )
}
