import React, { ReactElement } from "react"
import { Redirect, Route } from "react-router-dom"
import { PageWrapperProps, TileOrPhoneSupportActions } from "../../../components/dynamic-content/util/dynamicContent"
import { getHelpPath } from "#/components/talk-to-carrot/util/talkToCarrotMolecules"
import { HelpPage } from "#/components/talk-to-carrot/TalkToCarrotNeedHelp"
import PageBreadcrumb from "./PageBreadcrumb"
import { fieldIsValid, getNextTileAction, getPathForTile, isLanguagePage, isTilePage } from "../utils/mappingUtils"
import { TileContent } from "./RouteContent"
import { Paths } from "#/utils/Paths"
import { getLanguageRoutes } from "./LanguagePageRoutes"
import { Breadcrumb } from "@carrotfertility/carotene-core"
import { Switch } from "#/services/routing/Switch"
import { EntryWithoutUnresolvableLinks } from "#/utils/ContentTypes"
import { TypeTilePageSkeleton, TypeTileSkeleton } from "#/types/contentful"

type TileRoutesProps = {
  actions: TileOrPhoneSupportActions
  baseUrl: string
  breadcrumbs: Array<ReactElement<typeof Breadcrumb>>
  Wrapper?: ({ children, breadcrumbs }: PageWrapperProps) => ReactElement
  tile: EntryWithoutUnresolvableLinks<TypeTileSkeleton>
}

const getTileRoutes = ({
  actions,
  baseUrl,
  breadcrumbs,
  Wrapper,
  tile
}: TileRoutesProps): ReactElement<typeof Route>[] => {
  const tilePath = getPathForTile({ tile, baseUrl })
  const newBreadcrumbs = [
    ...breadcrumbs,
    <PageBreadcrumb tile={tile} baseUrl={baseUrl} actions={actions} isCurrentPage key={tilePath} />
  ]

  const contentRender = (): JSX.Element => (
    <TileContent tile={tile} actions={actions} baseUrl={baseUrl} breadcrumbs={newBreadcrumbs} Wrapper={Wrapper} />
  )
  const getNestedTileRoutes = (): ReactElement<typeof Route>[] => {
    const nextAction = getNextTileAction(tile)
    if (fieldIsValid(nextAction) && isTilePage(nextAction)) {
      const {
        fields: { tiles }
      } = nextAction
      const parentCrumbs = [
        ...breadcrumbs,
        <PageBreadcrumb tile={tile} baseUrl={baseUrl} actions={actions} key={tilePath} />
      ]
      const nestedRoutes = tiles.map((tile): ReactElement<typeof Route>[] =>
        getTileRoutes({ actions, baseUrl: tilePath, breadcrumbs: parentCrumbs, Wrapper, tile })
      )
      return [
        <Route
          exact
          path={getHelpPath({ baseUrl: tilePath })}
          key={`${tilePath}-help`}
          render={(): JSX.Element => <HelpPage breadcrumbs={parentCrumbs} />}
        />,
        ...nestedRoutes.flat()
      ]
    } else if (fieldIsValid(nextAction) && isLanguagePage(nextAction)) {
      const {
        fields: { benefitChatLanguages }
      } = nextAction
      const parentCrumbs = [
        ...breadcrumbs,
        <PageBreadcrumb tile={tile} baseUrl={baseUrl} actions={actions} key={tilePath} />
      ]
      const nestedRoutes = benefitChatLanguages.map((language): ReactElement<typeof Route>[] =>
        getLanguageRoutes({ actions, baseUrl: tilePath, breadcrumbs: parentCrumbs, Wrapper, language })
      )
      return [
        <Route
          exact
          path={getHelpPath({ baseUrl: tilePath })}
          key={`${tilePath}-help`}
          render={(): JSX.Element => <HelpPage breadcrumbs={parentCrumbs} />}
        />,
        ...nestedRoutes.flat()
      ]
    }
    return []
  }

  return [<Route exact path={tilePath} render={contentRender} key={tilePath} />, ...getNestedTileRoutes()]
}

type TilePageRoutesProps = {
  page: EntryWithoutUnresolvableLinks<TypeTilePageSkeleton>
  actions: TileOrPhoneSupportActions
  baseUrl: string
  breadcrumbs?: Array<ReactElement<typeof Breadcrumb>>
  Wrapper?: ({ children, breadcrumbs }: PageWrapperProps) => ReactElement
}
const TilePageRoutes = ({ page, actions, baseUrl, breadcrumbs = [], Wrapper }: TilePageRoutesProps): JSX.Element => {
  if (page && page.fields.tiles) {
    const {
      fields: { tiles }
    } = page
    return (
      <Switch>
        <Route
          exact
          path={getHelpPath({ baseUrl })}
          key={`${baseUrl}-help`}
          render={(): JSX.Element => <HelpPage breadcrumbs={breadcrumbs} />}
        />
        {tiles.map((tile) => getTileRoutes({ actions, baseUrl, breadcrumbs, Wrapper, tile })).flat()}
        <Redirect from="/" to={Paths.TALK_TO_CARROT} />
      </Switch>
    )
  } else {
    return null
  }
}

export { TilePageRoutes, getTileRoutes }
