import React, { useContext, useReducer } from "react"
import { CarrotErrorCodes } from "#/utils/CarrotErrors"
import { reportError } from "#/utils/ErrorReporting"
import { useSignUpFlow } from "../../context/signup/SignUpFlowContext"
import { getHeap } from "../../../utils/heap"
import { carrotClient } from "#/utils/CarrotClient"
import { Dayjs } from "@carrotfertility/carotene-core"
import { useHistory } from "react-router"

interface SignUpGetHelpReducerState {
  errorMessage: string
  inputsEnabled: boolean
  showSubmittingLabel: boolean
  shake: boolean
  success: boolean
}

type SignUpGetHelpOnSubmit = {
  email: string
  firstName: string
  lastName: string
  benefitSponsor: string
  dateOfBirth: Dayjs
  country: { label: string; value: string }
  employeeOrPlanId: string
  phoneNumber?: string
  contactMethod: string
}

interface SignUpGetHelpProviderProps extends SignUpGetHelpReducerState {
  onSubmit: ({
    email,
    firstName,
    lastName,
    benefitSponsor,
    dateOfBirth,
    country,
    employeeOrPlanId,
    phoneNumber,
    contactMethod
  }: SignUpGetHelpOnSubmit) => Promise<void>
}

export type SendTalkToCarrotSignUpMessagePayload = {
  message: string
  subject?: string
  topic: string
  sendFromPartner: boolean
  email: string
  name: string
}

const SignUpGetHelpContext = React.createContext<SignUpGetHelpProviderProps>(null)

export const useSignUpGetHelp = () => useContext(SignUpGetHelpContext)

const actionTypes = {
  error: "ERROR",
  success: "REQUEST_SUCCESS",
  start: "REQUEST_START"
}

function signUpGetHelpReducer(
  state: SignUpGetHelpReducerState,
  action: { type: string; payload?: any }
): SignUpGetHelpReducerState {
  switch (action.type) {
    case actionTypes.error: {
      return {
        ...state,
        inputsEnabled: true,
        shake: true,
        errorMessage: action.payload.errorCode,
        showSubmittingLabel: false
      }
    }
    case actionTypes.success: {
      return {
        ...state,
        inputsEnabled: false,
        success: true
      }
    }
    case actionTypes.start: {
      return {
        ...state,
        inputsEnabled: false,
        showSubmittingLabel: true,
        shake: false
      }
    }
    default: {
      throw new Error(`Unhandled type: ${action.type}`)
    }
  }
}

export function SignUpGetHelpProvider({ children }: { children: React.ReactNode | React.ReactNode[] }) {
  const [state, dispatch] = useReducer(signUpGetHelpReducer, {
    inputsEnabled: true,
    shake: false,
    showSubmittingLabel: false,
    errorMessage: null,
    success: false
  })
  const { handleSaveMemberInfoInMemory } = useSignUpFlow()
  const history = useHistory()

  function onGetHelpSuccessfulRequest(): void {
    history.push("/signup-get-help-success")
  }

  const setFormError = (errorCode: string) => dispatch({ type: actionTypes.error, payload: { errorCode } })
  const startRequest = () => dispatch({ type: actionTypes.start })
  const endSuccessfulRequest = () => dispatch({ type: actionTypes.success })

  const setErrorState = (errorCode: string) => {
    setFormError(errorCode)
  }

  const onSubmit = async ({
    email,
    firstName,
    lastName,
    benefitSponsor,
    dateOfBirth,
    country,
    employeeOrPlanId,
    phoneNumber,
    contactMethod
  }: SignUpGetHelpOnSubmit) => {
    getHeap().track("NoEmailOnboarding", { EventName: "submit support request" })
    startRequest()
    const countryNameInEnglish = new Intl.DisplayNames(["en-US"], { type: "region" }).of(country.value)
    try {
      await carrotClient.sendTalkToCarrotSignUpMessage({
        message: `INFORMATION ENTERED -- 
          (email: ${email}), 
          (first name: ${firstName}), 
          (last name: ${lastName}),
          (date of birth: ${dateOfBirth.format("YYYY-MM-DD")}), 
          (benefit sponsor: ${benefitSponsor}), 
          (country: ${countryNameInEnglish}),
          ${employeeOrPlanId ? `(employee or plan ID: ${employeeOrPlanId}),` : ""}
          ${phoneNumber ? `(phone number: ${phoneNumber}),` : ""}
          (preferred contact method: ${contactMethod})`,
        subject: "Member needs help finding account",
        topic: "Sign up get help",
        sendFromPartner: false,
        email,
        name: `${firstName} ${lastName}`
      })
      handleSaveMemberInfoInMemory({
        email,
        dateOfBirth,
        firstName,
        lastName,
        country,
        benefitSponsor,
        employeeOrPlanId,
        phoneNumber,
        contactMethod
      })
    } catch (error) {
      reportError(error)
      setErrorState(CarrotErrorCodes.UNEXPECTED_ERROR)
      return
    }
    onGetHelpSuccessfulRequest()
    endSuccessfulRequest()
  }

  return <SignUpGetHelpContext.Provider value={{ onSubmit, ...state }}>{children}</SignUpGetHelpContext.Provider>
}
