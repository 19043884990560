import { createSelector } from "reselect"
import moment from "moment"
import { getCompanyName, getParentCompanyOrCompanyName, getLaunchDate } from "#/redux/reducers/companyInfo"
import {
  getCardState,
  getEligibilityDate,
  getFirstName,
  getHasBankInfoFilledOut,
  getLastName,
  getMemberHasSuccessfullySyncedInsurance
} from "#/redux/reducers/userInfo"
import CardStates from "#/utils/CardStates"
import { getCompanyHasCarrotManagedDeductibleEnabled, getHasDeductible } from "./deductibleSelectors"
import { getBankInfoEnabled } from "#/redux/reducers/benefitConfiguration"
import { dayjs } from "@carrotfertility/carotene-core"

const getEligibilityDateInFuture = createSelector([getEligibilityDate], (eligibilityDate) => {
  if (eligibilityDate) {
    const now = dayjs()
    const eligible = dayjs(eligibilityDate)
    return now.isBefore(eligible, "date")
  }
  return false
})

const getLaunchDateInFuture = createSelector([getLaunchDate], (launchDate) => {
  if (launchDate) {
    const now = dayjs()
    const launch = dayjs(launchDate)
    return now.isBefore(launch, "date")
  }
  return false
})

export const getIsNBAMember = createSelector([getParentCompanyOrCompanyName], (parentCompanyOrCompanyName) => {
  return parentCompanyOrCompanyName === "NBA"
})

export const getIsWNBAMember = createSelector([getParentCompanyOrCompanyName], (parentCompanyOrCompanyName) => {
  return parentCompanyOrCompanyName === "WNBA"
})

export const getIsAvenuesMember = createSelector([getCompanyName], (companyName) => {
  return companyName === "Avenues USA"
})

export const getIsRomanHealthNYMandateMember = createSelector([getCompanyName], (companyName) => {
  return (
    companyName === "RHV" ||
    companyName === "Roman Health Ventures" ||
    companyName === "RHP" ||
    companyName === "Roman Health Pharmacy"
  )
})

// currently we have a custom view for demo accounts of Latham and Watkins,
// which are set up in Stage DB only

export const getBenefitStarted = createSelector(
  [getLaunchDateInFuture, getEligibilityDateInFuture],
  (launchDateInFuture, eligibilityDateInFuture) => {
    if (!launchDateInFuture && !eligibilityDateInFuture) {
      return true
    }
    return false
  }
)

export const getBenefitStartDate = createSelector(
  [getLaunchDate, getEligibilityDate],
  (launchDate, eligibilityDate) => {
    // @ts-expect-error TS(7009) FIXME: 'new' expression, whose target lacks a construct s... Remove this comment to see the full error message
    const launchDateMoment = new moment(launchDate)
    if (launchDateMoment > eligibilityDate) {
      return launchDateMoment
    }

    return eligibilityDate
  }
)

export const getMemberFullName = createSelector(
  [getFirstName, getLastName],
  (firstName, lastName) => `${firstName} ${lastName}`
)

export const getIsMemberOfCarrotCardCompany = createSelector(
  [getCardState],
  (cardState) => cardState !== CardStates.INELIGIBLE
)

export const getShouldShowOptionalInsuranceInfoPromo = createSelector(
  [getCompanyHasCarrotManagedDeductibleEnabled, getHasDeductible, getMemberHasSuccessfullySyncedInsurance],
  (companyHasCarrotMangedDeductibleEnabled, hasDeductible, memberHasSuccessfullySyncedInsurance) => {
    return !memberHasSuccessfullySyncedInsurance && companyHasCarrotMangedDeductibleEnabled && hasDeductible
  }
)

export const getShouldFillOutBankInfo = createSelector(
  [getHasBankInfoFilledOut, getBankInfoEnabled],
  (hasBankInfoFilledOut, bankInfoEnabled) => !hasBankInfoFilledOut && bankInfoEnabled
)
