import React, { ReactElement } from "react"
import { UpdateAppDialog } from "../home/UpdateAppDialog"
import {
  useIsMobileAndSupportsPushNotifications,
  useIsAppOutdated,
  useIsPushPermissionsEnabled
} from "./hooks/useMobileAppStatus"
import { PushNotificationsSettingDialog } from "./PushNotificationsSettingDialog"
import {
  getAppUpdateModalSelection,
  getAppUpdateModalTimestamp,
  getPushPermissionsModalSelection
} from "./utils/localStorageUtils"
import moment from "moment"
import { isIOSNativeApp } from "#/lib/carrot-api/MobileNativeClient"

function isModalStale(timestamp: number | undefined, days: number): boolean {
  if (!timestamp) return true
  const diffInDays = moment().diff(moment(timestamp), "days")
  return diffInDays > days
}

export function MobileAppModalContainer(): ReactElement {
  const { data: isAppOutdated, isLoading: isAppOutdatedLoading } = useIsAppOutdated()
  const { data: isPushPermissionsEnabled, isLoading: isPushPermissionsEnabledLoading } = useIsPushPermissionsEnabled()
  const { data: isMobileAndSupportsPushNotifications, isLoading: isMobileAndSupportsPushNotificationsLoading } =
    useIsMobileAndSupportsPushNotifications()

  const pushPermissionsModalTriggered = getPushPermissionsModalSelection()

  const shouldOpenPushNotificationDialog =
    isMobileAndSupportsPushNotifications && !isPushPermissionsEnabled && !pushPermissionsModalTriggered

  const isUpdateModalDue = !getAppUpdateModalSelection() || isModalStale(getAppUpdateModalTimestamp(), 30)

  const shouldOpenUpdateDialog = isIOSNativeApp() && isAppOutdated && isUpdateModalDue

  if (isAppOutdatedLoading || isPushPermissionsEnabledLoading || isMobileAndSupportsPushNotificationsLoading) {
    return null
  }

  // TODO: Work with the android contractor to get the android redirect to the play store working.
  //We're gating this to just the iOS app as the redirect in the update modal is not working as expected in android
  if (shouldOpenUpdateDialog) {
    return <UpdateAppDialog initialOpen={isAppOutdated} />
  } else if (shouldOpenPushNotificationDialog) {
    return <PushNotificationsSettingDialog initialOpen={shouldOpenPushNotificationDialog} />
  }

  return null
}
