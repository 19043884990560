import React, { ReactElement, useEffect, useState } from "react"
import TwoColumnRightPrimary from "#/components/views/atoms/layouts/TwoColumnRightPrimary"
import { getTalkToCarrotNeedHelpGuidance } from "../../lib/contentful/contentfulClient"
import { GuidanceElement } from "../../lib/contentful/components/GuidanceElement"
import { TalkToCarrotLegal } from "./TalkToCarrotLegal"
import { SendMessage } from "./send-message/SendMessage"
import { talkToCarrotSource } from "./send-message/SendMessageUtil"
import { FormattedMessage, useIntl } from "react-intl"
import { PageLayout } from "#/features/global-ui/page-layout"
import { PageBreadcrumbHeader } from "#/features/global-ui/page-header"
import { Breadcrumb, Breadcrumbs } from "@carrotfertility/carotene-core"
// @ts-expect-error TS(7031) FIXME: Binding element 'breadcrumbs' implicitly has an 'a... Remove this comment to see the full error message
const HelpPage = ({ breadcrumbs }) => {
  const [guidance, setGuidance] = useState<ReactElement<typeof GuidanceElement>>(null)
  const intl = useIntl()
  const { locale } = intl

  useEffect(() => {
    const loadGuidance = async () => {
      const guidance = await getTalkToCarrotNeedHelpGuidance(locale)
      setGuidance(<GuidanceElement guidance={guidance} />)
    }
    if (!guidance) {
      loadGuidance()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- See https://carrotfertility.atlassian.net/wiki/spaces/PE/pages/2050295461/Remove+Build+Warnings#react-hooks%2Fexhaustive-deps
  }, [])

  return (
    <PageLayout
      footer={<TalkToCarrotLegal />}
      header={
        <PageBreadcrumbHeader
          breadcrumbs={
            <Breadcrumbs>
              {[
                ...breadcrumbs,
                <Breadcrumb key="help-breadcrumb" currentPage>
                  <FormattedMessage defaultMessage="I need help" />
                </Breadcrumb>
              ]}
            </Breadcrumbs>
          }
        />
      }
    >
      <TwoColumnRightPrimary
        secondaryContent={guidance}
        primaryContent={<SendMessage source={talkToCarrotSource.I_NEED_HELP} />}
      />
    </PageLayout>
  )
}

export { HelpPage }
