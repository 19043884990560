import React, { useContext, useEffect, useState } from "react"
import { getHeap } from "#/utils/heap"
import {
  disableBiometrics,
  isBiometricsCapable,
  shouldAttemptBioAuthenticationLogin
} from "carrot-api/MobileNativeClient"

interface BiometricAuthEnrollmentContextReturn {
  isBiometricAuthEnrollmentSuccessful: boolean
  isBiometricAuthEnrollmentComplete: boolean
  resetBiometricAuthEnrollmentState: () => void
  setBiometricAuthEnrollmentSuccess: () => void
  setBiometricAuthEnrollmentFailure: () => void
  hasBioAuthAlreadyBeenConfigured: boolean
  isBioauthCapable: boolean
  isBioauthCapableLoading: boolean
  disableBiometricAuth: () => void
}

const BiometricAuthEnrollmentContext = React.createContext<BiometricAuthEnrollmentContextReturn>(null)

export const useBiometricAuthEnrollment = (): BiometricAuthEnrollmentContextReturn =>
  useContext(BiometricAuthEnrollmentContext)

export function trackBiometricAuth(success: boolean): void {
  getHeap().track("Submitted Login Form via Biometric Auth", {
    "Biometric Login Succeeded?": String(success)
  })
}

export function trackBiometricAuthAttempt(): void {
  getHeap().track("Prompted for Biometric Auth")
}

export function trackBiometricAuthEnrollment(success: boolean): void {
  getHeap().track("Prompted for Biometric Auth Enrollment", {
    "Biometric Auth Enrollment Succeeded?": String(success)
  })
}

// @ts-expect-error TS(7031) FIXME: Binding element 'children' implicitly has an 'any'... Remove this comment to see the full error message
export function BiometricAuthEnrollmentContextProvider({ children }) {
  const [isBiometricAuthEnrollmentSuccessful, setBiometricAuthEnrollmentSuccessful] = useState(false)
  const [isBiometricAuthEnrollmentComplete, setBiometricAuthEnrollmentComplete] = useState(false)
  const [isBioauthCapable, setBioauthCapable] = useState(false)
  const [isBioauthCapableLoading, setBioauthCapableLoading] = useState(true)
  const [hasBioAuthAlreadyBeenConfigured, setHasBioAuthAlreadyBeenConfigured] = useState(false)

  useEffect(() => {
    async function execute(): Promise<void> {
      setBioauthCapable(await isBiometricsCapable())
      setBioauthCapableLoading(false)
      setHasBioAuthAlreadyBeenConfigured(await shouldAttemptBioAuthenticationLogin())
    }
    execute()
  }, [])

  const setBiometricAuthEnrollmentSuccess = React.useCallback(() => {
    setBiometricAuthEnrollmentSuccessful(true)
    setBiometricAuthEnrollmentComplete(true)
  }, [])

  const setBiometricAuthEnrollmentFailure = React.useCallback(() => {
    setBiometricAuthEnrollmentSuccessful(false)
    setBiometricAuthEnrollmentComplete(true)
  }, [])

  const resetBiometricAuthEnrollmentState = React.useCallback(() => {
    setBiometricAuthEnrollmentComplete(false)
  }, [])

  const disableBiometricAuth = React.useCallback(() => {
    disableBiometrics()
    setHasBioAuthAlreadyBeenConfigured(false)
  }, [])

  return (
    <BiometricAuthEnrollmentContext.Provider
      value={{
        isBiometricAuthEnrollmentSuccessful,
        isBiometricAuthEnrollmentComplete,
        resetBiometricAuthEnrollmentState,
        setBiometricAuthEnrollmentSuccess,
        setBiometricAuthEnrollmentFailure,
        isBioauthCapable,
        isBioauthCapableLoading,
        hasBioAuthAlreadyBeenConfigured,
        disableBiometricAuth
      }}
    >
      {children}
    </BiometricAuthEnrollmentContext.Provider>
  )
}
