import { useCurrentUser } from "#/components/context/user/UserContext"
import React from "react"
import { Redirect, Route } from "react-router-dom"
import { ThemeProvider } from "styled-components"
import { fleFormTheme } from "#/utils/formThemes"
import RoutingFlow from "."
import { Paths } from "../../utils/Paths"
import { talkToCarrotSource } from "../talk-to-carrot/send-message/SendMessageUtil"
import { Switch } from "#/services/routing/Switch"
import { useUserRole } from "../partner-access/hooks/usePartnerAccess"
import { useMemberBenefitStarted } from "#/services/user/derived-selectors/member-info"

interface ProtectFlowProps {
  children: React.ReactElement
}

function ProtectFlow({ children }: ProtectFlowProps): JSX.Element {
  const { carrotPlansRoutingCompleted } = useCurrentUser()
  const { isPartnerAccessingAccount } = useUserRole()
  const benefitStarted = useMemberBenefitStarted()

  if (benefitStarted.isLoading) {
    return null
  }

  if (!benefitStarted.data) {
    return <Redirect to="/" />
  }
  if (carrotPlansRoutingCompleted && !isPartnerAccessingAccount) {
    return <Redirect to={`${Paths.TALK_TO_CARROT}/${talkToCarrotSource.GET_YOUR_CARROT_PLAN}`} />
  }
  return children
}

function RedirectToCarrotPlansNextStep(): JSX.Element {
  const { carrotPlansRoutingCompleted } = useCurrentUser()
  const { isPartnerAccessingAccount } = useUserRole()
  const benefitStarted = useMemberBenefitStarted()

  if (benefitStarted.isLoading) {
    return null
  }

  if (!benefitStarted.data) {
    return <Redirect to="/" />
  }

  if (carrotPlansRoutingCompleted && !isPartnerAccessingAccount) {
    return <Redirect to={`${Paths.TALK_TO_CARROT}/${talkToCarrotSource.GET_YOUR_CARROT_PLAN}`} />
  }

  return <Redirect to="/carrot-plans/enrollment" />
}

export function CarrotPlansRouter(): JSX.Element {
  return (
    <ThemeProvider theme={fleFormTheme}>
      <Switch>
        <Route exact path="/carrot-plans/enrollment">
          <ProtectFlow>
            <RoutingFlow />
          </ProtectFlow>
        </Route>
        <Route path="/carrot-plans">
          <RedirectToCarrotPlansNextStep />
        </Route>
      </Switch>
    </ThemeProvider>
  )
}
