import React, { useEffect, useState } from "react"
import { useStateMachine } from "../../../context/stateMachine/StateMachineContext"
import { COMPANY_DEDUCTIBLE_STATUS, useUserDeductible } from "../../../context/user/DeductibleContext"
import { Steps } from "../../workflow/steps"
import { waitForMinimumDelay } from "../../../../utils/WaitForMinimumDelay"
import useInitialSetup from "../../hooks/useInitialSetup"
import { CenteredLoadingIndicator } from "../../../views/molecules/Molecules"
import { useBenefitEnrollment } from "#/components/context/enrollment/BenefitEnrollmentContext"
import { useCurrentUser } from "../../../context/user/UserContext"
import { GetWorkflow } from "../BenefitEnrollmentFlow"
import { Workflows } from "../../workflow/workflows"
import { MemberPlanSourceOptions } from "../MemberPlanSourceStep"
import { PartnerPlanSourceOptions } from "../PartnerPlanSourceStep"
import { Box } from "@carrotfertility/carotene-core"

const viewName = Steps.PROCESS_MEMBER_ENROLLMENT

function ProcessMemberEnrollmentStep(): JSX.Element {
  const {
    status: { needsBenefitEnrollment, areCommonInsuranceFieldsCompleted }
  } = useBenefitEnrollment()

  const {
    cmdConfiguration,
    companyDeductibleStatus,
    updateUserBenefitEnrollment,
    resetReimbursementDetailsEnrollmentStarted,
    enrollmentCreatedAndDataRefreshed
  } = useUserDeductible()
  const externallyManagedShouldSkip =
    areCommonInsuranceFieldsCompleted && companyDeductibleStatus === COMPANY_DEDUCTIBLE_STATUS.EXTERNAL

  const { memberPlanSource, partnerPlanSource, partnerPlanSourceSelectValue } = useCurrentUser()
  const workflowType = GetWorkflow()

  const { setNextStep } = useStateMachine(
    viewName,
    !needsBenefitEnrollment || externallyManagedShouldSkip ? "ALREADY_ENROLLED" : null
  )

  const [initialSetupComplete, setInitialSetupComplete] = useState(false)
  const [startedEnrollment, setStartedEnrollment] = useState(false)
  const [showLoadingIndicator, setShowLoadingIndicator] = useState(true)

  useInitialSetup(async () => {
    if (needsBenefitEnrollment) {
      setStartedEnrollment(true)
      await waitForMinimumDelay(updateUserBenefitEnrollment(), 1000)
    }
    setInitialSetupComplete(true)
  })

  useEffect(() => {
    // If we started a new enrollment we need to wait for data to be refreshed before
    // setting the next step.  If we didn't need to start creation of a new enrollment, we can move
    // immediately to the next step.
    if (
      (initialSetupComplete && startedEnrollment && enrollmentCreatedAndDataRefreshed) ||
      (initialSetupComplete && !startedEnrollment)
    ) {
      resetReimbursementDetailsEnrollmentStarted()

      setShowLoadingIndicator(false)
      let nextStep

      if (!startedEnrollment && workflowType !== Workflows.ACA_ONLY) {
        // If we didn't start a new enrollment it means we already enrolled and can move on to Connect Insurance
        nextStep = "ALREADY_ENROLLED"
      } else if (
        workflowType === Workflows.ACA_ONLY &&
        (memberPlanSource === MemberPlanSourceOptions.OTHER ||
          memberPlanSource === MemberPlanSourceOptions.NOT_INSURED ||
          partnerPlanSource === PartnerPlanSourceOptions.OTHER ||
          partnerPlanSource === PartnerPlanSourceOptions.NOT_INSURED)
      ) {
        nextStep = "HRA_INELIGIBLE"
      } else if (
        workflowType === Workflows.EMD &&
        partnerPlanSourceSelectValue !== PartnerPlanSourceOptions.MEMBER &&
        partnerPlanSource !== null
      ) {
        nextStep = "PARTNER_INELIGIBLE"
      } else if (workflowType === Workflows.CMD_NON_HDHP) {
        nextStep = "CMD_NON_HDHP"
      } else {
        nextStep =
          companyDeductibleStatus === COMPANY_DEDUCTIBLE_STATUS.CARROT_MANAGED_DEDUCTIBLE
            ? cmdConfiguration
            : companyDeductibleStatus
      }
      setNextStep(Steps.PROCESS_MEMBER_ENROLLMENT, nextStep)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- See https://carrotfertility.atlassian.net/wiki/spaces/PE/pages/2050295461/Remove+Build+Warnings#react-hooks%2Fexhaustive-deps
  }, [initialSetupComplete, enrollmentCreatedAndDataRefreshed, startedEnrollment])

  return <Box padding={(theme) => theme.tokens.spacing.xs}>{showLoadingIndicator && <CenteredLoadingIndicator />}</Box>
}

export { ProcessMemberEnrollmentStep }
