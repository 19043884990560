import React from "react"
import { useTrackView } from "../../../utils/heap"
import { FormattedMessage, useIntl } from "react-intl"
import { RegistrationFlowLayout } from "./RegistrationLayouts"
import { Box, Typography } from "@carrotfertility/carotene-core"
import { RegistrationButtonFooter, SkipButton, ContinueButton } from "./RegistrationButtons"
import { RegistrationFlowTitle } from "./RegistrationFlowTitle"
import { Form, FormTextField } from "@carrotfertility/carotene-core-x"

interface PreferredNameProps {
  preferredName?: string
  setPreferredName: (newValue: string) => void
  onBack: () => void
  onComplete: () => void
}
export function PreferredName({
  preferredName,
  setPreferredName,
  onBack,
  onComplete
}: PreferredNameProps): JSX.Element {
  const intl = useIntl()
  function handleSkip(): void {
    setPreferredName(null)
    onComplete()
  }
  // @ts-expect-error TS(7006) FIXME: Parameter 'formValues' implicitly has an 'any' typ... Remove this comment to see the full error message
  function onSubmit(formValues): void {
    if (formValues.preferredName) {
      setPreferredName(formValues.preferredName)
      onComplete()
    } else {
      handleSkip()
    }
  }
  useTrackView("PreferredNameRegFlow", "Preferred name screen")
  const header = intl.formatMessage({
    defaultMessage: "What should we call you?"
  })

  return (
    <>
      <RegistrationFlowTitle title={header} />
      <RegistrationFlowLayout
        header={header}
        description={
          <Box marginBottom={(theme) => theme.spacing(theme.tokens.spacing.lg)}>
            <Typography>
              <FormattedMessage defaultMessage="The name you'd like us to use, if different from your legal name. This will not be shared with your benefit sponsor." />
            </Typography>
          </Box>
        }
        interactiveElements={
          <Form onSubmit={onSubmit}>
            <FormTextField
              name="preferredName"
              label={intl.formatMessage({ defaultMessage: "Name" })}
              defaultValue={preferredName}
            />
            <RegistrationButtonFooter
              onBack={onBack}
              skipButton={
                <SkipButton onClick={handleSkip}>
                  <FormattedMessage defaultMessage="Skip" />
                </SkipButton>
              }
              continueButton={
                <ContinueButton>
                  <FormattedMessage defaultMessage="Continue" />
                </ContinueButton>
              }
            />
          </Form>
        }
      />
    </>
  )
}
