import toast from "react-hot-toast"
import { useMutation, UseMutationResult, useQueryClient } from "@tanstack/react-query"
import { loadingToast, successToast, errorToast } from "#/components/toasts"
import { useIntl } from "react-intl"
import buildCarrotApiRetryPolicy from "#/utils/CarrotApiRetryPolicy"

interface UseToastsWithMutationArgs {
  request: (...args: any) => Promise<any>
  loadingMessage?: string
  getSuccessMessage: () => string
  queryKey?: string
  onSuccess?: () => void
  retry?: boolean
}

export function useToastsWithMutation(args: UseToastsWithMutationArgs): UseMutationResult {
  const { request, loadingMessage, getSuccessMessage, queryKey, onSuccess: onSuccessCallback, retry } = args
  const intl = useIntl()

  const queryClient = useQueryClient()

  return useMutation(request, {
    onMutate: () => {
      loadingToast(loadingMessage)
    },
    onSuccess: () => {
      const message = getSuccessMessage()

      successToast(message)
      toast.dismiss("loading")

      if (queryKey != null) {
        queryClient.invalidateQueries([queryKey])
      }

      onSuccessCallback()
    },
    onError: () => {
      toast.dismiss("loading")
      errorToast(intl.formatMessage({ defaultMessage: "Something went wrong. Please try again." }))
    },
    retry: retry ?? buildCarrotApiRetryPolicy(1)
  })
}
