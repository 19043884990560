import React from "react"
import { Helmet } from "react-helmet"
import { useLocation } from "react-router"
import { Paths } from "#/utils/Paths"
import { defineMessage, MessageDescriptor, useIntl } from "react-intl"

const map: Record<string, MessageDescriptor> = {
  "/account": defineMessage({ defaultMessage: "Account settings" }),
  "/chat": defineMessage({ defaultMessage: "Expert chat" }),
  "/read": defineMessage({ defaultMessage: "Carrot Academy" }),
  "/reimbursements/": defineMessage({ defaultMessage: "Get reimbursed" }),
  "/transactions": defineMessage({ defaultMessage: "Financial support" }),
  "/support": defineMessage({ defaultMessage: "Carrot Card® support" }),
  "/talk-to-carrot": defineMessage({ defaultMessage: "Talk to Carrot" }),
  "/get-help": defineMessage({ defaultMessage: "Get help" }),
  "/insurance-details": defineMessage({ defaultMessage: "Insurance details" }),
  "/messages": defineMessage({ defaultMessage: "Messages" }),
  "/bank-details": defineMessage({ defaultMessage: "Bank details" }),
  "/make-a-payment": defineMessage({ defaultMessage: "Make a payment" }),
  [Paths.YOUR_BENEFIT_GUIDE]: defineMessage({ defaultMessage: "Your benefit guide" })
}

const homePagePaths = ["/", "/doula-attestation"]

function titleMap(pathname: string): MessageDescriptor {
  if (homePagePaths.includes(pathname)) return defineMessage({ defaultMessage: "Your Carrot Plan" })
  for (const key in map) {
    if (pathname.startsWith(key)) return map[key]
  }
  return defineMessage({ defaultMessage: "Page not found" })
}

export function Title(props: { title?: string; productTitle?: string }): JSX.Element {
  const intl = useIntl()
  const location = useLocation()
  const title: string = props.title ? props.title : intl.formatMessage(titleMap(location.pathname))
  const productTitle: string = props.productTitle
    ? props.productTitle
    : intl.formatMessage({ defaultMessage: "Carrot Fertility" })
  const combinedTitle: string = title
    ? intl.formatMessage(
        {
          defaultMessage: "{title} | {productTitle}",
          description:
            "The title of the current page as shown in the browser's tab or window. {pageTitle} is the name of current page. {productTitle} is the name of the product, usually 'Carrot Fertility'"
        },
        {
          title,
          productTitle
        }
      )
    : productTitle

  return (
    <Helmet>
      <title>{combinedTitle}</title>
    </Helmet>
  )
}
