import { useQuery } from "@tanstack/react-query"
import buildCarrotApiRetryPolicy from "#/utils/CarrotApiRetryPolicy"
import ResponseError from "#/types/responseError"
import { reportErrorMessage } from "#/utils/ErrorReporting"
import { getZendeskMessagingWidgetDisplay } from "#/lib/contentful/contentfulClient"
import { EntryWithoutUnresolvableLinks } from "#/utils/ContentTypes"
import { TypeTileDisplaySkeleton } from "#/types/contentful"

const zendeskMessagingWidgetDisplay = "getZendeskMessagingWidgetDisplay"

export function useGetTalkToCarrotZendeskMessagingWidgetDisplay(locale: string) {
  return useQuery<EntryWithoutUnresolvableLinks<TypeTileDisplaySkeleton>, ResponseError>(
    [zendeskMessagingWidgetDisplay],
    () => getZendeskMessagingWidgetDisplay(locale),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      refetchOnReconnect: false,
      retry: buildCarrotApiRetryPolicy(3),
      onError: (error) => reportErrorMessage(`Failed to fetch Zendesk Messaging Widget tile with error: ${error}`)
    }
  )
}
