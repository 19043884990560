import { UseQueryResult, useQuery } from "@tanstack/react-query"
import {
  isMobileAndSupportsPushNotifications,
  isAppOutdated,
  isPushPermissionsEnabled
} from "#/lib/carrot-api/MobileNativeClient"

const appStatusQueryKey = "getAppStatus"
const pushPermissionsEnabledQueryKey = "getPushPermissionsEnabled"
const pushNotificationSupportQueryKey = "getPushNotificationSupport"

export function useIsAppOutdated(): UseQueryResult<boolean, Error> {
  return useQuery<boolean, Error>([appStatusQueryKey], isAppOutdated, {
    refetchOnWindowFocus: false
  })
}

export function useIsPushPermissionsEnabled(): UseQueryResult<boolean, Error> {
  return useQuery<boolean, Error>([pushPermissionsEnabledQueryKey], isPushPermissionsEnabled, {
    refetchOnWindowFocus: false
  })
}

export function useIsMobileAndSupportsPushNotifications(): UseQueryResult<boolean, Error> {
  return useQuery<boolean, Error>([pushNotificationSupportQueryKey], isMobileAndSupportsPushNotifications, {
    refetchOnWindowFocus: false
  })
}
