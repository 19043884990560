import { ExpenseState } from "../../../../utils/ExpenseState"

// @ts-expect-error TS(7006) FIXME: Parameter 'reimbursements' implicitly has an 'any'... Remove this comment to see the full error message
export const getReimbursementsRequiringActionList = (reimbursements) => {
  return reimbursements
    ? reimbursements.filter(
        // @ts-expect-error TS(7006) FIXME: Parameter 'x' implicitly has an 'any' type.
        (x) =>
          x.expenseState === ExpenseState.NEEDS_MEMBER_UPLOADS ||
          (x.isCardExpense &&
            (x.expenseState === ExpenseState.DENIED_RECONCILIATION_REQUESTED ||
              x.expenseState === ExpenseState.PENDING_MEMBER_INFO))
      )
    : []
}

// @ts-expect-error TS(7006) FIXME: Parameter 'reimbursements' implicitly has an 'any'... Remove this comment to see the full error message
export const getPendingReimbursementsList = (reimbursements) => {
  return reimbursements
    ? reimbursements.filter(
        // @ts-expect-error TS(7006) FIXME: Parameter 'x' implicitly has an 'any' type.
        (x) =>
          (!x.isCardExpense &&
            (x.expenseState === ExpenseState.RECEIVED || x.expenseState === ExpenseState.APPROVED)) ||
          (x.isCardExpense && x.expenseState === ExpenseState.PENDING_CARE_NAV) ||
          x.expenseState === ExpenseState.ON_HOLD
      )
    : []
}

// @ts-expect-error TS(7006) FIXME: Parameter 'reimbursements' implicitly has an 'any'... Remove this comment to see the full error message
export const getCategoryPendingReimbursementsList = (reimbursements) => {
  return reimbursements
    ? reimbursements.filter(
        // @ts-expect-error TS(7006) FIXME: Parameter 'x' implicitly has an 'any' type.
        (x) =>
          (!x.isCardExpense &&
            ((x.expenseState === ExpenseState.RECEIVED && x.hasLineItems) ||
              x.expenseState === ExpenseState.APPROVED)) ||
          (x.isCardExpense && x.expenseState === ExpenseState.PENDING_CARE_NAV) ||
          x.expenseState === ExpenseState.ON_HOLD
      )
    : []
}

// @ts-expect-error TS(7006) FIXME: Parameter 'reimbursements' implicitly has an 'any'... Remove this comment to see the full error message
export const getReceivedReimbursementsList = (reimbursements) => {
  return reimbursements
    ? reimbursements.filter((x: any) => !x.isCardExpense && x.expenseState === ExpenseState.RECEIVED && !x.hasLineItems)
    : []
}

// @ts-expect-error TS(7006) FIXME: Parameter 'reimbursements' implicitly has an 'any'... Remove this comment to see the full error message
export const getCompletedReimbursementsList = (reimbursements) => {
  return reimbursements
    ? reimbursements.filter(
        // @ts-expect-error TS(7006) FIXME: Parameter 'x' implicitly has an 'any' type.
        (x) =>
          (!x.isCardExpense && (x.expenseState === ExpenseState.DENIED || x.expenseState === ExpenseState.PAID)) ||
          (x.isCardExpense &&
            (x.expenseState === ExpenseState.APPROVED || x.expenseState === ExpenseState.DENIED_RECONCILED))
      )
    : []
}
