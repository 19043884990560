import { ProviderFinderClient } from "#/lib/carrot-api/ProviderFinderClient"
import Settings from "#/utils/CarrotConfig"
import { useAuthentication } from "#/components/context/authentication/AuthenticationProvider"
import { useMemo } from "react"
import { MockProviderFinderClient } from "#/lib/carrot-api/MockProviderFinderClient"

export const useProviderFinderClient = (): ProviderFinderClient | typeof MockProviderFinderClient => {
  const { handleSessionExpiration } = useAuthentication()

  if (!handleSessionExpiration) {
    throw new Error("Missing handleSessionExpiration")
  }

  return useMemo(() => {
    return process.env.NODE_ENV === "development"
      ? MockProviderFinderClient
      : new ProviderFinderClient(Settings.PROVIDER_FINDER_BASE_URL, handleSessionExpiration)
  }, [handleSessionExpiration])
}
