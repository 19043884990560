import { useQuery } from "@tanstack/react-query"
import buildCarrotApiRetryPolicy from "#/utils/CarrotApiRetryPolicy"
import ResponseError from "#/types/responseError"
import { reportErrorMessage } from "#/utils/ErrorReporting"
import { getTalkToCarrotPhoneSupportDisplay } from "#/lib/contentful/contentfulClient"
import { EntryWithoutUnresolvableLinks } from "#/utils/ContentTypes"
import { TypeTileDisplaySkeleton } from "#/types/contentful"

const phoneSupportDisplay = "getTalkToCarrotPhoneSupportDisplay"

export function useGetTalkToCarrotPhoneSupportDisplay(locale: string) {
  return useQuery<EntryWithoutUnresolvableLinks<TypeTileDisplaySkeleton>, ResponseError>(
    [phoneSupportDisplay],
    () => getTalkToCarrotPhoneSupportDisplay(locale),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      refetchOnReconnect: false,
      retry: buildCarrotApiRetryPolicy(3),
      onError: (error) => reportErrorMessage(`Failed to fetch phone support tile with error: ${error}`)
    }
  )
}
