import { useMutation, UseMutationResult, useQuery, useQueryClient, UseQueryResult } from "@tanstack/react-query"
import { carrotClient } from "../../../utils/CarrotClient"
import { RoutingInfo } from "#/types/routingInfo"
import ResponseError from "../../../types/responseError"
import { HttpStatusCodes } from "../../../utils/HttpStatusCodes"
import buildCarrotApiRetryPolicy from "../../../utils/CarrotApiRetryPolicy"
import { carrotPlanEmployeeSettingsQueryKey } from "../hooks/useCarrotPlanEmployeeSettings"

export const ROUTING_INFO_QUERY_KEY = "routingInfoQueryKey"

function handleQueryError(error: ResponseError): void {
  const statusCode = error?.response?.status
  if (statusCode && statusCode === HttpStatusCodes.FORBIDDEN) {
    return
  }
}

/**
 * Fetches most recently routing info for the current user which may be INCOMPLETE if the user dropped out of routing early.
 * */
export function useGetRoutingInfo(): UseQueryResult<RoutingInfo, ResponseError> {
  return useQuery([ROUTING_INFO_QUERY_KEY], () => carrotClient.getCarrotPlansRoutingInfo(), {
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    refetchOnReconnect: false,
    retry: false,
    onError: handleQueryError
  })
}

/**Warning: Before creating and utilizing a hook like this:
 * Check to see if you can instead set the staleTime as an option on the query.
 */
export function useCheckRoutingInfoQueryCache(): RoutingInfo {
  const queryClient = useQueryClient()
  return queryClient.getQueryData([ROUTING_INFO_QUERY_KEY])
}

export function useStartJourneyChangeRoutingInfo(): UseMutationResult {
  const queryClient = useQueryClient()
  return useMutation(() => carrotClient.startJourneyChangeRoutingInfoFlow(), {
    onSuccess: () => queryClient.refetchQueries([ROUTING_INFO_QUERY_KEY]),
    retry: false
  })
}

export function useRequestJourneyChangeCarrotPlan(): UseMutationResult {
  const queryClient = useQueryClient()
  return useMutation(() => carrotClient.requestJourneyChangeCarrotPlan(), {
    retry: buildCarrotApiRetryPolicy(3),
    onSuccess: async () => {
      await queryClient.refetchQueries([ROUTING_INFO_QUERY_KEY])
      await queryClient.refetchQueries([carrotPlanEmployeeSettingsQueryKey])
    }
  })
}

export function useStartRoutingInfo(): UseMutationResult {
  const queryClient = useQueryClient()
  return useMutation(() => carrotClient.startRoutingInfoFlow(), {
    onSuccess: () => queryClient.refetchQueries([ROUTING_INFO_QUERY_KEY]),
    retry: false
  })
}

export function useUpdateRoutingInfo(): UseMutationResult {
  const queryClient = useQueryClient()
  const data = queryClient.getQueryData([ROUTING_INFO_QUERY_KEY])
  return useMutation(
    async (payload) => {
      if (!data) {
        await carrotClient.startRoutingInfoFlow()
      }
      await carrotClient.updateRoutingInfo(payload)
    },
    {
      onSuccess: () => queryClient.refetchQueries([ROUTING_INFO_QUERY_KEY]),
      retry: false
    }
  )
}

export function hasQuestionBeenSkipped(questionKey: string, data: RoutingInfo): boolean {
  return (data?.skipFields || []).includes(questionKey)
}
