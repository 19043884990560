import React from "react"
import { Redirect, Route } from "react-router"
import { useCurrentUser } from "../../context/user/UserContext"

// @ts-expect-error TS(7031) FIXME: Binding element 'Component' implicitly has an 'any... Remove this comment to see the full error message
export default function LockedDownRoute({ component: Component, path, ...rest }): JSX.Element {
  const { isInAppLockdown } = useCurrentUser()
  return (
    <Route
      path={path}
      {...rest}
      render={(props) => {
        return isInAppLockdown ? <Redirect to="/carrot-plan-required" /> : <Component {...props} />
      }}
    />
  )
}
