import React from "react"
import { Error } from "./Error"
import { useCurrentUser } from "../context/user/UserContext"
import { getBenefitConfigurationLoadFailed } from "#/redux/reducers/benefitConfiguration"
import { useSelector } from "react-redux"
import { Box, Progress } from "@carrotfertility/carotene-core"

export const Loading = () => {
  // @ts-expect-error TS(2339) FIXME: Property 'userInfoLoadError' does not exist on typ... Remove this comment to see the full error message
  const { userInfoLoadError } = useCurrentUser()
  const benefitLoadError = useSelector(getBenefitConfigurationLoadFailed)
  const hasError = userInfoLoadError || benefitLoadError
  return hasError ? <Error /> : <LoadingInner />
}

export const LoadingInner = () => {
  return (
    <Box
      display="flex"
      bgcolor={(theme) => theme.palette.info.light}
      justifyContent="center"
      alignItems="center"
      sx={{ blockSize: "100vb", inlineSize: "100vi" }}
    >
      <Progress />
    </Box>
  )
}
