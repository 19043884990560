import React from "react"
import { useIntl } from "react-intl"
import { Box, MessagesIcon } from "@carrotfertility/carotene-core"
import useGetThreads from "#/components/messaging/hooks/useGetThreads"
import { NavItemWrapper } from "./MainNavigation"

export function MessagesNavItem({ onClick }: { onClick: () => void }): JSX.Element {
  const { data: messageThreads } = useGetThreads()
  const unread = messageThreads?.threads?.some((thread) => thread.hasUnreadMessages)
  const intl = useIntl()

  return (
    <NavItemWrapper
      link="/messages"
      onClick={onClick}
      icon={<MessagesIcon />}
      text={intl.formatMessage({ defaultMessage: "Messages" })}
      extraNavIndicator={
        <>
          {unread ? (
            <Box display="flex" justifyContent="flex-end">
              <Box
                bgcolor={(theme) => theme.palette.primary.main}
                borderRadius={(theme) => theme.tokens.borderRadius.round}
                height="7px"
                width="7px"
              />
            </Box>
          ) : null}
        </>
      }
    />
  )
}
