import React from "react"
import { useStateMachine } from "../../../context/stateMachine/StateMachineV2"
import { Form } from "@carrotfertility/carotene-core-x"
import { useGetMedicalInfo, useMedicalInfo } from "../../context/MedicalInfoContext"
import { LowTestosteroneSymptomsDetails } from "./MedicalInfoOptions"
import {
  StepLayout,
  ButtonFooterLayout,
  ContinueButton,
  SkipButton,
  RoutingFlowCheckboxGroup,
  useConvertTranslatedLabel,
  RoutingFlowTitle
} from "#/components/carrot-plans/shared"
import { FormattedMessage, useIntl } from "react-intl"

type FormValues = {
  lowTestosteroneSymptoms?: string[]
}

export default function LowTestosteroneSymptomsStep(): JSX.Element {
  const intl = useIntl()
  const { updateMedicalInfo, hasQuestionBeenSkipped } = useMedicalInfo()
  const { data: medicalInfo } = useGetMedicalInfo()
  const defaultValues = medicalInfo?.lowTestosteroneSymptoms

  const options = useConvertTranslatedLabel(LowTestosteroneSymptomsDetails)

  const { send } = useStateMachine(defaultValues || hasQuestionBeenSkipped("lowTestosteroneSymptoms") ? "" : null)

  async function onClickContinue(values: FormValues): Promise<void> {
    await updateMedicalInfo({
      lowTestosteroneSymptoms: values?.lowTestosteroneSymptoms
    })
    send("")
  }

  async function onSkip(): Promise<void> {
    await updateMedicalInfo({ skipFields: ["lowTestosteroneSymptoms"] })
    send("")
  }

  const header = intl.formatMessage({
    defaultMessage: "Have you noticed any of the following symptoms?"
  })

  return (
    <>
      <RoutingFlowTitle title={header} />
      <StepLayout
        header={<span id="lowTestosteroneSymptoms-label">{header}</span>}
        description={<FormattedMessage defaultMessage="Select any that apply." />}
        formElements={
          <Form
            defaultValues={{
              lowTestosteroneSymptoms: medicalInfo?.lowTestosteroneSymptoms
            }}
            onSubmit={onClickContinue}
          >
            <RoutingFlowCheckboxGroup
              aria-labelledby="lowTestosteroneSymptoms-label"
              name="lowTestosteroneSymptoms"
              options={options}
            />

            <ButtonFooterLayout
              continueButton={
                <ContinueButton>
                  <FormattedMessage defaultMessage="Continue" />
                </ContinueButton>
              }
              skipButton={<SkipButton onClick={onSkip} />}
            />
          </Form>
        }
      />
    </>
  )
}
