import React, { useEffect } from "react"
import { useHistory } from "react-router"
import { useSignUpFlow } from "../context/signup/SignUpFlowContext"
import { AppAccessPageLayout } from "#/components/access-page/AppAccessPageLayout"
import { AppAccessPageCard } from "#/components/access-page/AppAccessPageCard"
import { FormattedMessage, useIntl } from "react-intl"
import { UnauthPreferredLocale } from "../views/UnauthPreferredLocale"
import { Box, Stack, Typography, useTheme } from "@carrotfertility/carotene-core"

export default function SignUpGetHelpSuccessPage() {
  const { email } = useSignUpFlow()
  const history = useHistory()
  const intl = useIntl()
  const theme = useTheme()

  useEffect(() => {
    if (!email) {
      history.push("/signup")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- See https://carrotfertility.atlassian.net/wiki/spaces/PE/pages/2050295461/Remove+Build+Warnings#react-hooks%2Fexhaustive-deps
  }, [])

  return (
    <AppAccessPageLayout title={intl.formatMessage({ defaultMessage: "We're on it" })}>
      <AppAccessPageCard>
        <Stack spacing={theme.spacing(theme.tokens.spacing.md)}>
          <Typography variant="h1">
            <FormattedMessage defaultMessage="We're on it" />
          </Typography>
          <Typography>
            <FormattedMessage defaultMessage="Our Care Team will reach out to you shortly. If you are in the US, you can also call us at 1 888-817-9040 to talk to one of our Carrot Companions." />
          </Typography>
          <Box display="block" sx={{ alignSelf: "center", marginBlockStart: theme.spacing(theme.tokens.spacing.xs) }}>
            <UnauthPreferredLocale />
          </Box>
        </Stack>
      </AppAccessPageCard>
    </AppAccessPageLayout>
  )
}
