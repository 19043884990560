import { fetchWrapper } from "#/lib/FetchWrapper/FetchWrapper"

export interface CarrotRxExtendRegistration {
  firstName: string
  lastName: string
  email: string
  phoneNumber: string
  dateOfBirth: string
  zipCode: string
}

export class CarrotRxExtendClient {
  baseUrl: string

  constructor(baseUrl: string) {
    this.baseUrl = baseUrl
  }

  async registerCarrotRxExtend(registration: CarrotRxExtendRegistration) {
    const headers = new Headers()
    headers.append("Content-Type", "application/json")
    const body = JSON.stringify(registration)
    const response = await fetchWrapper(`${this.baseUrl}/api/v2/carrot-rx-extend/register`, {
      body,
      headers,
      method: "POST"
    })

    try {
      return await response.text()
    } catch {
      return null
    }
  }
}
