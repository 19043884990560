import React from "react"
import { useIntl } from "react-intl"
import { Link, Stack, Tile, Typography, Box, useMediaQuery, Theme, Button } from "@carrotfertility/carotene-core"
import { Link as RouterLink } from "react-router-dom"
import { Journey } from "#/lib/contentful/utils/journeyOrderMapping"
import { ProviderRecommendationConfig } from "../../hooks/useShowProviderRecommendations"

type BannerProps = {
  heading: string
  description: string
  linkText: string
  providerFormLink: string
  journey: string
  showSecondarySteerageLink: boolean
  secondarySteerageLink: string
  secondarySteerageLinkText: string
}
function Banner({
  heading,
  description,
  linkText,
  providerFormLink,
  journey,
  showSecondarySteerageLink,
  secondarySteerageLink,
  secondarySteerageLinkText
}: BannerProps): JSX.Element {
  const showProviderImage = useMediaQuery((theme: Theme) => theme.breakpoints.up("sm"))
  return (
    <Tile color="primary" data-journey-type={journey}>
      <Stack direction="row" gap={(theme) => theme.tokens.spacing.lg}>
        {showProviderImage && (
          <Box component="img" src={"/images/provider.svg"} alt="" width={"7.5rem"} height={"7.5rem"} />
        )}
        <Stack gap={(theme) => theme.tokens.spacing.md}>
          <Stack gap={(theme) => theme.tokens.spacing.xs}>
            <Typography variant="h5" component="h3" color={(theme) => theme.palette.text.secondary}>
              {heading}
            </Typography>
            <Typography color={(theme) => theme.palette.text.secondary}>{description}</Typography>
          </Stack>
          {showSecondarySteerageLink ? (
            <Box
              display="flex"
              flexDirection={showProviderImage ? "row" : "column"}
              alignItems={showProviderImage ? "center" : "flex-start"}
              gap={(theme) => theme.spacing(theme.tokens.spacing.md)}
            >
              <Button
                size="medium"
                color="primary"
                variant="contained"
                component={RouterLink}
                to={providerFormLink}
                id="provider-recommendations-banner-primary-form-link"
              >
                {linkText}
              </Button>
              <Link
                color="inherit"
                component={RouterLink}
                to={secondarySteerageLink}
                id="provider-recommendations-banner-secondary-form-link"
              >
                {secondarySteerageLinkText}
              </Link>
            </Box>
          ) : (
            <Link
              color="inherit"
              component={RouterLink}
              to={providerFormLink}
              id="provider-recommendations-banner-form-link"
            >
              {linkText}
            </Link>
          )}
        </Stack>
      </Stack>
    </Tile>
  )
}

function PediatricianBanner({ showSecondarySteerageLink }: { showSecondarySteerageLink: boolean }): JSX.Element {
  const intl = useIntl()

  return (
    <Banner
      heading={intl.formatMessage({ defaultMessage: "Choose your pediatrician with confidence" })}
      description={intl.formatMessage({
        defaultMessage:
          "Finding the right pediatrician makes a difference. We simplify this step by connecting you with top-quality care nearby."
      })}
      linkText={intl.formatMessage({
        defaultMessage: "Request recommendations"
      })}
      providerFormLink="/providerfinder/find-pediatrician"
      journey={Journey.PARENTING}
      showSecondarySteerageLink={showSecondarySteerageLink}
      secondarySteerageLink="/providerfinder/find-obgyn"
      secondarySteerageLinkText={intl.formatMessage({
        defaultMessage: "Search for an OB/GYN instead"
      })}
    />
  )
}

function OBGYNBanner({ showSecondarySteerageLink }: { showSecondarySteerageLink: boolean }): JSX.Element {
  const intl = useIntl()

  return (
    <Banner
      heading={intl.formatMessage({ defaultMessage: "Choose your OB/GYN with confidence" })}
      description={intl.formatMessage({
        defaultMessage:
          "Finding the right OB/GYN makes a difference. We simplify this step by connecting you with top-quality care nearby."
      })}
      linkText={intl.formatMessage({
        defaultMessage: "Request recommendations"
      })}
      providerFormLink="/providerfinder/find-obgyn"
      journey={Journey.PREGNANT}
      showSecondarySteerageLink={showSecondarySteerageLink}
      secondarySteerageLink="/providerfinder/find-pediatrician"
      secondarySteerageLinkText={intl.formatMessage({
        defaultMessage: "Search for a pediatrician instead"
      })}
    />
  )
}

export function ProviderRecommendationBanner({
  providerRecommendationConfig
}: {
  providerRecommendationConfig: ProviderRecommendationConfig
}): JSX.Element {
  if (providerRecommendationConfig.showPediatricianRecommendationsBanner) {
    return <PediatricianBanner showSecondarySteerageLink={providerRecommendationConfig.showSecondarySteerageLink} />
  }
  if (providerRecommendationConfig.showObGynRecommendationsBanner) {
    return <OBGYNBanner showSecondarySteerageLink={providerRecommendationConfig.showSecondarySteerageLink} />
  }
  return null
}
