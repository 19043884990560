import React, { useState, useEffect } from "react"
import { useForm, FormProvider } from "react-hook-form"
import { withRouter } from "react-router-dom"
import ResetPassword from "../../../pages/reset-password"
import { useIntl } from "react-intl"
import { carrotClient } from "#/utils/CarrotClient"
import { validatePassword } from "../../../services/common-forms"
import { RouteComponentProps } from "react-router"
import { CarrotErrorCodes, getErrorMessageFromCode } from "../../../utils/CarrotErrors"

export const ResetPasswordContext = React.createContext<{
  isSubmitting: boolean
  isLoaded: boolean
  onSubmit: (data: any) => void
  isGuidError: boolean
}>({
  isSubmitting: false,
  isLoaded: false,
  onSubmit: () => Promise<void>,
  isGuidError: false
})

const defaultState = {
  isGuidError: false,
  isResetPasswordError: false,
  isLoaded: false,
  isSubmitting: false,
  isSuccess: false
}

interface MatchParams {
  guid: string
}

const ResetPasswordContainer = ({ match, history }: RouteComponentProps<MatchParams>): React.ReactElement => {
  const intl = useIntl()
  const [state, setState] = useState(defaultState)
  const formMethods = useForm({ mode: "onSubmit" })
  const { setError, clearErrors, handleSubmit } = formMethods

  const setPasswordError = (message: string) => {
    setError("password", { message, type: "invalidPassword" })
    setError("confirmPassword", { message, type: "invalidPassword" })
  }

  const onSubmit = handleSubmit(async (formData) => {
    const isValid = await validateInputs(formData)
    const { password, confirmPassword } = formData

    if (isValid) {
      setState({ ...state, isSubmitting: true })
      try {
        await carrotClient.resetPassword(password, confirmPassword, match.params.guid)
      } catch (error) {
        setState({ ...state, isSubmitting: false })
        setPasswordError(
          intl.formatMessage({ defaultMessage: "An error occurred while trying to reset your password" })
        )
        return
      }

      history.push("/reset-password-success")
    }
  })

  const validateInputs = async (formData: any) => {
    const { password, confirmPassword } = formData
    if (password !== confirmPassword) {
      setPasswordError(intl.formatMessage({ defaultMessage: "Passwords do not match" }))
      return false
    }
    if (password.length < 10 || confirmPassword.length < 10) {
      setPasswordError(intl.formatMessage(getErrorMessageFromCode(CarrotErrorCodes.PASSWORD_MIN_LENGTH)))
      return false
    }
    if (password.length > 64 || confirmPassword.length > 64) {
      setPasswordError(intl.formatMessage(getErrorMessageFromCode(CarrotErrorCodes.PASSWORD_MAX_LENGTH)))
      return false
    }

    const isInValidMessage = await validatePassword(password)
    if (isInValidMessage) {
      setPasswordError(intl.formatMessage(isInValidMessage))
      return false
    }

    clearErrors()
    return true
  }

  useEffect(() => {
    const validateGuid = async () => {
      try {
        await carrotClient.validateResetPasswordGuid(match.params.guid)
      } catch (error) {
        setState((state) => ({ ...state, isGuidError: true }))
        return
      }
      setState((state) => ({ ...state, isLoaded: true }))
    }
    validateGuid()
  }, [match.params.guid])

  const context = {
    isSubmitting: state.isSubmitting,
    isLoaded: state.isLoaded,
    onSubmit: onSubmit,
    isGuidError: state.isGuidError
  }

  return (
    <FormProvider {...formMethods}>
      <ResetPasswordContext.Provider value={context}>
        <ResetPassword />
      </ResetPasswordContext.Provider>
    </FormProvider>
  )
}
export default withRouter(ResetPasswordContainer)
