import { Container, FlexContainer, Stack } from "@carrotfertility/carotene"
import React, { useState } from "react"
import { Box, FormControlLabel, Radio, RadioGroup, Typography } from "@carrotfertility/carotene-core"

import { defaultMarkdownElements } from "#/services/markdown/elements"
import { surveyOptions } from "../lib/SurveyConstants"
import { useConvertTranslatedLabel } from "#/components/carrot-plans/shared"

export const GuideSurvey = ({
  title,
  text,
  questionIndex,
  onQuestionSelect
}: {
  title: string
  text: string
  questionIndex: number
  onQuestionSelect: (questionIndex: string, selectedOption: number) => void
}): JSX.Element => {
  const radioOptions = useConvertTranslatedLabel(surveyOptions)

  const [answeredQuestions, setAnsweredQuestions] = useState<{ [key: string]: string }>({})

  const onInputChange = (e: any): void => {
    onQuestionSelect(`Question${questionIndex}`, e.target.value)
    setAnsweredQuestions((prevState) => ({ ...prevState, [`Question${questionIndex}`]: e.target.value }))
  }

  return (
    <>
      <FlexContainer gap="large" direction="column" width={["100%"]}>
        <Stack>
          <defaultMarkdownElements.h2.component {...defaultMarkdownElements.h2.props} id="section-title">
            {title}
          </defaultMarkdownElements.h2.component>

          <Container>
            <defaultMarkdownElements.p.component {...defaultMarkdownElements.p.props} id="section-description">
              {text}
            </defaultMarkdownElements.p.component>

            <Box
              component={"fieldset"}
              aria-labelledby="guide-survey-options"
              aria-describedby="guide-survey-options"
              role="radiogroup"
              id={`Question${questionIndex}-survey-options`}
            >
              {questionIndex > 0 && (
                <>
                  <RadioGroup value={answeredQuestions[`Question${questionIndex}`] || ""} onChange={onInputChange}>
                    {radioOptions.map((option) => (
                      <FormControlLabel
                        className={`Question${questionIndex}-survey-option-button-${option.value}`}
                        key={option.value as string}
                        value={option.value}
                        control={<Radio />}
                        label={<Typography variant="body1">{option.label}</Typography>}
                        sx={(theme) => ({
                          margin: "0rem !important",
                          padding: theme.spacing(theme.tokens.spacing.sm),
                          borderRadius: theme.tokens.borderRadius.md,
                          borderStyle: "solid",
                          borderWidth: "2px",
                          inlineSize: "fit-content",
                          alignItems: "center",
                          borderColor:
                            option.value === answeredQuestions[`Question${questionIndex}`]
                              ? theme.palette.secondary.main
                              : "transparent",
                          [`:not(.Mui-disabled):hover`]: {
                            bgcolor: theme.palette.secondary.light
                          },
                          "&.Mui-disabled": {
                            borderColor:
                              option.value === answeredQuestions[`Question${questionIndex}`]
                                ? theme.palette.action.disabled
                                : "transparent"
                          }
                        })}
                      />
                    ))}
                  </RadioGroup>
                </>
              )}
            </Box>
          </Container>
        </Stack>
      </FlexContainer>
    </>
  )
}
