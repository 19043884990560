type PushNotificationsControl = {
  // API for wiring up the mobile app bridge. This get utilized by the Carrot React app
  _isPushPermissionsEnabled: boolean
  _promptPushPermissions: () => () => void

  // API that the mobile apps use
  updatePushPermissionsEnabled: (enabled: boolean) => void
  promptPushPermissions: () => () => void
}

export const pushNotificationsControl = globalThis as unknown as PushNotificationsControl

export function updatePushNotificationsControl(key: keyof PushNotificationsControl, val: unknown) {
  // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  globalThis[key] = val
}

// Register some callbacks that the native implementations will dynamically call back with the response
// It's ugly but we don't have another choice at this time.
const initPushNotificationsGlobalCallback = () => {
  updatePushNotificationsControl("updatePushPermissionsEnabled", (enabled: boolean) => {
    updatePushNotificationsControl("_isPushPermissionsEnabled", enabled)
  })

  updatePushNotificationsControl("promptPushPermissions", () => {
    pushNotificationsControl._promptPushPermissions()
  })
}

export { initPushNotificationsGlobalCallback }
