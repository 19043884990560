import useBenefit from "../../../dynamic-content/hooks/useGetBenefit"
import { defineMessage, MessageDescriptor } from "react-intl"
import { DbgPaths, Paths, TtcPaths } from "../../../../utils/Paths"
import { ProgramType } from "#/lib/carrot-api/types/Benefit"

function useBenefitHighlights(): BenefitHighlightsResult {
  const { data: benefit, isLoading: isBenefitLoading } = useBenefit()
  const hasProviderFinder = benefit?.providerFinder.showProviderFinder
  const programType = benefit?.program.type
  const programTypeAllowsBenefitHighlight = programType === ProgramType.PRO || programType === ProgramType.CORE
  const filteredValueProps = getValuePropositions({ programType, hasProviderFinder })
  return { filteredValueProps, isBenefitLoading, programTypeAllowsBenefitHighlight, programType }
}

enum ValuePropositionTypes {
  PERSONALIZED_ADVICE = "PERSONALIZED_ADVICE",
  UNLIMITED_CHATS = "UNLIMITED_CHATS",
  EDUCATIONAL_RESOURCES = "EDUCATIONAL_RESOURCES",
  FUNDS = "FUNDS",
  PROVIDER_FINDER = "PROVIDER_FINDER"
}

interface ValueProposition {
  valuePropId: ValuePropositionTypes
  valuePropMessage: MessageDescriptor
  iconId: string
  link: string
}

interface BenefitHighlightsResult {
  filteredValueProps: ValueProposition[]
  isBenefitLoading: boolean
  programTypeAllowsBenefitHighlight: boolean
  programType: ProgramType
}

function getValuePropositions({
  programType,
  hasProviderFinder
}: {
  programType: ProgramType
  hasProviderFinder: boolean
}): ValueProposition[] {
  // Determine the exclusions based on the programType
  let exclusions: ValuePropositionTypes[] = []

  switch (programType) {
    case ProgramType.PRO:
      exclusions = [ValuePropositionTypes.PROVIDER_FINDER]
      break
    case ProgramType.CORE:
      exclusions = [ValuePropositionTypes.FUNDS]
      break
  }

  // Always exclude PROVIDER_FINDER if hasProviderFinder is false
  if (!hasProviderFinder) {
    exclusions.push(ValuePropositionTypes.PROVIDER_FINDER)
  }

  // Return filtered valuePropositionOptions, excluding any that match the exclusion list
  return valuePropositionOptions.filter((valueProp) => !exclusions.includes(valueProp.valuePropId))
}

const valuePropositionOptions: ValueProposition[] = [
  {
    valuePropId: ValuePropositionTypes.PERSONALIZED_ADVICE,
    valuePropMessage: defineMessage({ defaultMessage: "Personalized advice from benefit experts" }),
    iconId: "icn-heart-circle",
    link: TtcPaths.TTC_CARE_NAV
  },
  {
    valuePropId: ValuePropositionTypes.UNLIMITED_CHATS,
    valuePropMessage: defineMessage({ defaultMessage: "Unlimited chats with specialists in your journey" }),
    iconId: "icn-chats",
    link: TtcPaths.TTC_LOCATION
  },
  {
    valuePropId: ValuePropositionTypes.EDUCATIONAL_RESOURCES,
    valuePropMessage: defineMessage({ defaultMessage: "Expert-produced educational resources" }),
    iconId: "icn-light-bulb",
    link: Paths.READ
  },
  {
    valuePropId: ValuePropositionTypes.FUNDS,
    valuePropMessage: defineMessage({ defaultMessage: "Funds for eligible expenses" }),
    iconId: "icn-dollar-sign-yellow",
    link: DbgPaths.CARROT_ELIGIBLE
  },
  {
    valuePropId: ValuePropositionTypes.PROVIDER_FINDER,
    valuePropMessage: defineMessage({ defaultMessage: "Access to a database of providers" }),
    iconId: "icn-star",
    link: Paths.PROVIDER_FINDER
  }
]

export type { ValueProposition }
export { useBenefitHighlights }
