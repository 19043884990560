import React from "react"
import { useConditionDepsStatus, useContentfulConditions } from "./hooks/use-contentful-conditions"
import { getCountryCode } from "#/redux/reducers/companyInfo"
import { useSelector } from "react-redux"
import { HttpStatusCodes } from "../../utils/HttpStatusCodes"
import { useAuthentication } from "#/components/context/authentication/AuthenticationProvider"

export enum AttributeConditionType {
  CANT_HAVE = "cantHave",
  MUST_HAVE = "mustHave",
  HAS_ANY = "hasAny"
}
export enum CountryConditionType {
  CANT_HAVE_COUNTRY = "cantHaveCountry",
  HAS_COUNTRY = "hasCountry"
}

export type Condition = {
  conditionType: AttributeConditionType | CountryConditionType
  attributes: Array<string>
}

export type ConditionalGatePropsTest = {
  conditions: Array<Condition>
}

type UseConditionGateArgs = Array<{ element: React.ReactNode; conditions: Array<Condition> }>

function getUserStateValues(
  attributeKeys: Array<string>,
  conditionsMap: Record<string, { value: boolean; toggle: (condition?: boolean) => void }>
): Array<boolean> {
  const serializeConditions = () => JSON.stringify(conditionsMap, null, 2)
  return attributeKeys.map((key) => {
    if (typeof conditionsMap[key] === "undefined") {
      throw new Error(`No mapping for Contentful attribute with key: ${key}. conditionsMap: ${serializeConditions()}`)
    } else if (typeof conditionsMap[key].value === "boolean") {
      return conditionsMap[key].value
    }

    const condition = JSON.stringify(conditionsMap[key])
    throw Error(
      `Invalid attribute value when mapping Contentful attributes. key: ${key}, conditionsMap[key]: ${condition}, conditionsMap: ${serializeConditions()}`
    )
  })
}

export function useDoesUserSatisfyConditions(allowConditionsOverride = false): {
  doesUserSatisfyConditions: (conditions: Array<Condition>) => boolean
  isLoading: boolean
} {
  const conditionsMap = useContentfulConditions(allowConditionsOverride)
  const companyCountryCode = useSelector(getCountryCode)

  const dependencies = useConditionDepsStatus()
  const { handleSessionExpiration } = useAuthentication()

  if (dependencies.isError) {
    if ([HttpStatusCodes.UNAUTHORIZED, HttpStatusCodes.FORBIDDEN].includes(dependencies.error.statusCode)) {
      handleSessionExpiration()
      return {
        doesUserSatisfyConditions: () => false,
        isLoading: false
      }
    }
    throw Error(`Error trying to retrieve condition dependencies: ${JSON.stringify(dependencies, null, 2)}`)
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'conditions' implicitly has an 'any' typ... Remove this comment to see the full error message
  function doesUserSatisfyConditions(conditions): boolean {
    if (!conditions) return true

    for (const { attributes, conditionType } of conditions) {
      switch (conditionType) {
        case AttributeConditionType.CANT_HAVE:
          if (getUserStateValues(attributes, conditionsMap).some(Boolean)) return false
          break
        case AttributeConditionType.MUST_HAVE:
          if (!getUserStateValues(attributes, conditionsMap).every(Boolean)) return false
          break
        case AttributeConditionType.HAS_ANY:
          if (!getUserStateValues(attributes, conditionsMap).some(Boolean)) return false
          break
        case CountryConditionType.CANT_HAVE_COUNTRY:
          if (attributes.includes(companyCountryCode)) return false
          break
        case CountryConditionType.HAS_COUNTRY:
          if (!attributes.includes(companyCountryCode)) return false
          break
      }
    }

    return true
  }
  return {
    doesUserSatisfyConditions,
    isLoading: dependencies.isLoading
  }
}

export function useConditionGate(
  componentsAndConditions: UseConditionGateArgs,
  allowConditionsOverride = false
): Array<React.ReactNode> {
  const { isLoading, doesUserSatisfyConditions } = useDoesUserSatisfyConditions(allowConditionsOverride)
  return isLoading
    ? []
    : componentsAndConditions
        .map(({ element, conditions }) => doesUserSatisfyConditions(conditions) && element)
        .filter(Boolean)
}
