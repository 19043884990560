import {
  Box,
  CarrotRxIcon,
  CloseIcon,
  Drawer,
  GetHelpIcon,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  NavigationIcon,
  ReadLearnIcon,
  TalkToCarrotIcon,
  Theme,
  useMediaQuery,
  useTheme,
  YourBenefitGuideIcon,
  YourCarrotPlanIcon
} from "@carrotfertility/carotene-core"
import { useCurrentUser } from "#/components/context/user/UserContext"
import { UserDropdown } from "#/features/global-ui/user-dropdown"
import React, { ReactElement } from "react"
import { useIntl } from "react-intl"
import { Link as RouterLink } from "react-router-dom"
import { useHasProviderFinder } from "./hooks/useHasProviderFinder"
import { MessagesNavItem } from "./MessagesNavItem"
import { getSupportCenterLink, goToHelpCenter } from "#/lib/carrot-api/HelpCenterClient"
import { FinancialSupportNavItem } from "./FinancialSupportNavItem"
import { FeatureGate } from "#/utils/FeatureGate"
import { ProviderFinderNavItem } from "./ProviderFinderNavItem"
import { useNavigateToDigitalFrontDoor } from "./hooks/useNavigateToDigitalFrontDoor"
import { TextDirection } from "#/types/Locale"
import { useTextDirection } from "#/services/locale/hooks/useTextDirection"

function getNavItemButtonStyles(theme: Theme, active?: boolean) {
  return {
    padding: theme.spacing(theme.tokens.spacing.sm, theme.tokens.spacing.md),
    background: active ? theme.palette.background.hover : null,
    color: theme.palette.text.secondary,
    ":focus, :hover": {
      background: theme.palette.background.hover,
      color: theme.palette.text.primary
    }
  }
}

export function NavItemWrapper({
  link,
  onClick,
  button = false,
  text,
  firstItem = false,
  icon,
  extraNavIndicator
}: {
  link?: string
  onClick?: () => void
  button?: boolean
  firstItem?: boolean
  text: string
  icon: ReactElement
  extraNavIndicator?: ReactElement
}): JSX.Element {
  const linkProps = button ? {} : { component: RouterLink, to: link }
  const active = link === window.location.pathname || (link !== "/" && window.location.pathname.startsWith(link))
  return (
    <ListItem
      disablePadding
      sx={{ position: "relative", marginY: firstItem ? (theme) => theme.spacing(theme.tokens.spacing.lg) : "unset" }}
    >
      {active ? (
        <Box
          position="absolute"
          bgcolor={(theme) => theme.palette.primary.main}
          width="6px"
          sx={{ inset: 0, zIndex: 2 }}
        />
      ) : null}
      <ListItemButton {...linkProps} sx={(theme) => getNavItemButtonStyles(theme, active)} onClick={onClick}>
        <ListItemIcon
          sx={{
            color: "inherit"
          }}
        >
          {React.cloneElement(icon, { color: "inherit" })}
        </ListItemIcon>
        <ListItemText primaryTypographyProps={{ color: "inherit" }} primary={text} />
        {extraNavIndicator}
      </ListItemButton>
    </ListItem>
  )
}

function NavItemsList({ setDrawerOpen }: { setDrawerOpen: (drawerOpen: boolean) => void }): JSX.Element {
  const intl = useIntl()
  const closeDrawer = () => setDrawerOpen(false)
  const { isInAppLockdown, company } = useCurrentUser()
  const hasProviderFinder = useHasProviderFinder(company.countryCode)
  const { navigateToDigitalFrontDoor } = useNavigateToDigitalFrontDoor()

  const navItemsToShow: {
    component: ReactElement
    key: string
    showForCarrotLite: boolean
    isHidden?: boolean
  }[] = [
    {
      key: "nav-messages-link",
      showForCarrotLite: true,
      component: <MessagesNavItem onClick={closeDrawer} />
    },
    {
      key: "nav-library-link",
      showForCarrotLite: true,
      component: (
        <NavItemWrapper
          link={"/read"}
          onClick={closeDrawer}
          icon={<ReadLearnIcon />}
          text={intl.formatMessage({ defaultMessage: "Carrot Academy" })}
        />
      )
    },
    {
      key: "nav-transactions-link",
      showForCarrotLite: false,
      component: <FinancialSupportNavItem onClick={closeDrawer} showLockedIcon={isInAppLockdown} />
    },
    {
      key: "nav-ttc-link",
      showForCarrotLite: true,
      component: (
        <NavItemWrapper
          link={"/talk-to-carrot"}
          onClick={closeDrawer}
          icon={<TalkToCarrotIcon />}
          text={intl.formatMessage({ defaultMessage: "Talk to Carrot" })}
        />
      )
    },
    {
      key: "nav-provider-finder-link",
      showForCarrotLite: true,
      isHidden: !hasProviderFinder,
      component: <ProviderFinderNavItem onClick={closeDrawer} />
    },
    {
      key: "nav-your-benefit-guide-link",
      showForCarrotLite: true,
      component: (
        <NavItemWrapper
          link={"/your-benefit-guide"}
          onClick={closeDrawer}
          icon={<YourBenefitGuideIcon />}
          text={intl.formatMessage({ defaultMessage: "Your benefit guide" })}
        />
      )
    },
    {
      key: "nav-carrot-rx-link",
      showForCarrotLite: true,
      isHidden: !navigateToDigitalFrontDoor,
      component: (
        <ListItem disablePadding onClick={() => closeDrawer()}>
          <ListItemButton
            id="carrot-rx-nav-link"
            sx={(theme) => getNavItemButtonStyles(theme)}
            component="a"
            href={"/carrot-rx"}
            target="_blank"
          >
            <ListItemIcon
              sx={{
                color: "inherit"
              }}
            >
              <CarrotRxIcon color="inherit" />
            </ListItemIcon>
            <ListItemText
              primaryTypographyProps={{ color: "inherit" }}
              primary={intl.formatMessage({ defaultMessage: "Carrot Rx" })}
            />
          </ListItemButton>
        </ListItem>
      )
    },
    {
      key: "nav-support-center-link",
      showForCarrotLite: true,
      component: (
        <FeatureGate filter={(c) => c.isSupportCenterEnabled}>
          <ListItem
            disablePadding
            onClick={(event) => {
              goToHelpCenter(event)
              closeDrawer()
            }}
          >
            <ListItemButton
              sx={(theme) => getNavItemButtonStyles(theme)}
              component="a"
              href={getSupportCenterLink()}
              target="_blank"
            >
              <ListItemIcon
                sx={{
                  color: "inherit"
                }}
              >
                <GetHelpIcon color="inherit" />
              </ListItemIcon>
              <ListItemText
                primaryTypographyProps={{ color: "inherit" }}
                primary={intl.formatMessage({ defaultMessage: "Get help" })}
              />
            </ListItemButton>
          </ListItem>
        </FeatureGate>
      )
    }
  ]
    .filter((ni) => (company.carrotLite && ni.showForCarrotLite) || !company.carrotLite)
    .filter(({ isHidden }) => !isHidden)

  return (
    <List>
      <NavItemWrapper
        firstItem
        link={"/"}
        onClick={closeDrawer}
        icon={<YourCarrotPlanIcon />}
        text={intl.formatMessage({ defaultMessage: "Your Carrot Plan" })}
      />
      {navItemsToShow.map(({ component, key }) => React.cloneElement(component, { key }))}
    </List>
  )
}

function MobileNav({
  drawerOpen,
  setDrawerOpen
}: {
  drawerOpen: boolean
  setDrawerOpen: (drawerOpen: boolean) => void
}) {
  const intl = useIntl()
  const textDirection = useTextDirection()

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        padding={(theme) => theme.tokens.spacing.md}
        bgcolor={(theme) => theme.palette.background.default}
        minHeight="96px"
        maxHeight="6rem"
      >
        <IconButton
          onClick={() => {
            setDrawerOpen(true)
          }}
        >
          <NavigationIcon />
        </IconButton>
        <RouterLink to="/">
          <Box
            component="img"
            src={`/images/CarrotGrey.svg`}
            aria-label={intl.formatMessage({ defaultMessage: "Go to homepage" })}
            height={(theme) => theme.spacing(theme.tokens.spacing.md)}
          />
        </RouterLink>
        <UserDropdown />
      </Box>

      <Drawer
        variant={"temporary"}
        ModalProps={{
          keepMounted: true // Better open performance on mobile.
        }}
        sx={(theme) => ({
          display: "block",
          [theme.breakpoints.up("lg")]: { display: "none" },
          "& .MuiDrawer-paper": {
            background: theme.palette.background.default,
            inlineSize: "256px"
          }
        })}
        anchor={textDirection === TextDirection.LeftToRight ? "left" : "right"}
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
      >
        <Box
          sx={(theme) => ({
            display: "flex",
            justifyContent: "flex-end",
            paddingBlockStart: theme.spacing(theme.tokens.spacing.md),
            paddingBlockEnd: theme.spacing(theme.tokens.spacing.sm),
            paddingX: theme.spacing(theme.tokens.spacing.md)
          })}
        >
          <IconButton aria-label={intl.formatMessage({ defaultMessage: "close" })} onClick={() => setDrawerOpen(false)}>
            <CloseIcon />
          </IconButton>
        </Box>
        <NavItemsList {...{ setDrawerOpen }} />
      </Drawer>
    </>
  )
}

export function MainNavigation(): JSX.Element {
  const [drawerOpen, setDrawerOpen] = React.useState<boolean>(false)
  const theme = useTheme()
  const showMobileNav = useMediaQuery(theme.breakpoints.down("lg"))
  const intl = useIntl()
  const textDirection = useTextDirection()

  return (
    <>
      {showMobileNav ? (
        <MobileNav {...{ drawerOpen, setDrawerOpen }} />
      ) : (
        <Drawer
          variant="permanent"
          anchor={textDirection === TextDirection.LeftToRight ? "left" : "right"}
          sx={(theme) => ({
            // position added to ensure this drawer always appears behind the dynamic content tool or blocking modals
            position: "sticky",
            display: "block",
            [theme.breakpoints.down("lg")]: { display: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              background: theme.palette.background.default,
              minInlineSize: "256px",
              maxInlineSize: "16rem"
            }
          })}
        >
          <Box
            sx={(theme) => ({
              display: "flex",
              flexDirection: "column",
              marginBlockStart: theme.tokens.spacing.xxxl,
              marginInlineStart: theme.tokens.spacing.md,
              inlineSize: "130px"
            })}
          >
            <Link href="/" underline="none">
              <Box
                component="img"
                src={`/images/CarrotGrey.svg`}
                aria-label={intl.formatMessage({ defaultMessage: "Go to homepage" })}
                height={(theme) => theme.spacing(theme.tokens.spacing.lg)}
              />
            </Link>
          </Box>
          <NavItemsList {...{ setDrawerOpen }} />
        </Drawer>
      )}
    </>
  )
}
