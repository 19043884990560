import React from "react"
import { H2, Container } from "@carrotfertility/carotene"
import styled from "styled-components"
import { BodySmall } from "#/components/views/atoms/Bodies"
import { IlloPaperAirplaneUnderwater } from "#/components/views/atoms/Illustrations"
import { RoundedRectangle } from "#/components/views/atoms/roundedrectangle/RoundedRectangle"
import { Row, RowItem } from "#/components/views/atoms/row/Row"
import { FormattedMessage } from "react-intl"

const HeroText = styled.span`
  font-size: 17px;
  line-height: 1.5em;
`

export default function CarrotPlanIsOnTheWayHero(): JSX.Element {
  return (
    <RoundedRectangle backgroundColor="dark-blue">
      <Row direction="row-reverse" space="80px">
        <RowItem minWidth="185px">
          <IlloPaperAirplaneUnderwater />
        </RowItem>
        <RowItem minWidth="239px">
          <Container stack="tiny">
            <H2 variant="secondary">
              <FormattedMessage defaultMessage="Your Carrot Plan is on the way" />
            </H2>
          </Container>
          <Container stack="medium">
            <BodySmall font="publico-text" color="white">
              <HeroText>
                <FormattedMessage defaultMessage="We'll be in touch." />
              </HeroText>
            </BodySmall>
          </Container>
        </RowItem>
      </Row>
    </RoundedRectangle>
  )
}
