import React, { ReactElement, useEffect } from "react"
import { PhoneNumberCollectionModal } from "../account/PhoneNumberCollectionModal"
import { CarrotPlansGatedAccountContainer } from "../../carrot-plans/case-rate-renewals/CarrotPlansGatedAccountModal"
import {
  useGetCaseRateRenewalStatus,
  useGetDaysSinceLastUnlock,
  useSetNonStaleCarrotPlanOnAnniversaryCheck
} from "../../carrot-plans/case-rate-renewals/useQueryCaseRateRenewals"
import { useFlags } from "launchdarkly-react-client-sdk"
import { useCurrentUser } from "../../context/user/UserContext"
import { useUserRole } from "../../partner-access/hooks/usePartnerAccess"
import queryString from "query-string"
import {
  getHasCrrModalTriggeredThisSession,
  getHasJourneyChangeModalTriggeredThisSession,
  updateHasCrrModalTriggeredThisSession
} from "./utils/localStorageUtils"
import { JourneyChangeDialogContainer } from "#/components/carrot-plans/journey-change/JourneyChangeDialog"
import { QueryStrings } from "#/utils/Paths"

export function AppLoginModalContainer(): ReactElement {
  const hasCrrModalTriggeredThisSession = getHasCrrModalTriggeredThisSession()
  const hasJourneyChangeModalTriggeredThisSession = getHasJourneyChangeModalTriggeredThisSession()
  const { phoneNumber, dismissedPhoneNumberFeatureModal } = useCurrentUser()
  const { showPhoneCollectionModal } = useFlags()
  const {
    isLoading: isCrrLoading,
    showAdvancedCheckInFeatures,
    shouldCrrModalRender,
    isCrrAdvancedCheckIn
  } = useGetCaseRateRenewalStatus()
  const { mutate: setNonStaleCarrotPlanOnAnniversaryCheck } = useSetNonStaleCarrotPlanOnAnniversaryCheck()
  const { data: daysFromLastUnlock } = useGetDaysSinceLastUnlock()
  const { isPartnerAccessingAccount, isUserRoleLoading } = useUserRole()
  const { dialog } = queryString.parse(window.location.search)
  const isDataLoading = isCrrLoading || isUserRoleLoading
  const isJourneyChangeModalQueryString = dialog === QueryStrings.JOURNEY_CHANGE_EMAIL

  const shouldRenderPhoneNumberModal =
    showPhoneCollectionModal &&
    !isPartnerAccessingAccount &&
    !shouldCrrModalRender &&
    !hasCrrModalTriggeredThisSession &&
    !dismissedPhoneNumberFeatureModal &&
    !hasJourneyChangeModalTriggeredThisSession &&
    !phoneNumber

  useEffect(() => {
    if (daysFromLastUnlock >= 365) {
      setNonStaleCarrotPlanOnAnniversaryCheck(null)
    }
  }, [daysFromLastUnlock, setNonStaleCarrotPlanOnAnniversaryCheck])

  if (!hasCrrModalTriggeredThisSession) {
    updateHasCrrModalTriggeredThisSession(shouldCrrModalRender)
  }

  if (isDataLoading) {
    return null
  }

  if (isJourneyChangeModalQueryString) {
    return <JourneyChangeDialogContainer origin="email" />
  }

  if (shouldCrrModalRender) {
    return (
      <CarrotPlansGatedAccountContainer
        shouldCrrModalRender={shouldCrrModalRender}
        showAdvancedCheckInFeatures={showAdvancedCheckInFeatures}
        isCrrAdvancedCheckIn={isCrrAdvancedCheckIn}
      />
    )
  }

  if (shouldRenderPhoneNumberModal) {
    return <PhoneNumberCollectionModal />
  }

  return null
}
