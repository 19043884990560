import React from "react"
import { useGetMedicalInfo, useMedicalInfo } from "../../context/MedicalInfoContext"
import { useStateMachine } from "../../../context/stateMachine/StateMachineV2"
import { useMedicalInfoWorkflow } from "../../hooks/useMedicalInfoFlow"
import { MaleCurrentlyTakingOptions, FemaleCurrentlyTakingOptions } from "./MedicalInfoOptions"
import { FormattedMessage, defineMessage, useIntl } from "react-intl"
import { Form, useWatch } from "@carrotfertility/carotene-core-x"
import { Stack, useTheme } from "@carrotfertility/carotene-core"
import {
  RoutingFlowCheckboxGroup,
  SkipButton,
  ButtonFooterLayout,
  ContinueButton,
  StepLayout,
  FormTextFieldWithCharacterCount,
  useConvertTranslatedLabel,
  RoutingFlowTitle
} from "#/components/carrot-plans/shared"

type FormValues = {
  currentlyTaking?: string[]
  currentlyTakingOther?: string
}

const OPTIONAL_ANSWER_KEY = "otherCurrentlyTaking"

const OPTIONAL_SELECTION = {
  label: defineMessage({ defaultMessage: "Something else you'd like us to know about" }),
  value: OPTIONAL_ANSWER_KEY
}

function OptionalInfoInput() {
  const selectedValues = useWatch({ name: "currentlyTaking" })
  if (!selectedValues.includes(OPTIONAL_ANSWER_KEY)) {
    return null
  }
  return (
    <FormTextFieldWithCharacterCount
      name="currentlyTakingOther"
      helperText={<FormattedMessage defaultMessage="Optional details about other medications and supplements" />}
    />
  )
}

export default function CurrentlyTakingStep(): JSX.Element {
  const intl = useIntl()
  const theme = useTheme()
  const { IsAMAB, IsAFAB } = useMedicalInfoWorkflow()
  const { updateMedicalInfo, hasQuestionBeenSkipped } = useMedicalInfo()
  const { data: medicalInfo } = useGetMedicalInfo()
  const defaultCurrentlyTakingValues = IsAMAB
    ? medicalInfo?.maleCurrentlyTaking
    : IsAFAB
    ? medicalInfo?.femaleCurrentlyTaking
    : ""
  const defaultCurrentlyTakingOtherValue = medicalInfo?.currentlyTakingOther
  const defaultCurrentlyTakingValuesWithOther = [
    ...(defaultCurrentlyTakingValues || []),
    defaultCurrentlyTakingOtherValue ? OPTIONAL_ANSWER_KEY : ""
  ].filter(Boolean)
  const buttonOptions = IsAMAB ? MaleCurrentlyTakingOptions : IsAFAB ? FemaleCurrentlyTakingOptions : null
  const { send } = useStateMachine(
    defaultCurrentlyTakingValues || defaultCurrentlyTakingOtherValue || hasQuestionBeenSkipped("currentlyTaking")
      ? ""
      : null
  )

  const options = useConvertTranslatedLabel([...buttonOptions, OPTIONAL_SELECTION])

  async function onClickContinue(values: FormValues) {
    const selected = values.currentlyTaking.filter((value) => value !== OPTIONAL_ANSWER_KEY)

    const medicalInfoPayload = {
      currentlyTakingOther: values?.currentlyTakingOther || ""
    }

    if (IsAMAB) {
      // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      medicalInfoPayload["maleCurrentlyTaking"] = selected
    } else if (IsAFAB) {
      // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      medicalInfoPayload["femaleCurrentlyTaking"] = selected
    }

    await updateMedicalInfo(medicalInfoPayload)
    send("")
  }

  async function onSkip() {
    await updateMedicalInfo({ skipFields: ["currentlyTaking"] })
    send("")
  }

  const header = intl.formatMessage({
    defaultMessage: "Are you currently taking any of the following?"
  })

  return (
    <>
      <RoutingFlowTitle title={header} />
      <StepLayout
        header={<span id="currentlyTaking-label">{header}</span>}
        description={<FormattedMessage defaultMessage="Select all that apply." />}
        formElements={
          <Form
            defaultValues={{
              currentlyTaking: defaultCurrentlyTakingValuesWithOther,
              currentlyTakingOther: defaultCurrentlyTakingOtherValue
            }}
            onSubmit={onClickContinue}
          >
            <Stack spacing={theme.spacing(theme.tokens.spacing.sm)}>
              <RoutingFlowCheckboxGroup
                name="currentlyTaking"
                aria-labelledby="currentlyTaking-label"
                options={options}
              />
              <OptionalInfoInput />
            </Stack>
            <ButtonFooterLayout
              continueButton={
                <ContinueButton>
                  <FormattedMessage defaultMessage="Continue" />
                </ContinueButton>
              }
              skipButton={<SkipButton onClick={onSkip} />}
            />
          </Form>
        }
      />
    </>
  )
}
