import { SUCCESS_CODES } from "#/utils/HttpStatusCodes"
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'uuid... Remove this comment to see the full error message
import { v4 as uuid } from "uuid"
import { HttpErrors, StatusCodeToHttpErrorMapping, parseErrorBody } from "#/utils/HttpErrors"

const fetchWrapper = async (url: any, options: any) => {
  const correlationId = uuid()
  // Stack traces of error following async don't show the entry point to the stack
  // So create an error with navigable stack trace just before the fetch
  const error = new Error()
  // @ts-expect-error TS(2339) FIXME: Property 'correlationId' does not exist on type 'E... Remove this comment to see the full error message
  error.correlationId = correlationId

  // For some reason adding headers to non v2 calls breaks things. Only
  // add to calls which are already sending headers
  if (options.headers) {
    options.headers.append("X-Request-ID", correlationId)
    options.headers.append("X-React-App-Version", process.env.REACT_APP_VERSION || "local")
  }

  let response
  try {
    response = await fetch(url, options)
  } catch (caughtError) {
    // eslint-disable-next-line no-console
    console.warn(caughtError)
    // Not including options as that would be a high risk of leaking sensitive information into our logs
    error.message = `${caughtError.name} - ${caughtError.message} | URL: ${url}`
    error.name = "FetchError"
    throw error
  }

  if (SUCCESS_CODES.includes(response.status)) {
    return response
  }

  let clientMessage = null

  if (Object.keys(StatusCodeToHttpErrorMapping).includes(response.status.toString())) {
    // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    error.name = StatusCodeToHttpErrorMapping[response.status]
    clientMessage = `URL: ${url}`
  } else {
    error.name = HttpErrors.UNHANDLED_STATUS_CODE
    clientMessage = `Status Code: ${response.status} | URL: ${url}`
  }

  // @ts-expect-error TS(2339) FIXME: Property 'response' does not exist on type 'Error'... Remove this comment to see the full error message
  error.response = response
  const responseBody = await parseErrorBody(error)
  const serverMessage = responseBody?.message
  error.message = serverMessage ? `${serverMessage} (${clientMessage})` : clientMessage
  throw error
}

export { fetchWrapper }
