import React, { useState } from "react"
import { Progress } from "#/components/views/modal/Progress"
import { useStateMachine } from "#/components/context/stateMachine/StateMachineV2"
import { useCurrentUser } from "#/components/context/user/UserContext"
import { fetchUserInfo } from "#/redux/actions/userActions"
import { useDispatch } from "react-redux"
import { useReimbursementContext } from "#/components/context/reimbursement/GetReimbursedContext"
import { ZendeskLink } from "../../views/atoms/links/ZendeskTextLink"
import { SupportCenterLinks } from "../../../utils/SupportCenterLinks"
import { FormattedMessage, useIntl } from "react-intl"
import {
  Button,
  Link,
  Checkbox,
  FormControlLabel,
  DialogContentText,
  DialogTitle,
  Box,
  Alert
} from "@carrotfertility/carotene-core"
import { Form, DialogActionsBar, FormTextField, FormOnSubmitHandler } from "@carrotfertility/carotene-core-x"
import { carrotClient } from "#/utils/CarrotClient"
import { ReimbursementDialogFraming, BackArrow } from "../shared/layouts"

export const AnythingElse = (): JSX.Element => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const { send, back } = useStateMachine()
  const { state, expenseId } = useReimbursementContext()
  const [showErrorMessage, updateErrorMessage] = useState(false)
  const { company, hasMetSignatureRequirement } = useCurrentUser()
  const hasIvfRequirement = company?.hasIvfRequirements
  const [isSubmitting, updateIsSubmitting] = useState(false)
  const [taxAcknowledged, setTaxAcknowledged] = useState(false)

  // @ts-expect-error TS(7006) FIXME: Parameter 'expenseJourneyType' implicitly has an '... Remove this comment to see the full error message
  const isQME = (expenseJourneyType): boolean => {
    return (
      expenseJourneyType === "IVF" ||
      expenseJourneyType === "IUI" ||
      expenseJourneyType === "PRESERVATION" ||
      expenseJourneyType === "PATHFINDING"
    )
  }

  const addReimbursement = async (
    // @ts-expect-error TS(7006) FIXME: Parameter 'files' implicitly has an 'any' type.
    files,
    // @ts-expect-error TS(7006) FIXME: Parameter 'eligibleReimbursementAmount' implicitly... Remove this comment to see the full error message
    eligibleReimbursementAmount,
    // @ts-expect-error TS(7006) FIXME: Parameter 'eligibleReimbursementAmountCurrencyCode... Remove this comment to see the full error message
    eligibleReimbursementAmountCurrencyCode,
    // @ts-expect-error TS(7006) FIXME: Parameter 'whoReceivedCare' implicitly has an 'any... Remove this comment to see the full error message
    whoReceivedCare,
    // @ts-expect-error TS(7006) FIXME: Parameter 'expenseJourneyType' implicitly has an '... Remove this comment to see the full error message
    expenseJourneyType,
    // @ts-expect-error TS(7006) FIXME: Parameter 'isStepchildAdoption' implicitly has an ... Remove this comment to see the full error message
    isStepchildAdoption,
    // @ts-expect-error TS(7006) FIXME: Parameter 'kindOfJourney' implicitly has an 'any' ... Remove this comment to see the full error message
    kindOfJourney,
    // @ts-expect-error TS(7006) FIXME: Parameter 'expenseJourneySubType' implicitly has a... Remove this comment to see the full error message
    expenseJourneySubType,
    // @ts-expect-error TS(7006) FIXME: Parameter 'relatedToStorageFees' implicitly has an... Remove this comment to see the full error message
    relatedToStorageFees,
    // @ts-expect-error TS(7006) FIXME: Parameter 'isInfertility' implicitly has an 'any' ... Remove this comment to see the full error message
    isInfertility,
    // @ts-expect-error TS(7006) FIXME: Parameter 'isMedicalNecessity' implicitly has an '... Remove this comment to see the full error message
    isMedicalNecessity,
    // @ts-expect-error TS(7006) FIXME: Parameter 'attestationIsMedicallyNecessary' implic... Remove this comment to see the full error message
    attestationIsMedicallyNecessary,
    // @ts-expect-error TS(7006) FIXME: Parameter 'attestationIsEligibilityRequirementMet'... Remove this comment to see the full error message
    attestationIsEligibilityRequirementMet,
    // @ts-expect-error TS(7006) FIXME: Parameter 'attestationIsMedicallyRelated' implicit... Remove this comment to see the full error message
    attestationIsMedicallyRelated,
    // @ts-expect-error TS(7006) FIXME: Parameter 'memberNotes' implicitly has an 'any' ty... Remove this comment to see the full error message
    memberNotes,
    // @ts-expect-error TS(7006) FIXME: Parameter 'expenseId' implicitly has an 'any' type... Remove this comment to see the full error message
    expenseId
  ) => {
    const isCardExpense = !!expenseId
    // @ts-expect-error TS(7006) FIXME: Parameter 'file' implicitly has an 'any' type.
    const fileGuids = files.map((file) => file.guid)
    if (kindOfJourney && expenseJourneyType === "FERTILITY_CARE") {
      expenseJourneyType = kindOfJourney
    }
    if (expenseJourneyType !== "ADOPTION") isStepchildAdoption = null

    if (!isQME(expenseJourneyType)) {
      isInfertility = false
      isMedicalNecessity = false
    }

    if (isCardExpense) {
      await carrotClient.submitCardExpense(
        fileGuids,
        [whoReceivedCare],
        expenseJourneyType,
        expenseJourneySubType,
        relatedToStorageFees,
        isStepchildAdoption,
        isInfertility,
        isMedicalNecessity,
        attestationIsMedicallyNecessary,
        attestationIsEligibilityRequirementMet,
        attestationIsMedicallyRelated,
        memberNotes,
        expenseId
      )
    } else {
      await carrotClient.submitExpense(
        fileGuids,
        eligibleReimbursementAmount,
        eligibleReimbursementAmountCurrencyCode,
        [whoReceivedCare],
        expenseJourneyType,
        expenseJourneySubType,
        relatedToStorageFees,
        isStepchildAdoption,
        isInfertility,
        isMedicalNecessity,
        attestationIsMedicallyNecessary,
        attestationIsEligibilityRequirementMet,
        attestationIsMedicallyRelated,
        memberNotes
      )
    }
  }

  const onSubmit: FormOnSubmitHandler<{ anythingElse?: string }> = async (data) => {
    updateIsSubmitting(true)
    const files = [...state.statementFiles]
    try {
      await addReimbursement(
        files,
        state.eligibleReimbursementAmount,
        state.eligibleReimbursementAmountCurrencyCode,
        state.whoReceivedCare.value,
        state.expenseRelatedTo.value,
        state.isStepchildAdoption,
        state.kindOfJourney.value,
        state.journeySubtype.value,
        state.relatedToStorageFees.value,
        state.isInfertilityDiagnosis,
        state.isMedicalNecessity,
        state.attestationIsMedicallyNecessary,
        state.attestationIsEligibilityRequirementMet,
        state.attestationIsMedicallyRelated,
        data.anythingElse,
        expenseId
      )
    } catch (error) {
      updateErrorMessage(true)
      updateIsSubmitting(false)
      return
    }

    await dispatch(fetchUserInfo())

    if (state.kindOfJourney.value === "IVF" && hasIvfRequirement && !hasMetSignatureRequirement)
      send("NEEDS_IVF_REQUIREMENT")
    else send("")
  }

  return (
    <>
      <Progress
        numSteps={3}
        step={3}
        label={intl.formatMessage({ defaultMessage: "Additional Info" })}
        inactiveStepBackgroundColor="#DBE6EE"
      />
      <BackArrow onClick={back} />
      <Form onSubmit={onSubmit}>
        <ReimbursementDialogFraming>
          <DialogTitle id="step-heading">
            <FormattedMessage defaultMessage="Anything else you'd like to share?" />
          </DialogTitle>
          <Box
            sx={{ display: "flex", flexDirection: "column", gap: (theme) => theme.spacing(theme.tokens.spacing.lg) }}
          >
            <FormTextField
              multiline
              name="anythingElse"
              rows={4}
              placeholder={intl.formatMessage({ defaultMessage: "Type your message here..." })}
              inputProps={{ maxLength: 2048 }}
              aria-labelledby="step-heading"
            />
            {showErrorMessage && (
              <Alert severity="error">
                <FormattedMessage
                  defaultMessage="We're having trouble. Please try submitting again or <link>contact us</link> for help."
                  values={{
                    link: (linkContent) => (
                      <Link fontWeight="inherit" target="_blank" href="/talk-to-carrot" color="inherit">
                        {linkContent}
                      </Link>
                    )
                  }}
                />
              </Alert>
            )}
          </Box>
        </ReimbursementDialogFraming>
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            paddingBlock: (theme) => theme.tokens.spacing.xxxl,
            gap: (theme) => theme.tokens.spacing.md,
            justifyContent: "space-between",
            alignItems: { xs: "flex-start", md: "center" }
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              alignItems: { xs: "flex-start", md: "center" }
            }}
          >
            <FormControlLabel
              label={
                <DialogContentText id="terms-acknowledgement" sx={{ lineHeight: "150%", maxInlineSize: "300px" }}>
                  <FormattedMessage
                    defaultMessage="I acknowledge that, depending on location and other factors, <link>taxes may apply</link>."
                    values={{
                      link: (linkContent) => (
                        <ZendeskLink
                          target="_blank"
                          supportCenterLink={SupportCenterLinks.ZENDESK_TAXABILITY_GUIDE}
                          color="inherit"
                        >
                          {linkContent}
                        </ZendeskLink>
                      )
                    }}
                  />
                </DialogContentText>
              }
              control={
                <Checkbox
                  aria-labelledby="terms-acknowledgement"
                  value="acknowledged"
                  checked={taxAcknowledged}
                  onChange={(e) => {
                    setTaxAcknowledged(e.target.checked)
                  }}
                />
              }
            />
          </Box>
          <DialogActionsBar
            primaryAction={
              <Button variant="contained" color="primary" type="submit" disabled={isSubmitting || !taxAcknowledged}>
                <FormattedMessage defaultMessage="Submit" />
              </Button>
            }
          />
        </Box>
      </Form>
    </>
  )
}
