import useBenefit from "#/components/dynamic-content/hooks/useGetBenefit"
import { useGetHasProviderTypeInCountry } from "./useGetHasProviderTypeInCountry"
import { useQuery } from "@tanstack/react-query"
import { ProviderNetwork } from "#/lib/carrot-api/ProviderFinderClient"
import { carrotClient } from "#/utils/CarrotClient"

// @ts-expect-error TS(7006) FIXME: Parameter 'countryCode' implicitly has an 'any' ty... Remove this comment to see the full error message
export const useHasProviderFinder = (countryCode) => {
  const benefit = useBenefit()

  const {
    data: hasProviderTypes,
    isLoading: isLoadingProviderTypes,
    isError
  } = useGetHasProviderTypeInCountry(countryCode)
  return (
    !benefit.isLoading &&
    benefit.data?.providerFinder?.showProviderFinder &&
    !isLoadingProviderTypes &&
    !isError &&
    hasProviderTypes?.hasProviders // the hook returns null when the session is expired, so the '?' is necessary here
  )
}

export const useProviderNetworkForCurrentUser = () => {
  return useQuery<ProviderNetwork>(["useProviderNetworkForCurrentUser"], () =>
    carrotClient.getProviderNetworkForCurrentUser()
  )
}

export const getProviderNetworkConfig = (providerNetworkIdentifier: string) => {
  switch (providerNetworkIdentifier) {
    case "82eb66b4-ac66-43b8-9549-448a19667a59":
      return {
        externalProviderNetworkId: "Priority Health - HMO",
        displayInNetworkStatusBanner: true,
        networkName: "Priority Health"
      }
    case "45fd6e39-11fd-4074-90c0-3789a7ca235d":
      return {
        externalProviderNetworkId: "Priority Health - PPO",
        displayInNetworkStatusBanner: true,
        networkName: "Priority Health"
      }
    default:
      return {
        externalProviderNetworkId: null,
        displayInNetworkStatusBanner: false,
        networkName: null
      }
  }
}
