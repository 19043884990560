import React, { useContext, useEffect, useState } from "react"
import { Stack, Typography, useTheme, Box } from "@carrotfertility/carotene-core"
import { useUserRole } from "../../partner-access/hooks/usePartnerAccess"
import { FormCheckbox, useFormContext } from "@carrotfertility/carotene-core-x"
import {
  IfMobileNative,
  isBiometricsCapable,
  shouldAttemptBioAuthenticationLogin
} from "#/lib/carrot-api/MobileNativeClient"
import { SettingsPageDivider } from "#/pages/account/page"
import { SettingsContext } from "#/pages/account"
import { FormattedMessage, useIntl } from "react-intl"

export function BiometricAuthenticationSettings(): JSX.Element {
  const theme = useTheme()
  const { isPartnerAccessingAccount } = useUserRole()
  const { isBioauthEnabled, setBioauthEnabled } = useContext(SettingsContext)
  const [isBioauthLoading, setBioauthLoading] = useState(true)

  const [isBioauthCapable, setBioauthCapable] = useState(false)
  const [isBioauthCapableLoading, setBioauthCapableLoading] = useState(true)
  const intl = useIntl()
  const { setValue } = useFormContext()

  useEffect(() => setValue("bioauthEnabled", isBioauthEnabled), [isBioauthEnabled, setValue])

  useEffect(() => {
    async function execute() {
      setBioauthEnabled(await shouldAttemptBioAuthenticationLogin())
      setBioauthLoading(false)

      setBioauthCapable(await isBiometricsCapable())
      setBioauthCapableLoading(false)
    }
    execute()
  }, [setBioauthEnabled])

  return (
    !isBioauthLoading &&
    !isBioauthCapableLoading &&
    isBioauthCapable && (
      <IfMobileNative>
        <Stack spacing={theme.spacing(theme.tokens.spacing.lg)}>
          <Typography variant="h2">
            <FormattedMessage defaultMessage="Sign-in preferences" />
          </Typography>
          <Box display="flex" flexDirection="column" justifyContent="space-between">
            <Stack spacing={theme.spacing(theme.tokens.spacing.xs)}>
              <Typography variant="h6">
                <FormattedMessage defaultMessage="Biometric sign-in" />
              </Typography>

              {isBioauthEnabled ? (
                <FormCheckbox
                  name="bioauthEnabled"
                  disabled={isPartnerAccessingAccount}
                  label={intl.formatMessage({ defaultMessage: "Use biometric sign-in." })}
                />
              ) : (
                <Typography>
                  <FormattedMessage defaultMessage="You can turn this on from the sign-in screen" />
                </Typography>
              )}
            </Stack>
          </Box>
        </Stack>
        <SettingsPageDivider />
      </IfMobileNative>
    )
  )
}
