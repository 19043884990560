import React from "react"
import { AppAccessPageLayout } from "#/components/access-page/AppAccessPageLayout"
import { AppAccessPageCard } from "#/components/access-page/AppAccessPageCard"
import { Stack, Typography, Link, useTheme, Box } from "@carrotfertility/carotene-core"
import { FormattedMessage, useIntl } from "react-intl"
import { EmployeeSupportUrl } from "../../utils/EmployeeSupportLink"

function Error(): JSX.Element {
  const theme = useTheme()
  const intl = useIntl()
  const headerText = intl.formatMessage({ defaultMessage: "Something’s not working" })

  return (
    <AppAccessPageLayout title={headerText}>
      <AppAccessPageCard>
        <Box textAlign="center" marginBottom={(theme) => theme.spacing(theme.tokens.spacing.md)}>
          <Typography variant="h1">{headerText}</Typography>
        </Box>

        <Stack textAlign="center" spacing={theme.spacing(theme.tokens.spacing.sm)}>
          <Typography>
            <FormattedMessage
              defaultMessage="Try reloading this page. If the problem persists, feel free to <link>contact us</link>."
              values={{
                link: (linkContent) => (
                  <Link href={EmployeeSupportUrl} target={"_blank"}>
                    {linkContent}
                  </Link>
                )
              }}
            />
          </Typography>
        </Stack>
      </AppAccessPageCard>
    </AppAccessPageLayout>
  )
}

export { Error }
