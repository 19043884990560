import React, { useLayoutEffect, useRef } from "react"
import { createElement, Sca } from "@airwallex/components-sdk"
import airwallexTheme from "./AirwallexScaTheme.json"
import { useCurrentUser } from "#/components/context/user/UserContext"
import { useVerificationToken } from "./AirwallexCard"

interface AirwallexScaVerifyProps {
  onVerificationSuccess: () => void
  onCancel: () => void
  onError: (error: any) => void
  scaSessionCode: string
}

export const AirwallexScaVerify: React.FC<AirwallexScaVerifyProps> = ({
  onVerificationSuccess,
  onCancel,
  onError,
  scaSessionCode
}) => {
  const { personalEmail } = useCurrentUser()

  const { setScaToken } = useVerificationToken()

  const scaManagementRef = useRef<HTMLDivElement>(null)
  const scaVerifyElementRef = useRef<Sca.ScaElement | null>(null)
  const isUnmountedRef = useRef(false)

  useLayoutEffect(() => {
    let isMounted = true

    const initializeScaVerify = async () => {
      try {
        if (!scaManagementRef.current) {
          reportError("Airwallex SCA management div not found")
          return
        }

        const scaVerifyElement = await createElement("scaVerify", {
          userEmail: personalEmail,
          theme: airwallexTheme,
          scaSessionCode: scaSessionCode
        })

        scaVerifyElementRef.current = scaVerifyElement

        scaVerifyElement.mount(scaManagementRef.current)

        scaVerifyElement.on("verificationSucceed", ({ token }: { token: string }) => {
          setScaToken(token)
          onVerificationSuccess()
          if (isMounted && scaVerifyElementRef.current) {
            scaVerifyElementRef.current.unmount()
            scaVerifyElementRef.current = null
          }
        })

        scaVerifyElement.on("verificationFailed", ({ reason }: { reason: string }) => {
          onError(reason)
        })

        scaVerifyElement.on("cancel", () => {
          onCancel()
          if (isMounted && scaVerifyElementRef.current) {
            scaVerifyElementRef.current.unmount()
            scaVerifyElementRef.current = null
          }
        })
      } catch (error) {
        onError(error)
      }
    }

    initializeScaVerify()

    return () => {
      isMounted = false
      isUnmountedRef.current = true
      if (scaVerifyElementRef.current) {
        scaVerifyElementRef.current.unmount()
        scaVerifyElementRef.current = null
      }
    }
  }, [personalEmail, onVerificationSuccess, onCancel, onError, setScaToken, scaSessionCode])

  return <div ref={scaManagementRef}></div>
}
