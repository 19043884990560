import { useQuery, UseQueryResult } from "@tanstack/react-query"
import buildCarrotApiRetryPolicy from "#/utils/CarrotApiRetryPolicy"
import { ProviderCount } from "carrot-api/ProviderFinderClient"
import ResponseError from "#/types/responseError"
import { useProviderFinderClient } from "./useProviderFinderClient"

const HAS_PROVIDER_TYPE_IN_COUNTRY_QUERY_KEY = "GetHasProviderTypeInCountry"

export function useGetHasProviderTypeInCountry(countryCode: string): UseQueryResult<ProviderCount, ResponseError> {
  const client = useProviderFinderClient()

  return useQuery<ProviderCount, ResponseError>(
    [HAS_PROVIDER_TYPE_IN_COUNTRY_QUERY_KEY, countryCode],
    async () => {
      const response = await client.getHasProviderTypeInCountry(countryCode)
      if (response) {
        return response
      }
      return null
    },
    {
      refetchOnWindowFocus: false,
      retry: buildCarrotApiRetryPolicy(3)
    }
  )
}
