import React from "react"
import { useStateMachine } from "../../../context/stateMachine/StateMachineV2"
import { Form } from "@carrotfertility/carotene-core-x"
import useBenefit from "../../../dynamic-content/hooks/useGetBenefit"
import {
  useCheckRoutingInfoQueryCache,
  useUpdateRoutingInfo
} from "#/components/carrot-plans/shared/useQueryRoutingInfo"
import { FormattedMessage, defineMessage, useIntl } from "react-intl"
import {
  ButtonFooterLayout,
  ContinueButton,
  RoutingFlowCheckboxGroup,
  RoutingFlowTitle,
  StepLayout,
  useConvertTranslatedLabel
} from "#/components/carrot-plans/shared"
import { FormRadioButtonGroup } from "#/services/common-forms"
import { useMostHelpfulOptions } from "#/components/carrot-plans/hooks/useMostHelpfulOptions"

const descriptions = {
  multiSelect: {
    description: defineMessage({
      defaultMessage: "Choose up to three options that feel like the top priority, just to get things started."
    })
  },
  singleSelect: {
    description: defineMessage({
      defaultMessage: "Choose the option that feels like the top priority, just to get things started."
    })
  }
}

export default function MostHelpfulStep(): JSX.Element {
  const { data: benefit, isLoading: isBenefitLoading } = useBenefit()
  const { mutateAsync } = useUpdateRoutingInfo()
  const routingData = useCheckRoutingInfoQueryCache()
  const journey = routingData?.journey
  const shouldSeeMultiSelect = ["PARENTING", "PREGNANT"].includes(journey)
  const defaultValue = shouldSeeMultiSelect ? routingData?.mostHelpfulNow : routingData?.mostHelpfulNow?.[0]
  const { buttonOptions } = useMostHelpfulOptions(routingData, benefit)
  const options = useConvertTranslatedLabel(buttonOptions)
  const { send } = useStateMachine(defaultValue ? "" : null)
  const intl = useIntl()

  async function onClickContinue({ mostHelpfulNow }: { mostHelpfulNow: string }): Promise<void> {
    await mutateAsync(
      { mostHelpfulNow: shouldSeeMultiSelect ? mostHelpfulNow : [mostHelpfulNow] },
      { onSuccess: () => send("") }
    )
  }

  const header = intl.formatMessage({
    defaultMessage: "What would be most helpful right now?"
  })

  const description = intl.formatMessage(
    descriptions[shouldSeeMultiSelect ? "multiSelect" : "singleSelect"]?.description
  )

  return (
    <>
      <RoutingFlowTitle title={header} />
      <StepLayout
        header={<span id="mostHelpfulNow-label">{header}</span>}
        description={description}
        formElements={
          <Form defaultValues={{ mostHelpfulNow: defaultValue }} onSubmit={onClickContinue}>
            {shouldSeeMultiSelect ? (
              <RoutingFlowCheckboxGroup
                maxSelections={3}
                controllerProps={{ rules: { required: intl.formatMessage({ defaultMessage: "Required" }) } }}
                aria-labelledby="mostHelpfulNow-label"
                name="mostHelpfulNow"
                options={isBenefitLoading ? [] : options}
              />
            ) : (
              <FormRadioButtonGroup
                name="mostHelpfulNow"
                controllerProps={{ rules: { required: intl.formatMessage({ defaultMessage: "Required" }) } }}
                aria-labelledby="mostHelpfulNow-label"
                options={isBenefitLoading ? [] : options}
              />
            )}
            <ButtonFooterLayout
              continueButton={
                <ContinueButton>
                  <FormattedMessage defaultMessage="Continue" />
                </ContinueButton>
              }
            />
          </Form>
        }
      />
    </>
  )
}
