import React, { useEffect } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { FormControl, Stack, Typography, useTheme } from "@carrotfertility/carotene-core"
import { FormSelect, useWatch } from "@carrotfertility/carotene-core-x"
import { useUserRole } from "#/components/partner-access/hooks/usePartnerAccess"
import { Locale, SupportedLocale } from "../../../types/Locale"
import { useEnabledLocalesForCurrentUser } from "#/services/locale/hooks/useGetEnabledLocalesForCurrentUser"

const getLocaleOptionsFromLocaleList = (localeStrings: string[]): { value: string; label: string }[] => {
  return localeStrings.map((localeString) => ({
    value: localeString,
    label: Locale.getDisplayNameFromString(localeString)
  }))
}

interface LocaleSelectorProps {
  locales: string[]
}

const LocaleSelector: React.FC<LocaleSelectorProps> = ({ locales }) => {
  const intl = useIntl()
  const theme = useTheme()
  const inputValue = useWatch({ name: "preferredLocale" })
  const { isPartnerAccessingAccount } = useUserRole()

  const preferredLocaleOptions = getLocaleOptionsFromLocaleList(locales)

  const preferredLocaleMessage = intl.formatMessage({
    defaultMessage: "Preferred Language",
    description: "preferred language on account settings page"
  })

  return (
    <Stack spacing={theme.spacing(theme.tokens.spacing.lg)}>
      <Typography variant="body1">
        <FormattedMessage defaultMessage="Your Carrot app and communications will be in your preferred language." />
      </Typography>
      <FormControl fullWidth>
        <FormSelect
          disabled={isPartnerAccessingAccount}
          label={preferredLocaleMessage}
          name={"preferredLocale"}
          options={preferredLocaleOptions}
          defaultValue={inputValue}
          style={{ textTransform: "capitalize" }}
        />
      </FormControl>
    </Stack>
  )
}

const NoLocaleSupportForCountry: React.FC = () => {
  const theme = useTheme()

  return (
    <Stack spacing={theme.spacing(theme.tokens.spacing.lg)}>
      <Typography variant="body1">
        <FormattedMessage defaultMessage="Language availability depends on your benefit sponsor’s location. In your location, Carrot is currently available in English (U.S.) only. We are working on expanding our language support and will continue to add more options over time. " />
      </Typography>
    </Stack>
  )
}

export const LanguageSettings: React.FC = () => {
  const [sortedLocaleStrings, setSortedLocaleStrings] = React.useState<string[]>([
    SupportedLocale.English_UnitedStates.toString()
  ])
  const { data: enabledLocalesForCurrentUser, isLoading } = useEnabledLocalesForCurrentUser()
  const doesCountryHaveLocaleSupport = enabledLocalesForCurrentUser?.length > 1

  useEffect(() => {
    if (enabledLocalesForCurrentUser) {
      setSortedLocaleStrings([
        SupportedLocale.English_UnitedStates.toString(),
        ...enabledLocalesForCurrentUser
          .filter((localeString) => localeString !== SupportedLocale.English_UnitedStates.toString())
          .sort()
      ])
    }
  }, [enabledLocalesForCurrentUser])

  return (
    <Stack>
      <Typography variant="h2">
        <FormattedMessage defaultMessage="Language" />
      </Typography>

      {!isLoading && doesCountryHaveLocaleSupport ? (
        <LocaleSelector locales={sortedLocaleStrings} />
      ) : (
        <NoLocaleSupportForCountry />
      )}
    </Stack>
  )
}
