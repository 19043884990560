import { CountryCode } from "#/utils/CountryCode"
import { useQueryActiveSignatures, CarrotDocumentType } from "#/lib/carrot-api/signatureClient"
import { useSelector } from "react-redux"
import { getCompanyInfo } from "#/redux/reducers/companyInfo"
import { useGetIsExcludedFromMedicalRecordsConsent } from "#/services/user/hooks/useGetIsExcludedFromMedicalRecordsConsent"
import { useUserRole } from "../../../partner-access/hooks/usePartnerAccess"

const assistedReproductionJourneyStagesWithLikelyKnownClinic: ReadonlySet<string> = new Set([
  "GETTING_READY_START_IVF",
  "ALREADY_STARTED_IVF_CYCLE",
  "RECENTLY_COMPLETED_IVF_CYCLE"
])

export function useMeetsInitialCriteriaToShowArtMedicalRecordsConsent({
  routingData
}: {
  routingData: { journey: string; journeyStage: string }
}): boolean {
  const { companyId, parentCompanyId, countryCode, hasIvfRequirements } = useSelector(getCompanyInfo)
  const getIsExcludedFromMedicalRecordsConsent = useGetIsExcludedFromMedicalRecordsConsent()
  const { isPartnerAccessingAccount } = useUserRole()

  const isExcludedFromMedicalRecordsConsent = getIsExcludedFromMedicalRecordsConsent({
    companyId: Number(companyId),
    parentCompanyId: parentCompanyId
  })

  return (
    countryCode === CountryCode.United_States &&
    !hasIvfRequirements &&
    routingData?.journey === "ASSISTED_REPRODUCTION" &&
    assistedReproductionJourneyStagesWithLikelyKnownClinic.has(routingData?.journeyStage) &&
    !isExcludedFromMedicalRecordsConsent &&
    !isPartnerAccessingAccount
  )
}

export function useShouldShowArtMedicalRecordsConsent({
  routingData
}: {
  routingData: { journey: string; journeyStage: string }
}): {
  shouldShowArtMedicalRecordsConsent: boolean
  isLoadingShouldShowArtMedicalRecordsConsent: boolean
} {
  const meetsInitialCriteria = useMeetsInitialCriteriaToShowArtMedicalRecordsConsent({ routingData })

  const { data: activeSignatures, isFetching: isLoadingActiveSignatures } = useQueryActiveSignatures({
    enabled: meetsInitialCriteria
  })

  return {
    isLoadingShouldShowArtMedicalRecordsConsent: isLoadingActiveSignatures,
    shouldShowArtMedicalRecordsConsent:
      meetsInitialCriteria &&
      !activeSignatures?.some((signature) => signature.documentType === CarrotDocumentType.ArtMedicalRecordsConsentForm)
  }
}
