import React from "react"
import { Box } from "@carrotfertility/carotene-core"
import { Title } from "#/services/page-title/Title"

interface AppAccessPageLayoutProps {
  title: string
}

export function AppAccessPageLayout({
  title,
  children
}: React.PropsWithChildren<AppAccessPageLayoutProps>): JSX.Element {
  return (
    <Box
      overflow="hidden"
      display="flex"
      bgcolor={(theme) => theme.palette.info.light}
      width="100%"
      justifyContent="center"
      minHeight="100vh"
      alignItems="center"
    >
      <Title title={title} />
      <Box
        zIndex="1"
        marginTop="4rem"
        sx={(theme) => ({
          minInlineSize: "345px",
          [theme.breakpoints.up("md")]: { maxInlineSize: "32rem", minInlineSize: "32rem" }
        })}
      >
        {children}
      </Box>
    </Box>
  )
}
