import React, { FC } from "react"
import { DetailsList, DetailsListItem } from "#/components/views/molecules/DetailsList"
import { useDidMount, useToggle } from "#/utils/Hooks"
import { CarrotCardModuleSharedProps } from "#/components/views/molecules/CarrotCardModuleContainer"
import { fetchCardDetails } from "#/redux/actions"
import { useDispatch, useSelector } from "react-redux"
import { getCardDetails } from "#/redux/reducers/cardDetails"
import { useLocation } from "react-router-dom"
import Helpers from "../../../../utils/Helpers"
import { DisallowWhilePosing } from "../../../../utils/DisallowWhilePosing"
import { FormattedDate, FormattedMessage, useIntl } from "react-intl"
import { Box, Button, InternalIcon, Link, Progress, Typography } from "@carrotfertility/carotene-core"
import { DbgPaths } from "#/utils/Paths"
import CardStates from "#/utils/CardStates"
import { CarrotCardModuleTemplate } from "./carrot-card-module-template"
import useGetReimbursementDetails from "#/services/reimbursements/hooks/useGetReimbursementDetails"
import { CarrotCardProvider } from "#/services/reimbursements/types/ReimbursementDetails"
import { AirwallexVirtualCardDetailsAccordion } from "./airwallex/AirwallexVirtualCardDetailsAccordion"

const VirtualCardDetailsAccordion = () => {
  const { data, isError, error } = useGetReimbursementDetails()

  if (isError) {
    reportError(`Error retrieving card details: ${error}`)
    return <ShowErrorRetrievingCardDetails />
  }

  const carrotCardProvider = data?.carrotCardProvider

  if (!carrotCardProvider) {
    return (
      <div>
        <Button
          disabled
          variant="outlined"
          color="secondary"
          sx={(theme) => ({ marginBlockStart: theme.tokens.spacing.md })}
        >
          <Progress data-testid="button-loading" size="1.25rem" color="inherit" />
        </Button>
      </div>
    )
  }

  const providerComponentMap = {
    [CarrotCardProvider.STRIPE]: StripeVirtualCardDetailsAccordion,
    [CarrotCardProvider.AIRWALLEX]: AirwallexVirtualCardDetailsAccordion
  }

  const CardDetailsComponentForProvider = providerComponentMap[carrotCardProvider] || null

  if (!CardDetailsComponentForProvider) {
    reportError(
      `CardDetailsComponentForProvider is null for provider: ${carrotCardProvider}. There's an unexpected provider in CarrotCardConfiguration`
    )
    return <ShowErrorRetrievingCardDetails />
  }

  return <CardDetailsComponentForProvider />
}

const StripeVirtualCardDetailsAccordion = () => {
  const location = useLocation()
  const [showCardDetails, toggleShowCardDetails] = useToggle(false)
  const dispatch = useDispatch()
  const cardDetails = useSelector(getCardDetails)

  useDidMount(() => {
    const cardDetailsShouldStartOpen = location.hash === "#card-details"
    toggleShowCardDetails(cardDetailsShouldStartOpen)
    dispatch(fetchCardDetails())
  })

  return (
    <div>
      <Button
        onClick={() => {
          !showCardDetails && dispatch(fetchCardDetails())
          toggleShowCardDetails()
        }}
        variant="outlined"
        color="secondary"
        sx={(theme) => ({ marginBlockStart: theme.tokens.spacing.md })}
      >
        {showCardDetails && !cardDetails.isLoaded && <FormattedMessage defaultMessage="Loading..." />}
        {showCardDetails && cardDetails.isLoaded && <FormattedMessage defaultMessage="Hide card details" />}
        {!showCardDetails && <FormattedMessage defaultMessage="Show card details" />}
      </Button>
      {showCardDetails && cardDetails.isLoaded && <ShowCardDetails cardDetails={cardDetails.cardDetails} />}
    </div>
  )
}

const CarrotCardActiveModule: FC<CarrotCardModuleSharedProps> = ({ isVirtualCard }) => (
  <CarrotCardModuleTemplate
    cardState={CardStates.ACTIVE}
    body={
      isVirtualCard ? (
        <FormattedMessage defaultMessage="Your virtual card is now active. Learn about how it works and what to do if a transaction is declined." />
      ) : (
        <FormattedMessage defaultMessage="Your card is now active. Learn about how it works and what to do if a transaction is declined." />
      )
    }
    links={[
      <Link
        key="carrot-card-how-to"
        endIcon={<InternalIcon fontSize="small" color="primary" />}
        href={DbgPaths.CARROT_CARD}
      >
        <FormattedMessage defaultMessage="How to use your Carrot Card" />
      </Link>,

      !isVirtualCard ? (
        <Link
          key="carrot-card-stolen"
          endIcon={<InternalIcon fontSize="small" color="primary" />}
          href="/talk-to-carrot/sendmessage/stolen"
        >
          <FormattedMessage defaultMessage="Report lost or stolen card" />
        </Link>
      ) : (
        <DisallowWhilePosing>
          <VirtualCardDetailsAccordion />
        </DisallowWhilePosing>
      )
    ]}
  />
)

type ShowCardDetailsProps = {
  cardDetails: any
}

type ShowRetrievedCardDetailsProps = {
  cardDetails: any
}

const ShowCardDetails: FC<ShowCardDetailsProps> = ({ cardDetails }) => {
  return (
    <>
      <Box paddingBottom={(theme) => theme.tokens.spacing.lg} />
      {cardDetails ? <ShowRetrievedCardDetails cardDetails={cardDetails} /> : <ShowErrorRetrievingCardDetails />}
    </>
  )
}

const ShowRetrievedCardDetails: FC<ShowRetrievedCardDetailsProps> = ({ cardDetails }) => {
  const address = cardDetails.billingAddress
  const formattedCreditCardNumber = Helpers.formatCreditCardNumber(cardDetails.number)
  const intl = useIntl()

  return (
    <DetailsList>
      <DetailsListItem header={intl.formatMessage({ defaultMessage: "Cardholder name" })} body={cardDetails.name} />
      <DetailsListItem header={intl.formatMessage({ defaultMessage: "Type" })} body="Visa" />
      <DetailsListItem
        header={intl.formatMessage({ defaultMessage: "Card number" })}
        body={formattedCreditCardNumber}
      />
      <DetailsListItem
        header={intl.formatMessage({ defaultMessage: "Expiration Date" })}
        body={
          <FormattedDate
            value={new Date(cardDetails.expirationYear, cardDetails.expirationMonth - 1)}
            year="numeric"
            month="2-digit"
          />
        }
      />
      <DetailsListItem header={intl.formatMessage({ defaultMessage: "SECURITY CODE CVV" })} body={cardDetails.cvc} />
      <DetailsListItem
        header={intl.formatMessage({ defaultMessage: "Billing address" })}
        body={
          <span>
            {address.line1}
            {address.line2 != null && (
              <>
                <br />
                {address.line2}
              </>
            )}
            <br />
            {/* eslint-disable-next-line formatjs/no-literal-string-in-jsx */}
            {address.city}, {address.state} {address.postalCode}
            <br />
            {address.country}
          </span>
        }
      />
    </DetailsList>
  )
}

export const ShowErrorRetrievingCardDetails = () => (
  <DetailsList>
    <Typography variant="body1" paddingBottom={(theme) => theme.tokens.spacing.md}>
      <FormattedMessage defaultMessage="We're having trouble retrieving your card details." />
    </Typography>
    <Box paddingBottom={(theme) => theme.tokens.spacing.xxs}>
      <Link
        endIcon={<InternalIcon fontSize="small" color="primary" />}
        onClick={() => window.location.reload()}
        component="button"
      >
        <FormattedMessage defaultMessage="Refresh the page" />
      </Link>
    </Box>
    <Link endIcon={<InternalIcon fontSize="small" color="primary" />} href="/talk-to-carrot">
      <FormattedMessage defaultMessage="Contact the Care Team" />
    </Link>
  </DetailsList>
)

export { CarrotCardActiveModule }
