import React, { ReactElement } from "react"
import { useStateMachine } from "../../../context/stateMachine/StateMachineV2"
import { Form } from "@carrotfertility/carotene-core-x"
import {
  useCheckRoutingInfoQueryCache,
  useUpdateRoutingInfo
} from "#/components/carrot-plans/shared/useQueryRoutingInfo"
import { defineMessage, FormattedMessage, useIntl } from "react-intl"
import {
  ButtonFooterLayout,
  ContinueButton,
  RoutingFlowTitle,
  StepLayout,
  useConvertTranslatedLabel
} from "#/components/carrot-plans/shared"
import { FormRadioButtonGroup } from "#/services/common-forms"
import { Workflows } from "../medical-questions/workflows"

export enum AMAB_AFAB_MEDICAL_PREFERENCE {
  AMAB = "AMAB_PREF",
  AFAB = "AFAB_PREF",
  DECLINE_MEDICAL_QUESTIONS = "DECLINED_HEALTH_QS"
}

export const AMAB_AFAB_MEDICAL_PREFERENCE_OPTIONS = [
  {
    label: defineMessage({ defaultMessage: "Yes, I'd like to answer questions related to ovarian/uterine health" }),
    workflow: Workflows.AFAB_MEDICAL_QUESTIONS,
    value: AMAB_AFAB_MEDICAL_PREFERENCE.AFAB
  },
  {
    label: defineMessage({ defaultMessage: "Yes, I'd like to answer questions related to sperm health" }),
    workflow: Workflows.AMAB_MEDICAL_QUESTIONS,
    value: AMAB_AFAB_MEDICAL_PREFERENCE.AMAB
  },
  {
    label: defineMessage({ defaultMessage: "No, I'd prefer not to answer health questions right now" }),
    value: AMAB_AFAB_MEDICAL_PREFERENCE.DECLINE_MEDICAL_QUESTIONS
  }
]
export default function AmabAfabMedicalPreferenceStep(): ReactElement {
  const { mutateAsync } = useUpdateRoutingInfo()
  const routingData = useCheckRoutingInfoQueryCache()
  const defaultValue = routingData?.amabAfabMedicalPreference
  const { send } = useStateMachine(defaultValue ? "" : null)
  const intl = useIntl()
  const radioOptions = useConvertTranslatedLabel(AMAB_AFAB_MEDICAL_PREFERENCE_OPTIONS)

  async function onClickContinue(formValues: { amabAfabMedicalPreference: string }): Promise<void> {
    await mutateAsync(formValues, { onSuccess: () => send("") })
  }
  const header = intl.formatMessage({
    defaultMessage: "Would you like to answer some health questions to help us support you better?"
  })

  return (
    <>
      <RoutingFlowTitle title={header} />
      <StepLayout
        header={<span id="amabAfabMedicalPreference-label">{header}</span>}
        formElements={
          <Form defaultValues={{ amabAfabMedicalPreference: defaultValue }} onSubmit={onClickContinue}>
            <FormRadioButtonGroup
              name="amabAfabMedicalPreference"
              options={radioOptions}
              aria-labelledby="amabAfabMedicalPreference-label"
              controllerProps={{ rules: { required: intl.formatMessage({ defaultMessage: "Required" }) } }}
            />
            <ButtonFooterLayout
              continueButton={
                <ContinueButton>
                  <FormattedMessage defaultMessage="Continue" />
                </ContinueButton>
              }
            />
          </Form>
        }
      />
    </>
  )
}
