import React from "react"
import useCarrotPlan from "../../hooks/useCarrotPlan"
import { Progress } from "@carrotfertility/carotene-core"
import { useGetMostRecentlyCompletedRouting } from "../../hooks/useGetMostRecentlyCompletedRouting"
import { useGetRoutingInfo } from "../../shared/useQueryRoutingInfo"
import { useGetRegistrationJourney } from "../../hooks/useGetRegistrationJourney"
import useGetMemberBenefit from "../../hooks/useGetMemberBenefit"
import { JourneyOption, useEnabledJourneyOptions } from "../../hooks/useJourneyOptions"
import { ErrorStep } from "./ErrorStep"
import { JourneyNotSupportedDialog } from "../JourneyNotSupportedDialogContent"
import { CarrotPlanOptionsContent } from "../CarrotPlansOptionsDialogContent"
import { CarrotPlan } from "#/types/carrotPlanTypes"
import { HttpErrors } from "../../../../utils/HttpErrors"

export enum OPTIONS {
  KEEP_MY_PLAN = "KEEP_MY_PLAN",
  REFRESH_MY_PLAN = "REFRESH_MY_PLAN",
  DONE_FOR_NOW = "DONE_FOR_NOW"
}

type UseCarrotPlanOptionsStep = {
  carrotPlan: CarrotPlan
  memberJourney: string
  memberJourneyIsSupported: boolean
  isAnythingLoading: boolean
  isAnythingInError: boolean
  hasCarrotPlan: boolean
  journeyOptions: JourneyOption[]
}

function useCarrotPlanOptionsStep(): UseCarrotPlanOptionsStep {
  const { data: carrotPlan, isLoading: isCarrotPlanLoading, hasCarrotPlan, isError: carrotPlanError } = useCarrotPlan()
  const { data: memberBenefit, isLoading: isMemberBenefitLoading, isError: memberBenefitError } = useGetMemberBenefit()
  const { journeyOptions } = useEnabledJourneyOptions(memberBenefit)
  const {
    data: registrationJourneyResult,
    isLoading: isRegistrationJourneyLoading,
    isError: isRegistrationJourneyError
  } = useGetRegistrationJourney()
  const {
    data: mostRecentCompleteRouting,
    isLoading: isMostRecentJourneyLoading,
    isError: isMostRecentJourneyError
  } = useGetMostRecentlyCompletedRouting()
  const {
    data: routingInfo,
    isLoading: isRoutingInfoLoading,
    isError: isRoutingError,
    error: routingError
  } = useGetRoutingInfo()
  const memberJourney = mostRecentCompleteRouting?.journey ?? routingInfo?.journey ?? registrationJourneyResult?.journey
  const memberJourneyIsSupported =
    memberJourney && journeyOptions && !!journeyOptions.find((journeyOption) => journeyOption.value === memberJourney)

  const isAnythingLoading =
    isCarrotPlanLoading ||
    isRegistrationJourneyLoading ||
    isRoutingInfoLoading ||
    isMostRecentJourneyLoading ||
    isMemberBenefitLoading

  const isAnythingInError =
    carrotPlanError ||
    isRegistrationJourneyError ||
    isMostRecentJourneyError ||
    memberBenefitError ||
    (isRoutingError && routingError.name !== HttpErrors.NOT_FOUND)

  return {
    carrotPlan,
    memberJourney,
    memberJourneyIsSupported,
    isAnythingLoading,
    isAnythingInError,
    hasCarrotPlan,
    journeyOptions
  }
}

export function CarrotPlanOptionsStep(): JSX.Element {
  const [showJourneyNotSupportedDialog, setShowJourneyNotSupportedDialog] = React.useState(false)
  const {
    carrotPlan,
    memberJourney,
    memberJourneyIsSupported,
    isAnythingLoading,
    isAnythingInError,
    hasCarrotPlan,
    journeyOptions
  } = useCarrotPlanOptionsStep()

  React.useEffect(() => {
    if (!memberJourneyIsSupported) {
      if (hasCarrotPlan && !memberJourney) {
        // This is an expected state for members who were in early registration cohorts
        // They did not go through the routing flow to request their carrot plan, so their journey is not set
        setShowJourneyNotSupportedDialog(false)
      } else if (!journeyOptions.length) {
        // We don't want to show the JourneyNotSupportedDialog if there are no journey options because this indicates that the memberBenefit is empty
        // We want to err on the side of not showing this dialog because it's concerning to members
        setShowJourneyNotSupportedDialog(false)
      } else {
        setShowJourneyNotSupportedDialog(true)
      }
    }
  }, [memberJourney, hasCarrotPlan, memberJourneyIsSupported, journeyOptions?.length])

  if (isAnythingLoading) {
    return <Progress />
  }

  if (isAnythingInError) {
    return <ErrorStep />
  }

  return showJourneyNotSupportedDialog ? (
    <JourneyNotSupportedDialog memberJourney={memberJourney} />
  ) : (
    <CarrotPlanOptionsContent {...{ carrotPlan }} />
  )
}
