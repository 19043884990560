import React, { useState } from "react"
import { GuideSurvey } from "./GuideSurvey"
import { useSubmitGuideSurveyAnswers } from "../hooks/useSubmitGuideSurveyAnswers"
import { Survey } from "../lib/SurveyConstants"
import { Frame } from "./Frame"
import { getHeap } from "../utils/heap"
import { useIntl } from "react-intl"

const SurveyFrame = ({
  questionIndex,
  menu,
  survey,
  guideSlugId
}: {
  questionIndex: number
  menu: JSX.Element
  survey: Survey
  guideSlugId: string
}): JSX.Element => {
  const [currentAnswers, setCurrentAnswers] = useState(null)
  const intl = useIntl()

  const { mutate, isLoading: isSubmitting } = useSubmitGuideSurveyAnswers()
  function trackMemberSkippedSurvey(): void {
    getHeap().track("Member Skipped Survey Questions", {
      "Guide Slug ID": guideSlugId
    })
  }

  function handleQuestionSelect(question: string, selectedOption: number): void {
    if (selectedOption) {
      setCurrentAnswers((prevState: any) => ({
        ...prevState,
        [question]: selectedOption
      }))
    }
  }

  function getHeaderText(): string {
    if (questionIndex > 0 && questionIndex < survey.questions.length) {
      return intl.formatMessage(
        {
          defaultMessage: "Question {currentQuestion, number} of 3"
        },
        { currentQuestion: questionIndex }
      )
    }

    return intl.formatMessage({ defaultMessage: "Survey" })
  }

  if (isSubmitting) {
    return null
  }

  if (questionIndex >= survey.questions.length) {
    if (currentAnswers) {
      const submitAnswersData = {
        guideId: guideSlugId,
        ...currentAnswers
      }
      mutate(submitAnswersData)
    } else {
      trackMemberSkippedSurvey()
    }
    return null
  }

  return (
    <Frame topGreyHeaderText={getHeaderText()} menu={menu}>
      <GuideSurvey
        title={intl.formatMessage(survey.questions[questionIndex].name)}
        text={intl.formatMessage(survey.questions[questionIndex].body)}
        questionIndex={questionIndex}
        onQuestionSelect={handleQuestionSelect}
      />
    </Frame>
  )
}

export { SurveyFrame }
