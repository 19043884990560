import React, { FC } from "react"
import { CarrotCardModuleSharedProps } from "#/components/views/molecules/CarrotCardModuleContainer"
import { FormattedMessage } from "react-intl"
import { DbgPaths } from "#/utils/Paths"
import CardStates from "#/utils/CardStates"
import { CarrotCardModuleTemplate } from "./carrot-card-module-template"
import { InternalIcon, Link } from "@carrotfertility/carotene-core"

const CarrotCardShippedModule: FC<CarrotCardModuleSharedProps> = () => (
  <CarrotCardModuleTemplate
    cardState={CardStates.SHIPPED}
    body={
      <FormattedMessage defaultMessage="Your card has shipped. Activate it when it arrives so you can start using it right away." />
    }
    links={[
      <Link
        key="carrot-card-how-to"
        endIcon={<InternalIcon fontSize="small" color="primary" />}
        href={DbgPaths.CARROT_CARD}
      >
        <FormattedMessage defaultMessage="Learn how Carrot Card works" />
      </Link>
    ]}
  />
)

export { CarrotCardShippedModule }
