import React, { useContext } from "react"
import {
  Container,
  DialogContent,
  DialogTitle,
  Link,
  Stack,
  Stepper,
  Typography,
  useTheme
} from "@carrotfertility/carotene-core"
import { UploadFileModalStep, FileTypes } from "../../get-reimbursed/steps/UploadFile"
import FileCategories from "../../../utils/FileCategories"
import { FormattedMessage } from "react-intl"
import { useStateMachine } from "../../context/stateMachine/StateMachineV2"
import { UploadAdditionalDocumentsContext } from "../index"
import Settings from "../../..//utils/CarrotConfig"
import { useTextDirection } from "#/services/locale/hooks/useTextDirection"

const UploadAdditionalDocuments = () => {
  const theme = useTheme()
  const { send } = useStateMachine(null)

  const { files, setFiles } = useContext(UploadAdditionalDocumentsContext)
  const maxFileSize = Settings.MAX_FILE_SIZE / 1000000
  const textDirection = useTextDirection()

  const UploadAdditionalDocumentsBody = () => {
    return (
      <Stack spacing={theme.tokens.spacing.md}>
        <Typography>
          <FormattedMessage
            defaultMessage="Add the files <link>requested by your Care Team</link>."
            values={{
              link: (linkContent) => (
                <Link target="_blank" href={"/messages"} color="inherit">
                  {linkContent}
                </Link>
              )
            }}
          />
        </Typography>

        <Typography>
          <FormattedMessage
            defaultMessage="We can accept jpg, jpeg, png, pdf, HEIC, doc, docx, txt, xlsx, and zip files up to {maxFileSize} MB."
            values={{ maxFileSize }}
          />
        </Typography>
      </Stack>
    )
  }

  const UploadAdditionalDocumentsHeader = () => {
    return (
      <DialogTitle id="modal-title">
        <FormattedMessage defaultMessage="Upload more documents " />
      </DialogTitle>
    )
  }

  const onContinueClick = (attachments: FileTypes): void => {
    setFiles(attachments)
    send("")
  }

  return (
    <Container>
      <Stepper
        dir={textDirection}
        variant="progress"
        steps={4}
        activeStep={1}
        position="static"
        sx={{ padding: "8px 0" }}
      />
      <Stack spacing={theme.tokens.spacing.lg}>
        <DialogContent>
          <UploadFileModalStep
            fileCategory={FileCategories.STATEMENT}
            header={<UploadAdditionalDocumentsHeader />}
            body={<UploadAdditionalDocumentsBody />}
            onContinueClick={onContinueClick}
            files={files}
            useArrowButton={false}
          />
        </DialogContent>
      </Stack>
    </Container>
  )
}

export { UploadAdditionalDocuments }
