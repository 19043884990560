import React, { useEffect } from "react"
import { useStateMachine } from "../../../context/stateMachine/StateMachineV2"
import { useGetRoutingInfo } from "#/components/carrot-plans/shared/useQueryRoutingInfo"
import { AMAB_AFAB_MEDICAL_PREFERENCE } from "../journey/AmabAfabMedicalPreferenceStep"

export default function ShowMedicalQuestionsDecisionStep(): JSX.Element {
  const { data: routingData } = useGetRoutingInfo()
  const medicalPreference = routingData?.amabAfabMedicalPreference
  const skipMedicalQuestionsForJourneyStage: boolean = routingData?.journeyStage === "POSTPARTUM"

  function showMedicalQuestion(): string {
    if (
      skipMedicalQuestionsForJourneyStage ||
      medicalPreference === AMAB_AFAB_MEDICAL_PREFERENCE.DECLINE_MEDICAL_QUESTIONS
    ) {
      return "NO"
    }
    return "YES"
  }

  const { send } = useStateMachine()
  useEffect(() => {
    send(showMedicalQuestion())
  })
  return <></>
}
