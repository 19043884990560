import React, { useEffect } from "react"
import { GuideComponentProps } from "#/pages/guides/GuideParser"
import { Redirect, Route, useHistory } from "react-router-dom"
import slugify from "slugify"
import { PageNavigation } from "./components/PageNavigation"
import { LandingPageState } from "./LandingPage"
import { useGetCountByGuideEmployee } from "./hooks/useGetCountByGuideEmployee"
import { SectionRouter } from "./SectionRouter"
import { SurveyRouter } from "./SurveyRouter"
import { FullScreenLoading } from "#/components/read/shared/FullScreenLoading"
import { Switch } from "#/services/routing/Switch"

const GuideRouter = ({ guide, category }: GuideComponentProps): JSX.Element => {
  const guideSlugId = `${guide.slug}-${guide.id}`
  const categorySlug = slugify(category?.fields?.navTitle)
  const guideHomePath = `/read/${categorySlug}/guide/${guide.slug}`

  const { data, isLoading } = useGetCountByGuideEmployee(guideSlugId)
  const history = useHistory()
  const willShowSurvey = !data

  useEffect(() => {
    if (history.action === "POP" && !willShowSurvey) {
      const [lastSection] = guide.sections.slice(-1)
      history.push(`${guideHomePath}/${lastSection.slug}/${lastSection.pages.length}`)
    }
  }, [guide.sections, guideHomePath, history, history.action, willShowSurvey])

  if (isLoading) {
    return <FullScreenLoading />
  }

  const returnHome = (landingPageState?: LandingPageState): void => history.push(guideHomePath, landingPageState || {})
  const handlePageChange = (sectionIndex: number): void => {
    history.push(`${guideHomePath}/${guide.sections[sectionIndex].slug}`)
  }
  const navigationMenu = <PageNavigation onExit={() => returnHome()} guide={guide} onPageChange={handlePageChange} />

  const handleOnLastPageNext = (): void => {
    returnHome({ isPostGuide: true })
  }
  const handleOnFirstPageBack = (): void => {
    const lastSectionIndex = guide.sections.length - 1
    const section = guide.sections[lastSectionIndex]
    history.push(`${guideHomePath}/${section.slug}/${section.pages.length}`)
  }

  return (
    <>
      <Switch>
        <Route
          exact
          path="/read/:category/guide/:guide/survey"
          component={() => (
            <SurveyRouter
              menu={navigationMenu}
              onLastPageNext={handleOnLastPageNext}
              onFirstPageBack={handleOnFirstPageBack}
              guideSlugId={guideSlugId}
              guideRootPath={guideHomePath}
              guideTitle={guide.title}
            />
          )}
        />

        <Route
          path="/read/:category/guide/:guide/:section"
          component={() => {
            return (
              <SectionRouter
                menu={navigationMenu}
                willShowSurvey={willShowSurvey}
                sections={guide.sections}
                guideRootPath={guideHomePath}
                guideSlugId={guideSlugId}
                guideTitle={guide.title}
              />
            )
          }}
        />
        <Redirect to={`/read/:category/guide/:guide`} />
      </Switch>
    </>
  )
}

export { GuideRouter }
