import React, { useEffect, useState, useRef } from "react"
import { Box, Tile, Typography } from "@carrotfertility/carotene-core"
import { useGetMostRecentlyCompletedRouting } from "#/components/carrot-plans/hooks/useGetMostRecentlyCompletedRouting"
import { useCurrentUser } from "#/components/context/user/UserContext"
import { defineMessage, FormattedMessage, useIntl } from "react-intl"
import { Locale } from "#/types/Locale"

declare global {
  interface Window {
    QSI?: any
    Q_Language?: string
  }
}

const JOURNEYS = {
  AssistedReproduction: "ASSISTED_REPRODUCTION",
  TryingToGetPregnant: "TRY_PREGNANT",
  Pregnant: "PREGNANT"
}

const QualtricsIntercept: React.FC = () => {
  const { employeeId } = useCurrentUser()
  const [isLinkInjected, setIsLinkInjected] = useState(false)
  const linkBoxRef = useRef<HTMLDivElement>(null)
  const intl = useIntl()
  const { locale } = intl

  const {
    data: routingData,
    isLoading: isRoutingInfoLoading,
    isError: isRoutingInfoError
  } = useGetMostRecentlyCompletedRouting()

  const isRoutingDataAvailable = !isRoutingInfoLoading && !isRoutingInfoError && routingData

  useEffect(() => {
    if (isRoutingDataAvailable && employeeId && window.QSI && locale) {
      window.Q_Language = Locale.getLocaleFromString(locale).qualtricsLanguageCode

      window.QSI.config = {
        ...window.QSI.config,
        externalReference: String(employeeId)
      }

      window.dispatchEvent(new Event("externalReferenceReady"))
    }
  }, [employeeId, locale, isRoutingDataAvailable])

  const getTileText = () => {
    switch (routingData?.journey) {
      case JOURNEYS.AssistedReproduction:
      case JOURNEYS.TryingToGetPregnant:
        return defineMessage({
          defaultMessage:
            "How is your journey to have a baby going so far? Your update helps us improve our support for everyone."
        })
      case JOURNEYS.Pregnant:
        return defineMessage({
          defaultMessage:
            "How is your pregnancy journey going so far? Your update helps us improve our support for everyone."
        })
      default:
        return null
    }
  }

  useEffect(() => {
    const linkBox = document.getElementById("outcomes-onboarding-survey-link-box")

    if (linkBox) {
      const observer = new MutationObserver(() => {
        const link = linkBox.querySelector<HTMLAnchorElement>("a")
        if (link) {
          Object.assign(link.style, {
            margin: "0px",
            fontFamily: '"Gotham A", "Gotham B", "Noto Sans", "Noto Sans Thai Looped", sans-serif',
            fontWeight: "400",
            fontSize: "1rem",
            lineHeight: "150%",
            textDecoration: "underline 2px rgb(228, 132, 0)",
            textUnderlineOffset: "0.25rem",
            outline: "rgb(33, 150, 243) none 0.125rem",
            outlineOffset: "0.125rem",
            zIndex: "1",
            color: "rgb(45, 45, 45)"
          })
          setIsLinkInjected(true)
          observer.disconnect()
        } else {
          setIsLinkInjected(false)
        }
      })

      observer.observe(linkBox, { childList: true, subtree: true })

      return () => {
        observer.disconnect()
      }
    } else {
      setIsLinkInjected(false)
    }
  }, [isRoutingInfoLoading, isRoutingInfoError, routingData])

  const tileText = getTileText()

  return (
    <Tile ref={linkBoxRef} sx={isLinkInjected ? {} : { display: "none", visibility: "hidden" }}>
      <Box
        display="flex"
        flexDirection={["column-reverse", "column-reverse", "row"]}
        minWidth="224px"
        gap={(theme) => theme.spacing(theme.tokens.spacing.lg)}
        alignItems="center"
      >
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          gap={(theme) => theme.spacing(theme.tokens.spacing.lg)}
        >
          <Box component="img" src="/images/speech-bubbles.png" alt="" width={93} height={93} />
          <Box display="flex" flexDirection="column" gap={(theme) => theme.spacing(theme.tokens.spacing.lg)}>
            <Box>
              <Typography variant="h4" component="h3">
                <FormattedMessage defaultMessage="Check-in time" />
              </Typography>
              {tileText && <Typography>{intl.formatMessage(tileText)}</Typography>}
            </Box>
            <Box id="outcomes-onboarding-survey-link-box"></Box>
          </Box>
        </Box>
      </Box>
    </Tile>
  )
}

export default QualtricsIntercept
