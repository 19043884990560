import React, { useContext, useEffect, useState } from "react"
import { Progress } from "./Progress"

type ProgressTrackerContextObject = {
  step: number
  title: string
  numSteps: number
  visible: boolean
  setProgress: (step: any, title: any) => void
  hideProgress: () => void
  showProgress: () => void
}

const ProgressTrackerContext: React.Context<ProgressTrackerContextObject> = React.createContext({
  step: 0,
  title: "",
  numSteps: 0,
  visible: false,
  setProgress: () => {},
  hideProgress: () => {},
  showProgress: () => {}
})

export const useProgressTracker = (step?: number, title?: string) => {
  const contextValues = useContext(ProgressTrackerContext)
  useEffect(() => {
    if (step && title) {
      contextValues.setProgress(step, title)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- See https://carrotfertility.atlassian.net/wiki/spaces/PE/pages/2050295461/Remove+Build+Warnings#react-hooks%2Fexhaustive-deps
  }, [])
  return contextValues
}

// @ts-expect-error TS(7031) FIXME: Binding element 'children' implicitly has an 'any'... Remove this comment to see the full error message
export default function ProcessTracker({ children, numSteps }) {
  const [step, setStep] = useState(0)
  const [title, setTitle] = useState("")
  const [visible, setVisible] = useState(false)
  // @ts-expect-error TS(7006) FIXME: Parameter 'step' implicitly has an 'any' type.
  function setProgress(step, title) {
    setStep(step)
    setTitle(title)
    setVisible(true)
  }
  function hideProgress() {
    setVisible(false)
  }
  function showProgress() {
    setVisible(true)
  }
  return (
    <ProgressTrackerContext.Provider
      value={{
        step,
        numSteps,
        title,
        visible,
        setProgress,
        hideProgress,
        showProgress
      }}
    >
      <ConnectedProgress />
      {children}
    </ProgressTrackerContext.Provider>
  )
}

function ConnectedProgress() {
  const { step, title, numSteps, visible } = useProgressTracker()
  return visible ? <Progress inactiveStepBackgroundColor="#DBE6EE" label={title} {...{ step, numSteps }} /> : null
}
