import React, { useContext } from "react"
import { FormattedMessage } from "react-intl"
import { Alert, DialogContentText, DialogTitle, Link } from "@carrotfertility/carotene-core"
import { Form, FormOnSubmitHandler } from "@carrotfertility/carotene-core-x"
import { useStateMachine } from "#/components/context/stateMachine/StateMachineV2"
import RequestCardWrapper from "#/pages/request-carrot-card-flow/requestCardWrapper"
import { Address, AddressFields } from "#/services/common-forms"
import { reportError } from "#/utils/ErrorReporting"
import { RequestCardContext } from "#/pages/request-carrot-card-flow"
import { TtcPaths } from "../../../utils/Paths"
import { MemberInternationalAddress, MemberUSAddress } from "#/lib/carrot-api/types/Address"
import { useMutation } from "@tanstack/react-query"
import { carrotClient } from "#/utils/CarrotClient"
import { useCurrentUser } from "#/components/context/user/UserContext"

const RequestCardMailingAddress = (): JSX.Element => {
  const { canGoBack, send } = useStateMachine(null)
  const showInformationScreen = useContext(RequestCardContext).showInformationScreen
  const { employeeId, address1, address2, city, state, zip, refreshUserInfo } = useCurrentUser()

  const updateAddress = useMutation({
    mutationFn: (addressFields: MemberUSAddress | MemberInternationalAddress) =>
      carrotClient.updateMemberCardAddress(addressFields),
    onSuccess: () => {
      refreshUserInfo()
      send("")
    }
  })

  const onSubmit: FormOnSubmitHandler<AddressFields> = async (fieldValues) => {
    try {
      await updateAddress.mutateAsync({ ...fieldValues, state: fieldValues.state.value })
    } catch (error) {
      error.message =
        `Error for employeeId: "${employeeId}" while confirming Carrot Card® address: RAW:` + error.message
      reportError(error)
    }
  }

  return (
    <Form onSubmit={onSubmit}>
      <RequestCardWrapper showBackButton={canGoBack || showInformationScreen} showContinueButton>
        <DialogTitle>
          <FormattedMessage defaultMessage="Confirm your address" />
        </DialogTitle>
        <DialogContentText marginBottom={(theme) => theme.tokens.spacing.xl}>
          <FormattedMessage defaultMessage="Where do you want us to mail your Carrot Card? This will also be your billing address for the card." />
        </DialogContentText>
        <Address
          defaultValues={{
            address1,
            address2,
            city,
            state,
            zip
          }}
        />
        {updateAddress.isError && (
          <Alert severity="error" sx={{ marginBlockStart: (theme) => theme.tokens.spacing.xl }}>
            <FormattedMessage
              defaultMessage="An unexpected error occurred. Please <link>contact us</link> for assistance."
              values={{
                link: (linkContent) => (
                  <Link color="inherit" href={TtcPaths.TTC_CARE_NAV} target={"_blank"}>
                    {linkContent}
                  </Link>
                )
              }}
            />
          </Alert>
        )}
      </RequestCardWrapper>
    </Form>
  )
}

export { RequestCardMailingAddress }
