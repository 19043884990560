import React from "react"
import { useUserRole } from "../partner-access/hooks/usePartnerAccess"
import { MemberOnlySpaceNotice } from "#/components/partner-access/member-only-space-notice"

const EmployeeOnlySpaceContainer = ({ ...props }): JSX.Element => {
  const { isPartnerAccessingAccount, isUserRoleLoading } = useUserRole()

  if (isUserRoleLoading) {
    return null
  }

  if (isPartnerAccessingAccount) {
    return <MemberOnlySpaceNotice />
  }

  return <div>{props.children}</div>
}

export default EmployeeOnlySpaceContainer
