import { CurrencyCodesToAllowedDecimalPlacesMap } from "#/utils/CurrencyCode"
import React, { Dispatch, SetStateAction, useContext, useState } from "react"

type StripeContextValues = {
  companyId: number
  employeeId: string
  email: string
  companyCurrencyCode: string
  companyCountryCode: string
  currencyCodesToAllowedDecimalPlacesMap: CurrencyCodesToAllowedDecimalPlacesMap
  selectedCurrencyCode: string
  setSelectedCurrencyCode: Dispatch<SetStateAction<string>>
  amount: number
  setAmount: Dispatch<SetStateAction<number>>
}

const StripeContext = React.createContext<StripeContextValues>(null)
export const useStripeContext = (): StripeContextValues => useContext(StripeContext)

type StripeContextProviderProps = {
  children: React.ReactNode
  companyId: number
  employeeId: string
  email: string
  companyCurrencyCode: string
  companyCountryCode: string
  currencyCodesToAllowedDecimalPlacesMap: CurrencyCodesToAllowedDecimalPlacesMap
}

export function StripePaymentsContextProvider({
  children,
  companyId,
  employeeId,
  email,
  companyCurrencyCode,
  companyCountryCode,
  currencyCodesToAllowedDecimalPlacesMap
}: StripeContextProviderProps): JSX.Element {
  const [amount, setAmount] = useState<number>()
  const [currencyCode, setCurrencyCode] = useState<string>()
  return (
    <StripeContext.Provider
      value={{
        companyId,
        employeeId,
        email,
        companyCurrencyCode,
        companyCountryCode,
        currencyCodesToAllowedDecimalPlacesMap,
        selectedCurrencyCode: currencyCode,
        setSelectedCurrencyCode: setCurrencyCode,
        amount,
        setAmount
      }}
    >
      {children}
    </StripeContext.Provider>
  )
}
