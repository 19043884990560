import { useQuery } from "@tanstack/react-query"
import ResponseError from "../../../../types/responseError"
import { BenefitEnrollmentStatus } from "../../../../types/benefitEnrollmentStatus"
import { useEffect, useState } from "react"
import buildCarrotApiRetryPolicy from "../../../../utils/CarrotApiRetryPolicy"
import useQueryExpirationHandler from "../../authentication/hooks/useQueryExpirationHandler"
import { carrotClient } from "#/utils/CarrotClient"

function buildResiliencePolicy() {
  return {
    retry: buildCarrotApiRetryPolicy(3),
    // @ts-expect-error TS(7006) FIXME: Parameter 'attempt' implicitly has an 'any' type.
    retryDelay: (attempt) => attempt * 100
  }
}

function buildRefetchPolicy() {
  return {
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false
  }
}

// @ts-expect-error TS(7006) FIXME: Parameter 'query' implicitly has an 'any' type.
function useQueryInitialLoading(query) {
  const isLoading = query.isLoading
  const [initialLoading, setInitialLoading] = useState(isLoading)

  useEffect(() => {
    if (initialLoading) {
      setInitialLoading(isLoading)
    }
  }, [isLoading, initialLoading])

  return initialLoading
}

export function useBenefitEnrollmentStatus() {
  const queryKey = "benefitEnrollmentStatus"
  const query = useQuery<BenefitEnrollmentStatus, ResponseError>(
    [queryKey],
    () => carrotClient.getBenefitEnrollmentStatus(),
    {
      ...buildRefetchPolicy(),
      ...buildResiliencePolicy()
    }
  )
  useQueryExpirationHandler(query)
  const initialLoading = useQueryInitialLoading(query)

  return {
    ...query,
    initialLoading
  }
}
