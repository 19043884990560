import { useHistory } from "react-router"
import { Workflows } from "../steps/medical-questions/workflows"

export const afabJourneys = ["1", "2", "3", "4", "5", "9"]
export const amabJourneys = ["6", "7", "8", "10"]
export const AmabJourneyByUrlParam = {
  "6": "PRESERVATION",
  "7": "LOW_TESTOSTERONE",
  "8": "ASSISTED_REPRODUCTION",
  "10": "TRY_PREGNANT"
}

export const AfabJourneyByUrlParam: { [key: string]: string } = {
  "1": "PREGNANT",
  "2": "TRY_PREGNANT",
  "3": "PRESERVATION",
  "4": "ASSISTED_REPRODUCTION",
  "5": "MENOPAUSE",
  "9": "LOW_TESTOSTERONE"
}

// @ts-expect-error TS(7006) FIXME: Parameter 'journey' implicitly has an 'any' type.
export const useMedicalQuestionMenuItemWorkflow = (journey) => {
  const history = useHistory()
  if (afabJourneys.includes(journey)) {
    return Workflows.AFAB_MEDICAL_QUESTIONS
  } else if (amabJourneys.includes(journey)) {
    return Workflows.AMAB_MEDICAL_QUESTIONS
  } else {
    history.push("/")
  }
}
