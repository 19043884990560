import React, { FC } from "react"
import { CarrotCardModuleSharedProps } from "#/components/views/molecules/CarrotCardModuleContainer"
import { FormattedMessage } from "react-intl"
import CardStates from "#/utils/CardStates"
import { CarrotCardModuleTemplate } from "./carrot-card-module-template"

const CarrotCardRestrictionPeriodModule: FC<CarrotCardModuleSharedProps> = () => (
  <CarrotCardModuleTemplate
    cardState={CardStates.RESTRICTION_PERIOD}
    body={
      <FormattedMessage defaultMessage="Due to your benefit sponsor’s end-of-year policies, your Carrot Card is locked until January 1." />
    }
  />
)

export { CarrotCardRestrictionPeriodModule }
