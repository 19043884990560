import React from "react"
import { useStateMachine } from "../../../context/stateMachine/StateMachineV2"
import { Form, FormDatePicker } from "@carrotfertility/carotene-core-x"
import {
  useCheckRoutingInfoQueryCache,
  useUpdateRoutingInfo
} from "#/components/carrot-plans/shared/useQueryRoutingInfo"
import { FormattedMessage, useIntl } from "react-intl"
import { ButtonFooterLayout, ContinueButton, RoutingFlowTitle, StepLayout } from "#/components/carrot-plans/shared"
import { dayjs, Typography } from "@carrotfertility/carotene-core"
import { validatePastDate } from "#/services/common-forms"

export default function EggRetrievalDateStep(): JSX.Element {
  const intl = useIntl()
  const { mutateAsync } = useUpdateRoutingInfo()
  const routingData = useCheckRoutingInfoQueryCache()
  const defaultValue = routingData?.eggRetrievalDate
  const { send } = useStateMachine(defaultValue ? "" : null)

  async function onClickContinue({ eggRetrievalDate }: { eggRetrievalDate?: dayjs.Dayjs }): Promise<void> {
    await mutateAsync({
      eggRetrievalDate: eggRetrievalDate.format("YYYY-MM-DD")
    })
    send("")
  }

  const header = intl.formatMessage({
    defaultMessage: "When did the egg retrieval take place?"
  })

  return (
    <>
      <RoutingFlowTitle title={header} />
      <StepLayout
        header={header}
        description={
          <Typography>
            <FormattedMessage
              defaultMessage="Your best estimate is fine."
              description="This relates to someone providing an approximate date if they do not know or remember the exact date."
            />
          </Typography>
        }
        formElements={
          <Form defaultValues={{ eggRetrievalDate: dayjs(defaultValue) }} onSubmit={onClickContinue}>
            <FormDatePicker
              name="eggRetrievalDate"
              controllerProps={{
                rules: {
                  required: intl.formatMessage({ defaultMessage: "Required" }),
                  validate: (value: dayjs.Dayjs) => {
                    const result = validatePastDate(value)
                    if (typeof result !== "boolean") {
                      return intl.formatMessage(result)
                    }
                    return result
                  }
                }
              }}
              slotProps={{
                textField: {
                  "aria-label": intl.formatMessage({ defaultMessage: "Egg retrieval date" })
                }
              }}
            />
            <ButtonFooterLayout
              continueButton={
                <ContinueButton>
                  <FormattedMessage defaultMessage="Continue" />
                </ContinueButton>
              }
            />
          </Form>
        }
      />
    </>
  )
}
