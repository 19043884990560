/* eslint formatjs/no-literal-string-in-jsx: "error" */
import { ReimbursementsListDeductibleString } from "#/components/views/reimbursements/reimbursementsList/reimbursement-status-lists/ReimbursementsListDeductibleString"
import React, { FC } from "react"
import { Reimbursement } from "#/types/reimbursement"
import { useSelector } from "react-redux"
import { getBankInfoEnabled } from "#/redux/reducers/benefitConfiguration"
import { FormattedMessage, useIntl } from "react-intl"
import moment from "moment"

type ReimbursementsListPaidExpenseStateProps = {
  reimbursement: Reimbursement
}

const ReimbursementsListPaidExpenseState: FC<ReimbursementsListPaidExpenseStateProps> = ({
  reimbursement
}: ReimbursementsListPaidExpenseStateProps) => {
  const intl = useIntl()
  const formattedDate = intl.formatDate(moment(reimbursement.paidDatetime).toDate(), {
    month: "short",
    day: "2-digit"
  })
  const reimbursedOrReferred = useSelector(getBankInfoEnabled) ? (
    <FormattedMessage defaultMessage="Reimbursed on {formattedDate}" values={{ formattedDate }} />
  ) : (
    <FormattedMessage defaultMessage="Approved and referred to benefit sponsor for payment" />
  )

  return (
    <>
      {reimbursement.amount > 0 && reimbursedOrReferred}
      <br />
      <ReimbursementsListDeductibleString reimbursement={reimbursement} />
    </>
  )
}

export { ReimbursementsListPaidExpenseState }
