import { UseMutationResult } from "@tanstack/react-query"
import { useCurrentUser } from "#/components/context/user/UserContext"
import { useIntl } from "react-intl"
import { carrotClient } from "#/utils/CarrotClient"
import { useToastsWithMutation } from "#/utils/hooks/useToastsWithMutation"

export function useUpdateAccountSettings(): UseMutationResult {
  const { refreshUserInfo } = useCurrentUser()
  const intl = useIntl()

  const getSuccessMessage = (): string => {
    return intl.formatMessage({ defaultMessage: "Account settings have been saved." })
  }

  const request = (userInfo: Record<string, any>) =>
    carrotClient.updateUser({
      ...userInfo,
      state: userInfo.state?.value ?? userInfo.state,
      internationalCountry: userInfo.internationalCountry?.value
    })

  return useToastsWithMutation({
    request,
    getSuccessMessage: getSuccessMessage,
    onSuccess: refreshUserInfo,
    retry: false
  })
}
