import React from "react"
import { BenefitAvailabilityStatus } from "#/types/benefitEnrollmentStatus"
import DeductibleEnrollmentReminder from "./DeductibleEnrollmentReminder"
import EnrollmentExpiredReminder from "./EnrollmentExpiredReminder"
import { defineMessage } from "react-intl"

type EnrollmentReminderProps = {
  availabilityStatus: BenefitAvailabilityStatus
  onExit: () => void
}

const EnrollmentReminder = ({ availabilityStatus, onExit }: EnrollmentReminderProps): JSX.Element => {
  switch (availabilityStatus) {
    case BenefitAvailabilityStatus.ENROLLMENT_PLAN_EXPIRED:
      return (
        <EnrollmentExpiredReminder
          onExit={onExit}
          title={defineMessage({ defaultMessage: "Your insurance info has expired" })}
        />
      )

    case BenefitAvailabilityStatus.COMPANY_ADDED_DEDUCTIBLE:
      return (
        <DeductibleEnrollmentReminder
          onExit={onExit}
          title={defineMessage({ defaultMessage: "To access your funds, tell us about your insurance" })}
        />
      )
  }
}

EnrollmentReminder.displayName = "EnrollmentReminder"

export default EnrollmentReminder
