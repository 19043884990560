import React, { useState } from "react"
import { useSetDoneWithCarrot } from "./useQueryCaseRateRenewals"
import { DoneForNowContent } from "./DoneForNowContent"
import { ThanksForLettingUsKnowContent } from "./ThanksForLettingUsKnowContent"
import { useCurrentUser } from "../../context/user/UserContext"
import { useHistory } from "react-router-dom"
import { ErrorStep } from "./steps/ErrorStep"
import { Box, CloseIcon, Dialog, IconButton } from "@carrotfertility/carotene-core"
import { useIntl } from "react-intl"

export function NonStaleDoneUsingCarrotModal({
  openDoneUsingCarrotModal,
  setOpenDoneUsingCarrotModal
}: {
  openDoneUsingCarrotModal: boolean
  // @ts-expect-error TS(7051) FIXME: Parameter has a name but no type. Did you mean 'ar... Remove this comment to see the full error message
  setOpenDoneUsingCarrotModal: (boolean) => void
}): JSX.Element {
  enum ModalContent {
    DONE_FOR_NOW,
    THANKS_FOR_LETTING_US_KNOW,
    ERROR
  }
  const { mutate, isLoading } = useSetDoneWithCarrot(true)
  const [modalContent, setModalContent] = useState(ModalContent.DONE_FOR_NOW)
  const { refreshUserInfo } = useCurrentUser()
  const history = useHistory()
  const intl = useIntl()

  async function doneWithCarrot(): Promise<void> {
    mutate(null, {
      onSuccess: () => setModalContent(ModalContent.THANKS_FOR_LETTING_US_KNOW),
      onError: () => setModalContent(ModalContent.ERROR)
    })
  }

  async function closeThanksForLettingUsKnowModal(): Promise<void> {
    setOpenDoneUsingCarrotModal(false)
    await refreshUserInfo()
    history.push("/")
  }

  const displayModalContent = [
    {
      modalContent: ModalContent.DONE_FOR_NOW,
      component: (
        <DoneForNowContent
          takeMeBackOnClick={() => setOpenDoneUsingCarrotModal(false)}
          yesImDoneOnClick={doneWithCarrot}
          isLoading={isLoading}
        />
      )
    },
    {
      modalContent: ModalContent.THANKS_FOR_LETTING_US_KNOW,
      component: <ThanksForLettingUsKnowContent close={closeThanksForLettingUsKnowModal} />
    },
    { modalContent: ModalContent.ERROR, component: <ErrorStep /> }
  ]

  return (
    <Dialog open={openDoneUsingCarrotModal} onClose={() => setOpenDoneUsingCarrotModal(false)}>
      <Box display="flex" justifyContent="flex-end">
        <IconButton
          aria-label={intl.formatMessage({ defaultMessage: "close" })}
          onClick={() => setOpenDoneUsingCarrotModal(false)}
        >
          <CloseIcon />
        </IconButton>
      </Box>
      <Box>{displayModalContent.find((content) => content.modalContent === modalContent).component}</Box>
    </Dialog>
  )
}
