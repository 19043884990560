import React from "react"
import { UploadFileModalStep, FileTypes } from "./UploadFile"
import { Progress } from "../../views/modal/Progress"
import FileCategories from "../../../utils/FileCategories"
import { useSelector } from "react-redux"
import { getCurrencyCode } from "#/redux/reducers/companyInfo"
import { useReimbursementContext } from "../../context/reimbursement/GetReimbursedContext"
import { FormattedDate, FormattedMessage, FormattedNumber, useIntl } from "react-intl"
import { Box, DialogContentText, DialogTitle, Link, Typography } from "@carrotfertility/carotene-core"
import { DbgPaths } from "../../../utils/Paths"
import { useStateMachine } from "../../context/stateMachine/StateMachineV2"
import Settings from "../../../utils/CarrotConfig"

export const UploadStatement = (): JSX.Element => {
  const intl = useIntl()
  const fileCategory = FileCategories.STATEMENT
  const { updateStatementFiles, expenseId, state } = useReimbursementContext()
  const isCardExpense = !!expenseId
  const { send } = useStateMachine()
  const maxFileSize = Settings.MAX_FILE_SIZE / 1000000

  const UploadStatementBody = () => {
    return (
      <Box sx={{ display: "flex", flexDirection: "column", gap: (theme) => theme.tokens.spacing.lg }}>
        <DialogContentText>
          <FormattedMessage
            defaultMessage="We can accept jpg, jpeg, png, pdf, HEIC, doc, docx, txt, xlsx, and zip files up to {maxFileSize} MB."
            values={{ maxFileSize }}
          />
        </DialogContentText>
        <Link href={DbgPaths.REQUIRED_DOCS} target="_blank" color="inherit">
          <FormattedMessage defaultMessage="What documents do I need to include?" />
        </Link>
        {expenseId && <Details />}
      </Box>
    )
  }

  const UploadStatementHeader = () => {
    return (
      <DialogTitle>
        <FormattedMessage defaultMessage="Upload itemized statement" />
      </DialogTitle>
    )
  }

  const handleContinueClick = (attachments: FileTypes): void => {
    updateStatementFiles(attachments)
    send(!isCardExpense ? "ABOUT_EXPENSE_ELIGIBLE_REIMBURSEMENT_AMOUNT" : "")
  }

  return (
    <>
      <Progress
        numSteps={3}
        step={1}
        label={intl.formatMessage({ defaultMessage: "Upload Statement" })}
        inactiveStepBackgroundColor="#DBE6EE"
      />
      <UploadFileModalStep
        fileCategory={fileCategory}
        header={<UploadStatementHeader />}
        body={<UploadStatementBody />}
        onContinueClick={handleContinueClick}
        files={state.statementFiles}
        useArrowButton
      />
    </>
  )
}

const Details = () => {
  const currencyCode = useSelector(getCurrencyCode)
  const { transAmount, transDate } = useReimbursementContext()
  const formattedAmount = Number(transAmount) / 100
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        flexWrap: "wrap",
        gap: (theme) => theme.spacing(theme.tokens.spacing.xxxl)
      }}
    >
      <div>
        <Typography variant="overline">
          <FormattedMessage defaultMessage="Transaction date" />
        </Typography>
        <Typography fontWeight="bold">
          <FormattedDate value={transDate} year="numeric" month="short" day="2-digit" />
        </Typography>
      </div>
      <div>
        <Typography variant="overline">
          <FormattedMessage defaultMessage="Amount" />
        </Typography>
        <Typography fontWeight="bold">
          <FormattedNumber
            value={formattedAmount}
            style="currency"
            currency={currencyCode}
            currencyDisplay="narrowSymbol"
          />
        </Typography>
      </div>
    </Box>
  )
}
