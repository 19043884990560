import React, { useCallback, useState } from "react"
import { Select } from "@carrotfertility/carotene"
import { ArrowSubmitButton, ReimbursementDialogFraming, BackArrow } from "../shared/layouts"
import { Progress } from "#/components/views/modal/Progress"
import { useStateMachine } from "#/components/context/stateMachine/StateMachineV2"
import { whoReceivedCareChoices } from "#/components/views/reimbursements/whoReceivedCareChoices"
import { useReimbursementContext } from "#/components/context/reimbursement/GetReimbursedContext"
import { FormattedMessage, useIntl } from "react-intl"
import { FormControlLabel, Link, Checkbox, DialogContentText, DialogTitle, Box } from "@carrotfertility/carotene-core"
import { useSelector } from "react-redux"
import { getBenefitConfiguration } from "#/redux/reducers/benefitConfiguration"
import { CorrectOption } from "@carrotfertility/carotene/dist/lib/components/Select/SelectTypes"
import { DialogActionsBar } from "@carrotfertility/carotene-core-x"

export const AboutThisExpenseWhoReceivedCare = (): JSX.Element => {
  const { send, back } = useStateMachine()
  const { updateWhoReceivedCare, state } = useReimbursementContext()
  const [answer, setAnswer] = useState(state.whoReceivedCare || null)
  const intl = useIntl()
  const [isChecked, setIsChecked] = useState(false)
  const { coverageConfig } = useSelector(getBenefitConfiguration)

  // @ts-expect-error TS(7006) FIXME: Parameter 'value' implicitly has an 'any' type.
  const handleChange = (value): void => {
    setAnswer(value)
  }

  const handleContinueClick = (): void => {
    updateWhoReceivedCare(answer)
    send("")
  }

  const formattedAndFilteredOptions = whoReceivedCareChoices
    .filter((choice) => {
      if (!choice.filter) return choice
      return choice.filter(coverageConfig)
    })
    .map((choice) => ({ ...choice, label: intl.formatMessage(choice.label) }))

  const toggleCheckbox = useCallback((): void => {
    if (!isChecked) {
      setIsChecked(true)
      const castedAnswer: CorrectOption = {
        label: "Me",
        value: "MEMBER"
      }
      setAnswer(castedAnswer)
    } else {
      handleChange(null)
      setIsChecked(false)
      setAnswer(null)
    }
  }, [isChecked])

  return (
    <>
      <Progress
        numSteps={3}
        step={2}
        label={intl.formatMessage({ defaultMessage: "About This Expense" })}
        inactiveStepBackgroundColor="#DBE6EE"
      />
      <BackArrow onClick={back} />
      {formattedAndFilteredOptions.length === 1 && (
        <ReimbursementDialogFraming>
          <DialogTitle>
            <FormattedMessage defaultMessage="Did you receive the care or service?" />
          </DialogTitle>
          <Box
            sx={{ display: "flex", flexDirection: "column", gap: (theme) => theme.spacing(theme.tokens.spacing.lg) }}
          >
            <DialogContentText>
              <FormattedMessage defaultMessage="You can only use funds for your own eligible expenses. " />
            </DialogContentText>
            <DialogContentText>
              <FormattedMessage
                defaultMessage="<link>Visit your benefit guide</link> for more information."
                values={{
                  link: (linkContent) => (
                    <Link target="_blank" href={"/your-benefit-guide/coverage"} color="inherit">
                      {linkContent}
                    </Link>
                  )
                }}
              />
            </DialogContentText>
            <FormControlLabel
              sx={{ alignItems: "center" }}
              label={<FormattedMessage defaultMessage="Yes, I received the care or service." />}
              control={
                <Checkbox value={"acknowledged"} checked={isChecked} onChange={toggleCheckbox} required={true} />
              }
            />
          </Box>
        </ReimbursementDialogFraming>
      )}
      {formattedAndFilteredOptions.length !== 1 && (
        <ReimbursementDialogFraming>
          <DialogTitle id="step-heading">
            <FormattedMessage defaultMessage="Who received the care or service?" />
          </DialogTitle>
          <Box
            sx={{ display: "flex", flexDirection: "column", gap: (theme) => theme.spacing(theme.tokens.spacing.md) }}
          >
            <DialogContentText>
              <FormattedMessage defaultMessage="If you have eligible expenses for more than one person, please upload them as separate submissions." />
            </DialogContentText>
            <Select
              aria-labelledby="step-heading"
              isSearchable={false}
              defaultValue={state.whoReceivedCare?.label ? (state.whoReceivedCare as any) : ""}
              onChange={handleChange}
              options={formattedAndFilteredOptions}
              placeholder={intl.formatMessage({ defaultMessage: "Select option" })}
            />
          </Box>
        </ReimbursementDialogFraming>
      )}
      <DialogActionsBar
        primaryAction={
          <ArrowSubmitButton enabled={Boolean(answer?.value || isChecked)} onClick={handleContinueClick}>
            <FormattedMessage defaultMessage="Continue" />
          </ArrowSubmitButton>
        }
      />
    </>
  )
}
