import React, { useEffect } from "react"
import { FormProvider, useForm } from "react-hook-form"
import { Steps } from "../workflow/steps"
import { useStateMachine } from "../../context/stateMachine/StateMachineContext"
import useSubmitWithErrorCatch from "../hooks/useSubmitWithErrorCatch"
import {
  BenefitEnrollmentFlowModalBackButton,
  BenefitEnrollmentModalContinueButton,
  BenefitEnrollmentModalFormSelect
} from "../shared"
import { useCurrentUser } from "../../context/user/UserContext"
import { useUserDeductible } from "../../context/user/DeductibleContext"
import { GetWorkflow } from "./BenefitEnrollmentFlow"
import { FormattedMessage, useIntl } from "react-intl"
import { Box, Typography } from "@carrotfertility/carotene-core"
import { useProgressTracker } from "../../views/modal/ProgressTracker"

const FieldName = "partnerTaxDependentValue"
const ViewName = Steps.PARTNER_TAX_DEPENDENT

function PartnerTaxDependentStep(): JSX.Element {
  const formMethods = useForm()
  const { partnerAsDependent, memberHasLegalPartner } = useCurrentUser()
  const { updateBenefitEnrollmentAnswers } = useUserDeductible()
  const { setNextStep } = useStateMachine(ViewName, partnerAsDependent)

  const { showProgress } = useProgressTracker()
  useEffect(showProgress, [showProgress])

  const workflowType = GetWorkflow()
  useStateMachine(ViewName, memberHasLegalPartner)
  // @ts-expect-error TS(7006) FIXME: Parameter 'args' implicitly has an 'any' type.
  const { onSubmit } = useSubmitWithErrorCatch(async (args) => {
    const val = args[FieldName].value
    val !== "IM_NOT_SURE" &&
      (await updateBenefitEnrollmentAnswers({
        partnerAsDependent: val
      }))
    setNextStep(ViewName, val)
  })
  const intl = useIntl()

  const { handleSubmit } = formMethods

  return (
    <FormProvider {...formMethods}>
      <Box paddingBottom={(theme) => theme.spacing(theme.tokens.spacing.xxxl)}>
        <BenefitEnrollmentFlowModalBackButton />
      </Box>
      <Typography
        id="step-heading"
        variant="h2"
        color={(theme) => theme.palette.text.primary}
        paddingBottom={(theme) => theme.tokens.spacing.xs}
      >
        <FormattedMessage defaultMessage="Do you have a partner you claim as a dependent on your tax return?" />
      </Typography>
      <Box paddingBottom={(theme) => theme.spacing(theme.tokens.spacing.md)}>
        <Typography color={(theme) => theme.palette.text.secondary}>
          {workflowType === "CMD_HDHP"
            ? intl.formatMessage({
                defaultMessage:
                  "Your partner's medical coverage can affect how your Carrot benefit is taxed and whether any deductibles apply."
              })
            : intl.formatMessage({
                defaultMessage: "Your partner's medical coverage can affect how your Carrot funds are taxed."
              })}
        </Typography>
      </Box>
      <Box paddingBottom={(theme) => theme.spacing(theme.tokens.spacing.lg)}>
        <BenefitEnrollmentModalFormSelect
          name={FieldName}
          aria-labelledby="step-heading"
          defaultValue={partnerAsDependent}
          options={[
            {
              label: intl.formatMessage({
                defaultMessage: "Yes"
              }),
              value: "true"
            },
            {
              label: intl.formatMessage({
                defaultMessage: "No"
              }),
              value: "false"
            },
            {
              label: intl.formatMessage({
                defaultMessage: "I'm not sure"
              }),
              value: "IM_NOT_SURE"
            }
          ]}
        />
      </Box>
      <Box height="18rem" />
      <BenefitEnrollmentModalContinueButton onClick={handleSubmit(onSubmit)} />
    </FormProvider>
  )
}

export { PartnerTaxDependentStep }
