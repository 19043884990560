import {
  TypeCantHaveCountrySkeleton,
  TypeCantHaveSkeleton,
  TypeCountrySkeleton,
  TypeHasAnySkeleton,
  TypeHasCountrySkeleton,
  TypeMustHaveSkeleton
} from "#/types/contentful"
import { AttributeConditionType, Condition, CountryConditionType } from "#/services/contentful/conditional-gate"
import { EntryWithoutUnresolvableLinks } from "#/utils/ContentTypes"

export type AttributeContentfulConditionSkeleton = TypeCantHaveSkeleton | TypeHasAnySkeleton | TypeMustHaveSkeleton
export type CountryContentfulConditionSkeleton = TypeCantHaveCountrySkeleton | TypeHasCountrySkeleton
export type RawContentfulConditionSkeleton = AttributeContentfulConditionSkeleton | CountryContentfulConditionSkeleton

export function toCondition(condition: EntryWithoutUnresolvableLinks<RawContentfulConditionSkeleton>): Condition {
  const conditionType = condition.sys.contentType.sys.id

  if (Object.values(AttributeConditionType).includes(conditionType as AttributeConditionType)) {
    const attributes = toAttributes(condition as EntryWithoutUnresolvableLinks<AttributeContentfulConditionSkeleton>)
    return { attributes, conditionType: conditionType as AttributeConditionType }
  } else if (Object.values(CountryConditionType).includes(conditionType as CountryConditionType)) {
    const attributes = toCountries(condition as EntryWithoutUnresolvableLinks<CountryContentfulConditionSkeleton>)
    return { attributes, conditionType: conditionType as CountryConditionType }
  }

  return null
}

function toAttributes(condition: EntryWithoutUnresolvableLinks<AttributeContentfulConditionSkeleton>): Array<string> {
  const conditionAttributes = condition.fields?.attributes || []
  return conditionAttributes.filter(({ fields }) => fields).map(({ fields: { key } }) => key)
}

function toCountries(condition: EntryWithoutUnresolvableLinks<CountryContentfulConditionSkeleton>): Array<string> {
  const conditionCountryList = condition.fields?.countryList || []
  return conditionCountryList
    .filter(({ fields }: EntryWithoutUnresolvableLinks<TypeCountrySkeleton>) => fields)
    .map(({ fields: { alpha2CountryCode } }: EntryWithoutUnresolvableLinks<TypeCountrySkeleton>) => alpha2CountryCode)
}
