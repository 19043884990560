import React, { FC } from "react"
import { useSelector } from "react-redux"
import { getCompanyDisplayName } from "#/redux/reducers/companyInfo"
import { PiiText } from "#/services/tracking"
import { Box, Divider, Stack, Typography } from "@carrotfertility/carotene-core"
import { FormattedMessage } from "react-intl"

export type YourCostShareInformationalProps = {
  reimbursementPercent: number
  isDefaultCategory: boolean
  costShareOnly: boolean
  supportsOOPM: boolean
}

const YourCostShareInformational: FC<YourCostShareInformationalProps> = ({
  reimbursementPercent,
  isDefaultCategory,
  costShareOnly,
  supportsOOPM
}) => {
  const companyDisplayName = useSelector(getCompanyDisplayName)

  return (
    <>
      {!costShareOnly && (
        <>
          <Stack
            paddingTop={(theme) => theme.spacing(theme.tokens.spacing.lg)}
            paddingBottom={(theme) => theme.spacing(theme.tokens.spacing.lg)}
          >
            <Divider flexItem />
          </Stack>
          <Typography variant="body1" fontWeight="bold" color={(theme) => theme.palette.text.secondary}>
            <FormattedMessage
              defaultMessage="Your cost share with {companyDisplayName}"
              values={{
                companyDisplayName: <PiiText>{companyDisplayName}</PiiText>
              }}
            />
          </Typography>
        </>
      )}
      {!supportsOOPM && (
        <Stack display="flex" gap={(theme) => theme.tokens.spacing.lg}>
          {isDefaultCategory ? (
            <Typography>
              <FormattedMessage
                defaultMessage="Cost sharing is when your benefit sponsor pays for a percentage of your medical expenses. {companyDisplayName} will cover {reimbursementPercent, number, ::percent} of each approved eligible expense."
                values={{
                  companyDisplayName: <PiiText>{companyDisplayName}</PiiText>,
                  reimbursementPercent: reimbursementPercent / 100
                }}
              />
            </Typography>
          ) : (
            <Typography>
              <FormattedMessage
                defaultMessage="Cost sharing is when your benefit sponsor pays for a percentage of your medical expenses. {companyDisplayName} will cover {reimbursementPercent, number, ::percent} of each approved eligible expense in this category."
                values={{
                  companyDisplayName: <PiiText>{companyDisplayName}</PiiText>,
                  reimbursementPercent: reimbursementPercent / 100
                }}
              />
            </Typography>
          )}
          {costShareOnly && (
            <>
              <Typography>
                <FormattedMessage defaultMessage="Carrot funds you use may also be subject to bank fees, taxes, and other plan details." />
              </Typography>
            </>
          )}
        </Stack>
      )}
      {supportsOOPM && (
        <Stack display="flex" gap={(theme) => theme.tokens.spacing.lg}>
          <Typography>
            <FormattedMessage defaultMessage="Cost sharing is when your benefit sponsor pays for a percentage of your medical expenses." />
          </Typography>
          <Box display="flex" gap={(theme) => theme.spacing(theme.tokens.spacing.xs)} />
          <Typography>
            <FormattedMessage
              defaultMessage="After you meet your deductible but before you meet your health plan's out-of-pocket maximum, {companyDisplayName} will cover {reimbursementPercent,number, ::percent} of your approved eligible expenses."
              values={{
                companyDisplayName: <PiiText>{companyDisplayName}</PiiText>,
                reimbursementPercent: reimbursementPercent / 100
              }}
            />
          </Typography>
          <Box display="flex" gap={(theme) => theme.spacing(theme.tokens.spacing.xs)} />
          <Typography>
            <FormattedMessage
              defaultMessage="After you meet your out-of-pocket maximum, {companyDisplayName} will cover 100%."
              values={{
                companyDisplayName: <PiiText>{companyDisplayName}</PiiText>
              }}
            />
          </Typography>
          {costShareOnly && (
            <>
              <Box display="flex" gap={(theme) => theme.spacing(theme.tokens.spacing.md)} />
              <Typography>
                <FormattedMessage defaultMessage="Carrot funds you use may also be subject to bank fees, taxes, and other plan details." />
              </Typography>
            </>
          )}
        </Stack>
      )}
    </>
  )
}

export { YourCostShareInformational }
