// eslint-disable
import React, { useState, useEffect } from "react"
import { useStateMachine } from "#/components/context/stateMachine/StateMachineContext"
import { Steps } from "#/components/cmd-enrollment/workflow/steps"
import { Container } from "@carrotfertility/carotene"
import PlanPeriodWarning from "#/components/cmd-enrollment/shared/PlanPeriodWarning"
import { BenefitEnrollmentFlowModalBackButton, BenefitEnrollmentModalContinueButton } from "../../shared"
import moment from "moment"
import useSubmitWithErrorCatch from "../../hooks/useSubmitWithErrorCatch"
import { useCurrentUser } from "../../../context/user/UserContext"
import { CMD_CONFIGURATION, useUserDeductible } from "#/components/context/user/DeductibleContext"
import { useBenefitEnrollment } from "#/components/context/enrollment/BenefitEnrollmentContext"
import { FormProvider, useForm } from "react-hook-form"
import { dateRangeContainsLeapDay, DAYS_IN_YEAR, DAYS_IN_LEAP_YEAR } from "#/components/cmd-enrollment/util/date-utils"
import styled from "styled-components"

import { Box, DatePicker, Typography, dayjs } from "@carrotfertility/carotene-core"
import { PiiText } from "#/services/tracking"
import { FormattedMessage, useIntl } from "react-intl"
import { GetWorkflow } from "../BenefitEnrollmentFlow"
import { Workflows } from "#/components/cmd-enrollment/workflow/workflows"
import { useProgressTracker } from "../../../views/modal/ProgressTracker"

const MOMENT_FORMAT = "YYYY-MM-DD"

const BoldSpan = styled.span`
  font-weight: 700;
`
interface CoverageDateStepProps {
  title: string
  description: string
  stepName: string
  isMember?: boolean
  initialValue: string | null
  periodLengthWarningDescription: string
  coverageRangeReminder?: string
}

function CoverageDateStep({
  title,
  description,
  stepName,
  isMember,
  initialValue,
  periodLengthWarningDescription,
  coverageRangeReminder
}: CoverageDateStepProps): JSX.Element {
  const formMethods = useForm()
  const { setError, clearErrors, handleSubmit } = formMethods

  const { showProgress } = useProgressTracker()
  useEffect(showProgress, [showProgress])

  const coverageStartDateKey = isMember ? "memberCoverageStartDate" : "partnerCoverageStartDate"
  const coverageEndDateKey = isMember ? "memberCoverageEndDate" : "partnerCoverageEndDate"

  const { setNextStep, gotoPreviousStep } = useStateMachine(stepName, initialValue)
  const { updateBenefitEnrollmentAnswers } = useUserDeductible()

  const defaultStartDate = dayjs(getJanuaryFirstOfCurrentYear())
  const defaultEndDate = dayjs(getDecemberThirtyFirstOfCurrentYear())

  const intl = useIntl()

  const [selectedStartDate, setSelectedStartDate] = useState(defaultStartDate)
  const [selectedEndDate, setSelectedEndDate] = useState(defaultEndDate)
  const [isStartDateError, setStartDateIsError] = useState(Boolean)
  const [isEndDateError, setEndDateIsError] = useState(Boolean)
  const [startDateHelperText, setStartDateHelperText] = useState("")
  const [endDateHelperText, setEndDateHelperText] = useState("")
  const [warnUserForUnusualPlanPeriod, setWarnUserForUnusualPlanPeriod] = useState(false)
  const [daysInRange, setDaysInRange] = useState(0)

  isStartDateError &&
    setError("coverageDateStart", {
      type: "manual",
      message: intl.formatMessage({ defaultMessage: "Start date can't be in the future" })
    })
  isEndDateError &&
    setError("coverageDateEnd", {
      type: "manual",
      message: intl.formatMessage({ defaultMessage: "End date must be in the future" })
    })

  const { onSubmit } = useSubmitWithErrorCatch(async () => {
    if (!isStartDateError && !isEndDateError) {
      await updateBenefitEnrollmentAnswers({
        [coverageStartDateKey]: selectedStartDate.format(MOMENT_FORMAT),
        [coverageEndDateKey]: selectedEndDate.format(MOMENT_FORMAT)
      })
      setNextStep(stepName, "")
    }
  })

  useEffect(() => {
    const handleWarnUserForUnusualPlanPeriod = () => {
      const daysInRange = selectedEndDate.diff(selectedStartDate, "day")
      setDaysInRange(daysInRange)
      const shouldWarnUser =
        daysInRange >
        (dateRangeContainsLeapDay(selectedStartDate.toString(), selectedEndDate.toString())
          ? DAYS_IN_LEAP_YEAR
          : DAYS_IN_YEAR)

      setWarnUserForUnusualPlanPeriod(shouldWarnUser)
    }

    handleWarnUserForUnusualPlanPeriod()
  }, [selectedStartDate, selectedEndDate])

  const handleStartDateChange = (newDate: any) => {
    setStartDateIsError(false)
    setStartDateHelperText(null)
    if (newDate.isAfter(moment().utc().add(1, "day"))) {
      setStartDateIsError(true)
      setStartDateHelperText(intl.formatMessage({ defaultMessage: "Start date can't be in the future" }))
    }
    clearErrors("coverageDateStart")
    setSelectedStartDate(newDate)
  }

  const handleEndDateChange = (newDate: any) => {
    setEndDateIsError(false)
    setEndDateHelperText(null)
    if (newDate.isBefore(moment().utc().add(1, "day"))) {
      setEndDateIsError(true)
      setEndDateHelperText(intl.formatMessage({ defaultMessage: "End date must be in the future" }))
    }
    clearErrors("coverageDateEnd")
    setSelectedEndDate(newDate)
  }

  function getJanuaryFirstOfCurrentYear(): Date {
    const currentYear = new Date().getFullYear()
    return new Date(currentYear, 0, 1)
  }

  function getDecemberThirtyFirstOfCurrentYear(): Date {
    const currentYear = new Date().getFullYear()
    return new Date(currentYear, 11, 31)
  }

  return (
    <FormProvider {...formMethods}>
      <Container stack="huge">
        <BenefitEnrollmentFlowModalBackButton handleGoBack={gotoPreviousStep} />
      </Container>
      <Typography
        id="step-heading"
        variant="h2"
        color={(theme) => theme.palette.text.primary}
        paddingBottom={(theme) => theme.tokens.spacing.sm}
      >
        {title}
      </Typography>
      <Container stack="huge">
        <Typography component={PiiText}>{description}</Typography>
      </Container>
      {coverageRangeReminder && (
        <Container stack="huge">
          <Typography color={(theme) => theme.palette.text.secondary}>
            <BoldSpan className="bold">
              <FormattedMessage defaultMessage="Note: " />
            </BoldSpan>
            {coverageRangeReminder}
          </Typography>
        </Container>
      )}

      <DatePicker
        defaultValue={dayjs(getJanuaryFirstOfCurrentYear())}
        onChange={handleStartDateChange}
        slotProps={{
          textField: {
            label: "Coverage Start Date",
            helperText: startDateHelperText,
            error: isStartDateError,
            required: true
          }
        }}
      />
      <Box height="2rem" />
      <DatePicker
        defaultValue={dayjs(getDecemberThirtyFirstOfCurrentYear())}
        onChange={handleEndDateChange}
        slotProps={{
          textField: {
            label: "Coverage End Date",
            helperText: endDateHelperText,
            error: isEndDateError,
            required: true
          }
        }}
      />
      <Box height="2rem" />
      {warnUserForUnusualPlanPeriod && (
        <PlanPeriodWarning
          warningDescription={periodLengthWarningDescription}
          warningTitle={intl.formatMessage(
            {
              defaultMessage: "The period you selected is over a year ({daysInRange} days)"
            },
            { daysInRange: daysInRange }
          )}
        />
      )}
      <Box height="1rem" />
      <BenefitEnrollmentModalContinueButton onClick={handleSubmit(onSubmit)} />
    </FormProvider>
  )
}

interface TextProps {
  title: string
  description: string
  periodLengthWarningDescription: string
  coverageRangeReminder?: string
}

export function MemberPlanCoverageDateStep(): JSX.Element {
  const {
    company: { companyDisplayName, medicalPlanStartDate, medicalPlanEndDate }
  } = useCurrentUser()
  const intl = useIntl()

  const { cmdConfigurationPreview } = useUserDeductible()
  let { shouldSkipMemberCoverageDate } = useBenefitEnrollment()
  const workflowType = GetWorkflow()

  if (workflowType === Workflows.CMD_NON_HDHP) {
    shouldSkipMemberCoverageDate = false
  }

  const collectDeductiblePeriod =
    workflowType === Workflows.CMD_NON_HDHP ||
    [
      CMD_CONFIGURATION.MEMBER_AND_PARTNER_SEPARATE,
      CMD_CONFIGURATION.MEMBER_AND_PARTNER_COMBINED,
      CMD_CONFIGURATION.MEMBER_ONLY
    ].includes(cmdConfigurationPreview)
  const textProps: TextProps = collectDeductiblePeriod
    ? {
        title: intl.formatMessage({
          defaultMessage: "When does the annual deductible period on your medical plan start and end?"
        }),
        description: intl.formatMessage({
          defaultMessage:
            "This often aligns with plan start and end dates, which may be listed on your insurance card. The accuracy of your answer can have financial implications, so if you're not sure, please check with the relevant insurance provider or HR department."
        }),
        periodLengthWarningDescription: intl.formatMessage({
          defaultMessage: "Please check your dates. Deductible periods are typically a year."
        }),
        coverageRangeReminder:
          medicalPlanStartDate &&
          medicalPlanEndDate &&
          intl.formatMessage(
            {
              defaultMessage:
                "If you are enrolled in {companyDisplayName}'s medical plan, your deductible period is {medicalPlanStartDate} to {medicalPlanEndDate}."
            },
            {
              companyDisplayName: companyDisplayName,
              medicalPlanStartDate: intl.formatDate(medicalPlanStartDate, {
                year: "numeric",
                month: "2-digit",
                day: "2-digit"
              }),
              medicalPlanEndDate: intl.formatDate(medicalPlanEndDate, {
                year: "numeric",
                month: "2-digit",
                day: "2-digit"
              })
            }
          )
      }
    : {
        title: intl.formatMessage({
          defaultMessage: "When does your medical plan coverage for this year start and end?"
        }),
        description: intl.formatMessage({
          defaultMessage:
            "These dates may be listed on your insurance card. The accuracy of your answer can have financial implications, so if you're not sure, please check with the relevant insurance provider or HR department."
        }),
        periodLengthWarningDescription: intl.formatMessage({
          defaultMessage: "Please check your dates. Plan periods are typically a year."
        }),
        coverageRangeReminder:
          medicalPlanStartDate &&
          medicalPlanEndDate &&
          intl.formatMessage(
            {
              defaultMessage:
                "If you are enrolled in {companyDisplayName}'s medical plan, your plan year is {medicalPlanStartDate} to {medicalPlanEndDate}."
            },
            {
              companyDisplayName: companyDisplayName,
              medicalPlanStartDate: intl.formatDate(medicalPlanStartDate, {
                year: "numeric",
                month: "2-digit",
                day: "2-digit"
              }),
              medicalPlanEndDate: intl.formatDate(medicalPlanEndDate, {
                year: "numeric",
                month: "2-digit",
                day: "2-digit"
              })
            }
          )
      }
  return (
    <CoverageDateStep
      {...textProps}
      initialValue={shouldSkipMemberCoverageDate ? "" : null}
      stepName={Steps.MEMBER_DEDUCTIBLE_PERIOD_COVERAGE_DATE}
      isMember
    />
  )
}

export function PartnerPlanCoverageDateStep(): JSX.Element {
  const { cmdConfigurationPreview } = useUserDeductible()
  let { shouldSkipPartnerCoverageDate } = useBenefitEnrollment()
  const intl = useIntl()
  const workflowType = GetWorkflow()
  if (workflowType === Workflows.CMD_NON_HDHP) {
    shouldSkipPartnerCoverageDate = false
  }

  const collectDeductiblePeriod = [
    CMD_CONFIGURATION.MEMBER_AND_PARTNER_SEPARATE,
    CMD_CONFIGURATION.PARTNER_ONLY
  ].includes(cmdConfigurationPreview)

  const textProps = collectDeductiblePeriod
    ? {
        title: intl.formatMessage({
          defaultMessage: "When does the annual deductible period on your partner's medical plan start and end?"
        }),
        description: intl.formatMessage({
          defaultMessage:
            "This often aligns with coverage start and end dates, which may be listed on their insurance card. The accuracy of your answer may have financial implications, so if you're not sure, please check with the relevant insurance provider or HR department."
        }),
        periodLengthWarningDescription: intl.formatMessage({
          defaultMessage: "Please check your dates. Plan periods are typically a year."
        })
      }
    : {
        title: intl.formatMessage({
          defaultMessage: "When does your partner's medical plan coverage for this year start and end?"
        }),
        description: intl.formatMessage({
          defaultMessage:
            "These dates may be listed on their insurance card. The accuracy of your answer may have financial implications, so if you're not sure, please check with the relevant insurance provider or HR department."
        }),
        periodLengthWarningDescription: intl.formatMessage({
          defaultMessage: "Please check your dates. Plan periods are typically a year."
        })
      }
  return (
    <CoverageDateStep
      {...textProps}
      initialValue={shouldSkipPartnerCoverageDate ? "" : null}
      stepName={Steps.PARTNER_DEDUCTIBLE_PERIOD_COVERAGE_DATE}
    />
  )
}
