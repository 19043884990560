import { Box, BoxProps, Divider, Typography, TypographyProps, useTheme } from "@carrotfertility/carotene-core"
import { useFlags } from "launchdarkly-react-client-sdk"
import React from "react"

interface PageHeaderProps extends Omit<TypographyProps, "children"> {
  pageTitle: TypographyProps["children"]
  startGlyph?: React.ReactNode
  endSlot?: React.ReactNode
}

export function PageHeaderGlyph(props: BoxProps<"img">) {
  return <Box alt="" component="img" height="2.25rem" width="2.25rem" {...props} />
}

export function PageBreadcrumbHeader({
  breadcrumbs,
  search = null
}: {
  breadcrumbs: React.ReactNode
  search?: React.ReactNode
}) {
  const { showCarrotAcademySearch } = useFlags()
  return (
    <div>
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        flexWrap="wrap"
        padding={(theme) => theme.spacing(theme.tokens.spacing.md, 0, theme.tokens.spacing.xs)}
      >
        {breadcrumbs}
        {search && showCarrotAcademySearch ? (
          <Box sx={(theme) => ({ marginBlockStart: theme.spacing(theme.tokens.spacing.xxs) })}>{search} </Box>
        ) : null}
      </Box>
      <Divider />
    </div>
  )
}

export function PageHeader({ pageTitle, startGlyph, endSlot, ...otherProps }: PageHeaderProps) {
  const theme = useTheme()
  return (
    <div>
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        flexWrap="wrap"
        gap={theme.spacing(theme.tokens.spacing.md)}
        padding={(theme) => theme.spacing(theme.tokens.spacing.md, 0, theme.tokens.spacing.xs)}
      >
        <Box
          flexWrap="wrap"
          display="flex"
          flexDirection="row"
          alignItems="center"
          gap={theme.spacing(theme.tokens.spacing.md)}
        >
          {startGlyph}
          <Typography variant="h1" {...otherProps}>
            {pageTitle}
          </Typography>
        </Box>
        {endSlot}
      </Box>
      <Divider />
    </div>
  )
}
