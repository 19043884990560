type Preprocessor = (source: string) => string

export function preprocessNewLinesAndWhitespacesInCustomComponents(source: string): string {
  /*
   In related to a known markdown-to-jsx gotcha
   (https://github.com/quantizor/markdown-to-jsx/blob/main/README.md#significant-indentation-inside-arbitrary-html),
   how new lines are parsed in markdown-to-jsx was changed in v7.1.6 (https://github.com/quantizor/markdown-to-jsx/releases/tag/7.1.6),
   This preprocessor removes all new lines and whitespaces between the html tag `>` of custom Markdown elements and
   the subsequent heading or paragraph, if there are any new lines, to adhere to the standard HTML tag in Markdown behavior.
   */
  return source.replace(
    /(<([\w-]+)[^>]*>)\s*((?:(?!- |\d+\.\s|> |<\/\2>).)*?\s*<\/\2>)/gsu,
    (_match, openingTag, _tagName, contentAndClosingTag) => {
      return `${openingTag}${contentAndClosingTag}`
    }
  )
}

const defaultPreprocessors: Preprocessor[] = [preprocessNewLinesAndWhitespacesInCustomComponents]

export function preprocess(source?: string, preprocessor = defaultPreprocessors): string {
  if (!source || source === "") {
    return null
  }

  return preprocessor.reduce((processedSource, preprocessor) => preprocessor(processedSource), source)
}
