import { useQuery } from "@tanstack/react-query"
import buildCarrotApiRetryPolicy from "#/utils/CarrotApiRetryPolicy"
import ResponseError from "#/types/responseError"
import { reportErrorMessage } from "#/utils/ErrorReporting"
import { getCareNavTileDisplay } from "#/lib/contentful/contentfulClient"
import { TypeTileDisplaySkeleton } from "#/types/contentful"
import { EntryWithoutUnresolvableLinks } from "#/utils/ContentTypes"

const careNavigationTileDisplayQueryKey = "getCareNavigationTileDisplay"

export function useGetCareNavigationTileDisplay(locale: string) {
  return useQuery<EntryWithoutUnresolvableLinks<TypeTileDisplaySkeleton>, ResponseError>(
    [careNavigationTileDisplayQueryKey],
    () => getCareNavTileDisplay(locale),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      refetchOnReconnect: false,
      retry: buildCarrotApiRetryPolicy(3),
      onError: (error) => reportErrorMessage(`Failed to fetch care navigation tile with error: ${error}`)
    }
  )
}
