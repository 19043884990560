import { Box, Button, Stack, Typography, useTheme } from "@carrotfertility/carotene-core"
import React from "react"
import { FormattedMessage, useIntl } from "react-intl"
import CarrotLogo from "../resources/images/carrot-logo.svg"
import CirclePattern from "../resources/images/circles-pattern.svg"
import { useUserRole } from "#/components/partner-access/hooks/usePartnerAccess"
import { useDigitalFrontDoorBreakpointViews } from "../hooks/useDigitalFrontDoorBreakpointViews"
export function Footer() {
  const { isPartnerAccessingAccount } = useUserRole()

  const theme = useTheme()
  const { isMobileView } = useDigitalFrontDoorBreakpointViews()
  const responsiveHeight = isMobileView ? "19.5rem" : "28rem"
  const intl = useIntl()

  const handleOnclick = () => {
    const baseUrl = window.location.origin
    const messagePath = isPartnerAccessingAccount ? "talk-to-carrot/care-navigation/send-a-message" : "messages"
    const absoluteUrl = `${baseUrl}/${messagePath}`
    window.open(absoluteUrl, "_blank")
  }

  return (
    <Box
      sx={{
        background: theme.palette.text.secondary
      }}
      width="100%"
      minHeight={responsiveHeight}
    >
      <Stack
        direction={"row"}
        alignItems={"flex-start"}
        width="100%"
        justifyContent={"space-between"}
        gap={(theme) => theme.tokens.spacing.md}
      >
        <Stack
          direction={"column"}
          paddingTop={isMobileView ? (theme) => theme.tokens.spacing.xxxl : "6.25rem"}
          paddingLeft={isMobileView ? (theme) => theme.tokens.spacing.lg : "7.5rem"}
          paddingBottom={isMobileView ? (theme) => theme.tokens.spacing.xxxl : "4.25rem"}
          paddingRight={isMobileView ? (theme) => theme.tokens.spacing.lg : "7.5rem"}
          gap={(theme) => theme.tokens.spacing.xl}
        >
          <Box
            component="img"
            src={CarrotLogo}
            color={"white"}
            width={"10rem"}
            height={"1.5rem"}
            aria-label={intl.formatMessage({ defaultMessage: "Carrot's logo" })}
          />
          <Typography
            color={theme.palette.secondary.contrastText}
            variant="body1"
            fontSize={isMobileView ? "0.813rem" : "1.25rem"}
            minWidth={isMobileView ? "auto" : "32rem"}
          >
            <FormattedMessage
              defaultMessage="If you have questions or concerns, send a message to connect with your Carrot Care Team or call Alto Pharmacy at <b>(844) 725-0001</b>"
              values={{
                b: (boldContent) => (
                  <Typography
                    component="span"
                    color={theme.palette.secondary.contrastText}
                    fontWeight="bold"
                    fontSize={"inherit"}
                  >
                    {boldContent}
                  </Typography>
                )
              }}
            />
          </Typography>
          <Button
            id="digital-front-door-send-a-message-button"
            onClick={handleOnclick}
            variant="outlined"
            fullWidth={false}
            sx={{
              inlineSize: "fit-content",
              background: (theme) => theme.palette.background.paper,
              color: (theme) => theme.palette.text.primary
            }}
            aria-label={intl.formatMessage({ defaultMessage: "Send message to Carrot Care Team" })}
          >
            <FormattedMessage defaultMessage="Send a message" />
          </Button>
        </Stack>

        {!isMobileView && <Box component={"img"} src={CirclePattern} alt="" />}
      </Stack>
    </Box>
  )
}
