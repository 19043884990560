import React, { FC } from "react"
import { CarrotCardModuleSharedProps } from "#/components/views/molecules/CarrotCardModuleContainer"
import { FormattedMessage } from "react-intl"
import { Button, InternalIcon, Link } from "@carrotfertility/carotene-core"
import { DbgPaths } from "#/utils/Paths"
import CardStates from "#/utils/CardStates"
import { CarrotCardModuleTemplate } from "./carrot-card-module-template"

const CarrotCardLockedModule: FC<CarrotCardModuleSharedProps> = ({ isVirtualCard }) => {
  const links = [
    <Link
      key="carrot-card-how-to"
      endIcon={<InternalIcon fontSize="small" color="primary" />}
      href={DbgPaths.CARROT_CARD}
    >
      <FormattedMessage defaultMessage="How to use your Carrot Card" />
    </Link>
  ]

  if (!isVirtualCard) {
    links.push(
      <Link
        key="carrot-card-stolen"
        endIcon={<InternalIcon fontSize="small" color="primary" />}
        href="/talk-to-carrot/sendmessage/stolen"
      >
        <FormattedMessage defaultMessage="Report lost or stolen card" />
      </Link>
    )
  }

  links.push(
    <Button
      key="carrot-card-locked-card-support"
      href="/talk-to-carrot/sendmessage/lockedcardsupport"
      variant="outlined"
      color="secondary"
      sx={(theme) => ({ marginBlockStart: theme.tokens.spacing.md })}
    >
      <FormattedMessage defaultMessage="Contact Care Team" />
    </Button>
  )

  return (
    <CarrotCardModuleTemplate
      cardState={CardStates.LOCKED}
      body={
        isVirtualCard ? (
          <FormattedMessage defaultMessage="Your virtual card has been locked. Check your email for details or contact us for further assistance." />
        ) : (
          <FormattedMessage defaultMessage="Your card has been locked. Check your email for details or contact us for further assistance." />
        )
      }
      links={links}
    />
  )
}

export { CarrotCardLockedModule }
