import { Body, Container, FlexContainer, H2, Stack, Text, TextLink } from "@carrotfertility/carotene"
import React, { useCallback, useEffect, useRef } from "react"
import { Message, ThreadMessages } from "./inAppMessagingTypes"
import { CenteredLoadingIndicator } from "../views/molecules/Molecules"
import { reportError } from "../../utils/ErrorReporting"
import useGetThreadMessages from "./hooks/useGetThreadMessages"
import { DisclaimerMessage } from "./MessagingDisclaimer"
import useMarkThreadAsRead from "./hooks/useMarkThreadAsRead"
import { useInAppMessaging } from "../context/messaging/InAppMessagingContext"
import { ClosedThreadMessage } from "./ClosedThreadMessage"
import { getParsedHtmlElements } from "./utils"
import { FormattedTime, FormattedMessage, useIntl } from "react-intl"
import { getPiiProps } from "#/services/tracking"
import { Box, Typography, useTheme } from "@carrotfertility/carotene-core"
import { EmployeeSupportUrl } from "../../utils/EmployeeSupportLink"

type MessageReplyBubbleProps = {
  message: Message
}

function MessageReplyBubble({ message }: MessageReplyBubbleProps): JSX.Element {
  const theme = useTheme()
  const fixBulletStyle = {
    ul: {
      listStyle: "none" /* Remove default bullets */,
      paddingLeft: "1em",
      "li::before": {
        color: "black80"
      }
    }
  }

  return (
    <FlexContainer
      direction="column"
      alignItems="start"
      padding="24px 20px"
      gap="16px"
      background={message.authoredBy === "CARROT" ? "lightPampas" : "rgba(224, 232, 234, 0.25)"}
      borderRadius="8px"
      stack="small"
    >
      <Stack direction="row" spacing="tiny" alignItems="center">
        {message.authoredBy === "CARROT" ? (
          <Typography variant="body1" fontWeight="bold" color={theme.palette.primary.dark}>
            <FormattedMessage defaultMessage="Care Team" />
          </Typography>
        ) : (
          <Typography variant="body1" fontWeight="bold" color={theme.palette.text.secondary}>
            <FormattedMessage defaultMessage="You" />
          </Typography>
        )}
        <Typography variant="body1" color={theme.palette.text.tertiary}>
          <FormattedTime value={message.createdAt} />
        </Typography>
      </Stack>
      <Box
        {...getPiiProps()}
        padding="24px 20px"
        flexDirection="column"
        gap={theme.spacing(theme.tokens.spacing.md)}
        sx={{
          fixBulletStyle,
          whiteSpace: "pre-line",
          lineHeight: theme.typography.body1.lineHeight,
          wordBreak: "break-word",
          ...theme.typography.body1
        }}
      >
        {getParsedHtmlElements(message.htmlBody)}
      </Box>
    </FlexContainer>
  )
}
export function MessagingErrorContainer(): JSX.Element {
  return (
    <Container background={"lightGrey"} borderRadius={"8px"} padding={"48px"} width={"100%"}>
      <Stack direction={"column"} spacing={"small"}>
        <H2>
          <FormattedMessage defaultMessage="Something's not working" />
        </H2>
        <Body size={"small"} color="black-80">
          <FormattedMessage
            defaultMessage="Try reloading this page. If the problem persists, feel free to <link>contact us</link>."
            values={{
              link: (linkContent) => (
                <TextLink to={EmployeeSupportUrl} target={"_blank"}>
                  {linkContent}
                </TextLink>
              )
            }}
          />
        </Body>
      </Stack>
    </Container>
  )
}

export function ThreadMessagesContainer(): JSX.Element {
  const intl = useIntl()
  const { threadUpdateInFlight, selectedThread, threadIdForFailedUpdate } = useInAppMessaging()
  const {
    isLoading: isLoadingMessages,
    error,
    data,
    isSuccess: messagesFetchedSuccessfully
  } = useGetThreadMessages(selectedThread?.threadId)
  const { mutate: markThreadAsRead } = useMarkThreadAsRead()
  const theme = useTheme()

  useEffect(() => {
    if (selectedThread?.hasUnreadMessages && !isLoadingMessages && messagesFetchedSuccessfully) {
      markThreadAsRead(selectedThread?.threadId)
    }
  }, [
    selectedThread?.hasUnreadMessages,
    messagesFetchedSuccessfully,
    isLoadingMessages,
    selectedThread?.threadId,
    markThreadAsRead
  ])

  const getMappedMessageBubbles = useCallback(
    (data: ThreadMessages) => {
      // @ts-expect-error TS(7034) FIXME: Variable 'lastUsedDate' implicitly has type 'any' ... Remove this comment to see the full error message
      let lastUsedDate = null
      // @ts-expect-error TS(7006) FIXME: Parameter 'lastUsedDate' implicitly has an 'any' t... Remove this comment to see the full error message
      function dateHelper(lastUsedDate, currentDate): string | null {
        if (lastUsedDate === currentDate) return null
        if (currentDate === intl.formatDate(new Date(), { month: "short", day: "numeric" })) {
          return intl.formatRelativeTime(0, "day", { numeric: "auto" })
        } else {
          return currentDate
        }
      }
      return [...data.messages].reverse().map((message, idx) => {
        const currentDate = intl.formatDate(message.createdAt, { month: "short", day: "numeric" })
        // @ts-expect-error TS(7005) FIXME: Variable 'lastUsedDate' implicitly has an 'any' ty... Remove this comment to see the full error message
        const dateToDisplay = dateHelper(lastUsedDate, currentDate)
        if (dateToDisplay) lastUsedDate = currentDate
        return (
          <React.Fragment key={idx}>
            <FlexContainer justifyContent="center" padding="0px 0px 16px 0px">
              <Typography variant="overline" color={theme.palette.text.tertiary}>
                {dateToDisplay}
              </Typography>
            </FlexContainer>
            <MessageReplyBubble message={message} />
          </React.Fragment>
        )
      })
    },
    [intl, theme]
  )

  const AlwaysScrollToBottom = (): JSX.Element => {
    const elementRef = useRef<HTMLDivElement>()
    useEffect(() => elementRef.current.scrollIntoView({ block: "nearest", inline: "start" }), [])
    return <div ref={elementRef} />
  }

  if (isLoadingMessages) {
    return <CenteredLoadingIndicator />
  }
  if (error) {
    reportError(error, {
      threadId: selectedThread?.threadId.toString()
    })
    return (
      <Container sx={{ marginInlineStart: "36px" }}>
        <MessagingErrorContainer />
      </Container>
    )
  }
  if (data) {
    return (
      <Container padding={"16px 16px 0px 16px"} stack="small">
        <DisclaimerMessage />
        {getMappedMessageBubbles(data)}
        {selectedThread?.isClosed && <ClosedThreadMessage />}
        {threadIdForFailedUpdate === selectedThread?.threadId && (
          <Text color="errorRed" as="p" size={"small"}>
            <FormattedMessage
              defaultMessage="Your message failed to send. Please try again or <link>contact us</link>."
              values={{
                link: (linkContent) => (
                  <TextLink color="errorRed" to={EmployeeSupportUrl} target={"_blank"}>
                    {linkContent}
                  </TextLink>
                )
              }}
            />
          </Text>
        )}
        {threadUpdateInFlight && <CenteredLoadingIndicator />}
        <AlwaysScrollToBottom />
      </Container>
    )
  }

  return null
}
