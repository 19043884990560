import React from "react"
import { GuideFrame } from "./components/GuideFrame"
import { NavFooter } from "./components/NavFooter"
import { useHistory, useParams } from "react-router"
import { Section } from "./utils/utils"
import { BackgroundWrapper } from "./components/BackgroundWrapper"
import { useIntl } from "react-intl"
import { Title } from "#/services/page-title/Title"

export const PageRouter = ({
  menu,
  onLastPageNext,
  onFirstPageBack,
  isLastSection,
  willShowSurvey,
  section,
  isFirstSection,
  sectionRootPath,
  guideSlugId,
  guideTitle
}: {
  menu: JSX.Element
  onLastPageNext: () => void
  onFirstPageBack: () => void
  isLastSection: boolean
  willShowSurvey: boolean
  section: Section
  isFirstSection: boolean
  sectionRootPath: string
  guideSlugId: string
  guideTitle: string
}): JSX.Element => {
  const history = useHistory()
  const { pageNumber } = useParams<{ pageNumber: string }>()
  const intl = useIntl()

  const currentPageNumber = parseInt(pageNumber, 10)
  if (isNaN(Number(pageNumber)) || currentPageNumber > section.pages.length) {
    history.replace(`${sectionRootPath}/1`)
    return null
  }
  const onNext = (e: React.MouseEvent<HTMLElement>): void => {
    e.preventDefault()
    if (currentPageNumber >= section.pages.length) {
      onLastPageNext()
    } else {
      history.push(`${sectionRootPath}/${currentPageNumber + 1}`)
    }
  }
  const onBack = (e: React.MouseEvent<HTMLElement>): void => {
    e.preventDefault()
    if (currentPageNumber === 1) {
      onFirstPageBack()
    } else {
      history.push(`${sectionRootPath}/${currentPageNumber - 1}`)
    }
  }

  const isFirstPage = currentPageNumber === 1 && isFirstSection

  const nextText =
    isLastSection && !willShowSurvey && currentPageNumber === section.pages.length
      ? intl.formatMessage({ defaultMessage: "Finish" })
      : intl.formatMessage({ defaultMessage: "Next" })

  return (
    <BackgroundWrapper guideSlugId={guideSlugId}>
      <Title title={guideTitle} productTitle="Carrot Academy" />

      <GuideFrame menu={menu} section={section} pageIndex={currentPageNumber - 1} />
      <NavFooter
        onBack={onBack}
        onNext={onNext}
        isFirstPage={isFirstPage}
        nextText={nextText}
        navDataAttributes={{
          slug: section.slug,
          pageIndex: currentPageNumber,
          sectionTotalPages: section.pages.length
        }}
      />
    </BackgroundWrapper>
  )
}
