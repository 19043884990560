import { TileOrPhoneSupportActions } from "#/components/dynamic-content/util/dynamicContent"
import React from "react"
import { getLanguageName, getPathForLanguage, getPathForTile, getTileTitle } from "../utils/mappingUtils"
import { Breadcrumb, BreadcrumbProps } from "@carrotfertility/carotene-core"
import { EntryWithoutUnresolvableLinks } from "#/utils/ContentTypes"
import { TypeTalkToCarrotLanguageSkeleton, TypeTileSkeleton } from "#/types/contentful"

type PageBreadcrumbProps = {
  tile?: EntryWithoutUnresolvableLinks<TypeTileSkeleton>
  language?: EntryWithoutUnresolvableLinks<TypeTalkToCarrotLanguageSkeleton>
  baseUrl: string
  actions: TileOrPhoneSupportActions
  isCurrentPage?: boolean
}

const PageBreadcrumb = ({ tile, language, baseUrl, actions, isCurrentPage = false }: PageBreadcrumbProps) => {
  const breadcrumbText = tile ? getTileTitle(tile) : getLanguageName(language)
  const onClick = tile
    ? () => actions.gotoPageAction(getPathForTile({ tile, baseUrl }))
    : () => actions.gotoPageAction(getPathForLanguage({ language, baseUrl }))
  const fallbackText = "unknown"
  const breadcrumbProps = isCurrentPage
    ? { currentPage: true }
    : ({ component: "button", onClick: onClick } as BreadcrumbProps<"button">)

  return <Breadcrumb {...breadcrumbProps}>{breadcrumbText || fallbackText}</Breadcrumb>
}

export default PageBreadcrumb
