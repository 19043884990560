import React, { useEffect } from "react"
import { Button, DialogContentText } from "@carrotfertility/carotene-core"
import { FormattedMessage, useIntl } from "react-intl"
import { ConfirmationDialog } from "@carrotfertility/carotene-core-x"
import { promptPushPermissions } from "#/lib/carrot-api/MobileNativeClient"
import { getHeap } from "#/utils/heap"
import { updatePushPermissionsModalSelection, PushModalValues } from "./utils/localStorageUtils"
import useUpdatePushNotificationsOptIn from "../account/hooks/usePushNotifications"

export function PushNotificationsSettingDialog({ initialOpen }: { initialOpen: boolean }): JSX.Element {
  const intl = useIntl()
  const updatePushNotificationsOptInSetting = useUpdatePushNotificationsOptIn()

  const [open, setOpen] = React.useState(initialOpen)

  useEffect(() => {
    if (initialOpen) {
      getHeap().track("Push notification setting dialog rendered")
    }
  }, [initialOpen])

  function handleClose() {
    updatePushPermissionsModalSelection(PushModalValues.NotNow)
    setOpen(false)
  }

  const handlePrimaryAction = async () => {
    await updatePushNotificationsOptInSetting(true)
    updatePushPermissionsModalSelection(PushModalValues.TurnOn)
    setOpen(false)
    await promptPushPermissions()
  }

  return (
    <ConfirmationDialog
      aria-labelledby="push-notifications-dialog"
      onClose={handleClose}
      open={open}
      title={intl.formatMessage({ defaultMessage: "Make the most of your benefit" })}
      primaryAction={
        <Button onClick={handlePrimaryAction} data-button-id="push-notifications-dialog-turn-on-button">
          <FormattedMessage defaultMessage="Turn on" />
        </Button>
      }
      secondaryAction={
        <Button
          color="secondary"
          variant="outlined"
          onClick={handleClose}
          data-button-id="push-notifications-dialog-not-now-button"
        >
          <FormattedMessage defaultMessage="Not now" />
        </Button>
      }
    >
      <DialogContentText>
        <FormattedMessage defaultMessage="Turn on push notifications to receive occasional benefit updates such as new resources, tips, reminders, and more." />
      </DialogContentText>
    </ConfirmationDialog>
  )
}
