import React, { useEffect } from "react"
import { Container, ContainerProps, FlexContainer } from "@carrotfertility/carotene"
import { useStateMachine } from "../../../context/stateMachine/StateMachineContext"
import { Steps } from "../../workflow/steps"
import { BenefitEnrollmentModalContinueButton } from "../../shared"
import { useUserDeductible } from "../../../context/user/DeductibleContext"
import styled from "styled-components"
import { useCurrentUser } from "../../../context/user/UserContext"
import { DbgPaths } from "../../../../utils/Paths"
import { Link, Typography } from "@carrotfertility/carotene-core"
import { FormattedMessage, useIntl, defineMessage } from "react-intl"
import { useProgressTracker } from "../../../views/modal/ProgressTracker"

const DeductibleTable = (props: ContainerProps): JSX.Element => (
  <Container
    stack="medium"
    background="#faf9f7"
    borderRadius="8px"
    padding={["24px", null, "24px 116px 24px 24px"]}
    {...props}
  />
)

const Divider = styled.hr`
  block-size: 1px;
  inline-size: 100%;
  background: #cccccc;
  border: none;
`
const carrotCardTextFragment = defineMessage({
  defaultMessage: ", and you'll have the option to pay directly from your account with a Carrot Card",
  description:
    "this is a potential fragment of a larger sentence that would read: 'Once it’s met, your qualified medical expenses (QMEs) will be eligible for reimbursement{insert_fragment_here}.'"
})

function DeductibleExplanation(deductibleInfoText: string): JSX.Element {
  return (
    <>
      <Container padding="none" stack="medium">
        <Typography color={(theme) => theme.palette.text.secondary}>{deductibleInfoText}</Typography>
      </Container>
      <Container padding="none" stack="medium">
        <Typography color={(theme) => theme.palette.text.secondary}>
          <Link color={"inherit"} href={DbgPaths.QUALIFIED_MEDICAL_EXPENSES} target="_blank">
            <FormattedMessage defaultMessage="Learn about QMEs" />
          </Link>
        </Typography>
      </Container>
    </>
  )
}

export function MemberOnlyOrCombinedDeductibleStatusStep(): JSX.Element {
  const { setNextStep } = useStateMachine(Steps.MEMBER_ONLY_OR_COMBINED_DEDUCTIBLE_STATUS, null)
  const { deductible } = useUserDeductible()
  const { isCarrotCardEligible } = useCurrentUser()
  const intl = useIntl()
  const additionalCarrotCardText = isCarrotCardEligible ? intl.formatMessage(carrotCardTextFragment) : ""
  const deductibleInfoText = intl.formatMessage(
    {
      defaultMessage:
        "Once it’s met, your qualified medical expenses (QMEs) will be eligible for reimbursement{additionalCarrotCardText}. Other types of expenses are always eligible for reimbursement."
    },
    { additionalCarrotCardText: additionalCarrotCardText }
  )

  return (
    <div>
      <Typography
        id="step-heading"
        variant="h2"
        color={(theme) => theme.palette.text.primary}
        paddingBottom={(theme) => theme.tokens.spacing.lg}
      >
        <FormattedMessage defaultMessage="Based on what you’ve told us, it looks like you have a deductible through Carrot." />
      </Typography>
      <DeductibleTable>
        <FlexContainer justifyContent="space-between">
          <Typography color={"black-80"}>
            <FormattedMessage defaultMessage="Your annual deductible" />
          </Typography>
          <Typography color={(theme) => theme.palette.text.secondary}>
            {intl.formatNumber(deductible.deductibleSnapshot?.totalAmount / 100, {
              style: "currency",
              currency: "USD",
              currencyDisplay: "narrowSymbol",
              maximumFractionDigits: 0
            })}
          </Typography>
        </FlexContainer>
      </DeductibleTable>
      {DeductibleExplanation(deductibleInfoText)}
      <BenefitEnrollmentModalContinueButton
        onClick={() => setNextStep(Steps.MEMBER_ONLY_OR_COMBINED_DEDUCTIBLE_STATUS, "")}
      />
    </div>
  )
}

export function PartnerOnlyDeductibleStatusStep(): JSX.Element {
  const { setNextStep } = useStateMachine(Steps.PARTNER_ONLY_DEDUCTIBLE_STATUS, null)
  const { partnerDeductible } = useUserDeductible()
  const { isCarrotCardEligible } = useCurrentUser()
  const intl = useIntl()

  const { showProgress } = useProgressTracker()
  useEffect(showProgress, [showProgress])

  const additionalCarrotCardText = isCarrotCardEligible ? intl.formatMessage(carrotCardTextFragment) : ""
  const deductibleInfoText = intl.formatMessage(
    {
      defaultMessage:
        "Once it’s met, your partner’s qualified medical expenses (QMEs) will be eligible for reimbursement{additionalCarrotCardText}. Other types of expenses are always eligible for reimbursement."
    },
    { additionalCarrotCardText: additionalCarrotCardText }
  )

  return (
    <div>
      <Typography
        id="step-heading"
        variant="h2"
        color={(theme) => theme.palette.text.primary}
        paddingBottom={(theme) => theme.tokens.spacing.lg}
      >
        <FormattedMessage defaultMessage="Based on what you’ve told us, it looks like your partner has a deductible through Carrot." />
      </Typography>
      <DeductibleTable>
        <FlexContainer justifyContent="space-between">
          <Typography color={(theme) => theme.palette.text.secondary}>
            <FormattedMessage defaultMessage="Your partner's annual deductible" />
          </Typography>
          <Typography color={(theme) => theme.palette.text.secondary}>
            {intl.formatNumber(partnerDeductible.deductibleSnapshot?.totalAmount / 100, {
              style: "currency",
              currency: "USD",
              currencyDisplay: "narrowSymbol",
              maximumFractionDigits: 0
            })}
          </Typography>
        </FlexContainer>
      </DeductibleTable>
      {DeductibleExplanation(deductibleInfoText)}
      <BenefitEnrollmentModalContinueButton onClick={() => setNextStep(Steps.PARTNER_ONLY_DEDUCTIBLE_STATUS, "")} />
    </div>
  )
}

export function MemberPartnerDeductibleStatusStep(): JSX.Element {
  const { setNextStep } = useStateMachine(Steps.MEMBER_PARTNER_DEDUCTIBLE_STATUS, null)
  const { deductible, partnerDeductible } = useUserDeductible()
  const { isCarrotCardEligible } = useCurrentUser()
  const intl = useIntl()
  const additionalCarrotCardText = isCarrotCardEligible ? intl.formatMessage(carrotCardTextFragment) : ""
  const deductibleInfoText = intl.formatMessage(
    {
      defaultMessage:
        "Once the deductibles are met, qualified medical expenses (QMEs) will be eligible for reimbursement{additionalCarrotCardText}. Other types of expenses are always eligible for reimbursement."
    },
    { additionalCarrotCardText: additionalCarrotCardText }
  )

  return (
    <div>
      <Typography
        id="step-heading"
        variant="h2"
        color={(theme) => theme.palette.text.primary}
        paddingBottom={(theme) => theme.tokens.spacing.lg}
      >
        <FormattedMessage defaultMessage="Based on what you’ve told us, it looks like you and your partner have deductibles through Carrot." />
      </Typography>
      <DeductibleTable stack="24px">
        <FlexContainer justifyContent="space-between">
          <Typography color={(theme) => theme.palette.text.secondary}>
            <FormattedMessage defaultMessage="Your annual deductible" />
          </Typography>
          <Typography color={(theme) => theme.palette.text.secondary}>
            {intl.formatNumber(deductible.deductibleSnapshot?.totalAmount / 100, {
              style: "currency",
              currency: "USD",
              currencyDisplay: "narrowSymbol",
              maximumFractionDigits: 0
            })}
          </Typography>
        </FlexContainer>
        <Divider />
        <FlexContainer justifyContent="space-between">
          <Typography color={(theme) => theme.palette.text.secondary}>
            <FormattedMessage defaultMessage="Your partner's annual deductible" />
          </Typography>
          <Typography color={(theme) => theme.palette.text.secondary}>
            {intl.formatNumber(partnerDeductible.deductibleSnapshot?.totalAmount / 100, {
              style: "currency",
              currency: "USD",
              currencyDisplay: "narrowSymbol",
              maximumFractionDigits: 0
            })}
          </Typography>
        </FlexContainer>
      </DeductibleTable>
      {DeductibleExplanation(deductibleInfoText)}
      <BenefitEnrollmentModalContinueButton onClick={() => setNextStep(Steps.MEMBER_PARTNER_DEDUCTIBLE_STATUS, "")} />
    </div>
  )
}
