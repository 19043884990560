import React from "react"
import { useStateMachine } from "../../../context/stateMachine/StateMachineV2"
import { Form } from "@carrotfertility/carotene-core-x"
import { useGetMedicalInfo, useMedicalInfo } from "../../context/MedicalInfoContext"
import { MenopauseDiagnosis } from "./MedicalInfoOptions"
import { FormattedMessage } from "react-intl"
import {
  StepLayout,
  ButtonFooterLayout,
  SkipButton,
  useConvertTranslatedLabel,
  ContinueButton
} from "#/components/carrot-plans/shared"
import { FormRadioButtonGroup } from "#/services/common-forms"

type FormValues = {
  menopauseDiagnosis: string
}

export default function MenopauseDiagnosisStep(): JSX.Element {
  const { updateMedicalInfo, hasQuestionBeenSkipped } = useMedicalInfo()
  const { data: medicalInfo } = useGetMedicalInfo()
  const defaultValue = medicalInfo?.menopauseDiagnosis

  const { send } = useStateMachine(hasQuestionBeenSkipped("menopauseDiagnosis") ? "NO" : defaultValue)

  const options = useConvertTranslatedLabel(MenopauseDiagnosis)

  async function onClickContinue(formValues: FormValues): Promise<void> {
    if (formValues?.menopauseDiagnosis) {
      await updateMedicalInfo(formValues)
      send(formValues?.menopauseDiagnosis)
    } else {
      await onSkip()
    }
  }

  async function onSkip(): Promise<void> {
    await updateMedicalInfo({ skipFields: ["menopauseDiagnosis"] })
    send("NO")
  }

  return (
    <StepLayout
      header={<FormattedMessage defaultMessage="Have you been diagnosed with menopause?" />}
      formElements={
        <Form
          defaultValues={{
            menopauseDiagnosis: defaultValue
          }}
          onSubmit={onClickContinue}
        >
          <FormRadioButtonGroup name="menopauseDiagnosis" options={options} />
          <ButtonFooterLayout
            continueButton={
              <ContinueButton>
                <FormattedMessage defaultMessage="Continue" />
              </ContinueButton>
            }
            skipButton={<SkipButton onClick={onSkip} />}
          />
        </Form>
      }
    />
  )
}
