import { Link, LinkProps } from "@carrotfertility/carotene-core"
import React, { FC } from "react"
import { getSupportCenterLink, goToHelpCenter } from "carrot-api/HelpCenterClient"

type ZendeskLinkProps = {
  supportCenterLink?: string
} & LinkProps

const ZendeskLink: FC<ZendeskLinkProps> = ({ supportCenterLink, ...props }: ZendeskLinkProps) => {
  let zdLinkFragment = supportCenterLink
  // Contentful zendesk links will be provided via the "to" and they will be full URLs to zendesk, so parse out the fragment
  // e.g. https://get-carrot.zendesk.com/hc/sections/4410088379931-Carrot-Plans
  if (props.href) {
    const path = props.href?.split("/")
    zdLinkFragment = path[path.length - 2] + "/" + path[path.length - 1]
  }

  return (
    <Link
      href={getSupportCenterLink(zdLinkFragment)}
      // @ts-expect-error TS(7006) FIXME: Parameter 'event' implicitly has an 'any' type.
      onClick={(event) => goToHelpCenter(event, zdLinkFragment)}
      target={"_blank"}
      {...props}
    >
      {props.children}
    </Link>
  )
}
export { ZendeskLink }
